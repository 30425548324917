import React, { useContext, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import styled from "styled-components";

import { Empty, ConfigProvider } from "antd";
import Loader from "components/ui/Loader";
import Search from "components/ui/Search";
import { contentRef } from "components/common/layouts/App";
import EmptyPageLayout from "components/common/EmptyPage";
import StyledTable from "components/ui/Table";
import { ListContext } from "modules/list/index";

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

export function List({ items, Item, isLoading, smallLoader, isInitializing }) {
  function renderItem(item, index) {
    return <Item data={item} key={index} />;
  }

  function renderLoader() {
    if (!isLoading) {
      return null;
    }

    return (
      <LoaderWrap>
        <Loader small={items.length || smallLoader} />
      </LoaderWrap>
    );
  }

  if (!Item) {
    return null;
  }
  if (isInitializing) {
    return <Loader small={smallLoader} />;
  }

  return (
    <>
      {items.map(renderItem)}
      {renderLoader()}
    </>
  );
}

export function LoadAsYouScroll({ children, watchElement = contentRef }) {
  const context = useContext(ListContext);
  const nextPage = context.nextPage;
  const onScroll = useCallback(() => {
    const element = watchElement.current;
    if (
      element.scrollHeight - parseInt(element.scrollTop) ===
      element.clientHeight
    ) {
      nextPage();
    }
  }, [nextPage, watchElement]);
  const throttledScroll = useMemo(() => {
    return _.throttle(onScroll, 200);
  }, [onScroll]);
  useEffect(() => {
    const element = watchElement.current;
    element.addEventListener("scroll", throttledScroll);
    return () => {
      element.removeEventListener("scroll", throttledScroll);
    };
  }, [throttledScroll, watchElement]);

  return children || null;
}

export function SearchBar({ changeQuery, filters }) {
  return (
    <Search
      value={filters.search}
      onChange={(ev) => changeQuery("search", ev.target.value)}
    />
  );
}

export function Table({
  items = [],
  hasLoader = true,
  isLoading,
  columns,
  slim,
  rowKey = (record) => record.guid,
  onRowClick = () => {},
  hasEmptyPage = false,
  noDataComponent,
  ...rest
}) {
  if (items.length === 0 && hasEmptyPage) {
    return null;
  }

  return (
    <ConfigProvider renderEmpty={noDataComponent && (() => noDataComponent)}>
      <StyledTable
        dataSource={items}
        // TODO: Maybe find a better solution for when table is loading data from polling
        loading={hasLoader && isLoading}
        rowKey={rowKey}
        pagination={false}
        columns={columns}
        slim={slim}
        selectableRow={onRowClick}
        onRow={(record) => {
          return {
            onClick: () => onRowClick(record),
          };
        }}
        {...rest}
      />
    </ConfigProvider>
  );
}

export function EmptyPage(props) {
  if (!props.isLoading && !props.items.length) {
    return <EmptyPageLayout {...props} />;
  }

  return null;
}

export function EmptyResult(props) {
  if (!props.isLoading && !props.items.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props} />;
  }
  return null;
}
