import React from "react";
import { Route as ReactRouterRoute } from "react-router-dom";

const PROJECT_PREFIX = "/projects/:projectUid";
const ADMIN_PREFIX = "/admin";

export default function Route({
  path,
  adminEnabled = false,
  adminOnly = false,
  ...rest
}) {
  let prefix = PROJECT_PREFIX;

  if (adminOnly) {
    prefix = ADMIN_PREFIX;
  }

  if (!adminEnabled || adminOnly) {
    return <ReactRouterRoute path={`${prefix}${path}`} {...rest} />;
  }

  return (
    <>
      <ReactRouterRoute path={`${PROJECT_PREFIX}${path}`} {...rest} />
      <ReactRouterRoute path={`${ADMIN_PREFIX}${path}`} {...rest} />
    </>
  );
}
