import { getEntity } from "utils/entities";
import {
  UserSchema,
  TeamSchema,
  ProjectSchema,
  RoleSchema,
  ProjectRolesSchema,
} from "utils/schemas";
import { projectRolesModal } from "state/roleManagement/actions/projectRoles";
import { createSelector } from "reselect";

const MODULE_MAPPING = {
  projectScopeRoles: "projectRoles",
  tenantScopeRoles: "tenantRoles",
};

export const getSelectedUser = getEntity(
  (state) => state.roleManagement.selectedEntityId,
  UserSchema
);

export const getSelectedTeam = getEntity(
  (state) => state.roleManagement.selectedEntityId,
  TeamSchema
);

export const getSelectedProjects = getEntity(
  (state) =>
    state.forms.projectRoles.data?.selectedProjects?.map(
      (project) => project.key
    ),
  [ProjectSchema]
);

export const getSelectedProjectRoles = getEntity(
  (state) => state.forms.projectRoles.data?.roles,
  [RoleSchema]
);

export const getSelectedTenantRoles = getEntity(
  (state) => state.forms.tenantRoles.data?.roles,
  [RoleSchema]
);

export const getSelectedProjectRoleEntity = getEntity(
  (state) => state.modal.data[projectRolesModal.guid]?.selectedProjectRoleId,
  ProjectRolesSchema
);

export const getCurrentProjectRoles = getEntity(
  (state) => state.list.projectRoles.items.initial,
  [ProjectRolesSchema]
);

export const getCurrentTenantRoles = getEntity(
  (state) => state.list.tenantRoles.items.initial,
  [RoleSchema]
);

export const makeDisabledValuesSelector = (moduleName) => {
  const getProjectRoles = getEntity(
    (state) => state.list?.[moduleName]?.items.initial,
    [RoleSchema]
  );
  const getSelectedValues = getEntity(
    (state) => state.forms?.[MODULE_MAPPING[moduleName]]?.data?.roles,
    [RoleSchema]
  );

  return createSelector(getSelectedValues, getProjectRoles, (values, roles) => {
    if (!values) {
      return [];
    }

    const selectedPermissions = [
      ...new Set(
        values.reduce((acc, value) => [...acc, ...value.spec.permissions], [])
      ),
    ];
    return roles
      .filter((role) => {
        if (values.find((value) => value.guid === role.guid)) {
          return false;
        }
        return role.spec.permissions.every((permission) =>
          selectedPermissions.includes(permission)
        );
      })
      .map((role) => role.guid);
  });
};
