import store, { getStoreEntity } from "services/store";
import fetchers, { MODULES } from "../services";
import { PackSchema } from "utils/schemas";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
} from "utils/parsers";

export default function editorActions(
  guid,
  {
    forms,
    packManifests,
    getFormUpdatesOnPresetsChange,
    revertPackValuesConfirm,
    setCurrentEditorName,
    onEditorSelectLayer,
  }
) {
  const getState = () => {
    return store.getState().profileBuilder[guid];
  };

  const dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid,
    });
  };

  const toggleEditor = async () => {
    dispatch({
      type: "PROFILE_BUILDER_EDITOR_TOGGLE",
    });

    const { showEditor } = getState();

    if (showEditor) {
      await store.dispatch(
        forms.editorActions.init({ module: MODULES.PACKS_EDITOR_MODULE })
      );

      const { layers } = getState();
      const presentedLayers = layers?.filter(
        (layer) => layer.type === "manifest" || layer.config?.packVersionGuid
      );
      const firstLayerGuid = presentedLayers?.[0].guid;

      const firstLayer = getStoreEntity(firstLayerGuid, PackSchema);

      if (firstLayer?.spec?.type === "manifest") {
        setCurrentEditorName(`${firstLayerGuid}.values`);
        packManifests.onAttachedManifestSelect(
          firstLayer.manifests?.[0].guid,
          firstLayerGuid,
          false
        );
        return;
      }

      onEditorSelectLayer(firstLayerGuid);
      setCurrentEditorName(`${firstLayerGuid}.values`);
    }
  };

  const getSelectedLayerConfig = () => {
    const { selectedLayer, layers, draftLayers, packsEditor } = getState();
    const layerGuid =
      selectedLayer || draftLayers?.[0] || packsEditor.selectedGuid;
    return layers.find((layer) => layer.guid === layerGuid)?.config;
  };

  const getSelectedPackDefaultValues = () => {
    const { result } = fetchers.packValuesFetcher.selector(store.getState());
    return result?.[0]?.spec?.values;
  };

  return {
    getSelectedLayerConfig,
    getSelectedPackDefaultValues,
    toggleEditor,
    onPacksEditorRevert: () => {
      revertPackValuesConfirm.open().then(() => {
        const defaultValues = getSelectedPackDefaultValues();
        const defaultPresets = getDefaultPresetsFromValues(defaultValues);
        const valuesWithoutComment = getPackValuesWithoutPresetsComment(
          defaultValues
        );

        forms.onEditorPresetsFormChange(defaultPresets);
        forms.onEditorValuesFormChange(valuesWithoutComment);
      });
    },

    onPacksEditorPresetsChange: (data) => {
      const { packsEditor } = getState();
      const formData = store.getState().forms[MODULES.PACKS_EDITOR_MODULE]
        ?.data[packsEditor.selectedGuid];
      const { values, presets } = getFormUpdatesOnPresetsChange(data, {
        currentPresets: formData?.presets,
        currentValues: formData?.values,
      });
      forms.onEditorValuesFormChange(values);
      forms.onEditorPresetsFormChange(presets);
    },

    onEditorConfirm: () => {
      const packsData = store.getState().forms[MODULES.PACKS_EDITOR_MODULE]
        ?.data;
      dispatch({
        type: "PROFILE_BUILDER_APPLY_EDITOR_CHANGES",
        packsData,
      });
      toggleEditor();
    },
  };
}
