import Button from "components/ui/Button";
import React from "react";

export default function MigrationSuccessful({ endpoint }) {
  return (
    <>
      Spectro Cloud Platform has been migrated to Enterprise Platform. Please
      navigate to the new platform.
      <Button
        className="button"
        data-qa="enterprise-cluster"
        as="a"
        href={`https://${endpoint}`}
      >
        Enterprise cluster
      </Button>
    </>
  );
}
