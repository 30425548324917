import i18n from "i18next";
import uuid from "uuid";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import debounce from "redux-debounce-thunk";

import Validator from "services/validator";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  DebouncedRule,
  areValidKubernetesTags,
  isValidIPRange,
  areValidIPTags,
} from "services/validator/rules";
import createFormActions from "modules/form/actions";
import store from "services/store";
import flags from "services/flags";
import { VpcidSchema, ClusterProfileSchema } from "utils/schemas";
import {
  MANAGED_TO_PURE_ENVIRONMENT,
  UPDATE_STRATEGIES,
  EXPIRY_PERIODS,
  FOUR_MONTHS_IN_HOURS,
} from "utils/constants";
import { parseYAMLValues } from "utils/parsers";

import {
  getSelectedClusterProfile,
  getSelectedCloud,
  getSubnetsForSelectedAz,
  getVMwarePlacementOptions,
  getSelectedCredential,
  getSelectedEnvironment,
  isSupervizedEnvironment,
} from "state/cluster/selectors/create";
import api from "services/api";
import historyService from "services/history";
import notifications from "services/notifications";
import ModalService from "services/modal";
import { WizardActions } from "modules/wizard/actions";
import { getCheckpointStep, getCurrentStep } from "modules/wizard/selectors";
import {
  fetchAwsCloudConfigParams,
  fetchAzureNodeParams,
  fetchGoogleCloudNodeParams,
  fetchMaasCloudNodeParams,
} from "./nodes";
import {
  getPayload,
  getClusterRatePayload,
} from "state/cluster/selectors/create";
import {
  regionsFetcher,
  subscriptionsFetcher,
  adminGOIDsFetcher,
  virtualNetworksFetcher,
  resourceGroupsFetcher,
  propertiesFetcher,
  datacentersFetcher,
  projectsFetcher,
  regionsByProjectFetcher,
  networksFetcher,
  sshFetcher,
  ipamFetcher,
  profileModule,
  onSpotWarningConfirm,
} from "state/cluster/services/create";
import { cloudAccountsFetcher as cloudAccountsSummaryFetcher } from "state/cluster/actions/list";
import { cloudAccountsFetcher } from "state/cluster/services/create";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";
import { getAzureAzs } from "../selectors/nodes";
import { sshKeysFetcher } from "state/sshKeys/services";
import { dnsMappingsFetcher } from "state/dns/services";
import { dnsFormActions, openInlineModal } from "state/dns/actions/create";
import { clusterRbacsFetcher } from "state/rolebindings/services";
import {
  azureInstanceTypesFetcher,
  gcpInstanceTypesFetcher,
} from "../services/nodes";
import { createOpenstackFormFactory } from "modules/cluster/openstack";
import { createMaasFormFactory } from "modules/cluster/maas";

export const onCancelClusterModal = new ModalService("cancelModal");

const POLICIES_STEP = {
  title: () => i18n.t("Policies"),
  id: "policies",
};

const cloudFields = {
  aws: ["credential", "region", "ssh", "vpcid"],
  eks: ["credential", "region", "ssh", "vpcid", "controlPlane"],
  vsphere: ["credential", "datacenter", "sshKeys", "ntpServers", "folder"],
  azure: [
    "credential",
    "region",
    "subscriptionId",
    "vnetName",
    "resourceGroup",
    "controlPlaneSubnet",
    "workerSubnet",
    "sshKey",
    "adminGroupObjectIDs",
  ],
  aks: [
    "credential",
    "region",
    "subscriptionId",
    "vnetName",
    "resourceGroup",
    "controlPlaneSubnet",
    "workerSubnet",
    "sshKey",
  ],
  openstack: [
    "credential",
    "domain",
    "region",
    "project",
    "sshKeyName",
    "nodeCidr",
    "dnsNameservers",
    "network",
    "subnet",
  ],
  gcp: ["credential", "project", "region", "network"],
  maas: ["credential", "domain", "sshKeys"],
};

export async function submit(data) {
  const state = store.getState();
  const cloudType = getSelectedCloud(state);

  const payload = getPayload(state);

  let response = null;

  try {
    response = await api.post(`v1alpha1/spectroclusters/${cloudType}`, payload);
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong when creating the cluster"),
      description: error.message,
    });
  }

  if (response) {
    historyService.push(`/clusters/${response.uid}/overview`);
    notifications.success({
      message: `Cluster "${data.name}" has been created successfully. Deployment is in progress...`,
    });
  }
}

const nodePoolValidator = new Validator();
function createNodePoolValidator() {
  nodePoolValidator.removeRules();
  const selectedClusterProfile = getSelectedClusterProfile(store.getState());
  const cloudType = selectedClusterProfile?.spec?.published?.cloudType;

  const mandatoryCloudRules = {
    aws: ["poolName", "size", "instanceType", "azs"],
    eks: ["poolName", "instanceType", "maxNodeSize", "minNodeSize"],
    vsphere: ["poolName", "size", "disk", "memory", "cpu"],
    azure: ["poolName", "size", "disk", "instanceType", "storageAccountType"],
    aks: ["poolName", "size", "disk", "instanceType", "storageAccountType"],
    gcp: ["poolName", "size", "instanceType", "azs"],
    openstack: ["poolName", "flavor", "azs"],
    maas: ["poolName", "resourcePool", "azs", "minCPU", "minMem"],
  };

  const rules = mandatoryCloudRules[cloudType] || [];

  if (cloudType === "aws") {
    nodePoolValidator.addRule("maxPricePercentage", (value, key, data) => {
      if (data.instanceOption === "onSpot") {
        return Missing()(value, key, data);
      }

      return false;
    });
  }

  if (["azure"].includes(cloudType)) {
    nodePoolValidator.addRule("azs", (value, key, data) => {
      if (data.isMaster || data.isControlPlane) {
        return false;
      }

      if (!getAzureAzs(store.getState()).length) {
        return false;
      }

      return Missing()(value, key, data);
    });
  }

  if (cloudType === "vsphere") {
    const domainValidator = new Validator();
    domainValidator.addRule(
      ["cluster", "datastore", "network"],
      Missing({ message: () => " " })
    );
    domainValidator.addRule(["parentPoolUid"], (...args) => {
      if (store.getState().forms.cluster.data.networkType !== "staticIP") {
        return false;
      }

      return Missing({ message: () => " " })(...args);
    });
    nodePoolValidator.addRule("domains", domainValidator);
  }

  nodePoolValidator.addRule(rules, Missing());
  nodePoolValidator.addRule("poolName", (poolName) => {
    const nodePools = store.getState().forms.cluster.data.nodePools;
    const sameNames = nodePools.filter(
      (nodePool) => nodePool.poolName === poolName
    );

    return sameNames.length > 1
      ? i18n.t("Node pool names should be unique")
      : false;
  });

  nodePoolValidator.addRule("size", (size, _, nodePool) => {
    if (nodePool.isMaster && size % 2 === 0) {
      return i18n.t("Master node pool size should be an even number");
    }

    return false;
  });
  nodePoolValidator.addRule(["poolName"], isKubernetesName());
}

const awsFieldsValidator = new Validator();
const eksControlPlaneValidator = new Validator();
eksControlPlaneValidator.addRule("azs", function(value, key, data) {
  if (value.length < 2) {
    return i18n.t("Please select at least 2 AZs");
  }

  return false;
});
awsFieldsValidator.addRule(["credential", "region"], CloudRule());
awsFieldsValidator.addRule(
  ["ssh"],
  CloudRule({
    message() {
      const regionName = store.getState().forms.cluster.data.region;
      const staticPlacement = store.getState().forms.cluster.data
        .staticPlacement;
      const isSupervized = isSupervizedEnvironment(store.getState());
      const message = i18n.t(
        "Please import/create a new keypair into your region {{regionName}}.",
        {
          regionName,
        }
      );
      if (!isSupervized) {
        return message;
      }

      if (staticPlacement) {
        return null;
      }

      return message;
    },
  })
);
awsFieldsValidator.addRule("controlPlane", function*(value, key, data) {
  if (data.cloudType === "eks" && data.staticPlacement) {
    yield eksControlPlaneValidator;
  }

  for (const az of data?.controlPlane?.azs || []) {
    yield {
      result:
        data.staticPlacement && !data.controlPlane?.[`subnet_${az}`]?.length
          ? "Missing subnet for selected az"
          : false,
      field: `controlPlane.subnet_${az}`,
    };
  }
});

const vmwareFieldsValidator = new Validator();
vmwareFieldsValidator.addRule(
  ["credential", "datacenter", "folder"],
  CloudRule()
);

const maasFieldsValidator = new Validator();
maasFieldsValidator.addRule(["credential", "domain", "sshKeys"], CloudRule());

const azureFieldsValidator = new Validator();
azureFieldsValidator.addRule(
  ["credential", "region", "subscriptionId", "resourceGroup", "sshKey"],
  CloudRule()
);
azureFieldsValidator.addRule(["vnetName"], (value, key, data) => {
  if (!data.staticPlacement) {
    return false;
  }

  return Missing()(value, key, data);
});
azureFieldsValidator.addRule(["adminGroupObjectIDs"], (value, key, data) => {
  if (!data.hasTenantName) {
    return false;
  }

  if (!data.managedAD) {
    return false;
  }

  return Missing()(value, key, data);
});
azureFieldsValidator.addRule(["vnetName"], (value, key, data) => {
  if (!data.staticPlacement) {
    return false;
  }

  return Missing()(value, key, data);
});

const googleFieldsValidator = new Validator();
googleFieldsValidator.addRule(["credential", "project", "region"], CloudRule());
googleFieldsValidator.addRule(["network"], StaticPlacementRule());

const validateClusterName = async (value) => {
  let error;

  const promise = api.get(
    `v1alpha1/spectroclusters/validate/name?name=${value}`
  );

  try {
    await promise;
  } catch (err) {
    error = {
      result: i18n.t(
        `A spectrocluster with the name "{{ value }}" already exists`,
        {
          value,
        }
      ),
      field: "name",
    };
  }

  return error || false;
};

const openStackFieldsValidator = new Validator();

openStackFieldsValidator.addRule(["nodeCidr"], isValidIPRange());
openStackFieldsValidator.addRule(["dnsNameservers"], areValidIPTags());

openStackFieldsValidator.addRule(
  ["credential", "domain", "region", "project", "sshKeyName"],
  CloudRule()
);

openStackFieldsValidator.addRule(
  ["nodeCidr", "dnsNameservers"],
  NoStaticPlacementRule()
);
openStackFieldsValidator.addRule(["network"], StaticPlacementRule());

openStackFieldsValidator.addRule(["subnet"], (value, key, data) => {
  const state = store.getState();
  const subnets = openstackCloudForm.selectors.getOpenstackNetworkSubnets(
    state
  );

  if (data.network && subnets.length > 0) {
    return Missing()(value, key, data);
  }

  return false;
});

const nameValidationRule = DebouncedRule()(validateClusterName);

export const validator = new Validator();
validator.addRule(["name", "clusterprofile"], Missing());
validator.addRule(["tags"], areValidKubernetesTags());
validator.addRule(["name"], (value) => {
  if (!value) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "CLUSTER_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

validator.addRule(function*(data) {
  const cloudType = getSelectedCloud(store.getState());

  if (cloudType === "aws") {
    yield awsFieldsValidator;
  }
  if (cloudType === "eks") {
    yield awsFieldsValidator;
  }

  if (cloudType === "vsphere") {
    yield vmwareFieldsValidator;
  }

  if (cloudType === "azure") {
    yield azureFieldsValidator;
  }

  if (cloudType === "aks") {
    yield azureFieldsValidator;
  }

  if (cloudType === "gcp") {
    yield googleFieldsValidator;
  }

  if (cloudType === "maas") {
    yield maasFieldsValidator;
  }

  if (cloudType === "openstack") {
    yield openStackFieldsValidator;
  }
});
validator.addRule(["vpcid"], StaticPlacementRule());
validator.addRule(
  ["controlPlaneSubnet", "workerSubnet"],
  (value, key, data) => {
    if (!data.vnetName) {
      return false;
    }

    return Missing()(value, key, data);
  }
);
validator.addRule(["nodePools"], nodePoolValidator);
validator.addRule(["nodePools"], function*(value, key, data) {
  const cloudType = getSelectedCloud(store.getState());
  const staticPlacementEnabled = data.networkType === "staticIP";

  if (cloudType === "vsphere") {
    for (const index in value) {
      const nodePool = value[index];
      const clusters = nodePool.domains.map((domain) => domain.cluster);

      for (const clusterIndex in clusters) {
        const cluster = clusters[clusterIndex];
        const duplicates = clusters.filter(
          (currentItem) => currentItem === cluster
        );
        yield {
          result:
            duplicates.length > 1 ? i18n.t("Clusters must be unique") : false,
          field: `nodePools.${index}.domains.${clusterIndex}.cluster`,
        };
      }

      for (const domainIndex in nodePool.domains) {
        const domain = nodePool.domains[domainIndex];
        yield {
          result:
            nodePool.isMaster &&
            nodePool.domains
              .map((domain) => domain.network)
              .some((network) => network !== "" && network !== domain.network)
              ? i18n.t("Master nodes must share the same network")
              : false,
          field: `nodePools.${index}.domains.${domainIndex}.network`,
        };

        if (domainIndex === "0" && nodePool.isMaster) {
          yield {
            result:
              isEmpty(nodePool.domains[0].dns) && !staticPlacementEnabled
                ? i18n.t("Please define a DNS mapping")
                : false,
            field: `nodePools.${index}.domains.${domainIndex}.dns.spec.dnsName`,
          };
        }
      }
    }

    return;
  }

  if (cloudType === "aks") {
    const hasOneSystemPool = value.find(
      (nodePool) => nodePool.isSystemNodePool
    );

    for (const index in value) {
      yield {
        result: !hasOneSystemPool
          ? i18n.t("At least one pool must be set to be system")
          : false,
        field: `nodePools.${index}.isSystemNodePool`,
      };
    }
  }

  if (!["aws", "eks"].includes(cloudType)) {
    return;
  }

  for (const index in value) {
    const nodePool = value[index];

    if (cloudType === "eks") {
      if (data.staticPlacement) {
        yield {
          result:
            data.staticPlacement && !nodePool.azs.length
              ? "Select at least one AZ"
              : false,
          field: `nodePools.${index}.azs`,
        };
      }
    }

    for (const az of nodePool.azs) {
      yield {
        result:
          data.staticPlacement && !nodePool[`subnet_${az}`]?.length
            ? "Missing subnet for selected az"
            : false,
        field: `nodePools.${index}.subnet_${az}`,
      };
    }

    if (
      (nodePool.isMaster || nodePool.isControlPlane) &&
      data.staticPlacement
    ) {
      const subnetOptions = getSubnetsForSelectedAz(store.getState());
      const hasPrivateSubnet = nodePool.azs.some((az) => {
        const subnets = nodePool[`subnet_${az}`]?.map((subnet) =>
          subnetOptions[az].find((subnetData) => subnetData.value === subnet)
        );
        return subnets?.some((subnet) => subnet?.isPrivate);
      });

      yield {
        result: !hasPrivateSubnet
          ? "Please assign at least one private subnet to this nodepool"
          : false,
        field: `nodePools.${index}.azs`,
      };
    }
  }
});

validator.addRule(["layers"], async function() {
  const clusterProfile = getSelectedClusterProfile(store.getState());
  if (!clusterProfile) {
    return false;
  }
  let response;
  try {
    response = await api.post("v1alpha1/spectroclusters/validate/packs", {
      profiles: profileModule.payload,
    });
  } catch (err) {
    notifications.error({
      message: i18n.t("Something went wrong"),
      description: err?.message,
    });

    return false;
  }

  const packsErrors = response.profiles.map((profile) => ({
    results: profile?.packs?.results,
    uid: profile.uid,
  }));
  profileModule.actions.setLayersErrors(packsErrors);
  return packsErrors.length > 0 ? packsErrors : false;
});

const GET_DEFAULT_NODE_VALUES = ({ cpu = 4, memory = 8 } = {}) => ({
  size: 1,
  azs: [],
  instanceOption: "onDemand",
  instanceType: null,
  maxPricePercentage: 1,
  guid: uuid(),
  cpu,
  maxNodeSize: 3,
  minNodeSize: 1,
  minCPU: cpu,
  memory,
  minMem: memory,
  disk: 60,
  osType: "linux",
  storageAccountType: "",
  updateStrategy: UPDATE_STRATEGIES[0].value,
  flavor: "",
  resourcePool: "",
});

function generateDomain({ domain } = {}) {
  let base = domain;
  if (!domain) {
    base = {
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
      dns: {},
    };
  }

  return { ...base };
}

function defaultRecurrency() {
  return {
    type: "never",
    recurrency: "",
  };
}

export const clusterFormActions = createFormActions({
  validator,
  submit,
  init: () => {
    return Promise.resolve({
      sshKey: "",
      networkType: "dhcp",
      nodePoolsCount: 2,
      useFolderSufix: true,
      folder: "",
      dns: null,
      osPatchingScheduleOption: "never",
      patchOnBoot: true,
      nodePools: [],
      name: "",
      cloudType: "",
      layers: "",
      endpointAccess: "public",

      kubebenchEnabled: false,
      kubebench: defaultRecurrency(),
      kubehunterEnabled: false,
      kubehunter: defaultRecurrency(),
      sonobuoyEnabled: false,
      sonobuoy: defaultRecurrency(),

      backupPrefix: "",
      location: null,
      schedule: "never",
      occurrence: undefined,
      includeAllDisks: true,
      includeClusterResources: true,
      expiryPeriod: EXPIRY_PERIODS[0].value,
      expiryHours: FOUR_MONTHS_IN_HOURS,

      fargateProfiles: [],

      roleBindingOptions: [],
    });
  },
});

export function onVpcIdChange(value) {
  return function thunk(dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vpcid: value,
          controlPlane: {
            azs: [],
          },
        },
      })
    );

    const formErrors = getState().forms.cluster.errors;
    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: formErrors.map((error) => {
          if (error.field.startsWith("controlPlane")) {
            return {
              ...error,
              result: false,
            };
          }

          return error;
        }),
      })
    );
  };
}

export function onChangeManagedActiveDirectory(value) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "managedAD",
        value,
      })
    );

    if (!value) {
      dispatch(
        clusterFormActions.validateField({
          name: "adminGroupObjectIDs",
          module: "cluster",
        })
      );
    }
  };
}
export function onChangeStaticPlacement(value) {
  return (dispatch, getState) => {
    const cloudType = getSelectedEnvironment(getState());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "staticPlacement",
        value,
      })
    );

    if (cloudType === "aws") {
      if (!value) {
        dispatch(
          clusterFormActions.onChange({
            name: "vpcid",
            module: "cluster",
            value: "",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "vpcid",
            module: "cluster",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "ssh",
            module: "cluster",
          })
        );
      }
      dispatch(
        clusterFormActions.validateField({
          name: "ssh",
          module: "cluster",
        })
      );
    }

    if (cloudType === "openstack") {
      if (!value) {
        dispatch(
          clusterFormActions.batchChange({
            updates: {
              network: undefined,
              subnet: undefined,
            },
            module: "cluster",
          })
        );

        dispatch(
          clusterFormActions.validateField({
            name: ["network", "subnet"],
            module: "cluster",
          })
        );
      }

      dispatch(
        clusterFormActions.batchChange({
          updates: {
            nodeCidr: undefined,
            dnsNameservers: undefined,
          },
          module: "cluster",
        })
      );

      dispatch(
        clusterFormActions.validateField({
          name: ["nodeCidr", "dnsNameservers"],
          module: "cluster",
        })
      );
    }

    if (cloudType === "azure") {
      if (!value) {
        dispatch(
          clusterFormActions.batchChange({
            updates: {
              vnetName: undefined,
              controlPlaneSubnet: undefined,
              workerSubnet: undefined,
            },
            module: "cluster",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "vnetName",
            module: "cluster",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "controlPlaneSubnet",
            module: "cluster",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "workerSubnet",
            module: "cluster",
          })
        );
      }
    }

    if (cloudType === "gcp") {
      if (!value) {
        dispatch(
          clusterFormActions.onChange({
            name: "network",
            module: "cluster",
            value: "",
          })
        );
        dispatch(
          clusterFormActions.validateField({
            name: "network",
            module: "cluster",
          })
        );
      }
    }
  };
}

function resetClusterConfigForm() {
  return (dispatch, getState) => {
    const selectedCloud = getSelectedEnvironment(getState());
    const cloudFieldsToReset = cloudFields[selectedCloud].reduce(
      (acc, fieldName) => {
        acc[fieldName] = "";
        return acc;
      },
      {}
    );
    if (selectedCloud === "vsphere") {
      cloudFieldsToReset.sshKeys = [];
      cloudFieldsToReset.ntpServers = [];
    }

    if (selectedCloud === "openstack") {
      cloudFieldsToReset.dnsNameservers = [];
    }

    if (selectedCloud === "maas") {
      cloudFieldsToReset.sshKeys = [];
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: cloudFieldsToReset,
      })
    );
  };
}

function resetNodesConfigForm() {
  return (dispatch, getState) => {
    const isSupervized = isSupervizedEnvironment(getState());
    const workerPool = {
      poolName: "worker-pool",
      ...GET_DEFAULT_NODE_VALUES(),
      domains: [generateDomain()],
    };
    const nodePools = isSupervized
      ? [workerPool]
      : [
          {
            poolName: "master-pool",
            ...GET_DEFAULT_NODE_VALUES({ cpu: 2, memory: 4 }),
            isMaster: true,
            domains: [generateDomain()],
          },
          workerPool,
        ];

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
    dispatch({
      type: "CLUSTER_CREATE_RESET_ESTIMATED_RATE",
    });
  };
}

function initializeProfileStack() {
  return async function thunk(dispatch, getState) {
    await dispatch(fetchClusterProfilePacks());
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    profileModule.actions.initialize({
      profiles: [{ ...selectedClusterProfile, type: "infra" }],
    });

    let resolvedValuesPromise = api
      .get(
        `v1alpha1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs/resolvedValues`
      )
      .then((res) =>
        profileModule.actions.updateResolvedValues([
          {
            resolved: res.resolved,
            uid: selectedClusterProfile.metadata.uid,
          },
        ])
      );
    dispatch({
      type: "FETCH_CLUSTER_PROFILE_RESOLVED_VALUES",
      promise: resolvedValuesPromise,
      schema: ClusterProfileSchema,
    });

    await resolvedValuesPromise;
  };
}

function clusterProfileSelectionEffect() {
  return async function thunk(dispatch, getState) {
    const selectedCloud = getSelectedEnvironment(getState());
    dispatch({
      type: "SET_SELECTED_CLOUD_TYPE",
      selectedCloud,
    });

    dispatch(resetClusterConfigForm());
    dispatch(resetNodesConfigForm());
  };
}

export const wizardActions = new WizardActions({
  formActions: clusterFormActions,
  fieldsToValidate() {
    const selectedCloud = getSelectedCloud(store.getState());

    return {
      0: ["name"],
      1: ["clusterprofile"],
      2: ["layers"],
      3: cloudFields[selectedCloud],
      4: ["nodePools"],
    };
  },
  getDescription({ id }) {
    const formData = store.getState().forms.cluster.data;

    if (id === "basic-info") {
      return formData.name;
    }

    if (id === "infra-profile") {
      return getSelectedClusterProfile(store.getState())?.metadata.name;
    }

    if (
      ["parameters", "cluster-config", "nodes-config", "policies"].includes(id)
    ) {
      return i18n.t("Completed");
    }
  },
  steps: [
    {
      title: () => i18n.t("Basic information"),
      id: "basic-info",
    },
    {
      title: () => i18n.t("Infrastructure profile"),
      id: "infra-profile",
    },
    {
      title: () => i18n.t("Parameters"),
      id: "parameters",
    },
    {
      title: () => i18n.t("Cluster config"),
      id: "cluster-config",
    },
    {
      title: () => i18n.t("Nodes config"),
      id: "nodes-config",
    },
    POLICIES_STEP,
    {
      title: () => i18n.t("Review"),
      id: "review",
    },
  ],
  async onStepChange({ id }) {
    if (id === "infra-profile") {
      store.dispatch(cloudAccountsSummaryFetcher.fetch());
    }
    if (id === "parameters") {
      store.dispatch(initializeProfileStack());
    }
    if (id === "cluster-config") {
      store.dispatch(cloudAccountsFetcher.fetch());
      store.dispatch(sshKeysFetcher.fetch());
    }

    if (id === "nodes-config") {
      const cloudType = getSelectedEnvironment(store.getState());

      createNodePoolValidator();

      if (cloudType === "aws") {
        store.dispatch(fetchAwsCloudConfigParams({ type: "create" }));
      }

      if (cloudType === "azure") {
        store.dispatch(fetchAzureNodeParams());
      }

      if (cloudType === "gcp") {
        store.dispatch(fetchGoogleCloudNodeParams());
      }

      if (cloudType === "maas") {
        store.dispatch(fetchMaasCloudNodeParams());
      }
    }

    if (id === "policies" && flags.has("backup")) {
      await store.dispatch(fetchBackupLocationsFetcher.fetch());
      store.dispatch(setDefaultLocation());
    }

    if (id === "policies" && flags.has("rolebindings")) {
      await store.dispatch(clusterRbacsFetcher.fetch());
      store.dispatch(setDefaultRoleBindings());
    }
  },
});

function setDefaultRoleBindings() {
  return async (dispatch, getState) => {
    const state = getState();
    const currentRoleBindings = state.forms.cluster?.data?.roleBindingOptions;
    const roleBindings = clusterRbacsFetcher.selector(state)?.result || [];
    const items = (roleBindings || [])
      .filter((item) => item.spec?.isDefault)
      .map((item) => item.metadata?.uid);

    if (currentRoleBindings?.length) {
      return;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "roleBindingOptions",
        value: items,
      })
    );
  };
}

function setDefaultLocation() {
  return (dispatch, getState) => {
    const state = getState();
    const currentLocation = state.forms.cluster?.data?.location;
    const locations =
      fetchBackupLocationsFetcher.selector(store.getState())?.result?.items ||
      [];
    const location =
      locations.find((l) => l.spec?.isDefault)?.metadata?.uid || null;

    if (currentLocation) {
      return;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "location",
        value: location,
      })
    );
  };
}

function CloudRule(message) {
  return function validation(value, key, data) {
    if (data?.cloudType !== "bare_metal") {
      return Missing(message)(value, key, data);
    }

    return false;
  };
}

function StaticPlacementRule(message) {
  return function validation(value, key, data) {
    if (data?.staticPlacement) {
      return Missing(message)(value, key, data);
    }

    return false;
  };
}

function NoStaticPlacementRule(message) {
  return function validation(value, key, data) {
    if (!data?.staticPlacement) {
      return Missing(message)(value, key, data);
    }

    return false;
  };
}

export function fetchClusterProfilePacks() {
  return function thunk(dispatch, getState) {
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    const selectedCloud = getSelectedCloud(getState());
    const promise = api
      .get(
        `v1alpha1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs?includePackMeta=schema,presets`
      )
      .then((res) => {
        const packMapping = res.items.reduce((accumulator, item) => {
          accumulator[item.metadata.uid] = item;
          return accumulator;
        }, {});

        return {
          ...selectedClusterProfile,
          spec: {
            ...selectedClusterProfile.spec,
            published: {
              ...selectedClusterProfile.spec.published,
              packs: selectedClusterProfile.spec.published.packs.map((pack) => {
                return {
                  ...pack,
                  ...packMapping[pack.packUid],
                };
              }),
            },
          },
        };
      });

    dispatch({
      type: "FETCH_CLUSTER_PROFILE_PACKS",
      promise,
      schema: ClusterProfileSchema,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "cloudType",
        value: selectedCloud,
      })
    );

    return promise;
  };
}

function resetNextStepFields() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);
    const isNextStepCompleted = !!state.wizard.cluster.steps?.[currentStep + 1]
      ?.description;

    if (!isNextStepCompleted) {
      return;
    }

    if (currentStep === 3) {
      dispatch(resetNodesConfigForm());
    }

    dispatch(resetForwardSteps());
  };
}

export const openstackCloudForm = createOpenstackFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

export const maasCloudForm = createMaasFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

export function selectCredential(credential) {
  return async (dispatch, getState) => {
    const selectedCloud = getSelectedEnvironment(getState());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "credential",
        value: credential,
      })
    );
    dispatch(resetNextStepFields());

    if (selectedCloud === "aws") {
      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            ssh: "",
            region: "",
            vpcid: "",
          },
        })
      );

      dispatch(regionsFetcher.fetch());
    }

    if (selectedCloud === "vsphere") {
      dispatch(datacentersFetcher.fetch());
      dispatch(ipamFetcher.fetch());
    }

    if (selectedCloud === "openstack") {
      const account = getSelectedCredential(getState());
      await openstackCloudForm.actions.onAccountSelected("credential", account);
      dispatch(
        clusterFormActions.validateField({
          module: "cluster",
          name: ["domain", "region", "project"],
        })
      );
    }

    if (selectedCloud === "maas") {
      dispatch(maasCloudForm.fetchers.domainsFetcher.fetch());
    }

    if (selectedCloud === "azure") {
      const cloudAccounts = cloudAccountsFetcher.selector(getState())?.result;
      const selectedCloudAccount = cloudAccounts?.find(
        (ca) => ca.metadata.uid === credential
      );

      const hasTenantName = selectedCloudAccount?.spec.tenantName;

      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            region: "",
            subscriptionId: "",
            vnetName: "",
            resourceGroup: "",
            hasTenantName,
            managedAD: true,
            adminGroupObjectIDs: [],
          },
        })
      );

      hasTenantName && dispatch(adminGOIDsFetcher.fetch());
      dispatch(subscriptionsFetcher.fetch());
    }

    if (selectedCloud === "gcp") {
      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            project: "",
            region: "",
            network: "",
          },
        })
      );
      dispatch(projectsFetcher.fetch());
    }
  };
}

export function selectRegion(region) {
  return (dispatch, getState) => {
    const accountUid = getState().forms.cluster.data.credential;
    const selectedCloud = getState().cluster.create.selectedCloud;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "region",
        value: region,
      })
    );
    dispatch(resetNextStepFields());
    const isSupervized = isSupervizedEnvironment(getState());
    // TODO: to be changed with a selectors
    if (selectedCloud === "aws") {
      const vpcids = api
        .get(
          `v1alpha1/clouds/${selectedCloud}/regions/${region}/vpcs?cloudAccountUid=${accountUid}`
        )
        .then((res) => res.vpcs);

      if (isSupervized) {
        store.dispatch(fetchAwsCloudConfigParams({ type: "create" }));
      }

      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            ssh: "",
            vpcid: "",
          },
        })
      );

      dispatch({
        type: "CLUSTER_CLOUD_SETTINGS_VPCS",
        promise: vpcids,
        schema: [VpcidSchema],
      });

      dispatch(sshFetcher.fetch());
    }

    if (selectedCloud === "azure") {
      dispatch(virtualNetworksFetcher.fetch());
      dispatch(resourceGroupsFetcher.fetch());
      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            vnetName: "",
            resourceGroup: "",
            controlPlaneSubnet: "",
            workerSubnet: "",
          },
        })
      );
    }

    if (selectedCloud === "gcp") {
      dispatch(networksFetcher.fetch());
      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            network: "",
          },
        })
      );
    }
  };
}

export function selectSubscription(subscriptionId) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          subscriptionId,
          region: "",
          resourceGroup: "",
          vnetName: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );

    dispatch(regionsFetcher.fetch());
  };
}

export function selectVirtualNetwork(vnetName) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetName,
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );
  };
}

function resetForwardSteps() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);

    state.wizard.cluster.steps.forEach((_, index) => {
      if (index >= currentStep) {
        dispatch({
          type: "WIZARD__SET_STEP_DESCRIPTION",
          step: index,
          description: "",
          module: "cluster",
        });
      }
    });
  };
}

export function onClusterProfileChange(name, value) {
  return async (dispatch, getState) => {
    const checkpoint = getCheckpointStep("cluster")(getState());
    const currentStep = getCurrentStep("cluster")(getState());

    dispatch(clusterFormActions.onChange({ name, value, module: "cluster" }));

    if (currentStep < checkpoint) {
      dispatch(resetForwardSteps());
    }

    dispatch(clusterProfileSelectionEffect());
  };
}

export function setClusterSelectedLayer(selectedLayer) {
  return {
    type: "SET_CLUSTER_SELECTED_LAYER",
    selectedLayer: selectedLayer,
  };
}

export function backToClusterProfileSelection() {
  return {
    type: "FORM_ON_CHANGE",
    name: "clusterprofile",
    value: null,
    module: "cluster",
  };
}

export function addNodePool() {
  return (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.cluster.data;

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools: [
            ...formData.nodePools,
            {
              poolName: `worker-pool-${formData.nodePoolsCount}`,
              ...GET_DEFAULT_NODE_VALUES(),
              domains: [generateDomain()],
            },
          ],
          nodePoolsCount: formData.nodePoolsCount + 1,
        },
      })
    );
  };
}

export function removeNodePool(index) {
  return (dispatch, getState) => {
    const state = getState();
    const formNodePools = state.forms.cluster.data?.nodePools;

    const newNodePools = [...formNodePools];
    newNodePools.splice(index, 1);

    dispatch({
      type: "FORM_ON_CHANGE",
      name: "nodePools",
      value: newNodePools,
      module: "cluster",
    });

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.startsWith(`nodePools.${index}`)
        ? { ...error, result: null }
        : error
    );

    dispatch({
      type: "UPDATE_FORM_ERRORS",
      module: "cluster",
      errors: errorRemnants,
    });
  };
}

export function copyFromMasterPool(index) {
  return (dispatch, getState) => {
    const state = getState();
    const formNodePools = state.forms.cluster.data?.nodePools || [];
    const nodePools = [...formNodePools];
    const nodePool = nodePools[index];
    const masterPool = nodePools.find((nodePool) => nodePool.isMaster);
    const getPaths = (keys) => keys.map((key) => `nodePools.${index}.${key}`);
    const fieldsToCopy =
      omit(masterPool, [
        "isMaster",
        "guid",
        "poolName",
        "size",
        "instanceOption",
        "updateStrategy",
        "allowWorkerCapability",
        "minCPU",
        "minMem",
      ]) || {};

    nodePools[index] = {
      ...nodePool,
      ...fieldsToCopy,
    };

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: getPaths(Object.keys(fieldsToCopy)),
      })
    );
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${index}.domains`,
      })
    );
  };
}

export function onModalClose() {
  const formData = store.getState().forms.cluster.data;

  if (formData.name === "") {
    historyService.push("/clusters");
    return;
  }

  onCancelClusterModal.open().then(() => {
    historyService.push("/clusters");
  });
}

export function onDatacenterChange(datacenter) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          datacenter,
          folder: "",
        },
      })
    );
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[3];
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];
    const domains = [...nodePool.domains];

    const prevValue = domains[domainIndex].cluster;

    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      cluster,
      datastore: "",
      network: "",
      resourcePool: "",
      dns: {},
    });
    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(propertiesFetcher.key(cluster).fetch());
    dispatch(dnsMappingsFetcher.fetch());

    if (prevValue !== "") {
      const relatedErrors = domains.reduce((accumulator, domain, index) => {
        if (domain.cluster === prevValue && index !== domainIndex) {
          accumulator.push(index);
        }

        return accumulator;
      }, []);

      dispatch(
        clusterFormActions.validateField({
          name: relatedErrors.map(
            (index) => `nodePools.${poolIndex}.domains.${index}.cluster`
          ),
          module: "cluster",
        })
      );
    }
  };
}

export function onPoolChange(name, value, isMaster) {
  return (dispatch, getState) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name,
        value,
      })
    );

    if (isMaster) {
      const nodePools = [...getState().forms.cluster.data.nodePools].map(
        (nodePool) => {
          if (nodePool.isMaster) {
            return {
              ...nodePool,
              domains: nodePool.domains.map((domain) => ({
                ...domain,
                parentPoolUid: value,
              })),
            };
          }

          return nodePool;
        }
      );

      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            nodePools,
          },
        })
      );
    }
  };
}

export function onNetworkChange(name, value) {
  return (dispatch, getState) => {
    const staticPlacementEnabled =
      getState().forms.cluster?.data.networkType === "staticIP";

    const pathParts = name.split(".");
    const domainIndex = pathParts[3];
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];
    const domains = [...nodePool.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network: value,
    });

    if (
      !staticPlacementEnabled &&
      (nodePool.isMaster || nodePool.isControlPlane) &&
      domainIndex === "0"
    ) {
      const domain = domains[domainIndex];
      const properties = getVMwarePlacementOptions(getState());
      const selectedNetwork = properties[domain.cluster].networks.find(
        (option) => domain.network === option.value
      );

      if (selectedNetwork) {
        domains.splice(domainIndex, 1, {
          ...domains[domainIndex],
          dns: selectedNetwork.dnsMapping,
        });
      }
    }

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: { nodePools },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === value && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      clusterFormActions.validateField({
        name: relatedErrors.map(
          (index) => `nodePools.${poolIndex}.domains.${index}.network`
        ),
        module: "cluster",
      })
    );

    dispatch(
      clusterFormActions.validateField({
        name: `nodePools.${poolIndex}.domains.0.dns.spec.dnsName`,
        module: "cluster",
      })
    );
  };
}

function mapDomains(isMaster) {
  return function mappingFn(domain, index) {
    if (isMaster) {
      return { ...domain, ipamDisabled: index > 0 };
    }
    return domain;
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    let newDomain = generateDomain();
    if (nodePool.isMaster) {
      newDomain = {
        ...newDomain,
        parentPoolUid: nodePool.domains[0].parentPoolUid,
      };
    }

    let domains = [...nodePool.domains];
    domains.push(newDomain);
    domains = domains.map(mapDomains(nodePool.isMaster));

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const poolIndex = pathParts[1];
    const domainIndex = pathParts[3];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    let domains = [...nodePool.domains];
    domains.splice(domainIndex, 1);
    domains = domains.map(mapDomains(nodePool.isMaster));

    if (domains.length === 0) {
      domains.push(generateDomain());
    }
    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `nodePools.${poolIndex}.domains.${domainIndex}.${field}`
    );
    const formErrors = getState().forms.cluster.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: updatedErrors,
      })
    );

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function hasCloudAccounts(cloudAccounts) {
  return function checker(cloudType) {
    let environment = cloudType;
    if (MANAGED_TO_PURE_ENVIRONMENT[cloudType]) {
      environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType];
    }

    return cloudAccounts?.find((cloud) => cloud.kind === environment);
  };
}

export function onCloudSelectorChange(cloudType) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudAccounts = cloudAccountsSummaryFetcher.selector(state)?.result;
    const hasAccount = hasCloudAccounts(cloudAccounts)(cloudType);

    if (hasAccount) {
      dispatch(
        clusterFormActions.onChange({
          name: "cloudType",
          module: "cluster",
          value: cloudType,
        })
      );
    } else {
      historyService.push("/settings/cloudaccounts");
    }
  };
}

export function openDnsSettings() {
  return async function thunk(dispatch, getState) {
    const formData = getState().forms?.cluster?.data;
    const dns = formData.nodePools[0].domains[0].dns;
    const cloudAccount = getSelectedCredential(getState());
    const updates = {
      network: formData.nodePools[0].domains[0].network,
      datacenter: formData.datacenter,
      privateGatewayUid: cloudAccount.metadata.annotations.overlordUid,
    };

    await dispatch(
      openInlineModal((dns) => {
        dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: "nodePools.0.domains.0.dns",
            value: dns,
          })
        );

        dispatch(
          clusterFormActions.validateField({
            name: `nodePools.0.domains.0.dns.spec.dnsName`,
            module: "cluster",
          })
        );
      }, dns?.metadata?.uid)
    );

    if (!dns) {
      dispatch(
        dnsFormActions.batchChange({
          module: "dnsMapping",
          updates,
        })
      );
    }
  };
}

export function selectProject(project) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          project,
          region: "",
          network: "",
        },
      })
    );
    dispatch(regionsByProjectFetcher.fetch());
    dispatch(resetNextStepFields());
  };
}

export function selectNetwork(network) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "network",
        value: network,
      })
    );
  };
}

function parseResultsErrors(results = []) {
  return results.reduce((acc, result) => {
    if (result.errors?.length) {
      const errors = result.errors.map((error) => ({
        ...error,
        displayName: result.displayName,
      }));
      return [...acc, ...errors];
    }
    return acc;
  }, []);
}

export function validateCluster() {
  return async (dispatch, getState) => {
    const state = getState();
    const clusterFormData = state.forms.cluster?.data;
    const payload = getPayload(state);
    const selectedCloud = getSelectedCloud(state);
    const errors = await dispatch(
      clusterFormActions.validateForm({ module: "cluster" })
    );
    let response = null;

    if (errors && errors.length) return;

    const promise = api.post(
      `v1alpha1/spectroclusters/${selectedCloud}/validate`,
      payload
    );

    dispatch({
      type: "VALIDATE_CLUSTER",
      promise,
    });

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to validate the cluster"
        ),
        description: error.message,
      });
      return;
    }

    const machinePools = response?.machinePools?.results || [];
    const packs = response?.packs?.results || [];
    const validationSuccess =
      response && isEmpty(machinePools) && isEmpty(packs);

    const workerPools = clusterFormData.nodePools.filter(
      (nodePool) => !nodePool.isMaster
    );

    const masterPool = clusterFormData.nodePools.find(
      (nodePool) => nodePool.isMaster
    );

    if (validationSuccess) {
      if (
        !masterPool?.allowWorkerCapability &&
        workerPools.length > 0 &&
        workerPools.every((pool) => pool.instanceOption === "onSpot")
      ) {
        onSpotWarningConfirm.open().then(
          () => dispatch(onSpotWarningAllow()),
          () => dispatch(onSpotWarningIgnore())
        );
        return;
      }

      dispatch(wizardActions.nextStep("cluster"));
      return;
    }

    const machinePoolsErrors = parseResultsErrors(machinePools);
    const packsErrors = parseResultsErrors(packs);

    machinePoolsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
    packsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
  };
}

export function onSpotWarningAllow() {
  return (dispatch, getState) => {
    let nodePools = [...getState().forms.cluster.data.nodePools];
    const masterPoolIndex = nodePools.findIndex(
      (nodePool) => nodePool.isMaster
    );
    const masterPool = nodePools[masterPoolIndex];

    nodePools.splice(masterPoolIndex, 1, {
      ...masterPool,
      allowWorkerCapability: true,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onSpotWarningIgnore() {
  return (dispatch) => {
    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onClusterFormSubmit() {
  return async (dispatch) => {
    try {
      await dispatch(clusterFormActions.submit({ module: "cluster" }));
    } catch (err) {
      if ((err || []).find((error) => error.field === "layers")) {
        notifications.warn({
          message: i18n.t(
            "There are pack validation errors. Please fix them in order to be able to deploy"
          ),
        });
      }
    }
  };
}

export function onInstanceTypeChange(value, poolIndex) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudType = getSelectedCloud(state);
    let result = { instanceTypes: [] };
    if (cloudType === "aws") {
      result = state.cluster.details.cloudConfigParams;
    }

    if (cloudType === "azure") {
      result = azureInstanceTypesFetcher.selector(state).result;
    }

    if (cloudType === "gcp") {
      result = gcpInstanceTypesFetcher.selector(state).result;
    }
    const nodePools = [...state.forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceType: value,
      instancePrice:
        result?.instanceTypes.find(
          (instanceType) => instanceType.type === value
        )?.price || 0,
      azs: [],
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.instanceType`,
      })
    );
  };
}

export function onAzsChange(value, poolIndex) {
  return (dispatch, getState) => {
    const state = getState();
    let nodePools = [...state.forms.cluster.data.nodePools];
    const currentNodePool = nodePools[poolIndex];
    const { isMaster } = currentNodePool;

    nodePools[poolIndex] = {
      ...currentNodePool,
      azs: value,
    };

    if (isMaster) {
      nodePools = nodePools.map((node) => ({
        ...node,
        azs: node.isMaster ? node.azs : [],
      }));
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function onPresetsChange({ value, group, presets }) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const packGuid = state.cluster?.create?.selectedLayer;
    const packData = state.forms?.cluster?.data?.[packGuid];
    const packValues = packData?.values || "";
    const packPresets = packData?.presets || {};
    const selectedPresets = presets.find(
      (preset) => preset.group === group && preset.value === value
    );
    const updatedValues =
      parseYAMLValues(packValues, selectedPresets) || packValues;

    const updatedPresets = {
      ...packPresets,
      [group]: value,
    };

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          [packGuid]: {
            ...packData,
            values: updatedValues,
            presets: updatedPresets,
          },
        },
      })
    );
  };
}

export function onInstanceOptionChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceOption: value,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.maxPricePercentage`,
      })
    );
  };
}

export function updatePolicyMenuSelection(newKey) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_POLICY_MENU_SELECTION",
      newKey,
    });
  };
}

export function onAksSetPoolAsSystem(value, index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];

    nodePools[index].isSystemNodePool = value;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: nodePools.map(
          (_, index) => `nodePools.${index}.isSystemNodePool`
        ),
      })
    );
  };
}

export const debouncedNodesFormChange = debounce(onNodesConfigFormChange, 2000);

function onNodesConfigFormChange(data) {
  return async (dispatch) => {
    let errors = [];
    const validations = nodePoolValidator.run(data.nodePools);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length) {
      dispatch(fetchClusterRate());
    }
  };
}

function fetchClusterRate() {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = getClusterRatePayload(state);
    const cloudType = getSelectedCloud(state);

    const promise = api.post(
      `v1alpha1/spectroclusters/${cloudType}/rate?periodType=hourly`,
      payload
    );

    dispatch({
      promise,
      type: "FETCH_CREATE_CLUSTER_RATE",
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err.message,
      });
      return;
    }

    return promise;
  };
}
