import ModalService from "services/modal";
import history from "services/history";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";

export const createOverlordModal = new ModalService("createOverlord");
export const overlordPin = keyedDataFetcher({
  selectors: ["overlord", "pin"],
  async fetchData([_1, _2, cloudType]) {
    return await api.get(
      `v1alpha1/overlords/pairing/code?cloudType=${cloudType}`
    );
  },
});

export const overlordOva = dataFetcher({
  selectors: ["overlord", "ova"],
  async fetchData() {
    return await api.get(`v1alpha1/overlords/vsphere/ova`);
  },
});

export function onCreateOverlordOpen(cloudType) {
  return function(dispatch) {
    const cloudTypeDispatcher = {
      vsphere: () => {
        dispatch(overlordPin.key(cloudType).fetch());
        dispatch(overlordOva.fetch());
      },
      openstack: () => {
        dispatch(overlordPin.key(cloudType).fetch());
      },
      maas: () => {
        dispatch(overlordPin.key(cloudType).fetch());
      },
    };

    cloudTypeDispatcher[cloudType]();

    createOverlordModal.open({ cloudType }).then(
      () => {},
      () => history.push("/settings/privatecloudgateways")
    );
  };
}
