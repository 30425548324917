import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import { TextButton } from "components/ui/Button";
import Enumeration from "components/common/Enumeration";
import { whiteSmoke } from "utils/constants/colors";

const RoleCardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  background: ${whiteSmoke};
  border-radius: 2px;
  padding: 10px;
`;

const ProjectName = styled.div`
  font-weight: bold;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled(TextButton)`
  height: 20px;
  text-align: right;
  min-width: 60px;
`;

const InheritedRoles = styled.div`
  display: flex;

  > span {
    margin-right: 5px;
  }
`;

const RoleCard = ({ projectRole, openModal, onRemove }) => {
  const { t } = useTranslation();
  const { project, roles, inheritedRoles, guid } = projectRole;
  const rolesNames = roles.map((role) => role.metadata.name);
  const inheritedRoleNames = [
    ...new Set(inheritedRoles.map((role) => role.metadata.name)),
  ].filter((roleName) => !rolesNames.includes(roleName));

  if (rolesNames.length === 0 && inheritedRoleNames.length === 0) {
    return null;
  }

  return (
    <RoleCardWrap>
      <div>
        <ProjectName>{project.metadata.name}</ProjectName>
        <Enumeration items={rolesNames} />
        {inheritedRoleNames.length > 0 && (
          <InheritedRoles>
            <span>{t("Team inherited")}:</span>
            <Enumeration items={inheritedRoleNames} />
          </InheritedRoles>
        )}
      </div>
      <ButtonWrap>
        <Button onClick={openModal(guid)} data-qa="edit_project_role">
          {t("Edit")}
        </Button>
        {!isEmpty(roles) && (
          <Button danger onClick={onRemove(guid)} data-qa="remove_project_role">
            {t("Remove")}
          </Button>
        )}
      </ButtonWrap>
    </RoleCardWrap>
  );
};

export default RoleCard;
