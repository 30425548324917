import { createSelector } from "reselect";
import { getEntity } from "utils/entities";
import { ProjectSchema } from "utils/schemas";
import { getUserProjects } from "state/auth/selectors";
import { projectOverviewModalService } from "../services/list";

export const getProjects = getEntity(
  (state) => state.list.project?.items.initial || [],
  [ProjectSchema]
);

export const getProjectUids = createSelector(getProjects, (projects) =>
  projects.map((project) => project.metadata.uid)
);

const getSelectedProjectModal = getEntity(
  () => projectOverviewModalService.data?.guid,
  ProjectSchema
);

export const getSelectedProjectPermissionEntity = createSelector(
  getUserProjects,
  getSelectedProjectModal,
  (userProjects, project) =>
    userProjects.find(
      (userProject) =>
        userProject.project.metadata.uid === project?.metadata.uid
    )
);
