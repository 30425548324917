import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import spaceMan from "assets/images/login/man_space_lost.png";
import FullPageOverlay from "components/common/layouts/Page";
import { white } from "utils/constants/colors";
import Title from "components/common/Title";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  background-color: white;
  max-width: 1200px;
  overflow: hidden;
  text-align: center;
  border-radius: 20px;

  margin: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const BoundingBox = styled.div`
  display: inline-block;
  padding: 20px;
  width: 320px;
`;
const Man = styled.img`
  margin: 0 20px;
  width: 50%;

  @media (max-height: 500px) {
    display: none;
  }
`;

const PageTitle = styled.h1`
  color: #4432f5;
`;

export default function Error() {
  const { t } = useTranslation();

  return (
    <FullPageOverlay>
      <Title>{t("Page Not Found | Spectro")}</Title>
      <ContentWrap>
        <Content>
          <BoundingBox>
            <PageTitle>{t("404 - Page not found")}</PageTitle>
            <br />
            <h2>{t("The page you requested could not be found.")}</h2>
            <br />
            <p>{t("We are sorry. Here are some helpful links")}</p>
            <a href="https://console.spectrocloud.com/auth">
              {t("Back to Console")}
            </a>
            <br />
            <a href="https://spectrocloud.com">{t("spectrocloud.com")}</a>
          </BoundingBox>
          <Man src={spaceMan} />
        </Content>
      </ContentWrap>
    </FullPageOverlay>
  );
}
