import { createSelector } from "reselect";
import moment from "moment";
import uniq from "lodash/uniq";

import { ClusterProfileSchema, ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import {
  presentClusterProfileParams,
  groupPresetsOptions,
  generateEditorYamlSchema,
} from "utils/presenters";

import { getCurrentContext } from "state/auth/selectors";
import {
  clusterCertificatesFetcher,
  gaugeFetcher,
  utilizationFetcher,
} from "state/cluster/services";
import * as colors from "utils/constants/colors";
import { clusterErrorFetcher } from "../services";
import { round } from "utils/number";
import { BEGINNING_OF_TIME } from "utils/constants";
import i18next from "i18next";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import { clusterRbacsFetcher } from "state/rolebindings/services";

const nodeMock = (isConfigured, isProvisioning) => {
  function getStatus() {
    if (isProvisioning) {
      return "provisioning";
    }

    if (isConfigured) {
      return "configured";
    }

    return "pending";
  }
  return {
    metadata: {},
    spec: {
      phase: getStatus(),
    },
    status: {},
  };
};

export const getRawCluster = getEntity(
  (state) => state.cluster.details.currentClusterId,
  ClusterSchema
);

export const getClusterImport = createSelector(getRawCluster, (cluster) => {
  return cluster?.status?.clusterImport;
});

export const isClusterImportPending = createSelector(
  getClusterImport,
  (clusterImport) => {
    return clusterImport?.state === "ImportPending";
  }
);

export const isBrownfield = createSelector(
  getClusterImport,
  (clusterImport) => {
    return clusterImport?.isBrownfield || !!clusterImport?.state;
  }
);

export const isClusterProvisioning = createSelector(
  getRawCluster,
  (cluster) => {
    return cluster?.status?.state === "Provisioning";
  }
);

export const isClusterImporting = createSelector(getRawCluster, (cluster) => {
  const status = cluster?.status || {};
  const { clusterImport, state } = status;
  const isBrownfield = clusterImport?.isBrownfield || !!clusterImport?.state;

  return (
    isBrownfield && ["pending", "importing"].includes(state?.toLowerCase())
  );
});

function KiBtoGB(value) {
  return value && round(value / 976562, 2);
}

export const getMemoryGaugeMetrics = createSelector(
  gaugeFetcher.selector,
  (state) => state.cluster.details.includeMasters,
  ({ result }, includeMasters) => {
    if (!result) {
      return { data: [], labelData: {} };
    }

    const { memoryTotal, memoryRequest, masters } = result;

    const denominator = memoryTotal?.aggregation.sum;
    const numerator = memoryRequest?.aggregation.sum;

    const percentage = (numerator * 100) / denominator;

    const formattedNumerator = KiBtoGB(numerator);
    const formattedDenominator = KiBtoGB(denominator);

    let data = [
      {
        id: "requests",
        label: "requests",
        value: percentage || 0,
        color: colors.lavander,
      },
      {
        id: "undefined",
        label: "undefined",
        value: 100 - percentage || 1,
        color: colors.lightGray,
      },
    ];

    let labelData = {
      denominator: formattedDenominator,
      numerator: formattedNumerator,
      label: "Gb",
      chartLabel: "memory",
    };

    if (includeMasters) {
      const allNodesDenominator = masters?.memoryTotal?.aggregation.sum;
      const allNodesNumerator = masters?.memoryRequest?.aggregation.sum;

      const greyArea = 100 - (allNodesNumerator * 100) / allNodesDenominator;
      const nodesPercentage = (numerator * 100) / allNodesDenominator;
      const mastersPercentge = 100 - nodesPercentage - greyArea;

      data = [
        {
          id: "requests",
          label: "requests",
          value: nodesPercentage || 0,
          color: colors.lavander,
        },
        {
          id: "masters",
          label: "masters",
          value: mastersPercentge || 0,
          color: colors.darkLavander,
        },
        {
          id: "undefined",
          label: "undefined",
          value: greyArea || 1,
          color: colors.lightGray,
        },
      ];

      labelData = {
        ...labelData,
        denominator: KiBtoGB(allNodesDenominator),
        numerator: KiBtoGB(allNodesNumerator),
      };
    }

    return { data, labelData };
  }
);

export const getCPUGaugeMetrics = createSelector(
  gaugeFetcher.selector,
  (state) => state.cluster.details.includeMasters,
  ({ result }, includeMasters) => {
    if (!result) {
      return { data: [], labelData: {} };
    }

    const { cpuTotal, cpuRequest, masters } = result;

    const denominator = cpuTotal?.aggregation.sum / 1000;
    const numerator = cpuRequest?.aggregation.sum / 1000;

    const percentage = (numerator * 100) / denominator;

    let data = [
      {
        id: "requests",
        label: "requests",
        value: percentage || 0,
        color: colors.lavander,
      },
      {
        id: "undefined",
        label: "undefined",
        value: 100 - percentage || 1,
        color: colors.lightGray,
      },
    ];

    let labelData = {
      denominator,
      numerator,
      label: "Cores",
      chartLabel: "cpu",
    };

    if (includeMasters) {
      const allNodesDenominator = masters?.cpuTotal?.aggregation.sum / 1000;
      const allNodesNumerator = masters?.cpuRequest?.aggregation.sum / 1000;

      const greyArea = 100 - (allNodesNumerator * 100) / allNodesDenominator;
      const nodesPercentage = (numerator * 100) / allNodesDenominator;
      const mastersPercentge = 100 - nodesPercentage - greyArea;

      data = [
        {
          id: "requests",
          label: "requests",
          value: nodesPercentage || 0,
          color: colors.lavander,
        },
        {
          id: "masters",
          label: "masters",
          value: mastersPercentge || 0,
          color: colors.darkLavander,
        },
        {
          id: "undefined",
          label: "undefined",
          value: greyArea || 1,
          color: colors.lightGray,
        },
      ];

      labelData = {
        ...labelData,
        denominator: allNodesDenominator,
        numerator: allNodesNumerator,
      };
    }

    return { data, labelData };
  }
);

function mapChartPoints(metric, totalMetric, prop) {
  if (!metric?.points?.length) {
    return [];
  }

  return metric.points?.map((point) => {
    let value = point[prop];
    if (!value) {
      value = point["value"];
    }

    return {
      x: point.timestamp,
      y: round((value / totalMetric?.aggregation.avg) * 100, 2),
    };
  });
}

export const getCpuUtilizationMetrics = createSelector(
  utilizationFetcher.selector,
  ({ result }) => {
    if (!result) {
      return [];
    }

    const { cpuUsage, cpuTotal, period } = result;

    const values = {
      avg: (cpuUsage?.aggregation?.avg / cpuTotal?.aggregation?.avg) * 100,
      max: (cpuUsage?.aggregation?.max / cpuTotal?.aggregation?.max) * 100,
    };

    const maxLabel = {
      label: "Maximum",
      value: Number.isNaN(values.max) ? "-" : round(values.max, 2),
      color: colors.lavander,
    };
    const maxData = {
      id: "max",
      color: colors.lavander,
      data: mapChartPoints(cpuUsage, cpuTotal, "max"),
    };
    let statistics = [
      {
        label: "Average",
        value: Number.isNaN(values.avg) ? "-" : round(values.avg, 2),
        color: period ? colors.lightBlue : colors.lavander,
      },
    ];
    let chartData = [
      {
        id: "avg",
        color: period ? colors.lightBlue : colors.lavander,
        data: mapChartPoints(cpuUsage, cpuTotal, "avg"),
      },
    ];

    if (period) {
      statistics = [...statistics, maxLabel];
      chartData = [...chartData, maxData];
    }

    const data = {
      label: "CPU Utilization %",
      statistics,
      chartData,
    };

    return data;
  }
);

export const getMemoryUtilizationMetrics = createSelector(
  utilizationFetcher.selector,
  ({ result }) => {
    if (!result) {
      return [];
    }

    const { memoryUsage, memoryTotal, period } = result;

    const values = {
      avg:
        (memoryUsage?.aggregation?.avg / memoryTotal?.aggregation?.avg) * 100,
      max:
        (memoryUsage?.aggregation?.max / memoryTotal?.aggregation?.max) * 100,
    };

    const maxLabel = {
      label: "Maximum",
      value: Number.isNaN(values.max) ? "-" : round(values.max, 2),
      color: colors.lavander,
    };
    const maxData = {
      id: "max",
      color: colors.lavander,
      data: mapChartPoints(memoryUsage, memoryTotal, "max"),
    };

    let statistics = [
      {
        label: "Average",
        value: Number.isNaN(values.avg) ? "-" : round(values.avg, 2),
        color: colors.lightBlue,
      },
    ];

    let chartData = [
      {
        id: "avg",
        color: period ? colors.lightBlue : colors.lavander,
        data: mapChartPoints(memoryUsage, memoryTotal, "avg"),
      },
    ];

    if (period) {
      statistics = [...statistics, maxLabel];
      chartData = [...chartData, maxData];
    }

    const data = {
      label: "Memory Utilization %",
      statistics,
      chartData,
    };

    return data;
  }
);

export const getChartMetrics = createSelector(
  getCpuUtilizationMetrics,
  getMemoryUtilizationMetrics,
  (cpu, memory) => [cpu, memory]
);

export const getChartPoints = createSelector(
  utilizationFetcher.selector,
  (utilization) => {
    if (!utilization.result) {
      return 0;
    }

    return utilization.result.memoryUsage?.points?.length || 0;
  }
);

export const isKubeconfigReady = createSelector(getRawCluster, (cluster) => {
  const kubeMeta = cluster.status?.kubeMeta;

  return !!kubeMeta?.hasKubeConfig || !!kubeMeta?.hasKubeConfigClient;
});

export const getKubernetesConfigUrl = createSelector(
  getRawCluster,
  getCurrentContext,
  (cluster, currentContext) => {
    const kubeMeta = cluster.status?.kubeMeta;
    const suffix = !!kubeMeta?.hasKubeConfigClient
      ? "kubeconfigclient"
      : "kubeconfig";
    let baseUrl = `/v1alpha1/spectroclusters/${cluster.metadata.uid}/assets/${suffix}`;
    if (currentContext.projectUid) {
      return `${baseUrl}?ProjectUid=${currentContext.projectUid}`;
    }

    return baseUrl;
  }
);

export const getCluster = createSelector(
  getRawCluster,
  (state) => state.cluster.nodes.desiredNodePoolSizes,
  isClusterProvisioning,
  (cluster, desiredNodePoolSizes, isProvisioning) => {
    if (!cluster) {
      return cluster;
    }

    const type = cluster.spec.cloudType ? "cloud" : "baremetal";
    const { cloudConfig } = cluster.spec;
    if (!cloudConfig) {
      return cluster;
    }
    const nodePoolSizes = {
      masters: 0,
      workers: 0,
    };

    const nodePools = cloudConfig.spec.machinePoolConfig.map((nodePool) => {
      if (nodePool.isControlPlane === true) {
        nodePoolSizes.masters = nodePoolSizes.masters + nodePool.size;
      } else {
        nodePoolSizes.workers = nodePoolSizes.workers + nodePool.size;
      }
      if (nodePool?.nodes?.length === nodePool.size) {
        return nodePool;
      }

      const maxNodes = Math.max(
        nodePool.size,
        nodePool?.nodes?.length || 0,
        desiredNodePoolSizes[nodePool.name] || 0
      );

      let machines = nodePool?.nodes || [];
      const isNodePoolConfigured =
        nodePool.instanceType && nodePool.azs?.length;
      const nodes = Array.from({ length: maxNodes }).map((_, index) => {
        return (
          machines[index] || {
            ...nodeMock(isNodePoolConfigured, isProvisioning),
            isPlaceholder: true,
          }
        );
      });

      if (["Deleted", "Deleting"].includes(cluster.status.state)) {
        return { ...nodePool, nodes: machines };
      }

      return { ...nodePool, nodes };
    });

    return {
      ...cluster,
      type,
      spec: {
        ...cluster.spec,
        machinePoolConfig: nodePools,
        nodePoolSizes,
      },
    };
  }
);

const CONDITION_CATEGORIES = [
  {
    type: "Provisioning",
    conditions: [
      {
        category: "preparation",
        conditions: [
          "ImageResolutionDone",
          "IpAllocationDone",
          "BootstrappingDone",
        ],
      },
      {
        category: "deployment",
        conditions: [
          "ImageCustomizationDone",
          "CloudInfrastructureReady",
          "ControlPlaneNodeAdditionDone",
          "WorkerNodeAdditionDone",
        ],
      },
      {
        category: "configuration",
        conditions: ["AddOnDeploymentDone"],
      },
    ],
  },
  {
    type: "Deleting",
    conditions: [
      {
        category: "preparation",
        conditions: ["DeProvisionDone"],
      },
      {
        category: "cleanup",
        conditions: [
          "CloudInfrastructureCleanedUp",
          "ControlPlaneNodeDeletionDone",
          "WorkerNodeDeletionDone",
        ],
      },
    ],
  },
];

export const getClusterProfile = createSelector(getCluster, (cluster) => {
  return cluster.spec.clusterprofile;
});

function getComposedCondition({ type, conditions }, rawConditions) {
  const subConditions = rawConditions.filter((condition) =>
    [conditions].includes(condition.type)
  );
  let state = "pending";
  let status = subConditions.map((condition) => condition.reason).join("");

  if (subConditions.length) {
    state = "loading";

    const isDone = subConditions.every(
      (condition) => condition.status === "True"
    );
    if (isDone) {
      state = "success";
      status = [];
    }

    const failedCondition = subConditions.find(
      (condition) => condition.reason === "Failure"
    );
    if (failedCondition) {
      state = "error";
      status = failedCondition.message;
    }
  }

  return {
    type,
    state,
    status,
  };
}

function getNormalizedCondition(condition, type) {
  let state = "pending";
  let status = condition?.reason || "";

  if (condition) {
    state = "loading";

    if (["True", "true"].includes(condition.status)) {
      state = "success";
      status = "";
    }

    if (condition.status === "False" && condition.reason === "Failure") {
      state = "error";
      status = condition.message;
    }
  }

  return {
    type: condition?.type || type,
    state,
    status,
  };
}

const getClusterRawConditions = createSelector(getCluster, (cluster) => {
  return (cluster.status?.conditions || []).filter(
    (condition) => condition.type !== "Unknown"
  );
});

export const getClusterConditions = createSelector(
  getCluster,
  getClusterRawConditions,
  (cluster, rawConditions) => {
    const clusterState = cluster.status?.state;
    const cloudType = cluster.spec?.cloudConfig?.kind;
    const isStaticIP = cluster.spec?.cloudConfig?.spec?.clusterConfig?.staticIp;
    const IP_ALLOCATION_TYPE = "IpAllocationDone";

    if (clusterState === "Running") {
      const conditionsInProgress = rawConditions.filter(
        (condition) => condition.status === "False"
      );
      if (!conditionsInProgress.length) {
        return [];
      }

      return [
        {
          category: "",
          conditions: conditionsInProgress.map(getNormalizedCondition),
        },
      ];
    }

    const currentStage = CONDITION_CATEGORIES.find(
      (stage) => stage.type === clusterState
    );

    if (!currentStage) {
      return [];
    }

    return currentStage.conditions.map(({ category, conditions }) => {
      let categoryConditions = conditions.map((type) => {
        if (typeof type !== "string") {
          return getComposedCondition(type, rawConditions);
        }

        const condition = rawConditions.find(
          (condition) => condition.type === type
        );

        return getNormalizedCondition(condition, type);
      });

      // BET-1134 if deletion then ControlPlaneNodeDeletionDone, WorkerNodeDeletionDone are successful if CloudInfrastructureCleanedUp is
      const CloudInfrastructureCleanedUp = categoryConditions.find(
        ({ type }) => type === "CloudInfrastructureCleanedUp"
      );
      categoryConditions = categoryConditions.map((condition) => {
        if (
          !["ControlPlaneNodeDeletionDone", "WorkerNodeDeletionDone"].includes(
            condition.type
          ) ||
          !CloudInfrastructureCleanedUp
        ) {
          return condition;
        }

        return {
          ...condition,
          state:
            CloudInfrastructureCleanedUp.state !== "success"
              ? "pending"
              : condition.state,
        };
      });

      if (!(cloudType === "vsphere" && isStaticIP)) {
        categoryConditions = categoryConditions.filter(
          (condition) => condition.type !== IP_ALLOCATION_TYPE
        );
      }
      if (["eks", "aks", "gke"].includes(cloudType)) {
        categoryConditions = categoryConditions.filter(
          (condition) => condition.type !== "ImageCustomizationDone"
        );
      }

      let state = "";
      const isDone = categoryConditions.every(
        (condition) => condition.state === "success"
      );
      const hasError = categoryConditions.find(
        (condition) => condition.state === "error"
      );

      const isLoading = categoryConditions.find(
        (condition) => condition.state === "loading"
      );

      if (isDone) {
        state = "success";
      }

      if (hasError) {
        state = "error";
      }

      return {
        category,
        conditions: categoryConditions,
        state,
        isLoading,
      };
    });
  }
);

// TODO: this should be replaced with clusterProfileTemplates.find(template => template.type !== "addon")
export const getClusterProfileParams = createSelector(getCluster, (cluster) => {
  const packs = (cluster?.spec?.clusterProfileTemplates || []).reduce(
    (acc, template) => [...acc, ...template.packs],
    []
  );
  return presentClusterProfileParams(packs);
});

export const isClusterActive = createSelector(getCluster, (cluster) => {
  return cluster?.status?.state === "Running";
});

export const isClusterUpdating = createSelector(
  getCluster,
  getClusterRawConditions,
  (cluster, rawConditions) => {
    const inProgress = rawConditions.some(
      (condition) => condition.status === "False"
    );

    return cluster?.status?.state === "Running" && inProgress;
  }
);

function isUpdateNotificationActionable(notification) {
  const events = notification.action?.events;
  if (!events) {
    return;
  }

  if (notification?.isDone) {
    return;
  }

  return notification;
}

export const getUpdateNotification = createSelector(
  getRawCluster,
  (cluster) => {
    const notifications = cluster?.notifications || [];
    return notifications.find(isUpdateNotificationActionable);
  }
);

export const isEventConfirmed = createSelector(getRawCluster, (cluster) => {
  return cluster?.notifications?.some(
    (notification) => notification?.action?.isDone === false
  );
});

export const canUpdateCluster = createSelector(
  getUpdateNotification,
  isEventConfirmed,
  isClusterActive,
  isClusterProvisioning,
  getClusterRawConditions,
  (notification, isEventConfirmed, isActive, isProvisioning, conditions) => {
    const isAlmostReady = !!conditions.find(
      (condition) => condition.type === "ControlPlaneNodeAdditionDone"
    );
    return (
      !!notification &&
      (isActive || (isProvisioning && isAlmostReady)) &&
      isEventConfirmed
    );
  }
);

export const isClusterDeleted = createSelector(getRawCluster, (cluster) => {
  return cluster.status?.state === "Deleted";
});

export const isClusterDeleting = createSelector(getRawCluster, (cluster) => {
  return cluster.status?.state === "Deleting";
});

export const getClusterErrors = createSelector(
  clusterErrorFetcher.selector,
  ({ isLoading, result }) => {
    if (!result) {
      return [];
    }
    return result.items?.slice(0, 3);
  }
);

export const showInfoNotification = createSelector(
  getRawCluster,
  (state) => state.cluster.details.scopePackValues,
  (cluster, scopePackValues) => {
    if (scopePackValues.length > 0) {
      return false;
    }

    return cluster?.notifications?.some(
      (notification) => notification?.action?.isInfo
    );
  }
);

const getSelectedPack = createSelector(
  getClusterProfileParams,
  (state) => state.cluster?.details?.currentPackGuid,
  (clusterProfileParams, packGuid) => {
    return clusterProfileParams.find((pack) => pack.guid === packGuid);
  }
);

export const getPresetsOptions = createSelector(
  getSelectedPack,
  (selectedPack) => {
    return groupPresetsOptions(selectedPack?.spec?.presets);
  }
);

export const getEditorSchema = createSelector(
  getSelectedPack,
  (selectedPack) => {
    return generateEditorYamlSchema(selectedPack?.spec?.schema);
  }
);

export const getDefaultPackValues = createSelector(
  getClusterProfileParams,
  (state) => state.cluster?.details?.currentPackGuid,
  (clusterProfileParams, packGuid) => {
    return clusterProfileParams.find((params) => params.guid === packGuid)
      ?.values;
  }
);

export const getResolvedValues = createSelector(
  getRawCluster,
  (cluster) => cluster?.status?.resolved || {}
);

export const isReadOnlyCategory = createSelector(
  isBrownfield,
  (state) => state?.location?.params?.clusterCategory,
  (isBrownfield, clusterCategory) =>
    isBrownfield || clusterCategory === "privatecloudgateways"
);

export const getCertificatesCount = createSelector(
  clusterCertificatesFetcher.selector,
  ({ result }) => {
    return result?.items?.length;
  }
);

export const areCertificatesLoading = createSelector(
  (state) => state.cluster.details.isLoading,
  clusterCertificatesFetcher.selector,
  (isClusterLoading, { isLoading }) => {
    return isLoading || isClusterLoading;
  }
);

export const getOnDemandPatchAfter = createSelector(getCluster, (cluster) => {
  return cluster?.spec?.clusterConfig?.machineManagementConfig?.osPatchConfig
    ?.onDemandPatchAfter;
});

export const isOnDemandPatchActive = createSelector(
  getOnDemandPatchAfter,
  (onDemandPatchAfter) => {
    return (
      onDemandPatchAfter &&
      onDemandPatchAfter !== BEGINNING_OF_TIME &&
      moment().diff(moment(onDemandPatchAfter)) < 0
    );
  }
);

export const getClusterProfileTemplate = createSelector(
  getRawCluster,
  getEntity((state) => state.cluster.details.profiles, [ClusterProfileSchema]),
  (cluster, entityProfileTemplates) => {
    const clusterProfileTemplates = cluster.spec.clusterProfileTemplates || [];

    return entityProfileTemplates.map((profile) => {
      const profileTemplate = clusterProfileTemplates.find(
        (profileTemplate) => profileTemplate.uid === profile.metadata.uid
      );

      return {
        ...profile,
        spec: {
          ...profile.spec,
          published: {
            packs: profile.spec.packs
              .map((pack) => {
                const clusterPack = (profileTemplate?.packs || []).find(
                  (templatePack) => {
                    return pack.metadata.uid === templatePack.packUid;
                  }
                );

                if (!clusterPack) {
                  return undefined;
                }

                return {
                  ...pack,
                  guid: clusterPack.guid,
                  manifests: clusterPack.manifests,
                  spec: {
                    ...pack.spec,
                    tag: clusterPack?.tag,
                  },
                };
              })
              .filter(Boolean),
          },
        },
        type: profile.spec.type,
      };
    });
  }
);

function extractPackFromEvent(event, [clusterInfo, profileInfo] = []) {
  let packSpec = event.pack.spec;
  let eventMeta = { message: event.message, type: event.type };
  const newManifest = {
    ...event.manifest,
    event: eventMeta,
    isDisabled: event?.type === "PackManifestDelete",
  };

  let manifests = [];
  if (event.manifest) {
    manifests = [newManifest];
  }
  if (event.type === "PackCreate") {
    manifests = (clusterInfo?.spec?.manifests || []).map((manifest) => ({
      ...manifest,
      event: { type: "PackManifestCreate" },
    }));
    packSpec = clusterInfo?.spec || event.pack.spec;
  }

  const packMeta = {
    metadata: event.pack.metadata,
    guid: event.pack.guid,
    logo: packSpec?.logoUrl,
    name: packSpec?.displayName || packSpec?.name || event.pack.metadata.name,
    tag: packSpec?.tag || packSpec?.version,
    event: eventMeta,
    manifests,
    type: event.packType,
    isDisabled: event?.type === "PackDelete",
  };

  if (event.packType === "manifest") {
    if (event.type === "PackManifestUpdate") {
      eventMeta = {
        type: "PackValuesUpdate",
        message: i18next.t("{{packName}} values are updated", {
          packName: event.packName,
        }),
      };
    }

    return {
      ...packMeta,
      event: eventMeta,
      tag: null,
    };
  }

  return packMeta;
}

export const getUpdateNotificationProfiles = createSelector(
  getUpdateNotification,
  (state) => state.cluster.details.notification.divergences,
  (updateNotification, divergencies) => {
    const profiles = [];

    if (!updateNotification) {
      return [];
    }

    updateNotification.events.forEach((event) => {
      const existingProfile = profiles.find((profile) => {
        return profile.metadata.uid === event.profileUid;
      });

      const eventMeta = { message: event.message, type: event.type };
      const newPack = extractPackFromEvent(
        event,
        divergencies[event.profileUid]?.[event.packName]?.items
      );

      if (!existingProfile) {
        profiles.push({
          metadata: event.profile.metadata,
          guid: event.profile.guid,
          spec: {
            packs: [newPack],
          },
        });

        return;
      }

      const existingPack = existingProfile.spec.packs.find(
        (pack) => pack.metadata.name === event.packName
      );

      if (!existingPack) {
        existingProfile.spec.packs.push(newPack);
        return;
      }

      if (event.manifest) {
        existingPack.manifests.push(newPack?.manifests?.[0]);
        return;
      }

      existingPack.event = eventMeta;
    });

    return profiles;
  }
);

export const getClusterDivergencies = createSelector(
  getUpdateNotificationProfiles,
  (state) => state.cluster.details.notification.divergences,
  (profiles, divergencies) => {
    return Object.keys(divergencies).reduce((accumulator, profileKey) => {
      const profile = profiles.find(
        (profile) => profile.metadata.uid === profileKey
      );
      Object.keys(divergencies[profileKey]).forEach((packName) => {
        const packVersion = profile?.spec?.packs?.find(
          (pack) => pack.metadata.name === packName
        );
        const [clusterInfo, profileInfo] = divergencies[profileKey][
          packName
        ]?.items;

        if (!packVersion) {
          return;
        }

        if (clusterInfo.spec.isValuesOverridden) {
          if (profileInfo) {
            accumulator[packVersion.guid] = {
              values: getPackValuesWithoutPresetsComment(
                profileInfo?.spec?.values
              ),
              isInversed: clusterInfo.spec.isValuesOverridden,
            };
          }
        } else {
          if (packVersion?.event?.type === "PackCreate") {
            accumulator[packVersion.guid] = {
              isInversed: true,
            };
          } else {
            accumulator[packVersion.guid] = {
              values: getPackValuesWithoutPresetsComment(
                clusterInfo?.spec?.values
              ),
              isInversed: clusterInfo.spec.isValuesOverridden,
            };
          }
        }

        const manifests = packVersion.manifests;

        if (Array.isArray(manifests)) {
          manifests.forEach((manifest) => {
            const parentUid = manifest.parentUid;
            const associatedManifest = profileInfo?.spec?.manifests?.find(
              (profileManifest) => profileManifest.uid === parentUid
            );

            if (associatedManifest) {
              accumulator[manifest.guid] = {
                values: associatedManifest.content,
                isInversed: manifest.isOverridden,
              };
            } else {
              accumulator[manifest.guid] = {
                values: manifest.content,
                isInversed: manifest.isOverridden,
              };
            }
          });
        }
      });

      return accumulator;
    }, {});
  }
);

export const canDownloadLogs = createSelector(
  isClusterImporting,
  (state) => state.location?.params,
  (isImporting, params) => {
    const { clusterCategory, id } = params || {};
    if (isImporting) {
      return false;
    }
    if (clusterCategory === "privatecloudgateways" && id === "system") {
      return false;
    }
    return true;
  }
);

export const getInitialClusterRoleBindings = createSelector(
  getRawCluster,
  clusterRbacsFetcher.selector,
  (cluster, { result }) => {
    const clusterRbac =
      cluster?.spec?.clusterConfig?.clusterRbac?.map((item) => item.uid) || [];
    const defaultRoleBindings = (result || [])
      .filter((item) => item.spec?.isDefault)
      .map((item) => item.metadata?.uid);

    return uniq([...defaultRoleBindings, ...clusterRbac]);
  }
);
