export function round(num, decimals) {
  return +(Math.round(num + `e+${decimals}`) + `e-${decimals}`);
}

export function formatToKilocoreHours(cores, decimals = 3) {
  return round(cores / 1000, decimals);
}

export function formatToUSCurrency(amount, currency) {
  if (amount === undefined || currency === undefined) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    maximumFractionDigits: 4,
  }).format(amount);
}

export function allowIntegerOnly(value) {
  const parsedValue = parseInt(value);

  if (/^[0-9]+$/.test(parsedValue)) {
    return parsedValue;
  }

  return "";
}
