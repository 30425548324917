import React from "react";
import uuid from "uuid/v4";
import { notification as notifications } from "antd";
import { Provider } from "react-redux";
import { getAuditHeaders } from "state/audit/actions";
import store from "services/store";
import i18next from "i18next";
import AuditNote, { AddNoteButton } from "components/common/AuditNote";

function openNoteNotification(promise, notificationId) {
  store.dispatch(getAuditHeaders(promise));
  const key = uuid();

  notifications.open({
    message: i18next.t("Add a note for your latest update"),
    key,
    duration: 0,
    description: (
      <Provider store={store}>
        <AuditNote
          closeNotification={() => {
            notifications.close(key);
            notifications.close(notificationId);
          }}
        />
      </Provider>
    ),
  });
}

notifications.config({
  duration: 10,
});

notifications.withAudit = ({ type = "success", message = "", promise }) => {
  const notificationId = uuid();
  notifications[type]({
    key: notificationId,
    duration: 20,
    message: message,
    ...(promise && {
      description: (
        <AddNoteButton
          onClick={() => openNoteNotification(promise, notificationId)}
        />
      ),
    }),
  });
};

export default notifications;
