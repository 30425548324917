import React from "react";
import { Tabs as TabsList } from "antd";
import styled from "styled-components";
import * as colors from "utils/constants/colors";
import Link from "components/common/History/Link";

const StyledNavLink = styled(Link)`
  display: inline-block;
  color: ${(props) => (props.selected ? colors.dodgerBlue : colors.midGray)};
  padding: 12px 16px;
  margin: -12px -16px;
`;

const Tabs = ({ tabs, children, activeKey, changeTab, ...rest }) => {
  const renderTabs = (item, index) => {
    let label = item.label;
    if (item.href) {
      label = (
        <StyledNavLink
          disabled={item.disabled}
          selected={activeKey === item.key}
          to={item.href}
          data-qa-value={item.key}
          data-qa-index={index}
        >
          {label}
        </StyledNavLink>
      );
    }
    return (
      <TabsList.TabPane
        key={item.key}
        tab={label}
        data-qa-value={item.key}
        data-qa-index={index}
        disabled={item.disabled}
      >
        {activeKey === item.key && children}
      </TabsList.TabPane>
    );
  };

  return (
    <TabsList {...rest} activeKey={activeKey} onChange={changeTab}>
      {tabs.map(renderTabs)}
    </TabsList>
  );
};

export default Tabs;
