import throttle from "lodash/throttle";
import api from "services/api";
import requestService from "./api/create";

const events = ["mousemove", "mousedown", "keydown", "mousewheel"];
const authApi = requestService();

class LoginService {
  constructor() {
    this.tokenRefresher = throttle(this.refreshToken, 4 * 60 * 1000, {
      leading: true,
      trailing: false,
    });
  }

  startWatchingEvents() {
    this.stopWatchingEvents();
    events.forEach((event) =>
      window.addEventListener(event, this.tokenRefresher)
    );
  }

  stopWatchingEvents() {
    events.forEach((event) =>
      window.removeEventListener(event, this.tokenRefresher)
    );
  }

  login = async (payload) =>
    authApi.post("v1alpha1/auth/authenticate", payload);

  async logout() {
    this.stopWatchingEvents();
    return authApi.post("v1alpha1/auth/logout");
  }

  refreshToken = async () => {
    // eslint-disable-next-line no-console
    console.info("refreshing the session", new Date().toString());
    try {
      await api.get("v1alpha1/auth/cookie/refresh");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    this.cookieTimestamp = new Date();
  };
}

export default new LoginService();
