import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

import language from "i18next";
import isUrl from "validator/lib/isURL";
import isIP from "validator/lib/isIP";
import isIPRange from "validator/lib/isIPRange";
import isFQDN from "validator/lib/isFQDN";
import isEmail from "validator/lib/isEmail";
import debouncePromise from "utils/debouncePromise";

const KUBERNETES_TAGS_REGEX = /^[a-zA-Z0-9]([-a-zA-Z0-9._]*[a-zA-Z0-9])?$/;

const K8sGuidelinesTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 400px;
  }
`;

export function Missing({ message = () => language.t("Missing field") } = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.length === 0 ? message() : false;
    }
    return ["", undefined, null].includes(value) ? message() : false;
  };
}

export function ApplyIf(conditionFn, continueFn) {
  return function validationFn(...args) {
    const isConditionMet = conditionFn(...args);
    if (!isConditionMet) {
      return false;
    }

    return continueFn(...args);
  };
}

const InvalidKubernetesTagsMessage = () => {
  const { t } = useTranslation();

  return (
    <K8sGuidelinesTooltip
      getPopupContainer={(triggerNode) => triggerNode}
      defaultVisible={true}
      placement={"right"}
      overlayStyle={{ width: 400 }}
      title={
        <div>
          <div>- {t("Value must be 63 characters or less")}</div>
          <div>
            - {t("Value must start and end with an alphanumeric character")}
          </div>
          <div>
            -{" "}
            {t(
              "Value can contain only alphanumeric characters, dots, dashes or underscores"
            )}
          </div>
        </div>
      }
    >
      {t(
        "One or more tags are invalid. Tags must respect kubernetes guidelines"
      )}{" "}
      <FontAwesomeIcon icon={faInfoCircle} />
    </K8sGuidelinesTooltip>
  );
};

export function areValidDomainTags({
  genericMessage = () => language.t("One or more domains are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((domain) => !isFQDN(domain));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidIPTags({
  genericMessage = () => language.t("One or more ips are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((ip) => !isIP(ip));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidKubernetesTags({
  genericMessage = () => <InvalidKubernetesTagsMessage />,
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const parsedValues = value
      .map((tag) => {
        if (tag.includes(":")) {
          return tag.split(":");
        }

        return tag;
      })
      .flat();

    const invalidTags = [
      ...new Set(
        parsedValues
          .map((tag) => {
            if (!tag.match(KUBERNETES_TAGS_REGEX)) {
              return value.find((originalTag) => originalTag.includes(tag));
            }

            return undefined;
          })
          .filter(Boolean)
      ),
    ];

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function isKubernetesName({
  allowDashStartEnd = false,
  dashRuleMessage = () => language.t("Field can't start or end with a dash"),
  genericMessage = () =>
    language.t("Field must contain only lowercase letters, dashes and numbers"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    if (
      !allowDashStartEnd &&
      [value[0], value[value.length - 1]].includes("-")
    ) {
      return dashRuleMessage();
    }

    return value.match(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/)
      ? false
      : genericMessage();
  };
}

export function MaxLength(
  maxNumber,
  {
    message = () =>
      language.t("A maximum of {{maxNumber}} characters are allowed", {
        maxNumber,
      }),
  } = {}
) {
  return (value) => (value && value.length > maxNumber ? message() : false);
}

export function isValidUrl(
  options,
  { message = () => language.t("Field must contain a valid url") } = {}
) {
  const defaultOptions = {
    protocols: ["http", "https"],
    require_protocol: true,
    require_tld: true,
    require_valid_protocol: true,
  };
  const urlOptions = { ...defaultOptions, ...options };

  return (value) => (value && !isUrl(value, urlOptions) ? message() : false);
}

export function isValidIPRange({
  message = () => language.t("Invalid IP Range"),
} = {}) {
  return (value) => (value && !isIPRange(value) ? message() : false);
}

export function isValidIP({ message = () => language.t("Invalid IP") } = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((ip) => !isIP(ip)) ? message() : false;
    }
    return value && !isIP(value) ? message() : false;
  };
}

export function isValidDomain({
  message = () => language.t("Invalid domain"),
} = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((domain) => !isFQDN(domain)) ? message() : false;
    }
    return value && !isFQDN(value) ? message() : false;
  };
}

export function isValidPrefix({
  message = () => language.t("Field must contain a value between 1 and 32"),
} = {}) {
  return (value) =>
    isNaN(value) || value < 1 || value > 32 ? message() : false;
}

export function isValidGateway({
  message = () => language.t("Invalid gateway"),
} = {}) {
  return (value) =>
    value && !isIPRange(value) && !isIP(value) ? message() : false;
}

export function isValidTagSelection({
  reservedTags = [],
  message = () =>
    language.t(
      "Invalid tag name. Following tag name prefixes are reserved: '{{tags}}'",
      {
        tags: reservedTags.join(","),
      }
    ),
} = {}) {
  const isReservedTagIncluded = (tag) =>
    reservedTags.some((reservedTag) =>
      tag.toLowerCase().startsWith(reservedTag.toLowerCase())
    );
  return (tags) =>
    tags?.some((tag) => isReservedTagIncluded(tag)) ? message() : false;
}

export function EmailType({
  message = () => language.t("Field must contain a valid email"),
} = {}) {
  return (value) => (value && !isEmail(value) ? message() : false);
}

export function EmptyString({
  message = () => language.t("Invalid field"),
} = {}) {
  return (value) => (value && !value.trim() ? message() : false);
}

export function DebouncedRule({ delay = 400 } = {}) {
  return (fn) => debouncePromise(fn, delay);
}
