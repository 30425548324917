import ListActions from "modules/list/actions";
import { RoleSchema } from "utils/schemas";
import api from "services/api";

export const rolesListActions = new ListActions({
  fetchData(query) {
    return api.get("v1alpha1/roles", query);
  },
  schema: [RoleSchema],
});

export function fetchRoles() {
  return {
    type: "FETCH_ROLES",
    promise: api.get("v1alpha1/roles").then((res) => res.items),
    schema: [RoleSchema],
  };
}
