import React from "react";
import i18next from "i18next";
import { createSelector } from "reselect";

import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
import store from "services/store";
import notifications from "services/notifications";

export function presentFlavorAsOption(flavor) {
  return {
    label: flavor.name,
    value: flavor.name,
    description: (
      <>
        <div>
          vCPUs: <strong>{flavor.vcpus}</strong>
        </div>
        <div>
          Memory: <strong>{flavor.memory} MB</strong>
        </div>
        <div>
          Disk size: <strong>{flavor.disk} GB</strong>
        </div>
      </>
    ),
  };
}

export function createOpenstackFormFactory(
  {
    formModuleName,
    formActions,
    getCloudAccountUid,
    getClusterConfig = (state) => state.forms[formModuleName].data,
  },
  { isOverlord = false, isNodes = false } = {}
) {
  const regionsFetcher = dataFetcher({
    selectors: ["openstack", "regions", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      try {
        const regions = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/regions`
        );

        return regions;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the domains"
          ),
          description: err?.message,
        });
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the regions"
          ),
          description: err?.message,
        });
      }
    },
  });

  const regionSelect = (state) => getClusterConfig(state).region;
  const domainSelect = (state) => getClusterConfig(state).domain;
  const projectSelect = (state) => getClusterConfig(state).project;

  const projectsFetcher = dataFetcher({
    selectors: ["openstack", "projects", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      try {
        const projects = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/projects`
        );

        return projects;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the projects"
          ),
          description: err?.message,
        });
      }
    },
  });

  const keyPairsFetcher = dataFetcher({
    selectors: [
      "openstack",
      "keypairs",
      getCloudAccountUid,
      regionSelect,
      domainSelect,
      projectSelect,
    ],
    fetchData: async ([_1, _2, cloudAccountUid, region, domain, project]) => {
      try {
        const keyPairs = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/keypairs`,
          {
            region,
            domain,
            project,
          }
        );

        return keyPairs;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the key pairs"
          ),
          description: err?.message,
        });
      }
    },
  });

  const flavorsFetcher = dataFetcher({
    selectors: [
      "openstack",
      "flavors",
      getCloudAccountUid,
      regionSelect,
      domainSelect,
      projectSelect,
    ],
    fetchData: async ([_1, _2, cloudAccountUid, region, domain, project]) => {
      try {
        const flavors = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/flavors`,
          {
            region,
            domain,
            project,
          }
        );
        const parsedFlavors = (flavors?.items || [])
          .filter((flavor) => flavor.vcpus >= 4 && flavor.memory >= 8192)
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        return parsedFlavors;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the flavors"
          ),
          description: err?.message,
        });
      }
    },
  });

  const networksFetcher = dataFetcher({
    selectors: [
      "openstack",
      "networks",
      getCloudAccountUid,
      regionSelect,
      domainSelect,
      projectSelect,
    ],
    fetchData: async ([_1, _2, cloudAccountUid, region, domain, project]) => {
      try {
        const networks = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/networks`,
          {
            region,
            domain,
            project,
          }
        );

        return networks;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the networks"
          ),
          description: err?.message,
        });
      }
    },
  });

  const azsFetcher = dataFetcher({
    selectors: [
      "openstack",
      "azs",
      getCloudAccountUid,
      regionSelect,
      domainSelect,
      projectSelect,
    ],
    fetchData: async ([_1, _2, cloudAccountUid, region, domain, project]) => {
      try {
        const azs = await nonProjectApi.get(
          `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}/properties/azs`,
          {
            region,
            domain,
            project,
          }
        );

        return azs;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the availability zones"
          ),
          description: err?.message,
        });
      }
    },
  });

  const getOpenstackNetworkSubnets = createSelector(
    networksFetcher.selector,
    (state) => getClusterConfig(state).network,
    ({ result }, selectedNetworkId) => {
      return (
        (result?.items || []).find(
          (network) => network.id === selectedNetworkId
        )?.subnets || []
      );
    }
  );

  const getOpenstackSelectedDomain = createSelector(
    regionsFetcher.selector,
    (state) => getClusterConfig(state).domain,
    ({ result }, selectedDomainId) => {
      return (result?.domains || []).find(
        (domain) => domain.name === selectedDomainId
      );
    }
  );

  // might get deprecated (can't use id for set defaultProject - only the defaultProject string)
  const getOpenstackSelectedProject = createSelector(
    projectsFetcher.selector,
    (state) => getClusterConfig(state).project,
    ({ result }, selectedProjectId) => {
      return (result?.items || []).find(
        (project) => project.id === selectedProjectId
      );
    }
  );

  const getOpenstackSelectedFlavor = createSelector(
    flavorsFetcher.selector,
    (state) => getClusterConfig(state).flavor,
    ({ result }, selectedFlavorName) => {
      return (result || []).find(
        (flavor) => flavor.name === selectedFlavorName
      );
    }
  );

  const getOpenstackSelectedNetwork = createSelector(
    networksFetcher.selector,
    (state) => getClusterConfig(state).network,
    ({ result }, selectedNetworkId) => {
      return (result?.items || []).find(
        (network) => network.id === selectedNetworkId
      );
    }
  );

  const getOpenstackAzs = createSelector(azsFetcher.selector, ({ result }) => {
    return (result?.azs || []).map((az) => ({
      label: az.name,
      value: az.name,
    }));
  });

  const getOpenstackFlavors = createSelector(
    flavorsFetcher.selector,
    ({ result }) => {
      return (result || []).map(presentFlavorAsOption);
    }
  );

  const getOpenstackSelectedNetworkSubnet = createSelector(
    getOpenstackNetworkSubnets,
    (state) => getClusterConfig(state).subnet,
    (subnets, selectedSubnetId) => {
      return (subnets || []).find((subnet) => subnet.id === selectedSubnetId);
    }
  );

  const fetchProperties = () => {
    const config = getClusterConfig(store.getState());
    if (!config.domain || !config.region || !config.project) {
      return;
    }

    return Promise.allSettled([
      store.dispatch(networksFetcher.fetch()),
      store.dispatch(keyPairsFetcher.fetch()),
      store.dispatch(flavorsFetcher.fetch()),
      store.dispatch(azsFetcher.fetch()),
    ]);
  };

  async function getDefaultsFromAccount(accountSpec) {
    await Promise.allSettled([
      store.dispatch(regionsFetcher.fetch()),
      store.dispatch(projectsFetcher.fetch()),
    ]);

    const state = store.getState();
    const regionsFetcherResult = regionsFetcher.selector(state)?.result;

    return {
      region:
        (regionsFetcherResult?.regions || []).find(
          (region) =>
            region.parentRegionId === accountSpec.parentRegion &&
            region.id === accountSpec.parentRegion
        )?.id || "",
      domain:
        (regionsFetcherResult?.domains || []).find(
          (domain) => domain.name === accountSpec.defaultDomain
        )?.name || "",
      project: accountSpec.defaultProject,
    };
  }

  return {
    actions: {
      async onAccountSelected(name, account) {
        store.dispatch(
          formActions.onChange({
            module: formModuleName,
            name,
            value: account.metadata.uid,
          })
        );

        const defaults = await getDefaultsFromAccount(account.spec);
        store.dispatch(
          formActions.batchChange({
            module: formModuleName,
            updates: defaults,
          })
        );
        fetchProperties();
      },
      onAccountFieldChange(name, value) {
        const isAccountValidated = store.getState().overlord.configure
          .isAccountValidated;

        store.dispatch(
          formActions.onChange({
            module: formModuleName,
            name,
            value,
          })
        );

        if (isAccountValidated) {
          store.dispatch(
            formActions.onChange({
              module: formModuleName,
              name: "password",
              value: "",
            })
          );
        }
      },
      onCloudFieldChange(name, value) {
        store.dispatch(
          formActions.onChange({
            module: formModuleName,
            name,
            value,
          })
        );

        store.dispatch(
          formActions.batchChange({
            module: formModuleName,
            updates: {
              sshKeyName: "",
              network: "",
              subnet: "",
            },
          })
        );

        fetchProperties();
      },
      onNetworkChange(value) {
        store.dispatch(
          formActions.batchChange({
            module: formModuleName,
            updates: {
              network: value,
              subnet: "",
            },
          })
        );

        const formErrors = store.getState().forms[formModuleName]?.errors;
        store.dispatch(
          formActions.updateErrors({
            module: "cluster",
            errors: formErrors.map((error) => {
              if (error.field.includes("subnet")) {
                return {
                  ...error,
                  result: false,
                };
              }

              return error;
            }),
          })
        );
      },
    },
    effects: {
      getDefaultsFromAccount,
      fetchProperties,
    },
    fetchers: {
      regionsFetcher,
      projectsFetcher,
      keyPairsFetcher,
      networksFetcher,
      flavorsFetcher,
    },
    selectors: {
      getOpenstackSelectedProject,
      getOpenstackSelectedDomain,
      getOpenstackSelectedNetwork,
      getOpenstackSelectedNetworkSubnet,
      getOpenstackNetworkSubnets,
      getOpenstackSelectedFlavor,
      getOpenstackAzs,
      getOpenstackFlavors,
    },
  };
}
