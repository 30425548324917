import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { CLUSTER_PROFILES } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const Create = React.lazy(() => import("./create"));
const Details = React.lazy(() => import("./details"));

export default createNamespace({
  namespace: "clusterprofiles",
  Component: function Main() {
    return (
      <>
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.ROOT}
          component={Index}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.CREATE}
          component={Create}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.DETAILS}
          component={Details}
        />
      </>
    );
  },
});
