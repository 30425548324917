import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import Button from "components/ui/Button";
import { lightGray } from "utils/constants/colors";

const TAB_WIDTH = 338;
const AUTOPLAY_SPEED = 4;

const StageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 24px 32px 0 0;

  .slick-slide,
  .slick-track {
    width: 268px !important;
  }

  > a {
    margin-top: 16px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  width: 300px;

  > strong {
    color: #4432f5;
    font-weight: bold;
  }
`;

const Tab = styled.div`
  width: ${TAB_WIDTH}px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  font-size: 13px;
  margin-bottom: 24px;
  padding: 8px 24px;
  position: relative;
  cursor: pointer;

  :last-child() {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: ${lightGray};
      border-radius: 4px;
    `}
`;

const ProgressBar = styled.div`
  width: 0px;
  height: 2px;
  background: #4432f5;
  position: absolute;
  left: 0;
  bottom: 0;

  ${(props) =>
    props.isActive &&
    css`
      width: ${TAB_WIDTH}px;
      transition: ${props.shouldTransition ? AUTOPLAY_SPEED : 0.5}s ease-in-out;
    `}
`;

function StageSlider({ options, stageName, description, docsHref }) {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(undefined);
  const [shouldAutoplay, setShouldAutoplay] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const beforeSlideChange = () => {
    shouldAutoplay &&
      setActiveSlide((prevSlide) =>
        options.length - 1 === prevSlide ? 0 : prevSlide + 1
      );
  };

  const onTabSelect = (index) => () => {
    setShouldAutoplay(false);
    sliderRef.current.goTo(index);
    setActiveSlide(index);
  };

  return (
    <StageWrapper>
      <CarouselWrapper>
        <Carousel
          autoplay={shouldAutoplay}
          autoplaySpeed={AUTOPLAY_SPEED * 1000}
          beforeChange={beforeSlideChange}
          dotPosition="right"
          dots={false}
          ref={(ref) => (sliderRef.current = ref)}
        >
          {options.map((option) => (
            <div key={option.key}>
              <img src={option.image} alt={`${option.key}`} />
            </div>
          ))}
        </Carousel>
        <a target="_blank" rel="noopener noreferrer" href={docsHref}>
          <Button secondary data-qa="goToDocs">
            {t("Learn more about {{ stageName }}", { stageName })}
          </Button>
        </a>
      </CarouselWrapper>
      <div>
        <Title>
          <strong>{stageName}</strong> - {description}
        </Title>
        {options.map((option, index) => {
          const isActive = index === activeSlide;
          return (
            <Tab
              key={option.key}
              isActive={isActive}
              onClick={onTabSelect(index)}
            >
              {option.tab}
              <ProgressBar
                isActive={isActive}
                shouldTransition={shouldAutoplay}
              />
            </Tab>
          );
        })}
      </div>
    </StageWrapper>
  );
}

export default StageSlider;
