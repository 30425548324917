import api from "services/api";
import store from "services/store";
import uuid from "uuid/v4";
import ProfileStackActions from "./actions";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import createList from "modules/list";
import { ClusterProfileSchema } from "utils/schemas";

export default class ProfileStackModule {
  constructor({ name = uuid() } = {}) {
    this.guid = name;
    this.profileSelectorModal = new ModalService();
    this.profileRemovalConfirm = new ModalService();
    this.listingActions = new ListActions({
      fetchData(query) {
        return api.get("v1alpha1/clusterprofiles", query);
      },
      schema: [ClusterProfileSchema],
    });

    this.actions = new ProfileStackActions({
      guid: this.guid,
      onChange: this.emitChange,
      profileSelectorModal: this.profileSelectorModal,
      profileRemovalConfirm: this.profileRemovalConfirm,
      listingActions: this.listingActions,
    });

    this.ListingModule = createList({
      actions: this.listingActions,
      schema: [ClusterProfileSchema],
    });
  }

  get state() {
    return store.getState().profileStack[this.guid];
  }

  get payload() {
    const state = this.state;
    if (!state) {
      return [];
    }

    const values = state.values;
    const markedForDeletion = state.markForDeletion;

    return state.profiles
      .filter((profile) => !markedForDeletion.includes(profile.guid))
      .map((profile) => {
        return {
          uid: profile.metadata.uid,
          packValues: profile.spec.published.packs.map((pack) => {
            let type = "spectro";
            if (pack.spec?.type === "manifest") {
              type = "manifest";
            }
            if (pack.spec?.type === "helmChart") {
              type = "helm";
            }

            const manifests = pack?.spec?.manifests
              ? pack.spec.manifests
              : pack?.pack?.manifests || [];

            return {
              tag: pack?.spec?.tag || pack.tag,
              name: pack.spec.name,
              type,
              values:
                pack.spec?.type === "manifest" ? undefined : values[pack.guid],
              manifests: manifests.map((manifest) => ({
                uid: manifest.uid,
                name: manifest.name,
                content: values?.[manifest.guid] || "",
              })),
            };
          }),
        };
      });
  }
}
