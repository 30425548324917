import dataFetcher from "modules/dataFetcher";

import { PackRegistriesSchema } from "utils/schemas";

import ModalService from "services/modal";
import api from "services/api";

export const HELM_MODULE = "helmregistries";
export const PACK_MODULE = "packregistries";
export const helmRegistryModal = new ModalService("helmRegistryModal");
export const packRegistryModal = new ModalService("packRegistryModal");

export const packRegistriesFetcher = dataFetcher({
  selectors: ["packregistries", () => packRegistryModal.data?.uid || ""],
  schema: PackRegistriesSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1alpha1/registries/pack/${uid}`);
  },
});

export const helmRegistriesFetcher = dataFetcher({
  selectors: ["helmregistries", () => helmRegistryModal.data?.uid || ""],
  schema: PackRegistriesSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1alpha1/registries/helm/${uid}`);
  },
});
