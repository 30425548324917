import { createSelector } from "reselect";
import isEqual from "fast-deep-equal";
import querystring from "querystring";
import { CLUSTER_PROFILES } from "utils/constants/routes";

export const isInitialProfileListEmpty = createSelector(
  (state) => state.list.clusterprofiles?.items.initial,
  (state) => state.list.clusterprofiles?.isLoading,
  (state) => state.list.clusterprofiles?.isInitializing,
  (state) => state.list.clusterprofiles?.query?.filter,
  (clusterProfiles, isLoading, isInitializing, filterQuery) => {
    const initialFilterQuery = {
      environment: [],
      profileName: {
        beginsWith: "",
      },
      profileType: [],
    };
    const isInitialState = isEqual(filterQuery, initialFilterQuery);
    return (
      !isLoading &&
      !isInitializing &&
      isInitialState &&
      (!clusterProfiles || clusterProfiles.length === 0)
    );
  }
);

export const getClusterProfilesLink = createSelector(
  (state) => state.forms?.profilesFilters?.data,
  (data) => {
    if (!data) {
      return CLUSTER_PROFILES.ROOT;
    }
    const query = querystring.stringify({
      search: "",
      cloudTypes: data.cloudTypes,
      profileTypes: data.profileTypes,
      order: data.order,
      sortBy: data.sortBy,
    });

    return `${CLUSTER_PROFILES.ROOT}?${query}`;
  }
);
