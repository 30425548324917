import i18next from "i18next";

import api from "services/api/index";

import { ClusterProfileSchema } from "utils/schemas";
import { pollClusterProfileNotifications } from "utils/tasks";

import {
  getRawClusterProfile,
  getUpdateNotification,
  getUpdateNotificationPacks,
} from "state/clusterprofile/selectors/details";

import { clusterProfileFormActions } from "./layerConfig";

import ModalService from "services/modal";
import notificationService from "services/notifications";
import flags from "services/flags";
import { updatesFormAction } from "../services";

export function fetchClusterProfile(
  uid,
  type = "FETCH_CLUSTER_PROFILE_DETAILS"
) {
  return function thunk(dispatch) {
    const promise = api.get(`v1alpha1/clusterprofiles/${uid}`);

    dispatch({
      type,
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function getClusterProfileByUid(uid) {
  return async function thunk(dispatch) {
    await dispatch(fetchClusterProfile(uid));

    pollClusterProfileNotifications.start();
  };
}

export function fetchClusterProfileNotifications() {
  return async (dispatch, getState) => {
    const clusterprofile = getRawClusterProfile(getState());

    if (!clusterprofile?.metadata.uid) {
      return;
    }

    if (!flags.has(["notifications"])) {
      return;
    }

    await dispatch({
      type: "FETCH_CLUSTER_PROFILE_NOTIFICATIONS",
      promise: api
        .get(
          `v1alpha1/notifications/clusterprofile/${clusterprofile?.metadata?.uid}?isDone=false`
        )
        .then((res) => {
          return {
            metadata: clusterprofile.metadata,
            notifications: res.items,
          };
        }),
      schema: ClusterProfileSchema,
    });
  };
}

export const clusterProfileUpdateConfirm = new ModalService(
  "clusterProfileUpdate"
);

export function notificationSelectLayer(layer) {
  return function thunk(dispatch) {
    dispatch({
      type: "NOTIFICATION_SELECT_LAYER",
      layer,
    });
  };
}

export function confirmClusterProfileUpdate() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const clusterprofile = getRawClusterProfile(state);
    const allNotifications = getUpdateNotification(state);

    if (allNotifications.length === 0) {
      return;
    }

    const actionableNotification = allNotifications.find(
      (value) => value.action.isInfo === false
    );
    const infoNotification = allNotifications.find(
      (value) => value.action.isInfo === true
    );
    clusterProfileUpdateConfirm.open().then(
      async () => {
        try {
          if (infoNotification) {
            await api.patch(`v1alpha1/${infoNotification.action.link}`);
          }
          if (actionableNotification) {
            const formData = getState().forms["profile-update-notification"]
              .data;
            const packs = getUpdateNotificationPacks(getState());
            const payload = {
              packs: packs
                .filter((pack) => !pack.isDisabled)
                .map((pack) => ({
                  name: pack.metadata.name,
                  values: formData[pack.guid],
                })),
            };
            await api.patch(
              `v1alpha1/${actionableNotification.action.link}`,
              payload
            );
          }

          dispatch(fetchClusterProfileNotifications());
          await dispatch(
            fetchClusterProfile(
              clusterprofile.metadata.uid,
              "REFRESH_CLUSTER_PROFILE_DETAILS"
            )
          );
          dispatch(
            clusterProfileFormActions.init({ module: "clusterprofile" })
          );
          pollClusterProfileNotifications.start();
        } catch (err) {
          notificationService.error({
            message: i18next.t(
              "Something went wrong when trying to confirm this notification"
            ),
            description: err.message,
          });

          return Promise.reject();
        }
      },
      async () => {
        if (infoNotification) {
          await api.patch(
            `v1alpha1/notifications/${infoNotification.metadata.uid}/ack`
          );
        }
        if (actionableNotification) {
          await api.patch(
            `v1alpha1/notifications/${actionableNotification.metadata.uid}/ack`
          );
        }
      }
    );

    await dispatch(
      updatesFormAction.init({ module: "profile-update-notification" })
    );

    const selectablePack = getUpdateNotificationPacks(getState()).find(
      (pack) => !pack.isDisabled
    );
    if (selectablePack) {
      dispatch(notificationSelectLayer(selectablePack));
    }
  };
}

export function fetchResolvedValues(uid) {
  return function(dispatch) {
    const promise = api.get(
      `v1alpha1/clusterprofiles/${uid}/packs/resolvedValues`
    );

    dispatch({
      type: "FETCH_RESOLVED_VALUES",
      promise: promise.then(({ resolved = {} } = {}) => ({
        metadata: { uid },
        status: {
          resolved,
        },
      })),
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}
