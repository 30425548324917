import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { TextButton } from "components/ui/Button";
import { whiteSmoke } from "utils/constants/colors";

const RoleCardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  background: ${whiteSmoke};
  border-radius: 2px;
  padding: 10px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled(TextButton)`
  height: 20px;
  text-align: right;
  min-width: 60px;
`;

const RoleCard = ({ loading, onRemove, tenantRole, inherited }) => {
  const { t } = useTranslation();

  return (
    <RoleCardWrap>
      <div>
        <span>{tenantRole.metadata.name}</span>{" "}
        {inherited && `(${t("team inherited")})`}
      </div>
      {!inherited && (
        <ButtonWrap>
          <Button
            disabled={loading}
            onClick={onRemove}
            data-qa="edit_tenant_role"
            danger
          >
            {t("Remove")}
          </Button>
        </ButtonWrap>
      )}
    </RoleCardWrap>
  );
};

export default RoleCard;
