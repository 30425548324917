import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { createForm } from "modules/form";
import { logout, acceptTermsAndAgreement } from "state/auth/actions";
import FullPageOverlay from "components/common/layouts/Page";
import Fields from "components/ui/Fields";
import Button from "components/ui/Button";
import { Link } from "react-router-dom";

import { white } from "utils/constants/colors";
import { ReactComponent as Logo } from "assets/icons/logo.svg";

const ContentWrap = styled.div`
  width: 546px;
  min-height: 504px;
  background: #0d1440;
  color: ${white};
  opacity: 0.8;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  p {
    margin: 20px 40px 0 40px;
  }
`;

const ActionsSection = styled.div`
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span`
  color: ${white};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`;

const StyledLogo = styled(Logo)`
  margin: 54px 0 45px 0;
`;

const FieldsWrap = styled.div`
  flex: 1;
  width: 100%;
  .ant-checkbox-wrapper {
    display: flex;
    color: ${white};
    white-space: initial;
    margin: 10px 64px;
    word-break: break-word;
    align-items: flex-start;
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
  }
  .ant-checkbox {
    top: 2px;
  }
  a {
    color: #5fd5f9;
  }
`;

const StyledLink = styled(Link)`
  color: #5fd5f9;
  margin: 28px 0 32px 0;
  font-size: 13px;
  line-height: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  border: 2px solid #5fd5f9;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  height: 48px;
  background-color: transparent;
  width: 100%;
  border-radius: 5px;
  color: ${white};

  :disabled {
    background-color: transparent;
    opacity: 0.3;
    border: 2px solid #5fd5f9;
    color: ${white};
  }
`;

const MSA_LINK = "http://www.spectrocloud.com/contracts/msa.pdf";

function ContractAcceptanceForm({ data, logout, acceptTermsAndAgreement }) {
  const { t } = useTranslation();

  function renderCheckboxLabel() {
    return (
      <Trans>
        Click here to indicate that you agree to the terms and conditions above.
      </Trans>
    );
  }

  return (
    <FullPageOverlay>
      <ContentWrap>
        <StyledLogo />
        <Title>{t("Terms & Conditions")}</Title>
        <p>
          Access to Spectro Cloud products and services is subject to the terms
          and conditions specified in the applicable agreement between Spectro
          Cloud and the Customer, or, if none exists, to the then - current
          applicable standard Spectro Cloud master service agreement (
          <a target="_blank" rel="noopener noreferrer" href={MSA_LINK}>
            {MSA_LINK}
          </a>
          ).
        </p>
        <FieldsWrap>
          <Fields.Checkbox data-qa="termsOfService" name="isContractAccepted">
            {renderCheckboxLabel()}
          </Fields.Checkbox>
        </FieldsWrap>
        <ActionsSection>
          <StyledButton
            data-qa="continue"
            disabled={!data.isContractAccepted}
            onClick={acceptTermsAndAgreement}
          >
            {t("Continue")}
          </StyledButton>
          <StyledLink to="#" onClick={logout}>
            {t("Logout")}
          </StyledLink>
        </ActionsSection>
      </ContentWrap>
    </FullPageOverlay>
  );
}

const ConnectedContractAcceptanceForm = connect(null, {
  logout,
  acceptTermsAndAgreement,
})(ContractAcceptanceForm);

const Form = createForm({
  Component: ConnectedContractAcceptanceForm,
  init: () => Promise.resolve({ isContractAccepted: false }),
});

export default function ContractAcceptance() {
  return <Form module="contractAcceptance" />;
}
