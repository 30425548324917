import { createSelector } from "reselect";
import isEqual from "fast-deep-equal";
import querystring from "querystring";

import { getEntity } from "utils/entities";
import { ClusterSchema } from "utils/schemas";
import { CLUSTERS } from "utils/constants/routes";

export const getClusters = getEntity(
  (state) => state.list.cluster?.items.initial,
  [ClusterSchema]
);

export const isInitialClusterListEmpty = createSelector(
  (state) => state.list.cluster?.items.initial,
  (state) => state.list.cluster?.isLoading,
  (state) => state.list.cluster?.query?.filter,
  (clusters, isLoading, filterQuery) => {
    const initialFilterQuery = {
      clusterName: {
        beginsWith: "",
      },
      isDeleted: false,
    };
    const isInitialState = isEqual(filterQuery, initialFilterQuery);
    return !isLoading && isInitialState && (!clusters || clusters.length === 0);
  }
);

export const getClustersLink = createSelector(
  (state) => state.forms?.clusterFilters?.data,
  (data) => {
    if (!data) {
      return CLUSTERS.ROOT;
    }
    const query = querystring.stringify({
      filter: "",
      sortBy: data.sortBy,
      order: data.order,
      deleted: data.deleted,
    });

    return `${CLUSTERS.ROOT}?${query}`;
  }
);
