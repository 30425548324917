import i18n from "i18next";
import store from "services/store";
import api from "services/api";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import AsyncAction from "modules/asyncAction";
import createFormActions from "modules/form/actions";
import {
  createStripePaymentMethod,
  cardsSelectorFormActions,
} from "state/billingdetails/actions";
import { getTenantPlan } from "state/auth/actions";
import { getTenantPlanType } from "state/auth/selectors";
import {
  CARD_SELECTION_MODULE,
  paymentCardsFetcher,
  publishableKeyFetcher,
  stripeService,
} from "state/billingdetails/services";
import {
  getPublishableKey,
  getPaymentCards,
} from "state/billingdetails/selectors";

import {
  cardPayModal,
  customerFetcher,
  PLAN_PAYMENT_MODULE,
  paymentSuccessModal,
  subscriptionFetcher,
  monthlyCancellationModal,
  annualCancellationModal,
  annualUpgradeModal,
  paymentPlansFetcher,
  cancelDeactivationModal,
} from "./services";

const validator = new Validator();
validator.addRule(
  ["firstname", "lastname", "cardName"],
  ignoreIfPaymentIsProvided(Missing())
);

function ignoreIfPaymentIsProvided(validationFn) {
  return function validation(value, key, data) {
    const hasPaymentMethods = !!getPaymentCards(store.getState())?.length;
    if (hasPaymentMethods) {
      return false;
    }
    return validationFn(value, key, data);
  };
}

export const subscribeFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      firstname: "",
      lastname: "",
      cardName: "",
      paymentMethod: null,
    });
  },
  validator,
  submit: async () => {
    const state = store.getState();
    const selectedPaymentMethod =
      state.forms.cardSelection?.data?.paymentMethod;
    let newPaymentMethod;

    if (!selectedPaymentMethod) {
      newPaymentMethod = await store.dispatch(createStripePaymentMethod());
    }
    const paymentMethodId = selectedPaymentMethod || newPaymentMethod?.id;
    await store.dispatch(prepareSubscriptionData());
    const subscription = await store.dispatch(
      createOrUpdateSubscription(paymentMethodId)
    );
    if (subscription?.subscriptionState === "Active") {
      paymentSuccessModal.open();
    } else {
      await handle3dSecureAuth(subscription, paymentMethodId);
    }
    store.dispatch(refreshPlanDetails());
  },
});

function refreshPlanDetails() {
  return (dispatch) => {
    dispatch(getTenantPlan());
    dispatch(paymentCardsFetcher.fetch());
    dispatch(subscriptionFetcher.fetch());
  };
}

export async function handle3dSecureAuth(subscription, paymentMethodId) {
  if (!subscription?.paymentIntent?.clientSecret) return;
  const { paymentIntent, subscriptionState } = subscription;

  if (
    subscriptionState === "Incomplete" &&
    paymentIntent.status === "requires_action"
  ) {
    const result = await stripeService.stripe.confirmCardSetup(
      paymentIntent.clientSecret,
      {
        payment_method: paymentMethodId,
      }
    );
    if (result.error) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: result.error.message,
      });
    } else if (result.setupIntent.status === "succeeded") {
      paymentSuccessModal.open();
    }
  }
}

export function initAllBillingData() {
  return (dispatch, getState) => {
    const state = getState();
    const publicKey = getPublishableKey(state);
    const planType = getTenantPlanType(state);
    !publicKey && dispatch(publishableKeyFetcher.fetch());
    dispatch(paymentPlansFetcher.fetch());
    if (planType !== "Trial") {
      dispatch(paymentCardsFetcher.fetch());
    }
  };
}

export function monthlyUpgrade(plan) {
  return async (dispatch) => {
    cardPayModal.open(plan).then(subscribeAsyncAction.trigger);
    dispatch(subscribeFormActions.init({ module: PLAN_PAYMENT_MODULE }));
    dispatch(cardsSelectorFormActions.init({ module: CARD_SELECTION_MODULE }));
  };
}

export const subscribeAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      subscribeFormActions.submit({ module: PLAN_PAYMENT_MODULE })
    );
  },
});

export const cancelDeactivationAsyncAction = new AsyncAction({
  promise: async () => {
    const subscription = subscriptionFetcher.selector(store.getState())?.result;
    const subscriptionId = subscription?.subscriptionId;
    const promise = api.post(
      `v1alpha1/payments/stripe/subscriptions/${subscriptionId}/activate`
    );

    try {
      await promise;
      notifications.success({
        message: i18n.t("Subscription has been activated successfully"),
      });
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while activating the subscription"
        ),
        description: error?.message,
      });
      return;
    }

    store.dispatch(subscriptionFetcher.fetch());
  },
});

function prepareSubscriptionData() {
  return (dispatch, getState) => {
    const planType = getTenantPlanType(getState());
    if (planType === "Trial") {
      return dispatch(customerFetcher.fetch());
    }
  };
}

export function createOrUpdateSubscription(paymentMethodId) {
  return async (dispatch, getState) => {
    const state = getState();
    const plan = cardPayModal?.data || {};
    const customerId = customerFetcher.selector(state)?.result;
    const subscription = subscriptionFetcher.selector(state)?.result;
    const subscriptionId = subscription?.subscriptionId;
    const payload = {
      customerId,
      priceId: plan.id,
      planType: plan.name,
      paymentMethodIds: [paymentMethodId],
      ...(subscription ? { subscriptionId } : {}),
    };
    let result;

    const promise = subscription
      ? api.put(
          `v1alpha1/payments/stripe/subscriptions/${subscriptionId}`,
          payload
        )
      : api.post("v1alpha1/payments/stripe/subscriptions", payload);

    dispatch({
      type: "SUBSCRIBE",
      promise,
    });

    try {
      result = await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          `Something went wrong while ${
            subscription ? "updating" : "creating"
          } the subscription`
        ),
        description: error?.message,
      });
      return Promise.reject(error);
    }

    return result;
  };
}

export const unsubscribeAsyncAction = new AsyncAction({
  promise: async () => {
    const state = store.getState();
    const subscriptionId = subscriptionFetcher.selector(state)?.result
      ?.subscriptionId;
    const promise = api.delete(
      `v1alpha1/payments/stripe/subscriptions/${subscriptionId}`
    );

    try {
      await promise;
      notifications.success({
        message: i18n.t("Subscription has been cancelled"),
      });
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while cancelling the subscription"
        ),
        description: err?.message,
      });
      return;
    }

    store.dispatch(refreshPlanDetails());
  },
});

export function changeCurrentPlan(plan) {
  return (dispatch) => {
    const planName = plan.name;

    if (planName === "MonthlyOnDemand") {
      dispatch(monthlyUpgrade(plan));
    }
    if (planName === "AnnualSubscription") {
      annualUpgradeModal.open();
    }
  };
}

export function unsubscribe() {
  return (dispatch, getState) => {
    const tenantPlan = getTenantPlanType(getState());

    if (tenantPlan === "MonthlyOnDemand") {
      monthlyCancellationModal.open().then(unsubscribeAsyncAction.trigger);
    }
    if (tenantPlan === "AnnualSubscription") {
      annualCancellationModal.open();
    }
  };
}

export function cancelDeactivation() {
  cancelDeactivationModal.open().then(() => {
    cancelDeactivationAsyncAction.trigger();
  });
}
