import React from "react";
import i18next from "i18next";
import styled from "styled-components";

import Fields from "components/ui/Fields";
import Button, { TextButton } from "components/ui/Button";
import LoginLayout from "components/common/layouts/Auth";

import Validator from "services/validator";
import notifications from "services/notifications";
import api from "services/api";
import store from "services/store";

import { createForm } from "modules/form";
import createActions from "modules/form/actions";

import { white } from "utils/constants/colors";
import { Missing } from "services/validator/rules";

const Title = styled.div`
  font-size: 15px;
  color: ${white};
  opacity: 0.6;
  text-align: center;
`;

const PasswordChangeWrap = styled.div`
  margin-top: 30px;

  ${TextButton} {
    width: 18px;
    height: 18px;
    border: none;
    margin-top: 10px;

    svg {
      fill: white;
    }
  }
`;

function PasswordChange({ onSubmit, onFieldChange, data }) {
  return (
    <PasswordChangeWrap>
      <Title>
        {i18next.t("Your current password has expired, please set a new one.")}
      </Title>
      <Fields.PasswordField
        name="currentPassword"
        data-qa="currentPassword"
        label="Current Password"
        allowAutofill={false}
        displayEyeIcon={true}
      />
      <Fields.Input
        name="newPassword"
        data-qa="newPassword"
        label="New Password"
        type="password"
        autoComplete="new-password"
        onChange={(value) => {
          onFieldChange("newPassword", value);
          onFieldChange("confirmPassword", data.confirmPassword);
        }}
      />
      <Fields.Input
        name="confirmPassword"
        data-qa="confirmPassword"
        label="Confirm Password"
        type="password"
        autoComplete="new-password"
      />
      <Button data-qa="submit" onClick={onSubmit}>
        {i18next.t("Update password")}
      </Button>
    </PasswordChangeWrap>
  );
}

const passwordValidator = new Validator();
passwordValidator.addRule("confirmPassword", (value, key, data) => {
  if (data.newPassword !== "" && value !== data.newPassword) {
    return i18next.t("Please make sure your passwords match");
  }
});
passwordValidator.addRule(["currentPassword", "newPassword"], Missing());

const passwordFormActions = createActions({
  validator: passwordValidator,
  init() {
    return Promise.resolve({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  },
  async submit(data) {
    try {
      await api.patch(`v1alpha1/users/password/change`, {
        emailId: store.getState().forms.login.data.emailId,
        newPassword: data.newPassword,
        currentPassword: data.currentPassword,
      });

      notifications.success({
        message: i18next.t("Your password has been updated successfully."),
      });

      store.dispatch({
        type: "SET_LOGIN_STEP",
        step: "email",
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while updating your password."
        ),
        description: err.message,
      });
    }
  },
});

const Form = createForm({
  Component: PasswordChange,
  actions: passwordFormActions,
});

export default function PasswordUpdate() {
  return (
    <LoginLayout>
      <Form module="passwordChange" />
    </LoginLayout>
  );
}
