import i18n from "i18next";
import api from "services/api/index";
import { getStoreEntity } from "services/store";

import { CloudAccountSchema } from "utils/schemas";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import notifications from "services/notifications";

import { CLOUD_ACCOUNTS_MODULE } from "state/cloudaccounts/services";

export const deleteConfirm = new ModalService("deleteCloudAccount");

export const cloudAccountsListActions = new ListActions({
  async fetchData() {
    const cloudAccounts = {
      aws: {
        cloudType: "aws",
        accounts: [],
      },
      vsphere: {
        cloudType: "vsphere",
        accounts: [],
      },
      gcp: {
        cloudType: "gcp",
        accounts: [],
      },
      azure: {
        cloudType: "azure",
        accounts: [],
      },
      maas: {
        cloudType: "maas",
        accounts: [],
      },
      openstack: {
        cloudType: "openstack",
        accounts: [],
      },
    };

    const data = await api.get("v1alpha1/cloudaccounts/summary");

    data.items.forEach((account) => {
      (cloudAccounts[account.kind]?.accounts || []).push(account);
    });
    return Promise.resolve({
      items: [
        cloudAccounts.aws,
        cloudAccounts.vsphere,
        cloudAccounts.azure,
        cloudAccounts.gcp,
        cloudAccounts.maas,
        cloudAccounts.openstack,
      ],
    });
  },
  schema: [{ accounts: [CloudAccountSchema] }],
});

export function deleteCloudAccount(guid) {
  return (dispatch) => {
    deleteConfirm.open({ guid }).then(async () => {
      const account = getStoreEntity(
        deleteConfirm.data.guid,
        CloudAccountSchema
      );
      const promise = api.delete(
        `v1alpha1/cloudaccounts/${account.kind}/${account.metadata.uid}`
      );

      dispatch({
        type: "DELETE_CLOUD_ACCOUNT",
        promise,
        guid,
      });

      try {
        await promise;
        dispatch(cloudAccountsListActions.initialize(CLOUD_ACCOUNTS_MODULE));
        notifications.success({
          message: i18n.t(
            'Account "{{accountName}}" has been deleted successfully',
            { accountName: account.metadata.name }
          ),
        });
      } catch (error) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying do delete the account"
          ),
          description: error.message,
        });
      }
    });
  };
}
