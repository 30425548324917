import api, { nonProjectApi } from "services/api";
import ListActions from "modules/list/actions";
import { ProjectSchema } from "utils/schemas";

import {
  projectOverviewModalService,
  configureAlertsModal,
} from "../services/list";

import { selectProject } from "state/auth/actions";

import { getSelectedProjectPermissionEntity } from "../selectors/list";
import createFormActions from "modules/form/actions";
import store, { getStoreEntity } from "services/store";
import dataFetcher from "modules/dataFetcher";
import notifications from "services/notifications";
import i18next from "i18next";

export const projectListActions = new ListActions({
  fetchData() {
    return api.get("v1alpha1/dashboard/projects");
  },
  schema: [ProjectSchema],
});

export function selectContext() {
  return (dispatch, getState) => {
    const projectPermission = getSelectedProjectPermissionEntity(getState());

    dispatch(selectProject(projectPermission.guid));
    projectOverviewModalService.close();
  };
}

export function openProjectOverviewModal(guid) {
  return async () => {
    projectOverviewModalService.open({ guid });
  };
}

const alertTypesFetcher = dataFetcher({
  selectors: ["projects", "alerts"],
  fetchData() {
    return nonProjectApi.get("v1alpha1/projects/alerts");
  },
});

const currentProject = dataFetcher({
  selectors: [
    "projects",
    () => {
      const project = getStoreEntity(
        configureAlertsModal.data.guid,
        ProjectSchema
      );

      return project.metadata.uid;
    },
  ],
  schema: ProjectSchema,
  fetchData([_, uid]) {
    return nonProjectApi.get(`v1alpha1/projects/${uid}`);
  },
});

export const configureAlertsFormActions = createFormActions({
  async init() {
    await store.dispatch(currentProject.fetch());
    const project = getStoreEntity(
      configureAlertsModal.data.guid,
      ProjectSchema
    );
    await store.dispatch(alertTypesFetcher.fetch());
    const response = alertTypesFetcher.selector(store.getState());

    return {
      alerts: response.result.components.map((alertType) => {
        const projectAlert = (project.spec.alerts || []).find(
          (alert) => alert.component === alertType.name
        );

        return {
          ...alertType,
          ...(projectAlert || {}),
          channels: projectAlert?.channels || [],
          isEnabled: !!projectAlert?.channels?.length,
        };
      }),
    };
  },
  async submit(data) {
    const project = getStoreEntity(
      configureAlertsModal.data.guid,
      ProjectSchema
    );
    const promises = data.alerts.map((alert) => {
      if (alert.isEnabled) {
        return nonProjectApi.put(
          `v1alpha1/projects/${project.metadata.uid}/alerts/${alert.name}`,
          {
            channels: alert.channels,
          }
        );
      } else {
        return nonProjectApi.delete(
          `v1alpha1/projects/${project.metadata.uid}/alerts/${alert.name}`
        );
      }
    });

    try {
      await Promise.all(promises);
      notifications.success({
        message: i18next.t(
          "Alerts for project '{{projectName}}' have been configured successfully",
          {
            projectName: project.metadata.name,
          }
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while configuring alerts for '{{projectName}}' project",
          {
            projectName: project.metadata.name,
          }
        ),
        description: error.message,
      });
    }
  },
});

export function configureAlerts(guid) {
  return (dispatch) => {
    configureAlertsModal.open({ guid }).then(() => {
      return dispatch(
        configureAlertsFormActions.submit({ module: "projectAlerts" })
      );
    });
    dispatch(configureAlertsFormActions.init({ module: "projectAlerts" }));
  };
}

export function onEnableAlert(index) {
  return (dispatch, getState) => {
    const alerts = [...getState().forms.projectAlerts.data.alerts];
    const enabled = alerts[index]?.isEnabled;

    if (enabled) {
      alerts[index] = {
        ...alerts[index],
        isEnabled: false,
      };
    } else {
      alerts[index] = {
        ...alerts[index],
        isEnabled: true,
        channels: [
          {
            type: "email",
          },
        ],
      };
    }

    dispatch(
      configureAlertsFormActions.batchChange({
        module: "projectAlerts",
        updates: {
          alerts,
        },
      })
    );
  };
}
