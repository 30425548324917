import React from "react";
import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { PROJECTS } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const FormModal = React.lazy(() => import("./create"));

const namespace = "projects";

const CreateOnly = (props) => {
  return <FormModal {...props} createOnly />;
};

const EditOnly = (props) => {
  return <FormModal {...props} editOnly />;
};

export default createNamespace({
  namespace,
  Component: function Main() {
    return (
      <>
        <Route adminOnly path={PROJECTS.ROOT} component={Index} />
        <Route
          adminOnly
          exact
          path={PROJECTS.CREATE_PROJECT}
          component={CreateOnly}
        />
        <Route
          adminOnly
          exact
          path={PROJECTS.EDIT_PROJECT}
          component={EditOnly}
        />
      </>
    );
  },
});
