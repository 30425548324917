import isEqual from "fast-deep-equal";
import debounce from "redux-debounce-thunk";
import entities, { createEntity } from "utils/entities";

export default class ListActions {
  constructor(actions) {
    if (actions instanceof ListActions) {
      return actions;
    }

    const {
      fetchData,
      schema,
      debounceDelay = 1000,
      initialQuery = () => ({}),
      dataFetcher,
    } = actions;
    Object.assign(this, { fetchData, schema, initialQuery, dataFetcher });
    this.debouncedFetchItems = debounce(this.fetchItems, debounceDelay);
  }

  initialize(module, initialQuery = this.initialQuery()) {
    return (dispatch) => {
      dispatch({ type: "LIST_INITIALIZE", query: initialQuery, module });
      return dispatch(this.createFetchAction(module, "LIST_INITIALIZE"));
    };
  }

  addItems({ module, items }) {
    return (dispatch) => {
      const entity = createEntity(items, this.schema);
      const normalization = entities.normalize(entity, this.schema);
      dispatch({
        type: "ADD_ENTITIES",
        ...normalization,
      });
      dispatch({
        type: "LIST_ADD_ITEMS",
        guid: entity.guid,
        module,
        items: normalization.result,
      });
    };
  }

  removeItems({ module, items }) {
    return {
      type: "LIST_REMOVE_ITEM",
      module,
      items,
    };
  }

  nextPage(module) {
    return (dispatch, getState) => {
      const listState = getState().list[module];
      if (listState.isLoading || !listState.nextToken) {
        return;
      }
      dispatch({ type: "LIST_NEXT_PAGE", module });
      dispatch(this.fetchItems(module));
    };
  }

  fetchItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_FETCH_ITEMS"));
    };
  };

  createFetchAction(module, type) {
    return (dispatch, getState) => {
      const query = getState().list[module]?.query;
      let token = getState().list[module]?.token;
      const promise = async () => {
        if (!this.fetchData && !this.dataFetcher) {
          return {
            items: [],
          };
        }

        let data = {
          items: [],
        };

        const fetchQuery = {
          ...query,
          continue: token !== "initial" ? token : undefined,
        };

        if (this.dataFetcher) {
          await dispatch(this.dataFetcher.fetch(fetchQuery));
          data = this.dataFetcher.selector(getState()).result;
        }

        if (this.fetchData) {
          data = await this.fetchData(fetchQuery);
        }

        dispatch({
          type: "LIST_SET_NEXT_PAGE_TOKEN",
          token: data?.listmeta?.continue,
          module,
        });

        return data?.items || [];
      };

      const apiCall = promise();

      dispatch({
        type,
        module,
        promise: apiCall,
        token,
        schema: this.schema,
      });

      return apiCall;
    };
  }

  changeQuery({ name, value, module }) {
    return (dispatch) => {
      dispatch({
        module: module,
        type: "LIST_CHANGE_QUERY",
        name,
        value,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  batchChangeQuery({ module, query }) {
    return (dispatch, getState) => {
      if (isEqual(query, getState().list[module]?.query)) return;

      dispatch({
        module,
        type: "BATCH_CHANGE_QUERY",
        query,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }
}
