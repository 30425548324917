import React, { useMemo } from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import { generatePath } from "react-router";

import PageHeader from "components/common/layouts/App/PageHeader";

import { NavLink, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  winterMist,
  white,
  midGray,
  nearBlack,
  blue,
  lightMidGray,
} from "utils/constants/colors";
import { SETTINGS } from "utils/constants/routes";

import { getCurrentContext } from "state/auth/selectors";

import flags from "services/flags";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import Icon from "components/ui/Icon";

const CloudAccountContainer = styled.div`
  min-height: calc(100% - 50px);
  width: 100%;
  background: ${white};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
`;

const SidebarMenu = styled.div`
  border-right: 1px solid ${winterMist};
  max-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  width: 100%;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  padding: 21px 40px;
  color: ${nearBlack};
  border-bottom: 1px solid ${winterMist};
`;

const Content = styled.div`
  padding: 23px 40px;
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  font-size: 14px;
  line-height: 22px;
  padding: 11px 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
  color: ${midGray};

  svg {
    margin-left: 7px;
    color: ${lightMidGray};
  }

  :hover {
    color: ${blue};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${blue};
      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: ${blue};
        width: 2px;
        height: 100%;
        left: 0px;
      }
      svg {
        color: ${blue};
      }
    `};
`;

function Settings({ history, children, currentContext }) {
  const PROJECT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
    },
    flags.has("backup") && {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
    },
    {
      key: "sshkeys",
      path: SETTINGS.SSH_KEYS,
      label: i18n.t("SSH Keys"),
    },
    flags.has("rolebindings") && {
      key: "rolebindings",
      path: SETTINGS.ROLE_BINDINGS,
      label: i18n.t("Role Bindings"),
    },
  ].filter(Boolean);

  const TENANT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
    },
    {
      key: "registries",
      path: generatePath(SETTINGS.REGISTRIES, { tab: "helmregistries" }),
      label: i18n.t("Registries"),
    },
    {
      key: "privatecloudgateways",
      path: SETTINGS.PRIVATE_CLOUD_GATEWAYS,
      label: i18n.t("Private Cloud Gateways"),
    },
    {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
    },
    {
      key: "sso",
      path: generatePath(SETTINGS.SSO, { tab: "configure" }),
      label: i18n.t("SSO"),
    },
    {
      key: "password-policy",
      path: SETTINGS.PASSWORD_POLICY,
      label: i18n.t("Password Policy"),
    },
    flags.has("billing") && {
      key: "plandetails",
      path: SETTINGS.PLAN_DETAILS,
      label: i18n.t("Plan Details"),
    },
    flags.has("billing") && {
      key: "billingdetails",
      path: SETTINGS.BILLING_DETAILS,
      label: i18n.t("Billing Details"),
    },
    {
      key: "certificates",
      path: "/settings/certificates",
      label: i18n.t("Certificates"),
    },
    flags.has("rolebindings") && {
      key: "rolebindings",
      path: SETTINGS.ROLE_BINDINGS,
      label: i18n.t("Role Bindings"),
    },
  ].filter(Boolean);

  const routes = useMemo(
    () => (currentContext?.isAdmin ? TENANT_ROUTES : PROJECT_ROUTES),
    [currentContext, TENANT_ROUTES, PROJECT_ROUTES]
  );

  let activeKey = "/";
  const location = history.location.pathname;
  if (location.startsWith("/projects/")) {
    activeKey = location.split("/")[4];
  }
  if (location.startsWith("/admin/")) {
    activeKey = location.split("/")[3];
  }

  const activeLabel = routes.find((navLink) => navLink.key === activeKey)
    ?.label;

  function renderNavLinks(navLink) {
    return (
      <StyledNavLink
        isSelected={activeKey === navLink.key}
        key={navLink.key}
        to={navLink.path}
      >
        {navLink.label}
        <Icon awesome={faChevronRight} />
      </StyledNavLink>
    );
  }

  const title = currentContext?.isAdmin
    ? i18n.t("Admin Settings")
    : i18n.t("Project Settings");

  return (
    <>
      <PageHeader
        title={title}
        tabTitleContent={{
          activeTab: activeLabel,
          pageTitle: title,
        }}
      />
      <CloudAccountContainer>
        <SidebarMenu>{routes.map(renderNavLinks)}</SidebarMenu>
        <ContentLayout>
          <ContentTitle>
            {i18n.t("Manage {{activeLabel}}", { activeLabel })}
          </ContentTitle>
          <Content>{children}</Content>
        </ContentLayout>
      </CloudAccountContainer>
    </>
  );
}

export default connect((state) => ({
  currentContext: getCurrentContext(state),
}))(withRouter(Settings));
