import React from "react";
import i18next from "i18next";
import moment from "moment";

import store from "services/store";
import {
  getAllPermissions,
  getCurrentContext,
  getCurrentUser,
  getUserContexts,
  getUserMenu,
  getUserProjects,
  isOnPremApplication,
} from "state/auth/selectors";
import createFormActions from "modules/form/actions";

import permissionsService from "services/permissions";
import api, { nonProjectApi } from "services/api";
import loginService from "services/loginService";
import {
  contextPreferenceCache,
  backToProjectCache,
  includeMastersCache,
} from "services/localstorage/cache";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import history from "services/history";
import notifications from "services/notifications";

import {
  UserSchema,
  ProjectSchema,
  ProjectPermissionsSchema,
} from "utils/schemas";
import permissions from "services/permissions";
import {
  customerFetcher,
  subscriptionFetcher,
} from "state/plandetails/services";
import { productTourModal } from "state/productTour/services";
import flags from "services/flags";
import {
  syncFullStoryWithUser,
  terminateFullstorySession,
} from "utils/fullstory";
import Button from "components/ui/Button";
import { Trans } from "react-i18next";

const loginValidator = new Validator();
loginValidator.addRule(["emailId"], Missing());

function handleOrganizationSSO(organization) {
  return function thunk() {
    const queryParams = history.getQuery();
    if (queryParams?.login) {
      return;
    }
    if (
      ["saml", "oidc"].includes(organization.authType) &&
      organization.redirectUrl
    ) {
      window.location.href = organization.redirectUrl;
    }
  };
}

export const loginActions = createFormActions({
  validator: loginValidator,
  init: () => Promise.resolve({ emailId: "", password: "" }),
  submit: async function(data) {
    try {
      await loginService.login(data);
    } catch (e) {
      if (e.code === "TenantPlanExpired") {
        return history.push(
          `/auth-error?message=${e.message}&errCode=${e.code}`
        );
      }

      if (e.code === "UserPasswordExpired") {
        return history.push("/auth/password-update");
      }

      throw e;
    }
    const state = store.getState();
    notifications.close("password-reset-success");
    const query = history.getQuery();
    let redirectUrl =
      state.auth.organization?.redirectUrl || window.location.origin;
    const returnTo = query.returnTo !== "/auth" ? query.returnTo : "";

    if (query?.login) {
      window.location.href = window.location.origin;
      return;
    }

    window.location.href = returnTo ? `${redirectUrl}${returnTo}` : redirectUrl;
  },
});

const setPasswordValidator = new Validator();
setPasswordValidator.addRule("confirmPassword", (value, key, data) => {
  if (data.newPassword !== data.confirmPassword) {
    return i18next.t("Passwords do not match");
  }
});

export const setPasswordFormActions = createFormActions({
  init: () => Promise.resolve({ newPassword: "", confirmPassword: "" }),
  validator: setPasswordValidator,
  submit: async function(data) {
    const { passwordToken, action } = store.getState().location.params;
    try {
      await api.patch(`v1alpha1/auth/password/${passwordToken}/${action}`, {
        password: data.newPassword,
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while reseting the password"),
        description: err.message,
      });
      return;
    }
    history.push("/auth");
    notifications.success({
      message: i18next.t(
        "Password changed successfully, please login using your new password"
      ),
      duration: null,
      key: "password-reset-success",
    });
  },
});

export function redeemTenantToken() {
  return async function thunk(dispatch, getState) {
    const locationParams = getState().location.params;
    const query = history.getQuery();
    if (!query.isTenantAdmin) {
      return;
    }
    const promise = api.post(
      `v1alpha1/tenants/signup/${locationParams.passwordToken}/activate`
    );
    dispatch({ type: "REDEEM_TENANT_TOKEN", promise });
    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t("Unable to activate this tenant"),
        duration: null,
        description: err.message,
      });
      throw err;
    }
  };
}

export function setPermissions() {
  return function thunk(_, getState) {
    const permissions = getAllPermissions(getState());
    permissionsService.init(permissions);
  };
}

function updatePreferredContext() {
  return function(_, getState) {
    const currentContext = getCurrentContext(getState());
    const currentUser = getCurrentUser(getState());

    contextPreferenceCache.set(
      currentUser.metadata.uid,
      currentContext?.isAdmin ? currentContext.guid : currentContext.projectUid
    );
  };
}

export function setCurrentProject(value) {
  return function thunk(dispatch) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    dispatch({ type: "SET_CURRENT_PROJECT", currentProjectId: value });

    dispatch(setPermissions());
    dispatch({ type: "APP_LOADING", isLoading: false });
  };
}

export function redirectToDefaultRoute() {
  return function thunk(_, getState) {
    const menu = getUserMenu(getState());
    if (!menu[0]?.path) {
      history.push("/no-projects");
    }
    const correspondingRouteIndex = menu.findIndex((route) =>
      history.location.pathname.endsWith(route.path)
    );
    if (correspondingRouteIndex !== -1) {
      history.push(menu[correspondingRouteIndex]?.path);
      return;
    }
    history.push(menu[0]?.path);
  };
}

function backupBackToProject() {
  return function thunk(dispatch, getState) {
    const currentContext = getCurrentContext(getState());
    if (!currentContext) {
      return null;
    }

    if (currentContext.projectUid) {
      const currentUser = getCurrentUser(getState());
      backToProjectCache.set(
        currentUser.metadata.uid,
        currentContext.projectUid
      );

      dispatch({
        type: "SET_BACK_TO_PROJECT",
        projectUid: currentContext.guid,
      });
    }
  };
}

export function selectProject(value) {
  return function thunk(dispatch) {
    if (value === "ADMIN_VIEW") {
      dispatch(backupBackToProject());
      dispatch(getTenantPlan());
    }

    dispatch(setCurrentProject(value));
    dispatch(updatePreferredContext());
    dispatch(redirectToDefaultRoute());
  };
}

export function selectProjectByUid(value) {
  return function thunk(dispatch, getState) {
    const userContexts = getUserContexts(getState());
    const project = userContexts.find(
      (context) => context.projectUid === value
    );

    dispatch(selectProject(project.guid));
  };
}

export function selectProjectFromRoute(params) {
  const projectUid = params.projectUid;
  return function thunk(dispatch, getState) {
    const userContexts = getUserContexts(getState());
    const project = userContexts.find(
      (context) => context.projectUid === projectUid
    );

    if (project.guid === "ADMIN_VIEW") {
      dispatch(backupBackToProject());
    }

    dispatch(setCurrentProject(project.guid));
  };
}

export function fetchCurrentUser() {
  return async function thunk(dispatch) {
    const promise = nonProjectApi.get("v1alpha1/users/me");
    dispatch({
      promise: promise,
      type: "FETCH_CURRENT_USER",
      schema: UserSchema,
    });

    const currentUser = await promise;
    const projectPermissions = currentUser?.status?.projectPermissions || {};

    dispatch({
      data: Object.keys(projectPermissions).map((key) => ({
        projectUid: key,
        permissions: projectPermissions[key],
      })),
      schema: [ProjectPermissionsSchema],
      type: "SET_USER_PROJECTS",
    });

    return promise;
  };
}

export function fetchUserProjects() {
  return function thunk(dispatch) {
    const promise = api.get("v1alpha1/projects");
    dispatch({
      promise: promise.then((data) => data.items),
      type: "FETCH_USER_PROJECTS",
      schema: [ProjectSchema],
    });

    return promise;
  };
}

export function fetchUserContext() {
  return async function(dispatch) {
    await dispatch(fetchCurrentUser());
    await dispatch(fetchUserProjects());
  };
}

function restoreBackToProject() {
  return function thunk(dispatch, getState) {
    const currentUser = getCurrentUser(getState());
    let backToProject = backToProjectCache.get(currentUser.metadata.uid);
    const contexts = getUserContexts(getState());
    backToProject = contexts.find(
      (context) => context.projectUid === backToProject
    );

    if (backToProject) {
      dispatch({
        type: "SET_BACK_TO_PROJECT",
        projectUid: backToProject.guid,
      });
    }
  };
}

function setFallbackProject() {
  return function(dispatch, getState) {
    const contexts = getUserContexts(getState());
    const projects = getUserProjects(getState());
    const admin = contexts.find((item) => item.guid === "ADMIN_VIEW");

    if (projects.length !== 0) {
      dispatch(setCurrentProject(projects[0].guid));
      return;
    }

    if (admin) {
      dispatch(setCurrentProject(admin?.guid));
      return;
    }

    dispatch({ type: "APP_LOADING", isLoading: false });
  };
}

export function initApp() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    loginService.refreshToken();
    loginService.startWatchingEvents();

    try {
      try {
        await dispatch(fetchUserContext());
        await dispatch(fetchOrganization());
      } catch (err) {}
      const contexts = getUserContexts(getState());
      const currentUser = getCurrentUser(getState());
      syncFullStoryWithUser(currentUser);
      const tenantOrg = currentUser?.status?.tenant?.orgName;
      const orgName = getState().auth.organization?.orgName;

      if (isOnPremApplication(getState())) {
        const updatedFlags = flags.params.filter(
          (flag) => !["billing"].includes(flag)
        );

        flags.update(updatedFlags);
      }

      let preferredContext = contextPreferenceCache.get(
        currentUser.metadata.uid
      );

      if (orgName !== "root" && orgName !== tenantOrg) {
        // dispatch(logout());
      }

      // TODO maybe we should have a single way to restore a users settings
      if (includeMastersCache.get(currentUser.metadata.uid)) {
        dispatch({
          type: "TOGGLE_GAUGE_METRICS",
        });
      }

      dispatch(restoreBackToProject());

      preferredContext = contexts.find(
        (project) =>
          project.projectUid === preferredContext ||
          project.guid === preferredContext
      );

      if (!preferredContext) {
        dispatch(setFallbackProject());
      }

      if (preferredContext) {
        dispatch(setCurrentProject(preferredContext.guid));
      }

      dispatch(setPermissions());

      if (permissions.isAdmin && flags.has("billing")) {
        const plan = await dispatch(getTenantPlan());
        if (!plan?.spec?.type) {
          return;
        }

        if (plan.spec.type !== "Trial") {
          dispatch(customerFetcher.fetch());
          dispatch(subscriptionFetcher.fetch());
        } else {
          const expiryMoment = plan?.spec?.expiry
            ? moment(plan?.spec?.expiry)
            : null;

          if (!expiryMoment) {
            return;
          }

          const expiresSoon = expiryMoment.diff(moment(), "days") < 8;

          if (expiresSoon) {
            notifications.open({
              key: "upgradePlan",
              message: (
                <div>
                  <Trans>
                    <div>
                      Your trial plan is expiring on{" "}
                      {expiryMoment.format("MMM DD YYYY")}
                    </div>
                    <br />
                    <div>
                      To continue using the Spectrocloud features please upgrade
                      your plan
                    </div>
                    <br />
                    <Button
                      data-qa="upgrade-plan"
                      onClick={() => {
                        history.push("/admin/settings/plandetails");
                        notifications.close("upgradePlan");
                      }}
                    >
                      Upgrade now
                    </Button>
                  </Trans>
                </div>
              ),
            });
          }
        }
      }

      dispatch({ type: "APP_LOADING", isLoading: false });
    } catch (e) {
      dispatch({ type: "APP_LOADING", isLoading: false });
    }
  };
}

export function fetchOrganization() {
  return async (dispatch) => {
    const promise = api.get("v1alpha1/auth/org");
    dispatch({
      type: "FETCH_TENANT_ORG",
      promise,
    });

    let response;
    try {
      response = await promise;
    } catch (err) {
      if (err.code === "OrganizationNotFound") {
        history.push("/404");
      }
    }

    if (
      ["quick-start", "enterprise", "airgap"].includes(response?.appEnv) &&
      !response.totalTenants
    ) {
      window.location.href = `${window.location.origin}/system`;
    }

    return response;
  };
}

export function fetchTenantInfo() {
  return async function thunk(dispatch, getState) {
    const location = window.location;
    if (!location) {
      return;
    }
    const response = await dispatch(fetchOrganization());

    if (!response) {
      return;
    }

    if (response.authType === "password") {
      loginValidator.removeRules();
      loginValidator.addRule(["emailId", "password"], Missing());
    }

    if (response.appEnv === "quick-start") {
      const migrationStatusFetcher = api.get(
        `v1alpha1/installers/spectro/status`
      );
      dispatch({
        type: "FETCH_QUICKSTART_MIGRATION_STATUS",
        promise: migrationStatusFetcher,
      });

      await migrationStatusFetcher;
    }

    //TODO: maybe find a better solution, onBack from browser cleans emailId
    if (getState().forms.forgotPassword) {
      dispatch({
        type: "SET_LOGIN_STEP",
        step: "email",
      });
    }
  };
}

export function nextStep() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const step = state.auth.step;
    const steps = ["email", "password", "resetPassword", "resetConfirm"];
    const stepIndex = steps.indexOf(step);

    if (step === "email") {
      try {
        const promise = api.post("v1alpha1/auth/login", {
          emailId: state.forms.login.data.emailId,
        });

        dispatch({
          type: "SET_DISABLED_EMAIL_FIELD",
          promise,
        });
        const organization = await promise;
        dispatch(handleOrganizationSSO(organization));

        dispatch({
          type: "SET_REDIRECT_URL",
          url: organization.redirectUrl,
        });
        dispatch({
          type: "FORM_SUBMIT_FAILURE",
          module: "login",
          error: null,
        });
      } catch (err) {
        dispatch({
          type: "FORM_SUBMIT_FAILURE",
          module: "login",
          error: err,
        });
        return;
      }
    }

    if (step === "password") {
      await dispatch(loginActions.submit({ module: "login" }));
      return;
    }

    if (step === "resetPassword") {
      dispatch(resetPassword());
    }

    dispatch({
      type: "SET_LOGIN_STEP",
      step: steps[stepIndex + 1],
    });
  };
}

export function logout() {
  return async function thunk(dispatch) {
    const data = await loginService.logout();

    terminateFullstorySession();
    dispatch({ type: "USER_LOGOUT" });

    if (data?.redirectUrl) {
      window.location.href = data.redirectUrl;
      return;
    }

    history.push("/auth");
  };
}

export function onForgotPassword() {
  return async function(dispatch) {
    dispatch({
      type: "SET_LOGIN_STEP",
      step: "resetPassword",
    });

    history.push("/auth/forgot-password");
  };
}

export function resetPassword() {
  return async function(dispatch, getState) {
    const redirectUrl = getState().auth.organization?.redirectUrl || "/";

    try {
      const promise = api.post("v1alpha1/auth/user/password/reset", {
        emailId: getState().forms.forgotPassword.data.emailId,
      });

      dispatch({
        type: "SET_REDIRECT_URL",
        url: redirectUrl,
      });

      await promise;
    } catch (err) {}
  };
}

export function previousStep() {
  return function(dispatch, getState) {
    const step = getState().auth.step;
    const steps = ["email", "password"];
    const stepIndex = steps.indexOf(step);

    dispatch({
      type: "SET_LOGIN_STEP",
      step: steps[stepIndex - 1],
    });
  };
}

export function acceptTermsAndAgreement() {
  return async function thunk(dispatch, getState) {
    const currentUser = getCurrentUser(getState());

    try {
      await api.patch(
        `v1alpha1/tenants/${currentUser.status.tenant.tenantUid}/contract/accept`
      );
      dispatch(fetchCurrentUser());
      productTourModal.open();
    } catch (err) {
      notifications.error({
        details: err.message,
        message: i18next.t("Unable to accept the contract."),
      });
    }
  };
}

export function getTenantPlan() {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;
    const promise = nonProjectApi.get(`v1alpha1/tenants/${tenantUid}/plan`);

    dispatch({
      type: "GET_TENANT_PLAN",
      promise,
    });
    return promise;
  };
}
