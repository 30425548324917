export const ROOT = "/";

export const AUDIT = {
  ROOT: "/auditlogs",
};

export const AUTH = {
  ROOT: "/auth",
  ERROR: "/auth-error",
  NOT_FOUND: "/404",
  NO_PROJECTS: "/no-projects",
  PASSWORD_ACTION: "/auth/password/:passwordToken/:action(activate|reset)",
  ACTIVATE_PASSWORD: "/auth/password/:passwordToken/activate",
  RESET_PASSWORD: "/auth/password/:passwordToken/reset",
  FORGOT_PASSWORD: "/auth/forgot-password",
  UPDATE_PASSWORD: "/auth/password-update",
};

export const CLUSTER_PROFILES = {
  ROOT: "/clusterprofiles",
  CREATE: "/clusterprofiles/:id(create)",
  DETAILS: "/clusterprofiles/:id",
};

const clusterCategory = "/:clusterCategory(clusters|privatecloudgateways)";

export const CLUSTERS = {
  ROOT: "/clusters",
  CREATE_CLUSTER: "/clusters/create",
  CLUSTER_DETAILS: "/clusters/:id",
  TAB_DETAILS: `${clusterCategory}/:id/:tab(overview|configure|nodes|events|backups|restores|scans)/:flag?`,
  SCAN_DETAILS: `${clusterCategory}/:id/:tab(scans)/:scanType/:scanUid`,
  SCANS: `${clusterCategory}/:id/:tab(scans)`,
  CERTIFICATES: `${clusterCategory}/:id/certificates`,
  IPAM_DNS_DETAILS:
    "/:clusterCategory(privatecloudgateways)/:id/:type/:uid/:tab(ipam|dns)",
  CONFIGURE_LAYER: "/clusters/:id/configure/:layerUid?",
  NODES_FLAG: `${clusterCategory}/:id/:tab(nodes)/:flag?`,
  NODES: `${clusterCategory}/:id/:tab(nodes)`,
  EVENTS: `${clusterCategory}/:id/:tab(events)`,
  BACKUPS: `${clusterCategory}/:id/:tab(backups)`,
  OVERVIEW: `${clusterCategory}/:id/:tab(overview)`,
};

export const MANAGEMENT = {
  ROOT: "/management/:tab(users|teams)",
  USERS: "/management/:tab(users)",
  CREATE_USER: "/management/:tab(users)/create",
  TEAMS: "/management/:tab(teams)",
  CREATE_TEAM: "/management/:tab(teams)/create",
  EDIT_TEAM: "/management/:tab(teams)/edit",
};

export const MY_PROFILE = "/my-profile";

export const PRIVATE_CLOUD_GATEWAYS = {
  IPAM: "/privatecloudgateways/:type/:uid/ipam",
  CREATE_IPAM: "/privatecloudgateways/:type/:uid/ipam/create",
  IPAM_DNS_TABS: "/privatecloudgateways/:id/:type/:uid/:tab(ipam|dns)",
};

export const PROJECTS = {
  ROOT: "/projects",
  CREATE_PROJECT: "/projects/create",
  EDIT_PROJECT: "/projects/:id/edit",
};

export const ROLES = {
  ROOT: "/roles",
};

export const SETTINGS = {
  ROOT: "/settings",

  CLOUD_ACCOUNTS: "/settings/cloudaccounts",
  CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/:uid",
  CREATE_CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/create",
  EDIT_CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/:uid/edit",
  CREATE_VSPHERE_CLOUD_ACCOUNT:
    "/settings/cloudaccounts/:cloudType(vsphere)/create",
  EDIT_VSPHERE_CLOUD_ACCOUNT:
    "/settings/cloudaccounts/:cloudType(vsphere)/:id/edit",

  SSH_KEYS: "/settings/sshkeys",
  CREATE_SSH_KEY: "/settings/sshkeys/create",
  EDIT_SSH_KEY: "/settings/sshkeys/:uid/edit",

  MAINTENANCE_POLICIES: "/settings/maintenancepolicies",
  CREATE_MAINTENANCE_POLICY: "/settings/maintenancepolicies/create",

  REGISTRIES: "/settings/registries/:tab(packregistries|helmregistries)",
  CREATE_PACK_REGISTRY: "/settings/registries/:tab(packregistries)/create",
  EDIT_PACK_REGISTRY: "/settings/registries/:tab(packregistries)/:uid/edit",
  CREATE_HELM_REGISTRY: "/settings/registries/:tab(helmregistries)/create",
  EDIT_HELM_REGISTRY: "/settings/registries/:tab(helmregistries)/:uid/edit",

  PRIVATE_CLOUD_GATEWAYS: "/settings/privatecloudgateways",
  CREATE_PRIVATE_CLOUD_GATEWAY:
    "/settings/privatecloudgateways/:cloudType/create",
  EDIT_PRIVATE_CLOUD_GATEWAY:
    "/settings/privatecloudgateways/:cloudType/:uid/edit",
  PRIVATE_CLOUD_GATEWAY_NODES:
    "/settings/privatecloudgateways/:cloudType/:uid/set-nodes",

  SSO: "/settings/sso/:tab(configure|domains)",
  PASSWORD_POLICY: "/settings/password-policy",
  BILLING_DETAILS: "/settings/billingdetails",
  PLAN_DETAILS: "/settings/plandetails",

  BACKUP_LOCATIONS: "/settings/backuplocations",
  CREATE_BACKUP_LOCATION: "/settings/backuplocations/create",
  EDIT_BACKUP_LOCATION: "/settings/backuplocations/:uid/edit",

  ROLE_BINDINGS: "/settings/rolebindings",
  CREATE_ROLE_BINDINGS: "/settings/rolebindings/create",
  EDIT_ROLE_BINDINGS: "/settings/rolebindings/:uid/edit",
};
