import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import * as colors from "utils/constants/colors";
import { TextButton } from "components/ui/Button";
import Descriptions from "components/common/Descriptions";

import createProjectRoleManagement from "modules/roleManagement/ProjectRoles";
import createTenantRoleManagement from "modules/roleManagement/TenantRoles";

import {
  projectRolesListActions,
  updateData,
} from "state/roleManagement/actions/projectRoles";

import {
  updateData as tenantRolesUpdateData,
  getSelectedEntityRoles,
  tenantRolesListActions,
} from "state/roleManagement/actions/tenantRoles";
import { getFullName } from "utils/presenters";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clipboard from "components/common/Clipboard";
import Ellipsis from "components/common/Ellipsis";

const Container = styled.div`
  padding: 20px;
  width: 400px;
  border-left: 1px solid ${colors.lightMidGray};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${colors.midGray};
  cursor: pointer;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderActions = styled.div`
  > .anticon-edit {
    margin-right: 20px;
  }
`;

const RemoveUser = styled(TextButton)`
  font-size: 14px;
  margin-top: 14px;
  font-weight: 500;
`;

const ResetPassword = styled(TextButton)`
  font-size: 14px;
  margin-top: 14px;
  font-weight: 500;
`;

const GroupedButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProjectRolesManagement = createProjectRoleManagement({
  listActions: projectRolesListActions,
  updateData,
});

const TenantRolesManagement = createTenantRoleManagement({
  updateData: tenantRolesUpdateData,
  getSelectedEntityRoles,
  entityType: "user",
  listActions: tenantRolesListActions,
});

const UserInformation = ({
  user,
  closeOverviewPanel,
  onUserRemove,
  resetUserPassword,
}) => {
  const { t } = useTranslation();
  const { emailId, teams = [] } = user.spec;
  const { isActive, activationLink } = user.status;

  const userOptions = [
    {
      label: "",
      value: getFullName(user),
    },
    {
      label: t("Email"),
      value: emailId,
    },
    {
      label: t("Team"),
      value: teams.map((team) => team.metadata.name).join(", ") || "-",
    },
    activationLink && {
      label: isActive ? t("Reset password link") : t("Activation link"),
      value: (
        <Clipboard text={activationLink}>
          <Ellipsis>{activationLink}</Ellipsis>
        </Clipboard>
      ),
    },
  ].filter(Boolean);

  return (
    <Container>
      <HeaderWrap>
        <HeaderActions />
        <CloseIcon
          data-qa="close-information"
          icon={faTimes}
          onClick={closeOverviewPanel}
        />
      </HeaderWrap>
      <Descriptions options={userOptions} />

      <ProjectRolesManagement />

      <TenantRolesManagement />

      <GroupedButtons>
        <RemoveUser danger data-qa="remove_user" onClick={onUserRemove}>
          {t("Remove user")}
        </RemoveUser>

        <ResetPassword data-qa="reset_password" onClick={resetUserPassword}>
          {t("Reset password")}
        </ResetPassword>
      </GroupedButtons>
    </Container>
  );
};

export default UserInformation;
