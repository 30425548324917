import i18next from "i18next";
import moment from "moment";
import createFormActions from "modules/form/actions";

import {
  getRawCluster,
  getCluster,
  isClusterDeleting,
  isClusterDeleted,
} from "state/cluster/selectors/details";

import store, { getStoreEntity } from "services/store";
import api from "services/api";
import ModalService from "services/modal";
import Validator from "services/validator";
import { Missing, isValidTagSelection } from "services/validator/rules";
import notifications from "services/notifications";

import { fetchCluster } from "./details";
import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { DEFAULT_SCHEDULE_OPTIONS, BEGINNING_OF_TIME } from "utils/constants";
import { scanFormActions } from "./scan";
import { scheduleBackupsFormAction } from "state/backups/actions/schedule";
import { fetchAwsCloudConfigParams } from "./nodes";
import { SCHEDULE_BACKUPS_MODULE } from "state/backups/services";
import { clusterRoleBindings } from "state/rolebindings/actions/create";
import { CLUSTER_ROLE_BINDINGS } from "state/rolebindings/services";
import { getClusterCloudConfig } from "../selectors/nodes";
import { sshKeysFetcher } from "state/sshKeys/services";
import { SSHKeysSchema } from "utils/schemas";

//

const EDIT_CLUSTER_MODULE = "editCluster";
const azureReservedTags = ["microsoft", "azure", "windows"];

export const validator = new Validator();
function AzureRule(validationFn) {
  const cluster = getRawCluster(store.getState());
  const cloudType = cluster?.spec?.cloudType;
  return (value, key, data) => {
    if (cloudType === "azure") {
      return validationFn(value, key, data);
    }
    return false;
  };
}
function createValidator() {
  validator.removeRules();
  validator.addRule(["name"], Missing());
  validator.addRule(
    ["tags"],
    AzureRule(isValidTagSelection({ reservedTags: azureReservedTags }))
  );
}

createValidator();
export const editClusterFormActions = createFormActions({
  validator,
  submit,
  init: () => {
    const { metadata } = getCluster(store.getState());
    const { name, annotations, labels } = metadata;
    return Promise.resolve({
      name: name,
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
    });
  },
});

export const fargatePoliciesFormActions = createFormActions({
  init: () => {
    const cluster = getCluster(store.getState());
    const fargateProfiles = cluster.spec.cloudConfig.spec.fargateProfiles || [];
    const vpcid = cluster.spec.cloudConfig.spec.clusterConfig.vpcId;
    store.dispatch(fetchAwsCloudConfigParams());

    return Promise.resolve({
      vpcid,
      fargateProfiles: fargateProfiles.map((profile) => ({
        ...profile,
        selectors: profile.selectors.map((selector) => ({
          ...selector,
          labels: parseTagsForInput(selector.labels),
        })),
      })),
    });
  },
  submit(data) {
    const cluster = getRawCluster(store.getState());
    return api.put(
      `v1alpha1/cloudconfigs/${cluster.spec.cloudType}/${cluster.spec.cloudConfigRef.uid}/fargateProfiles`,
      {
        fargateProfiles: data.fargateProfiles.map((profile) => ({
          ...profile,
          selectors: profile.selectors.map((selector) => ({
            ...selector,
            labels: formatTags(selector.labels),
          })),
        })),
      }
    );
  },
});

export const clusterMachineManagementFormActions = createFormActions({
  submit: async (data) => {
    const { metadata } = getCluster(store.getState());

    const getScheduleValue = () => {
      if (data.osPatchingScheduleOption === "never") {
        return undefined;
      }

      return data.osPatchingScheduleOption === "custom"
        ? data.osPatchingSchedule || "* * * * *"
        : data.osPatchingScheduleOption;
    };

    const promise = api.patch(
      `v1alpha1/spectroclusters/${metadata.uid}/clusterConfig/osPatch`,
      {
        osPatchConfig: {
          onDemandPatchAfter: data.onDemandPatchAfter
            ? moment().add(10, "minutes")
            : undefined,
          schedule: getScheduleValue(),
          patchOnBoot: data.patchOnBoot,
        },
      }
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t("The machine management config has been updated"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the machine management config"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
  init: () => {
    const { spec } = getCluster(store.getState());
    const { clusterConfig } = spec;

    const osPatchConfig =
      clusterConfig?.machineManagementConfig?.osPatchConfig || {};

    const isCustomSchedule = !DEFAULT_SCHEDULE_OPTIONS.map(
      (option) => option.value
    ).includes(osPatchConfig.schedule);

    const getScheduleOption = () => {
      if (!osPatchConfig.schedule) {
        return "never";
      }

      if (isCustomSchedule) {
        return "custom";
      }

      return osPatchConfig.schedule;
    };

    const isActiveOnDemandPatch =
      osPatchConfig.onDemandPatchAfter !== BEGINNING_OF_TIME &&
      moment().diff(moment(osPatchConfig.onDemandPatchAfter)) < 0;

    return Promise.resolve({
      patchOnBoot: osPatchConfig.patchOnBoot,
      onDemandPatchAfter: isActiveOnDemandPatch,
      persistedOnDemandPatchAfter: isActiveOnDemandPatch,
      timeUntilPatch:
        isActiveOnDemandPatch &&
        moment(osPatchConfig.onDemandPatchAfter).format("HH:mm"),
      osPatchingScheduleOption: getScheduleOption(),
      osPatchingSchedule: isCustomSchedule ? osPatchConfig.schedule : undefined,
    });
  },
});

export const clusterCloudFormActions = createFormActions({
  async init() {
    const cloudConfig = getClusterCloudConfig(store.getState());
    await store.dispatch(sshKeysFetcher.fetch());
    const keys = sshKeysFetcher.selector(store.getState()).result;
    return {
      sshKeys: cloudConfig.spec.clusterConfig.sshKeys.map((key) => {
        const existingKey = keys.find(
          (sshKey) => sshKey.spec.publicKey === key
        );
        if (existingKey) {
          return existingKey.guid;
        }

        return key;
      }),
      ntpServers: cloudConfig.spec.clusterConfig.ntpServers || [],
    };
  },
  async submit(data) {
    const cloudConfig = getClusterCloudConfig(store.getState());
    const cloudType = cloudConfig.spec.cloudAccount.kind;
    const promise = api.put(
      `v1alpha1/cloudconfigs/${cloudType}/${cloudConfig.metadata.uid}/clusterConfig`,
      {
        clusterConfig: {
          ...(cloudConfig.spec.clusterConfig || {}),
          ...data,
          sshKeys: getStoreEntity(data.sshKeys, [SSHKeysSchema])
            .map((key, index) => {
              if (key === null) {
                return data.sshKeys[index];
              }
              return key?.spec?.publicKey;
            })
            .filter(Boolean),
        },
      }
    );
    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "The cluster configuration has been updated. Cluster will update if necessary"
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the cluster configuration"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
});

export const clusterEditModal = new ModalService();

export function openClusterEditModal({ menuItem = "basicinfo" } = {}) {
  return () => {
    clusterEditModal.open({ menuItem });
  };
}

export function onSettingsConfirm() {
  return async (dispatch, getState) => {
    const cluster = getRawCluster(getState());
    const { menuItem } = clusterEditModal.data;

    if (menuItem === "basicinfo") {
      await dispatch(
        editClusterFormActions.submit({ module: EDIT_CLUSTER_MODULE })
      );
    }

    if (menuItem === "machinemanagement") {
      await dispatch(
        clusterMachineManagementFormActions.submit({
          module: "machineManagement",
        })
      );
    }

    if (menuItem === "fargateProfiles") {
      await dispatch(
        fargatePoliciesFormActions.submit({
          module: "fargateProfiles",
        })
      );
    }

    if (menuItem === "scans") {
      await dispatch(
        scanFormActions.submit({
          module: "scan-policies",
        })
      );
    }

    if (menuItem === "schedulebackups") {
      await dispatch(
        scheduleBackupsFormAction.submit({
          module: SCHEDULE_BACKUPS_MODULE,
        })
      );
    }

    if (menuItem === "rolebindings") {
      await dispatch(
        clusterRoleBindings.submit({ module: CLUSTER_ROLE_BINDINGS })
      );
    }

    if (menuItem === "clusterConfiguration") {
      await dispatch(
        clusterCloudFormActions.submit({ module: "clusterCloudConfig" })
      );
    }

    dispatch(fetchCluster(cluster.metadata.uid));
    clusterEditModal.close();
  };
}

export const importProcedureModal = new ModalService("importProcedureModal");

export function openImportProcedureModal() {
  return (_, getState) => {
    const state = getState();
    if (isClusterDeleting(state) || isClusterDeleted(state)) {
      return;
    }
    importProcedureModal.open();
  };
}

export async function submit(data) {
  const cluster = getRawCluster(store.getState());

  const promise = api.patch(
    `v1alpha1/spectroclusters/${cluster.metadata.uid}/metadata`,
    {
      metadata: {
        labels: formatTags(data.tags),
        annotations: { description: data.description },
      },
    }
  );
  try {
    await promise;
    notifications.success({
      message: i18next.t("Cluster details were updated successfully"),
    });
  } catch (err) {
    notifications.error({
      message: i18next.t("Something went wrong while updating the cluster"),
      description: err.message,
    });
  }
  return promise;
}
