import create from "./create";
import loginService from "services/loginService";
import history from "services/history";
import store from "services/store";
import { getCurrentContext } from "state/auth/selectors";

function getDefaultHeaders() {
  const state = store.getState();
  const projectUid = getCurrentContext(state)?.project?.metadata?.uid;

  const headers = {};

  if (projectUid && !state.auth.isLoading) {
    headers["ProjectUid"] = projectUid;
  }

  return headers;
}

async function catchMissingAuth() {
  const state = store.getState();
  const pathname = history.location.pathname;
  const returnTo = pathname !== "/auth" ? pathname : "";
  const inactive = !!state.auth?.me;

  const data = await loginService.logout();
  const query = `returnTo=${returnTo}&inactive=${inactive}`;

  if (data?.redirectUrl) {
    window.location.href = data.redirectUrl;
    return;
  }

  history.push(`/auth?${query}`);
}

const api = create({ getDefaultHeaders, catchMissingAuth });

export const nonProjectApi = create({ catchMissingAuth });

export default api;
