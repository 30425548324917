import dataFetcher from "modules/dataFetcher";
import {
  RepositorySchema,
  ClusterProfileSchema,
  PackSchema,
  PackVersionSchema,
} from "utils/schemas";
import api from "services/api";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import store from "services/store";
import history from "services/history";
import ProfileBuilderModule from "modules/profileBuilder";
import { ENVIRONMENTS } from "utils/constants";
import { getQueryFromFilters } from "./actions/listing";
import createFormActions from "modules/form/actions";
import {
  getRawClusterProfile,
  getUpdateNotification,
  getUpdateNotificationPacks,
} from "./selectors/details";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";

export const editClusterProfile = new ModalService("editClusterProfile");
export const deleteProfileModal = new ModalService("deleteProfile");

export const repositoriesFetcher = dataFetcher({
  selectors: ["repositories", "pack"],
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1alpha1/registries/pack");
    return response.items;
  },
});

export const packNamesFetcher = dataFetcher({
  selectors: [
    "packName",
    (state) => state.forms.packSteps.data.type,
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  async fetchData([_, layerType, cloudType]) {
    const isBasicLayer = ["os", "cni", "csi", "k8s"].includes(layerType);
    let filters = `spec.layer=${layerType}`;
    if (!isBasicLayer) {
      filters = `spec.layer=addonANDspec.addonType=${layerType}`;
    }

    if (cloudType === "all") {
      filters = `spec.cloudTypes_in_${ENVIRONMENTS.map(
        (env) => env.apiKey
      ).join(",")},allAND${filters}`;
    } else {
      filters = `spec.cloudTypes_in_${cloudType},allAND${filters}`;
    }

    const promise = api.get("v1alpha1/packs", { filters, limit: 200 });
    store.dispatch({
      type: "FETCH_LAYER_PACKS",
      promise,
      schema: {
        items: [PackSchema],
      },
    });
    const response = await promise;
    const items = response.items.reduce((accumulator, item) => {
      accumulator.add(item.spec.name);
      return accumulator;
    }, new Set());

    return [...items]
      .map((name) => {
        const item = response.items.find((item) => item.spec.name === name);
        const disabled = JSON.parse(item.spec?.annotations?.disabled || false);

        return {
          label: item.spec.displayName,
          value: name,
          disabled,
          comingSoon: disabled,
          name,
          logo: item.spec.logoUrl,
          cloudTypes: cloudType === "all" ? item.spec.cloudTypes || [] : [],
          registries: [
            ...new Set(
              response.items
                .filter((item) => item.spec.name === name)
                .map((item) => item.spec.registryUid)
            ),
          ],
          presets: item.spec.presets,
        };
      })
      .sort((packA, packB) => {
        return packA.disabled - packB.disabled;
      });
  },
});

export const configurePacksModal = new ModalService("configurePacks");

export const cloneClusterProfileModal = new ModalService("cloneClusterProfile");

export const profileListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  fetchData(query) {
    const { continue: continueToken } = query;
    return api.post(
      `v1alpha1/dashboard/clusterprofiles${
        continueToken ? `?continue=${continueToken}` : ""
      }`,
      query
    );
  },
  schema: [ClusterProfileSchema],
});

export const profileBuilderCreateModule = new ProfileBuilderModule();
export const profileBuilderEditModule = new ProfileBuilderModule();

// NOTE: similar to the cluster counterpart but simplified
export const updatesFormAction = createFormActions({
  async init() {
    const clusterprofile = getRawClusterProfile(store.getState());

    const updateNotifications = getUpdateNotification(store.getState());
    const events = updateNotifications.reduce(
      (accumulator, notification) => [...accumulator, ...notification.events],
      []
    );

    if (events.length === 0) {
      return {};
    }

    const listOfPacks = new Set();
    const promises = events.reduce((acc, event) => {
      if (
        ["RegistryPackUpdate", "RegistryPackVersionUpdate"].includes(
          event.type
        ) &&
        !listOfPacks.has(event.packName)
      ) {
        const promise = store.dispatch({
          promise: api.get(
            `v1alpha1/clusterprofiles/${clusterprofile.metadata.uid}/packs/${event.packName}/config?packUid=${event.packUid}`
          ),
          type: "FETCH_PACK_DIVERGENCE_INFO",
          packName: event.packName,
          packUid: event.packUid,
          schema: {
            items: [
              {
                spec: PackVersionSchema,
              },
            ],
          },
        });
        listOfPacks.add(event.packName);
        acc.push(promise);
      }

      return acc;
    }, []);
    await Promise.all(promises);

    const divergenceInfo = store.getState().clusterprofile.details.notification
      .divergences;
    const packs = getUpdateNotificationPacks(store.getState());

    const values = packs.reduce((accumulator, pack) => {
      const [profileInfo, repoInfo] =
        divergenceInfo[pack.metadata.name]?.items || [];

      if (!profileInfo) {
        return accumulator;
      }

      if (!profileInfo.spec.isValuesOverridden) {
        accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
          repoInfo.spec?.values
        );
      } else {
        accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
          profileInfo.spec?.values
        );
      }

      return accumulator;
    }, {});

    return values;
  },
});
