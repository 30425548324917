import React from "react";
import Fields from "components/ui/Fields";
import { Checkbox } from "antd";
import styled from "styled-components";

import createList, { Blocks } from "modules/list";
import { rolesListActions } from "state/roles/actions/list";
import { makeDisabledValuesSelector } from "state/roleManagement/selectors";
import { RoleSchema } from "utils/schemas";
import { FieldWrap } from "components/styled/Field";
import { lightWhite } from "utils/constants/colors";
import { connect } from "react-redux";

const RoleWrap = styled.div`
  background: ${lightWhite};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
  padding: 10px 15px;
  width: 100%;
`;

const CheckboxGroupWrap = styled.div`
  margin: 0 -24px -24px;

  .ant-checkbox-group {
    width: 100%;
  }

  > ${FieldWrap} {
    margin: 0;
  }
`;

function Item({ data, disabledValues }) {
  const { metadata } = data;
  const isImplicit = disabledValues.includes(data.guid);
  return (
    <RoleWrap>
      <Checkbox
        value={data.guid}
        disabled={isImplicit}
        indeterminate={isImplicit}
      >
        {metadata.name}
      </Checkbox>
    </RoleWrap>
  );
}

function createRoleSelector(query = {}, moduleName) {
  const ListModule = createList({
    initialQuery() {
      return query;
    },
    actions: rolesListActions,
    schema: [RoleSchema],
  });

  const getDisabledValues = makeDisabledValuesSelector(moduleName);

  const ConnectedItem = connect((state) => ({
    disabledValues: getDisabledValues(state),
  }))(Item);

  return function RoleSelector({ name, value }) {
    return (
      <ListModule module={moduleName}>
        <CheckboxGroupWrap>
          <Fields.CheckboxGroup name={name} data-qa={moduleName}>
            <Blocks.List
              Item={(props) => <ConnectedItem value={value} {...props} />}
            />
          </Fields.CheckboxGroup>
        </CheckboxGroupWrap>
      </ListModule>
    );
  };
}

export const ProjectRoleSelector = createRoleSelector(
  { filters: "spec.scope=project" },
  "projectScopeRoles"
);

export const TenantRoleSelector = createRoleSelector(
  { filters: "spec.scope=tenant" },
  "tenantScopeRoles"
);
