import React from "react";
import styled, { css } from "styled-components";

import * as COLORS from "utils/constants/colors";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.layout === "horizontal" &&
    css`
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
    `}
`;

const ItemContainer = styled.div`
 ${(props) =>
   props.addMargins &&
   css`
     margin-bottom: 30px;
   `}
  width: ${(props) => props.cols && `calc(100% / ${props.cols})`};
`;

const ItemLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${COLORS.silver};
  margin-bottom: 5px;
`;

const ItemValue = styled.div`
  font-size: 15px;
  color: ${COLORS.nearBlack};
`;

const DescriptionItem = ({ label, value, layout, cols, addMargins }) => {
  return (
    <ItemContainer layout={layout} cols={cols} addMargins={addMargins}>
      <ItemLabel>{label}</ItemLabel>
      <ItemValue>{value || "-"}</ItemValue>
    </ItemContainer>
  );
};

const Descriptions = ({ options, layout, cols, addMargins = true }) => {
  function renderOptions(item, index) {
    return (
      <DescriptionItem
        {...item}
        key={index}
        layout={layout}
        cols={cols}
        addMargins={addMargins}
      />
    );
  }
  return (
    <DescriptionContainer layout={layout}>
      {options.map(renderOptions)}
    </DescriptionContainer>
  );
};

export default Descriptions;
