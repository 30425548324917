import React from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import * as colors from "utils/constants/colors";
import { TextButton } from "components/ui/Button";
import Descriptions from "components/common/Descriptions";

import createProjectRoleManagement from "modules/roleManagement/ProjectRoles";
import createTenantRoleManagement from "modules/roleManagement/TenantRoles";

import {
  projectRolesListActions,
  updateData,
} from "state/roleManagement/actions/projectRoles";

import {
  updateData as tenantRolesUpdateData,
  getSelectedEntityRoles,
  tenantRolesListActions,
} from "state/roleManagement/actions/tenantRoles";
import { getFullName } from "utils/presenters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPen } from "@fortawesome/pro-solid-svg-icons";
import Link from "components/common/History/Link";

const Container = styled.div`
  padding: 20px;
  width: 400px;
  border-left: 1px solid ${colors.lightMidGray};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.darkGray};
  cursor: pointer;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderActions = styled.div`
  > .anticon-edit {
    margin-right: 20px;
  }
`;

const RemoveTeam = styled(TextButton)`
  font-size: 14px;
  margin-top: 14px;
  font-weight: 500;
`;

export const ProjectRolesManagement = createProjectRoleManagement({
  listActions: projectRolesListActions,
  updateData,
});

const TenantRolesManagement = createTenantRoleManagement({
  updateData: tenantRolesUpdateData,
  getSelectedEntityRoles,
  entityType: "team",
  listActions: tenantRolesListActions,
});

const TeamInformation = ({
  team,
  closeOverviewPanel,
  openTeamFormModal,
  onTeamRemove,
}) => {
  const { t } = useTranslation();
  const { name } = team.metadata;
  const { emailId, users = [] } = team.spec;

  const userOptions = [
    {
      label: "",
      value: name,
    },
    {
      label: t("Email"),
      value: emailId || "-",
    },
    {
      label: t("Team Members"),
      value: !isEmpty(users)
        ? users.map((user) => <div key={user.guid}>{getFullName(user)}</div>)
        : "-",
    },
  ];

  return (
    <Container>
      <HeaderWrap>
        <HeaderActions>
          <Link to="/management/teams/edit">
            <StyledIcon
              icon={faPen}
              data-qa="edit_team"
              onClick={() => openTeamFormModal("edit")}
            />
          </Link>
        </HeaderActions>
        <StyledIcon
          icon={faTimes}
          data-qa="close-informations"
          onClick={closeOverviewPanel}
        />
      </HeaderWrap>
      <Descriptions options={userOptions} />

      <ProjectRolesManagement />

      <TenantRolesManagement />

      <RemoveTeam danger data-qa="remove_team" onClick={onTeamRemove}>
        {t("Remove team")}
      </RemoveTeam>
    </Container>
  );
};

export default TeamInformation;
