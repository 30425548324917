import i18n from "i18next";

import api from "services/api/index";
import store from "services/store";
import ModalService from "services/modal";
import notifications from "services/notifications";

import AsyncAction from "modules/asyncAction";

import { getDeletedEntity } from "../selectors";
import { rbacListActions } from "./list";
import { RBAC_LIST_MODULE } from "../services";

export const deleteConfirmService = new ModalService();

export const deleteAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const state = store.getState();
    const entity = getDeletedEntity(state);
    const promise = api.delete(
      `v1alpha1/users/assets/clusterrbacs/${entity.metadata.uid}`
    );

    try {
      await promise;
      store.dispatch(rbacListActions.initialize(RBAC_LIST_MODULE));
      notifications.success({
        message: i18n.t(
          'Role binding "{{name}}" has been deleted successfully',
          { name: entity.metadata.name }
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying do delete the role binding"
        ),
        description: error.message,
      });
    }
  },
});

export function onRbacDelete(guid) {
  return function thunk() {
    deleteConfirmService.open({ guid }).then(() => {
      deleteAsyncAction.key(guid).trigger();
    });
  };
}
