import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  sampleRate: 100,
  service: "optic",
  env: process.env.NODE_ENV,
});

const logger = datadogLogs.logger;

export default logger;
