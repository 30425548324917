import { createSelector } from "reselect";
import { getEntity } from "utils/entities";
import { RoleBindingSchema } from "utils/schemas";

import { deleteConfirmService } from "./actions/delete";
import { clusterRbacsFetcher } from "./services";

export const getDeletedEntity = getEntity(
  () => deleteConfirmService.data?.guid,
  RoleBindingSchema
);

export const getClusterRoleBindingsPayload = createSelector(
  clusterRbacsFetcher.selector,
  (state) => state.forms?.clusterRoleBindings?.data?.roleBindingOptions,
  ({ result }, selectedOptions) => {
    return (selectedOptions || []).reduce((acc, uid) => {
      const item = result.find((item) => item.metadata?.uid === uid);
      if (item && !item.spec?.isDefault) {
        acc.push({
          uid,
          name: item?.metadata?.name,
        });
      }
      return acc;
    }, []);
  }
);
