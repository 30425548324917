import i18next from "i18next";

import api from "services/api";
import historyService from "services/history";
import ModalService from "services/modal";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";

import { BackupLocationSchema } from "utils/schemas";
import { getBoolean } from "utils/parsers";

import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import { getCurrentContext } from "state/auth/selectors";

export const BACKUP_LOCATIONS_MODULE = "backuplocations";
export const BACKUP_LOCATION_FORM_MODULE = "backuplocationmodal";

export const deleteConfirmService = new ModalService();
export const backupLocationModal = new ModalService();

const backupLocationValidator = new Validator();
backupLocationValidator.addRule(["name", "bucket", "region"], Missing());
backupLocationValidator.addRule(
  ["accessKey", "secretKey"],
  ApplyIf((value, key, data) => data.credentialType !== "sts", Missing())
);
backupLocationValidator.addRule(
  ["arn"],
  ApplyIf((value, key, data) => data.credentialType === "sts", Missing())
);

export const fetchBackupLocationsFetcher = dataFetcher({
  selectors: ["backuplocations"],
  async fetchData() {
    const response = await api.get("v1alpha1/users/assets/locations");
    return {
      items:
        response?.items?.map((item) => ({
          ...item,
          spec: {
            ...item.spec,
            isDefault: !!item.spec.isDefault,
          },
        })) || [],
    };
  },
});

export const backupLocationsListActions = new ListActions({
  dataFetcher: fetchBackupLocationsFetcher,
  schema: [BackupLocationSchema],
});

export function onBackupLocationDelete(guid) {
  return function thunk(dispatch) {
    deleteConfirmService.open({ guid }).then(async () => {
      const entity = getStoreEntity(guid, BackupLocationSchema);
      const { uid, name } = entity?.metadata || {};
      const promise = api.delete(`v1alpha1/users/assets/locations/s3/${uid}`);
      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18next.t(
            "Something went wrong while deleting the backup location."
          ),
          description: error.message,
        });
        dispatch(
          backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE)
        );
        return;
      }
      notifications.success({
        message: i18next.t(
          "Backup location '{{name}}' was deleted successfully",
          {
            name,
          }
        ),
      });
      dispatch(backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE));
    });
  };
}

export function onSetDefaultLocation(guid) {
  return async function thunk(dispatch) {
    const entity = getStoreEntity(guid, BackupLocationSchema);
    const promise = api.patch(
      `v1alpha1/users/assets/locations/s3/${entity?.metadata?.uid}/default`
    );
    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong."),
        description: error.message,
      });
      return;
    }
    notifications.success({
      message: i18next.t(
        "Default backup location has been successfully changed"
      ),
    });
    dispatch(backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE));
  };
}

function getPayload(data, uid) {
  const isAdmin = getCurrentContext(store.getState()).isAdmin;
  const {
    name,
    bucket,
    certificate,
    region,
    s3ForcePathStyle,
    s3Url,
    accessKey,
    secretKey,
    isDefault,
    arn,
    externalId,
    credentialType,
  } = data;

  const payload = {
    metadata: {
      name,
      uid,
    },
    spec: {
      type: isAdmin ? "admin" : "project",
      isDefault,
      config: {
        region,
        s3Url,
        s3ForcePathStyle: getBoolean(s3ForcePathStyle),
        bucketName: bucket,
        caCert: certificate,
        ...(credentialType === "secret"
          ? {
              credentials: {
                accessKey,
                secretKey,
                credentialType,
              },
            }
          : {
              credentials: {
                credentialType,
                sts: {
                  arn,
                  externalId,
                },
              },
            }),
      },
    },
  };

  return payload;
}

export const backupLocationFormActions = createFormActions({
  init: async () => {
    const locationUid = backupLocationModal?.data?.uid;
    let data;
    let config;

    if (locationUid) {
      data = await api.get(`v1alpha1/users/assets/locations/s3/${locationUid}`);
      config = data?.spec?.config;
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      provider: "aws",
      certificate: config?.caCert || "",
      bucket: config?.bucketName || "",
      credentialType: config?.credentials?.credentialType || "secret",
      region: config?.region || "",
      s3ForcePathStyle: !!config?.s3ForcePathStyle,
      s3Url: config?.s3Url || "",
      accessKey: config?.credentials?.accessKey || "",
      secretKey: config?.credentials?.secretKey || "",
      arn: config?.credentials?.sts?.arn || "",
      externalId: config?.credentials?.sts?.externalId || "",
      isDefault: !!data?.spec?.isDefault,
    });
  },
  validator: backupLocationValidator,
  submit: async (data) => {
    const locationUid = backupLocationModal?.data?.uid;
    const payload = getPayload(data, locationUid);
    let promise;

    if (locationUid) {
      promise = api.put(
        `v1alpha1/users/assets/locations/s3/${locationUid}`,
        payload
      );
    } else {
      promise = api.post("v1alpha1/users/assets/locations/s3", payload);
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: locationUid
          ? i18next.t(
              "Something went wrong while updating the backup location."
            )
          : i18next.t(
              "Something went wrong while creating the backup location."
            ),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: locationUid
        ? i18next.t("Backup location '{{name}}' was updated successfully", {
            name: data?.name,
          })
        : i18next.t("Backup location '{{name}}' was created successfully", {
            name: data?.name,
          }),
    });
  },
});

export function openBackupLocationModal(uid) {
  return (dispatch) => {
    function fallback() {
      historyService.push("/settings/backuplocations");
    }
    dispatch(
      backupLocationFormActions.init({ module: BACKUP_LOCATION_FORM_MODULE })
    );
    backupLocationModal.open({ uid }).then(async () => {
      await dispatch(
        backupLocationFormActions.submit({
          module: BACKUP_LOCATION_FORM_MODULE,
        })
      );
      fallback();
    }, fallback);
  };
}
