import store, { getStoreEntity } from "services/store";
import i18n from "i18next";

import api from "services/api";
import history from "services/history";
import ModalService from "services/modal";
import Validator from "services/validator";
import * as rules from "services/validator/rules";
import notifications from "services/notifications";

import { UserSchema, TeamSchema } from "utils/schemas";
import { getEntity } from "utils/entities";

import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";
import { projectRolesListActions } from "state/roleManagement/actions/projectRoles";
import { tenantRolesListActions } from "state/roleManagement/actions/tenantRoles";
import { getSelectedTeam } from "state/roleManagement/selectors";
import { getFullName } from "utils/presenters";

//

export const teamFormModal = new ModalService();
export const teamDeleteService = new ModalService("teamDelete");

export const listActions = new ListActions({
  fetchData() {
    return api.get("v1alpha1/teams/summary");
  },
  schema: [TeamSchema],
});

const validator = new Validator();
validator.addRule(["name"], rules.Missing());

export const teamFormActions = createFormActions({
  validator,
  init() {
    const state = store.getState();
    const selectedTeam = getSelectedTeam(state);
    const modalType = state.modal.data[teamFormModal.guid].type;

    if (modalType === "create") {
      return Promise.resolve({
        name: null,
        users: [],
      });
    }

    return Promise.resolve({
      name: selectedTeam?.metadata.name || null,
      users:
        selectedTeam?.spec.users.map((user) => ({
          key: user.guid,
          label: getFullName(user),
        })) || [],
    });
  },
  async submit(data) {
    const state = store.getState();
    const modalType = state.modal.data[teamFormModal.guid].type;
    const selectedTeam = getSelectedTeam(state);
    const teamUsersGuids = state.forms.createTeam.data.users.map(
      (user) => user.key
    );
    const users = getEntity(
      (state) => state.forms.createTeam.data.users.map((user) => user.key),
      [UserSchema]
    )(state);

    let payload = {
      metadata: {
        name: data.name,
      },
      spec: {
        users: users.map((user) => user.metadata.uid),
      },
      status: {},
    };

    const promise =
      modalType === "create"
        ? api.post("v1alpha1/teams", payload)
        : api.put(`v1alpha1/teams/${selectedTeam.metadata.uid}`, {
            ...payload,
            spec: {
              ...payload.spec,
              roles: selectedTeam.spec.roles.map((role) => role.metadata.uid),
            },
            metadata: { ...payload.metadata, uid: selectedTeam.metadata.uid },
          });

    let response;

    try {
      response = await promise;
      if (modalType === "edit") {
        const selectedTeamEntity = state.entities.team[selectedTeam.guid];
        store.dispatch({
          type: "UPDATE_ENTITY",
          entityType: "team",
          id: selectedTeamEntity.guid,
          updates: {
            ...selectedTeamEntity,
            metadata: {
              ...selectedTeamEntity.metadata,
              name: data.name,
            },
            spec: {
              ...selectedTeamEntity.spec,
              users: teamUsersGuids,
            },
          },
        });
      }
    } catch (err) {
      const message =
        modalType === "create"
          ? i18n.t("Something went wrong when creating the team")
          : i18n.t("Something went wrong when editing the team");

      notifications.error({
        message,
        description: err.message,
      });
      return Promise.reject(err);
    }

    if (!response) {
      return;
    }

    notifications.success({
      message: i18n.t('Team "{{teamName}}" has been created successfully', {
        teamName: data.name,
      }),
    });

    store.dispatch(setSelectedTeam(null));
    history.push("/management/teams");
    store.dispatch(listActions.fetchItems("teams"));
  },
});

export function openTeamFormModal(type) {
  return function thunk(dispatch) {
    teamFormModal.open({ type }).then(
      () => {
        return dispatch(teamFormActions.submit({ module: "createTeam" }));
      },
      () => history.push("/management/teams")
    );
  };
}

export function setSelectedTeam(team) {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_ENTITY",
      selectedEntityId: team?.guid || null,
      entityType: "team",
    });

    if (team) {
      dispatch(projectRolesListActions.initialize("projectRoles"));
      dispatch(tenantRolesListActions.initialize("tenantRoles"));
    }
  };
}

export function onTeamRemove() {
  return (dispatch, getState) => {
    const selectedTeamGuid = getSelectedTeam(getState());
    teamDeleteService.open({ guid: selectedTeamGuid }).then(async () => {
      const team = getStoreEntity(teamDeleteService.data.guid, TeamSchema);
      const promise = api.delete(`v1alpha1/teams/${team.metadata.uid}`);

      dispatch({
        type: "DELETE_TEAM",
        promise,
        schema: TeamSchema,
      });

      try {
        await promise;
      } catch (err) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to delete the team"
          ),
          description: err.message,
        });
      }

      notifications.success({
        message: i18n.t('Team "{{teamName}}" has been deleted successfully', {
          teamName: team.metadata.name,
        }),
      });
      dispatch(setSelectedTeam(""));
      dispatch(
        listActions.removeItems({
          module: "teams",
          items: [team],
        })
      );
    });
  };
}
