import dataFetcher from "modules/dataFetcher";
import ModalService from "services/modal";
import api from "services/api";

import { RoleBindingSchema } from "utils/schemas";

export const RBAC_LIST_MODULE = "roleBindingsList";
export const RBAC_FORM_MODULE = "roleBindings";
export const CLUSTER_ROLE_BINDINGS = "clusterRoleBindings";
export const rbacFormModal = new ModalService("rbacForm");

export const clusterRbacsFetcher = dataFetcher({
  selectors: ["clusterRolesBindings"],
  schema: [RoleBindingSchema],
  async fetchData() {
    const response = await api.get("v1alpha1/users/assets/clusterrbacs");
    return response.items;
  },
});

export const clusterRbacFetcher = dataFetcher({
  selectors: ["clusterRoleBinding", () => rbacFormModal.data?.uid || ""],
  schema: RoleBindingSchema,
  fetchData([_, uid]) {
    return api.get(`v1alpha1/users/assets/clusterrbacs/${uid}`);
  },
});
