import { createSelector } from "reselect";
import fetchers from "./services";
import rcompare from "semver/functions/rcompare";

export const getAllReposByPackType = createSelector(
  fetchers.repositoriesFetcher.selector,
  fetchers.helmRepositoriesFetcher.selector,
  (state) => state.forms.layerPack?.data?.packType,
  ({ result: repos }, { result: helmRepos }, packType) => {
    if (!packType) return [];
    return packType === "helmChart" ? helmRepos : repos;
  }
);

export const getAvailableRepositories = createSelector(
  getAllReposByPackType,
  (repos) => {
    return (
      repos?.map((repo) => ({
        ...repo,
        label: repo.metadata.name,
        value: repo.metadata.uid,
      })) || []
    );
  }
);

function matchAmbigousPatch(tag) {
  const [major, minor, patch] = tag.split(".");
  return major !== undefined && minor !== undefined && patch === "x";
}

export const getPackVersionsTree = createSelector(
  fetchers.packVersionsFetcher.selector,
  ({ result: versions }) => {
    if (!versions) {
      return;
    }

    const sortedVersions = [...versions].sort((pack1, pack2) => {
      let version1 = pack1.version;
      let version2 = pack2.version;

      if (version1.split(".").length < 3) {
        version1 = `${version1}.0`;
      }

      if (version2.split(".").length < 3) {
        version2 = `${version2}.0`;
      }

      return rcompare(version1, version2, true);
    });

    const roots = sortedVersions
      .filter((version) => {
        return matchAmbigousPatch(version.tag);
      })
      .map((version) => {
        return {
          title: version.tag,
          value: version.tag,
        };
      });

    sortedVersions.forEach((version) => {
      const parentTags = version?.parentTags || [];
      const parent = parentTags.find(matchAmbigousPatch);

      if (!parent) {
        return;
      }

      const parentVersion = roots.find(
        (rootVersion) => rootVersion.title === parent
      );

      if (parentVersion) {
        parentVersion.children = parentVersion.children || [];
        parentVersion.children.push({
          title: version.tag,
          value: version.tag,
        });
      }
    });

    return roots;
  }
);

export const arePacksLoading = createSelector(
  fetchers.clusterPacksFetcher.selector,
  (state) => state.forms?.importedPacks?.data?.clusterId,
  (result, clusterId) => result[clusterId]?.isLoading
);
