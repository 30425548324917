import _ from "lodash";
import store, { getStoreEntity } from "services/store";
import fetchers from "./services";
import { getPackValuesWithPresetsComment } from "utils/parsers";
import { PackSchema } from "utils/schemas";

export function mapFormDataToLayers(formData = {}) {
  const {
    packType,
    version,
    pack,
    repository,
    values,
    packUid,
    presets,
    manifests,
    installOrder,
  } = formData;
  const packVersions = fetchers.packVersionsFetcher.selector(store.getState())
    ?.result;
  const selectedPackVersion = packVersions?.find(
    (pack) => pack.tag === version
  );
  const packGuid = selectedPackVersion?.pack?.guid;
  const packValues = getStoreEntity(packGuid, PackSchema);

  return {
    type: packType,
    config: {
      registryUid: repository,
      name: pack,
      tag: version,
      values,
      packUid,
      logo: selectedPackVersion?.logoUrl,
      presets: packValues?.spec?.presets,
      schema: packValues?.spec?.schema,
      packVersionGuid: selectedPackVersion?.guid,
      selectedPresets: presets,
      installOrder,
      manifests: (manifests || []).filter((manifest) => manifest?.name),
    },
  };
}

export function extractPayload(layers = []) {
  return layers.reduce((acc, layer) => {
    const config = layer.config;
    let type = "spectro";

    if (layer.type === "manifest") {
      type = "manifest";
    }
    if (layer.type === "helmChart") {
      type = "helm";
    }

    if (config) {
      let manifests = config?.manifests
        ?.filter((manifest) => (manifest.uid ? true : !!manifest?.name))
        .map((manifest) => ({
          uid: manifest.uid,
          name: manifest?.name,
          content: manifest?.content,
        }));
      return [
        ...acc,
        {
          ..._.omit(config, [
            "presets",
            "schema",
            "packVersionGuid",
            "selectedPresets",
          ]),
          type,
          uid:
            type === "manifest"
              ? layer.config.packUid || "spectro-manifest-pack"
              : layer.config.packUid,
          values: getPackValuesWithPresetsComment(
            layer.config.values,
            layer.config.selectedPresets
          ),
          manifests,
        },
      ];
    }

    return acc;
  }, []);
}
