import { createSelector } from "reselect";
import { packRegistriesFetcher, helmRegistriesFetcher } from "./services";

export const getSelectedPackRegistry = createSelector(
  packRegistriesFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedHelmRegistry = createSelector(
  helmRegistriesFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);
