import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Fields from "components/ui/Fields";
import Button from "components/ui/Button";
import Switch from "components/common/Switch";
import LoginLayout from "components/common/layouts/Auth";

import { createForm } from "modules/form";

import { red, white } from "utils/constants/colors";

import { onForgotPassword, nextStep } from "state/auth/actions";

const ErrorWrap = styled.div`
  color: ${red};
  margin: 10px 0;
  text-align: center;
  text-transform: none;
  font-weight: normal;
`;

const Title = styled.div`
  color: ${white};
  font-size: 24px;
  font-weight: 500;
`;

const Subtitle = styled.div`
  font-size: 15px;
  color: ${white};
  opacity: 0.6;
`;

const StyledButton = styled(Button)`
  display: ${(props) => props.step === "resetConfirm" && "none"};
`;

function ForgotPassword({ nextStep, submitError, step }) {
  const { t } = useTranslation();

  function renderError() {
    if (!submitError) {
      return null;
    }
    return <ErrorWrap>{submitError.message}</ErrorWrap>;
  }

  function renderButtonText() {
    if (step === "resetPassword") {
      return t("Reset Password");
    }

    return t("Login");
  }

  return (
    <>
      <Switch value={step}>
        <Switch.Case value="resetPassword">
          <div>
            <Title>{t("Forgot your password?")}</Title>
            <Subtitle>
              {t(
                "We'll email your instructions on how to reset your password."
              )}
            </Subtitle>
          </div>
          <Fields.Input
            data-qa="email"
            label="email"
            name="emailId"
            type="email"
            placeholder={t("Email")}
          />
        </Switch.Case>
        <Switch.Case value="resetConfirm">
          <div>
            <Title>{t("Done")}</Title>
            <Subtitle>
              {t(
                "An email has been sent to you. Please follow the instructions to set a new password."
              )}
            </Subtitle>
          </div>
        </Switch.Case>
      </Switch>

      <div>
        {renderError()}
        <StyledButton
          data-qa="login"
          htmlType="submit"
          onClick={nextStep}
          step={step}
        >
          {renderButtonText()}
        </StyledButton>
      </div>
    </>
  );
}

const ConnectedForgotPassword = connect(
  (state) => ({
    step: state.auth.step,
  }),
  { onForgotPassword, nextStep }
)(ForgotPassword);

const Form = createForm({
  Component: ConnectedForgotPassword,
  init: () => Promise.resolve({ emailId: "" }),
});

export default function Login() {
  return (
    <LoginLayout>
      <Form module="forgotPassword" />
    </LoginLayout>
  );
}
