import React from "react";
import { Route } from "react-router";

import { MY_PROFILE } from "utils/constants/routes";
import ClusterProfiles from "./clusterprofiles/main";
import Clusters from "./clusters/main";
import Settings from "./settings/main";
import AuditLogs from "./audit/main";
import Roles from "./roles/main";
import Projects from "./projects/main";
import Management from "./management/main";
import Overlords from "./overlords/main";
import MyProfile from "./my-profile";

const Pages = () => (
  <>
    <Clusters />
    <ClusterProfiles />
    <Settings />
    <AuditLogs />
    <Roles />
    <Projects />
    <Management />
    <Overlords />
    <Route path={MY_PROFILE} component={MyProfile} />
  </>
);

export default Pages;
