import React, { useContext, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import {
  InfoCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Input as AntInput,
  Select as AntSelect,
  Radio,
  Checkbox,
  DatePicker,
  TimePicker,
  InputNumber,
  Upload,
  Tree,
  TreeSelect,
  Tooltip,
  Switch,
  Tag,
} from "antd";

import { TextButton } from "../Button";

import { connectField, Context as FormContext } from "modules/form";
import { FieldWrap, Error } from "components/styled/Field";
import RadioGroup from "components/ui/RadioGroup";
import CardSelector from "components/ui/CardSelector";
import RoleSelector from "components/common/RoleSelector";
import MultipleFields from "components/ui/MultipleFields";
import SelectWithOptions from "./Select";
import { darkGray, blue } from "utils/constants/colors";
import Cascader from "./Cascader";
import Cron from "./Cron";

const PasswordFieldContainer = styled.div`
  display: flex;

  .ant-input {
    height: 40px;
  }
`;

const InputContainer = styled.div`
  width: 428px;
  display: flex;

  > div {
    margin-top: 0px;
  }
`;

const Eye = styled(EyeOutlined)`
  font-weight: bold;
  color: ${darkGray};
  font-size: 18px;
`;

const EyeInvisible = styled(EyeInvisibleOutlined)`
  font-weight: bold;
  color: ${darkGray};
  font-size: 18px;
`;

const SeePasswordButton = styled(TextButton)`
  margin: 3px 0 0 10px;
  min-width: auto;
  :focus {
    outline: 0;
  }
`;

const RadioLabel = styled.div`
  display: inline-flex;
  align-items: center;
  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 11px;
  font-size: 14px;
  vertical-align: middle;
`;

const Select = styled(AntSelect)`
  .ant-select-arrow.ant-select-arrow-loading {
    color: ${blue};
  }

  .ant-select-selection-item > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function Field(Component) {
  function FieldComponent(
    { required = true, label, validation, description, hidden, ...rest },
    ref
  ) {
    let error = null;
    const { t } = useTranslation();

    if (validation) {
      error = validation[0];
    }

    function renderLabel() {
      if (!label) {
        return null;
      }
      return (
        <label>
          {label} {!required && t("(Optional)")}
          {description && (
            <Tooltip title={description} placement="right">
              <StyledInfoCircleOutlined />
            </Tooltip>
          )}
        </label>
      );
    }

    return (
      <FieldWrap
        className={error && `ant-form-item-has-${error.status}`}
        hidden={hidden}
      >
        {renderLabel()}
        <Component
          ref={ref}
          {...rest}
          validateStatus={error && error.status}
          error={error}
        />
        {error && <Error>{error.result}</Error>}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

function Input({ submitOnEnter = false, ...rest }, ref) {
  const context = useContext(FormContext);
  return (
    <AntInput
      ref={ref}
      onKeyPress={(ev) => {
        if (submitOnEnter && ev.key === "Enter") {
          context.onSubmit();
        }
      }}
      {...rest}
    />
  );
}

const NormalizeComponent = (Component) => {
  function NormalizedComponent({ validateStatus, ...rest }, ref) {
    function parseNativeEvent(ev) {
      if (!ev?.target) {
        return ev;
      }

      if (ev.target.type === "checkbox") {
        return ev.target.checked;
      }

      return ev.target.value;
    }

    return (
      <Component
        ref={ref}
        {...rest}
        onChange={(ev) => rest.onChange(parseNativeEvent(ev))}
      />
    );
  }

  NormalizedComponent.displayName = `NormalizedComponent(${Component.displayName})`;

  return React.forwardRef(NormalizedComponent);
};

const Tags = (props) => {
  const onTagChange = (value) => {
    if (value.length === 0) {
      props.onChange(value);
      return;
    }

    let lastValue = value[value.length - 1].trim();
    const parsedValue = [...value];

    if (lastValue.includes(" :") || lastValue.includes(": ")) {
      lastValue = lastValue
        .split(":")
        .map((tagParts) => tagParts.trim())
        .join(":");
    }

    parsedValue.splice(value.length - 1, 1, lastValue);
    props.onChange(parsedValue);
  };

  const invalidTags = props?.error?.invalidTags || [];

  return (
    <Select
      mode="tags"
      open={false}
      tagRender={
        invalidTags.length > 0 &&
        (({ value, label, ...rest }) => {
          const isInvalidTag = invalidTags.includes(value);

          return (
            <Tag
              color={!isInvalidTag ? "default" : "error"}
              style={{ padding: "0 4px 0 8px", fontSize: 14 }}
              {...rest}
            >
              {label}
            </Tag>
          );
        })
      }
      {...props}
      onChange={onTagChange}
      data-qa-mode="tags"
    />
  );
};
const CheckboxGroup = connectField(Field(Checkbox.Group));
const ConnectedTreeSelect = connectField(Field(TreeSelect));
const ConnectedRadioGroup = connectField(Field(RadioGroup));

function TreeSelectWithDescription(
  { treeData, children, selectableParent, ...rest },
  ref
) {
  const updateTreeDataTitles = useCallback(
    (data = []) => {
      if (!(Array.isArray(data) && data)) {
        return [];
      }

      return data.map((item) => ({
        ...item,
        title: getTitle(item),
        "data-qa": `${rest["data-qa"]}-option`,
        "data-qa-value": item.value,
        children: updateTreeDataTitles(item?.children || []),
      }));
    },
    [rest]
  );

  const data = useMemo(() => updateTreeDataTitles(treeData), [
    treeData,
    updateTreeDataTitles,
  ]);

  function getTitle({ title, description }) {
    if (!title) return;
    return (
      <span>
        {description ? (
          <Tooltip title={description} placement="right">
            {title}
          </Tooltip>
        ) : (
          title
        )}
      </span>
    );
  }

  return (
    <ConnectedTreeSelect
      ref={ref}
      {...rest}
      treeData={data}
      dropdownClassName={!selectableParent && "spectro-tree-selector"}
    >
      {children}
    </ConnectedTreeSelect>
  );
}

function RadioGroupWithDescription({ options, children, ...rest }) {
  const data = useMemo(() => {
    return options?.map((option) => ({
      ...option,
      label: getLabel(option),
    }));
  }, [options]);

  function getLabel({ label, description, ...rest }) {
    if (!label) {
      return null;
    }
    return (
      <RadioLabel {...rest}>
        <span>{label}</span>{" "}
        {description && (
          <Tooltip title={description} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </RadioLabel>
    );
  }

  return (
    <ConnectedRadioGroup options={data} {...rest}>
      {children}
    </ConnectedRadioGroup>
  );
}

export const NormalizedCheckbox = NormalizeComponent((props) => (
  <Checkbox {...props} checked={props.value} />
));

const NormalizedSwitch = NormalizeComponent((props) => (
  <Switch {...props} checked={props.value} />
));

const NormalizedTree = NormalizeComponent((props) => (
  <Tree {...props} checkedKeys={props.value} onCheck={props.onChange} />
));

const SeePasswordEye = ({ setCanSeePassword, canSeePassword }) => {
  return (
    <SeePasswordButton
      onClick={() => setCanSeePassword((prevState) => !prevState)}
    >
      {canSeePassword ? <EyeInvisible /> : <Eye />}
    </SeePasswordButton>
  );
};

const PasswordField = ({
  displayEyeIcon = true,
  allowAutofill = false,
  ...rest
}) => {
  const [canSeePassword, setCanSeePassword] = useState(false);
  return (
    <PasswordFieldContainer>
      <InputContainer>
        <AntInput
          type={canSeePassword ? "text" : "password"}
          autoComplete={allowAutofill ? "current-password" : "new-password"}
          {...rest}
        />
      </InputContainer>
      {displayEyeIcon && (
        <SeePasswordEye
          setCanSeePassword={setCanSeePassword}
          canSeePassword={canSeePassword}
        />
      )}
    </PasswordFieldContainer>
  );
};

export function connectComponent(Component) {
  return connectField(Field(NormalizeComponent(Component)));
}

export const NormalizedInput = NormalizeComponent(React.forwardRef(Input));
const ConnectedInput = connectField(Field(NormalizedInput), true);

const ConnectedTextArea = connectField(
  Field(
    NormalizeComponent((props) => <AntInput.TextArea rows="5" {...props} />)
  ),
  true
);

const NormalizedDatePicker = NormalizeComponent((props) => (
  <DatePicker {...props} value={props.value ? moment(props.value) : ""} />
));

const NormalizedTimePicker = NormalizeComponent((props) => (
  <TimePicker {...props} value={props.value ? moment(props.value) : ""} />
));

export default {
  Input: ConnectedInput,
  TextArea: ConnectedTextArea,
  Tags: connectField(Field(Tags)),
  Select: connectField(Field(React.forwardRef(SelectWithOptions))),
  RadioGroup: RadioGroupWithDescription,
  CardSelector: connectField(Field(CardSelector)),
  Radio,
  CheckboxGroup,
  Checkbox: connectField(Field(NormalizedCheckbox)),
  DatePicker: connectField(Field(NormalizedDatePicker)),
  TimePicker: connectField(Field(NormalizedTimePicker)),
  InputNumber: connectField(Field(NormalizeComponent(InputNumber)), true),
  Upload: connectField(Field(NormalizeComponent(Upload))),
  RoleSelector: connectField(Field(RoleSelector)),
  Tree: connectField(Field(NormalizedTree)),
  TreeSelect: React.forwardRef(TreeSelectWithDescription),
  MultipleInputs: connectField(Field(MultipleFields(ConnectedInput)), true),
  MultipleTextAreas: connectField(
    Field(MultipleFields(ConnectedTextArea)),
    true
  ),
  PasswordField: connectField(Field(NormalizeComponent(PasswordField)), true),
  Switch: connectField(Field(NormalizedSwitch)),
  Cascader: connectField(Field(Cascader)),
  Cron: connectField(Field(Cron)),
};
