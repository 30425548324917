import store from "services/store";
import api from "services/api";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(["name"], Missing());
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

validator.addRule(["accessKey", "secretKey"], (value, key, data) => {
  if (data.credentialType === "secret") {
    return Missing()(value, key, data);
  }
  return false;
});

validator.addRule(["arn", "externalId", "accountId"], (value, key, data) => {
  if (data.credentialType === "sts") {
    return Missing()(value, key, data);
  }
  return false;
});

async function submit(data) {
  const { credentialType, arn, externalId, accountId } = data;
  const getCredentials = () => {
    if (credentialType === "sts") {
      return {
        credentialType,
        sts: {
          arn,
          externalId,
          accountId,
        },
      };
    }
    return {
      credentialType,
      accessKey: data.accessKey.trim(),
      secretKey: data.secretKey.trim(),
    };
  };

  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        description: data.description,
      },
    },
    spec: getCredentials(),
  };

  return store.dispatch(createAccount("aws", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  const stsData = await api.get("v1alpha1/clouds/aws/account/sts");

  return Promise.resolve({
    name: data?.metadata.name || "",
    accessKey: data?.spec.accessKey || "",
    secretKey: data?.spec.secretKey || "",
    description: data?.metadata?.annotations?.description || "",
    credentialType: data?.spec?.credentialType || "secret",
    arn: data?.spec?.sts?.arn || "",
    accountId: stsData?.accountId,
    externalId: stsData?.externalId,
  });
}

export const awsAccountFormActions = createActions({
  submit,
  validator,
  init,
});
