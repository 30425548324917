import React from "react";
import { useTranslation } from "react-i18next";

import Fields from "components/ui/Fields";
import { ProjectRoleSelector } from "components/common/ScopedRolesSelector";

function Form({ projects, selectedProjectRoleId, data }) {
  const { t } = useTranslation();

  return (
    <>
      <Fields.Select
        label={t("Projects")}
        options={projects}
        name="projectUid"
        data-qa="select_project"
        disabled={selectedProjectRoleId}
      />
      <ProjectRoleSelector name="roles" value={data.roles} />
    </>
  );
}

export default Form;
