import moment from "moment";
import { coresChartTimePeriodCache } from "services/localstorage/cache";
import { getCurrentUser } from "state/auth/selectors";

import {
  projectCPUUtilizationFetcher,
  projectsMonthlyCoreHoursFetcher,
} from "state/project/services/metrics";

export function coresChartTimePeriodChange(timePeriod) {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    coresChartTimePeriodCache.set(currentUser.metadata.uid, timePeriod);

    const [value, unit] = timePeriod.split(" ");
    const filter = {
      value,
      unit,
    };

    const query = {
      startTime: moment()
        .subtract(value, unit)
        .utc(),
      endTime: moment().utc(),
    };

    dispatch({
      type: "CORES_CHART_TIME_PERIOD_CHANGE",
      query,
      filter,
      selectedKey: timePeriod,
    });

    dispatch(projectCPUUtilizationFetcher.fetch(query, filter));
  };
}

export function coresChartTimePeriodInit() {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const cachedDate = coresChartTimePeriodCache.get(currentUser.metadata.uid);
    const selectedKey = cachedDate || "30 days";

    dispatch({
      type: "CORES_CHART_TIME_PERIOD_INIT",
      selectedKey,
    });

    dispatch(coresChartTimePeriodChange(selectedKey));
  };
}

export function coresChartCalendarChange(date) {
  return (dispatch) => {
    const startOfMonth = moment(date)
      .startOf("month")
      .utc();

    const endOfMonth = moment(date)
      .endOf("month")
      .utc();

    const query = {
      startTime: startOfMonth,
      endTime: endOfMonth,
    };

    const filter = {
      value: moment(date).daysInMonth(),
      unit: "days",
    };

    dispatch({
      type: "CORES_CHART_TIME_PERIOD_CHANGE",
      filter,
      query,
      selectedKey: "calendar",
    });

    dispatch(projectCPUUtilizationFetcher.fetch(query, filter));
  };
}

export function monthlyCoreHoursChartCalendarChange(date = moment()) {
  return (dispatch) => {
    const startOfMonth = moment(date).startOf("month");
    const endOfMonth = moment(startOfMonth).endOf("month");

    const query = {
      startTime: startOfMonth,
      endTime: endOfMonth,
    };

    const filter = {
      value: moment(date).daysInMonth(),
      unit: "days",
    };

    dispatch({
      type: "MONTHLY_CORE_HOURS_CHART_CALENDAR_CHANGE",
      filter,
      query,
      selectedKey: "calendar",
    });

    dispatch(projectsMonthlyCoreHoursFetcher.fetch(query));
  };
}
