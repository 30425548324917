import createFormActions from "modules/form/actions";
import ListActions from "modules/list/actions";
import { scanWarning } from "../services/scan";
import { ClusterScanSchema } from "utils/schemas";
import { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import { getRawCluster } from "../selectors/details";
import notifications from "services/notifications";
import i18next from "i18next";
import { DEFAULT_SCHEDULE_OPTIONS } from "utils/constants";
import withPolling from "utils/withPolling";
import { getCronScheduleValue } from "utils/parsers";

//

const API_KEY_MAPPING = {
  kubebench: "kubeBench",
  kubehunter: "kubeHunter",
  sonobuoy: "sonobuoy",
};

const LOGS_API_KEY_MAPPING = {
  "kube-bench": "kubeBench",
  "kube-hunter": "kubeHunter",
  sonobuoy: "sonobuoy",
  kubebench: "kubeBench",
  kubehunter: "kubeHunter",
};

export const scansCountFetcher = keyedDataFetcher({
  selectors: ["scans", "count"],
  fetchData([_1, _2, clusterId]) {
    return api
      .get(
        `v1alpha1/spectroclusters/${clusterId}/features/complianceScan/logs/drivers`
      )
      .then((data) => {
        return {
          kubebench: data?.kubeBenchLogs?.length || 0,
          kubehunter: data?.kubeHunterLogs?.length || 0,
          sonobuoy: data?.sonobuoyLogs?.length || 0,
        };
      });
  },
});

export const kubebenchListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.kubeBenchLogs }));
  },
});

export const kubehunterListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.kubeHunterLogs }));
  },
});

export const sonobuoyListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.sonobuoyLogs }));
  },
});

export const scanFetcher = keyedDataFetcher({
  selectors: ["cluster-scans"],
  fetchData([_, scanUid], scanType) {
    const cluster = getRawCluster(store.getState());
    return api.get(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/${scanUid}/drivers/${LOGS_API_KEY_MAPPING[scanType]}`
    );
  },
});

const SCAN_LISTS = {
  kubebench: kubebenchListActions,
  kubehunter: kubehunterListActions,
  sonobuoy: sonobuoyListActions,
};

export function initList(type) {
  return function thunk(dispatch) {
    if (!SCAN_LISTS[type]) {
      return;
    }
    dispatch(SCAN_LISTS[type].initialize(type));
  };
}

export const watchScansTask = withPolling(async () => {
  const cluster = getRawCluster(store.getState());
  const data = await api.get(
    `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
  );
  store.dispatch({
    type: "SCANS_STATUS_UPDATE",
    schema: {
      kubeBenchLogs: [ClusterScanSchema],
      kubeHunterLogs: [ClusterScanSchema],
      sonobuoyLogs: [ClusterScanSchema],
    },
    promise: Promise.resolve(data),
  });

  const finished = Object.keys(data).every((key) => {
    const logs = data[key];

    return logs.every((log) => log.status.state === "Completed");
  });

  if (finished) {
    return Promise.resolve();
  }

  return Promise.reject();
}, 10000);

export function runOnDemand({ type }) {
  return function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());
    async function runScan() {
      try {
        await api.post(
          `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/onDemand`,
          {
            [API_KEY_MAPPING[type]]: {
              runScan: true,
            },
          }
        );
        notifications.success({
          message: i18next.t("Scan scheduled"),
        });
        dispatch(scansCountFetcher.key(cluster.metadata.uid).fetch());
        await dispatch(SCAN_LISTS[type].fetchItems(type));
        watchScansTask.start();
      } catch (err) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: err.message,
        });
      }
    }

    if (type === "sonobuoy") {
      scanWarning.open({ type }).then(runScan);
      return;
    }

    runScan();
  };
}

export function loadClusterScan(scanType, scanUid) {
  return function tunk(dispatch) {
    dispatch(scanFetcher.key(scanUid).fetch(scanType));
  };
}

export const scanFormActions = createFormActions({
  async init() {
    const cluster = getRawCluster(store.getState());
    const data = await api.get(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan`
    );
    function isEnabled(type) {
      return !!Object.keys(data.spec.driverSpec[type]?.config.schedule || {})
        .length;
    }

    function getRecurrency(type) {
      const schedule = data.spec.driverSpec[type]?.config.schedule;
      const selectedOption = DEFAULT_SCHEDULE_OPTIONS.find(
        (option) => option.value === schedule?.scheduledRunTime
      );
      return {
        type: selectedOption ? selectedOption.value : "custom",
        recurrency: schedule?.scheduledRunTime,
      };
    }

    return {
      kubebenchEnabled: isEnabled("kube-bench"),
      kubebench: getRecurrency("kube-bench"),
      kubehunterEnabled: isEnabled("kube-hunter"),
      kubehunter: getRecurrency("kube-hunter"),
      sonobuoyEnabled: isEnabled("sonobuoy"),
      sonobuoy: getRecurrency("sonobuoy"),
    };
  },

  submit(data) {
    const cluster = getRawCluster(store.getState());
    let policies = {
      scanPolicy: {},
    };

    if (data.kubebenchEnabled) {
      policies.scanPolicy.kubeBench = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubebench.type,
            data.kubebench.recurrency
          ),
        },
      };
    }

    if (data.kubehunterEnabled) {
      policies.scanPolicy.kubeHunter = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubehunter.type,
            data.kubehunter.recurrency
          ),
        },
      };
    }

    if (data.sonobuoyEnabled) {
      policies.scanPolicy.sonobuoy = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.sonobuoy.type,
            data.sonobuoy.recurrency
          ),
        },
      };
    }
    api.put(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/complianceScan`,
      policies.scanPolicy
    );
  },
});
