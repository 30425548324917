import i18next from "i18next";
import api from "services/api";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import store from "services/store";
import { editClusterProfile } from "../services";
import notifications from "services/notifications";

import createFormActions from "modules/form/actions";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";

import { ClusterProfileSchema } from "utils/schemas";
import { onDeleteProfile } from "./listing";
import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { clusterProfileFormActions } from "./layerConfig";

const EDIT_CLUSTER_MODULE = "editClusterProfile";

//

export async function submit(data) {
  const clusterprofile = getRawClusterProfile(store.getState());

  const promise = api.patch(
    `v1alpha1/clusterprofiles/${clusterprofile.metadata.uid}/metadata`,
    {
      metadata: {
        name: data.name,
        annotations: {
          description: data.description,
        },
        labels: formatTags(data.tags),
      },
    }
  );

  try {
    await promise;
    notifications.success({
      message: i18next.t("Cluster profile details were updated successfully"),
    });
  } catch (err) {
    notifications.error({
      message: i18next.t(
        "Something went wrong while updating the cluster profile"
      ),
      description: err.message,
    });

    return;
  }

  await store.dispatch(getClusterProfileAfterEdit(clusterprofile.metadata.uid));
  store.dispatch(clusterProfileFormActions.init({ module: "clusterprofile" }));
}

export const validator = new Validator();
function createValidator() {
  validator.removeRules();
  validator.addRule(["name"], Missing());
}

createValidator();

export const editClusterProfileFormActions = createFormActions({
  validator,
  submit,
  init: () => {
    const { metadata } = getRawClusterProfile(store.getState());
    const { name, annotations, labels } = metadata;

    return Promise.resolve({
      name: name,
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
    });
  },
});

export function onClusterProfileInfo() {
  return (dispatch) => {
    dispatch(
      editClusterProfileFormActions.init({ module: EDIT_CLUSTER_MODULE })
    );
    createValidator();
    editClusterProfile.open().then(async () => {
      await dispatch(
        editClusterProfileFormActions.submit({ module: EDIT_CLUSTER_MODULE })
      );
    });
  };
}

export function getClusterProfileAfterEdit(uid) {
  return function thunk(dispatch) {
    const promise = api.get(`v1alpha1/clusterprofiles/${uid}`);

    dispatch({
      type: "UPDATE_CLUSTER_PROFILE_DETAILS",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function onDetailsDelete() {
  return (dispatch, getState) => {
    const clusterprofile = getRawClusterProfile(getState());
    dispatch(onDeleteProfile(clusterprofile.guid));
  };
}

export function setIsClusterProfileEdit(isEdit) {
  return {
    type: "SET_CLUSTER_PROFILE_EDIT",
    isEdit,
  };
}
