import store from "services/store";
import i18n from "i18next";
import debounce from "redux-debounce-thunk";

import Validator from "services/validator";
import createFormActions from "modules/form/actions";
import { Missing, isKubernetesName } from "services/validator/rules";
import { NodeSchema, NodePoolSchema, CloudConfigSchema } from "utils/schemas";
import { UPDATE_STRATEGIES } from "utils/constants";

import {
  getAddedNodes,
  getNodePools,
  getNodePoolsNodes,
  getClusterCloudConfig,
  getNodeStatus,
  getSelectedNodePool,
  getSubnetsForSelectedAz,
  getAzureAzs,
  isStaticPlacementEnabled,
  getAzureInstanceTypes,
  getAllNodes,
} from "state/cluster/selectors/nodes";
import { getCluster } from "state/cluster/selectors/details";
import { getClusterByUid } from "./details";

import { pollNodes } from "utils/tasks";
import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import i18next from "i18next";

import { utilizationFetcher } from "state/cluster/services";
import moment from "moment";
import {
  azureInstanceTypesFetcher,
  azureAZFetcher,
  azureStorageAccountsFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
} from "../services/nodes";
import {
  datacentersFetcher,
  propertiesFetcher,
  ipamFetcher,
} from "../services/create";
import { dnsMappingsFetcher } from "state/dns/services";
import { createOpenstackFormFactory } from "modules/cluster/openstack";
import { maasCloudForm } from "./create";

const DEFAULT_RESOURCE_POOL_VALUE = "Default";
export const poolConfigModal = new ModalService();
export const connectNodeModal = new ModalService();
export const confirmNodePoolSizeModal = new ModalService();

const commonValidator = new Validator();
commonValidator.addRule(["poolName", "size"], Missing());
commonValidator.addRule("size", (size, _, nodePool) => {
  if (nodePool.isControlPlane && size % 2 === 0) {
    return i18n.t("Master node pool size should be an even number");
  }

  return false;
});
commonValidator.addRule(["poolName"], isKubernetesName());

export const openstackNodesForm = createOpenstackFormFactory(
  {
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
    getClusterConfig(state) {
      const config = getClusterCloudConfig(state)?.spec?.clusterConfig;

      return {
        domain: config?.domain?.name,
        region: config?.region,
        project: config?.project?.name,
        staticPlacement: !!config?.network?.name,
      };
    },
  },
  { isNodes: true }
);

const awsNodePoolValidator = new Validator();
awsNodePoolValidator.addRule(["instanceType", "azs"], Missing());
awsNodePoolValidator.addRule("maxPricePercentage", (value, key, data) => {
  if (data.instanceOption === "onSpot") {
    Missing()(value, key, data);
  }

  return false;
});
awsNodePoolValidator.addRule(function*(nodePool) {
  const staticPlacement = getSubnetsForSelectedAz(store.getState());
  for (const az of nodePool.azs) {
    yield {
      result:
        staticPlacement && !nodePool[`subnet_${az}`]
          ? "Missing subnet for selected az"
          : false,
      field: `subnet_${az}`,
    };
  }
});

const openstackNodePoolValidator = new Validator();
openstackNodePoolValidator.addRule(["flavor", "disk", "azs"], Missing());

const vsphereNodePoolValidator = new Validator();
vsphereNodePoolValidator.addRule(["memory", "disk", "cpu"], Missing());

const domainValidator = new Validator();
domainValidator.addRule(
  ["cluster", "datastore", "network"],
  Missing({ message: () => " " })
);
domainValidator.addRule(["parentPoolUid"], (...args) => {
  const useStaticIp = isStaticPlacementEnabled(store.getState());
  if (!useStaticIp) {
    return false;
  }

  return Missing({ message: () => " " })(...args);
});

domainValidator.addRule("cluster", function*(value, key, data) {
  const domains = store.getState().forms.nodePool.data.domains;
  const clusters = domains.map(({ cluster }) => cluster);

  for (const clusterIndex in clusters) {
    const cluster = clusters[clusterIndex];
    const duplicates = clusters.filter(
      (currentItem) => currentItem === cluster
    );
    yield {
      result:
        duplicates.length > 1 ? i18next.t("Clusters must be unique") : false,
      field: `domains.${clusterIndex}.cluster`,
    };
  }
});

vsphereNodePoolValidator.addRule("domains", domainValidator);
vsphereNodePoolValidator.addRule("domains", function*(value, key, data) {
  for (const domainIndex in value) {
    const domain = value[domainIndex];
    yield {
      result:
        data.isControlPlane &&
        data.domains
          .map((domain) => domain.network)
          .some((network) => network !== "" && network !== domain.network)
          ? i18n.t("Master nodes must share the same network")
          : false,
      field: `domains.${domainIndex}.network`,
    };
  }
});

const azurePoolValidator = new Validator();
azurePoolValidator.addRule(
  ["disk", "instanceType", "storageAccountType"],
  Missing()
);

azurePoolValidator.addRule("azs", (value, key, data) => {
  if (data.isMaster || data.isControlPlane) {
    return false;
  }

  const kind = getClusterCloudConfig(store.getState())?.kind;
  if (kind === "aks") {
    return false;
  }

  if (!getAzureAzs(store.getState()).length) {
    return false;
  }

  return Missing()(value, key, data);
});

azurePoolValidator.addRule("isSystemNodePool", (value, key, data) => {
  const kind = getClusterCloudConfig(store.getState())?.kind;

  if (kind === "aks") {
    const nodePools = getNodePools(store.getState());
    const nodePoolsAsSystemPool = (nodePools || []).filter(
      (nodePool) => nodePool.isSystemNodePool
    );

    if (nodePoolsAsSystemPool.length > 1) {
      return false;
    }

    if (
      nodePoolsAsSystemPool.length === 1 &&
      nodePoolsAsSystemPool[0].name === data.poolName
    ) {
      return value
        ? false
        : i18n.t("At least one pool must be set to be system");
    }
  }

  return false;
});

const googleNodePoolValidator = new Validator();
googleNodePoolValidator.addRule(["instanceType", "azs"], Missing());

const maasNodePoolValidator = new Validator();
maasNodePoolValidator.addRule(["azs", "resourcePool"], Missing());

// FIXME: EKS validator missing ???
const VALIDATOR_MAPPING = {
  aws: awsNodePoolValidator,
  vsphere: vsphereNodePoolValidator,
  azure: azurePoolValidator,
  aks: azurePoolValidator,
  gcp: googleNodePoolValidator,
  maas: maasNodePoolValidator,
  openstack: openstackNodePoolValidator,
};
const nodePoolValidator = new Validator();
nodePoolValidator.addRule(function*() {
  yield commonValidator;
  const kind = getClusterCloudConfig(store.getState()).kind;
  if (VALIDATOR_MAPPING[kind]) {
    yield VALIDATOR_MAPPING[kind];
  }
});

const PAYLOAD_MAPPING = {
  aws(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        spotMarketOptions:
          data.instanceOption === "onSpot"
            ? {
                maxPrice: `${(
                  (data.instancePrice * data.maxPricePercentage) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 5,
                })}`,
              }
            : undefined,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        subnets: data.azs.reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];

          if (subnetIsSet) {
            acc.push({
              az,
              id: subnetIsSet,
            });
          }
          return acc;
        }, []),
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: data.isControlPlane ? ["master"] : ["worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  eks(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        subnets: data.azs.reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];

          if (subnetIsSet) {
            acc.push({
              az,
              id: subnetIsSet,
            });
          }
          return acc;
        }, []),
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        maxSize: data.maxNodeSize,
        minSize: data.minNodeSize,
        labels: data.isControlPlane ? ["master"] : ["worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    return {
      cloudConfig: {
        instanceType: {
          diskGiB: data?.disk,
          memoryMiB: data?.memory * 1024,
          numCPUs: data?.cpu,
        },
        placements: data.domains.map(
          ({ network, parentPoolUid, dns, resourcePool, ...rest }) => ({
            ...rest,
            resourcePool:
              resourcePool === DEFAULT_RESOURCE_POOL_VALUE ? "" : resourcePool,
            network: {
              networkName: network,
              staticIp: useStaticIp,
              parentPoolUid,
            },
          })
        ),
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: data.isControlPlane ? ["master"] : ["worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  azure(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
          osType: data.osType,
        },
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: [data.isControlPlane ? "master" : "worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },

  aks(data) {
    return {
      managedPoolConfig: {
        isSystemNodePool: data.isSystemNodePool,
      },
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
        },
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: [data.isControlPlane ? "master" : "worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  gcp(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data.disk,
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: [data.isControlPlane ? "master" : "worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  maas(data) {
    return {
      cloudConfig: {
        instanceType: {
          minCPU: data.minCPU,
          minMemInMB: data.minMem * 1024,
        },
        azs: data.azs,
        resourcePool: data.resourcePool,
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: [data.isControlPlane ? "master" : "worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
  openstack(data) {
    const selectors = openstackNodesForm.selectors;
    const state = store.getState();
    const subnets = selectors.getOpenstackNetworkSubnets(state);
    const flavorsData = openstackNodesForm.fetchers.flavorsFetcher.selector(
      state
    );
    const flavorConfig = (flavorsData.result || []).find(
      (flavor) => flavor.name === data.flavor
    );
    const subnet = subnets.find((subnet) => subnet.id === data.subnet);

    return {
      cloudConfig: {
        flavorConfig: flavorConfig
          ? {
              numCPUs: flavorConfig.vcpus,
              memoryMiB: flavorConfig.memory,
              name: flavorConfig.name,
            }
          : undefined,
        azs: data.azs,
        diskGiB: data.disk,
        subnet,
      },
      poolConfig: {
        name: data.poolName,
        size: data.size,
        labels: [data.isControlPlane ? "master" : "worker"],
        isControlPlane: data.isControlPlane,
        useControlPlaneAsWorker: data.useControlPlaneAsWorker,
        updateStrategy: {
          type: data.updateStrategy,
        },
      },
    };
  },
};

export function fetchClusterCloudConfig() {
  return function thunk(dispatch, getState) {
    const cluster = getCluster(getState());
    const { cloudType, cloudConfigRef } = cluster.spec;
    const type = cloudType === "all" ? "generic" : cloudType;

    if (!cloudConfigRef) {
      return Promise.resolve(null);
    }

    const clusterCloudConfigPromise = api.get(
      `v1alpha1/cloudconfigs/${type}/${cloudConfigRef.uid}`
    );

    dispatch({
      promise: clusterCloudConfigPromise,
      type: "FETCH_CLUSTER_CLOUD_CONFIG",
      schema: CloudConfigSchema,
    });

    return clusterCloudConfigPromise;
  };
}

export function fetchAwsCloudConfigParams({ type = null } = {}) {
  return async function thunk(dispatch, getState) {
    const state = getState();
    let region, cloudAccountUid, response;

    if (type === "create") {
      region = state.forms.cluster.data?.region;
      cloudAccountUid = state.forms.cluster.data?.credential;
    } else {
      const clusterCloudConfig = getClusterCloudConfig(getState());
      region = clusterCloudConfig.spec.clusterConfig.region;
      cloudAccountUid = clusterCloudConfig.spec.cloudAccountRef.uid;
    }

    const promise = Promise.all([
      api.get(
        `v1alpha1/clouds/aws/regions/${region}/availabilityzones?cloudAccountUid=${cloudAccountUid}`
      ),
      api.get(
        `v1alpha1/clouds/aws/regions/${region}/instancetypes?cloudAccountUid=${cloudAccountUid}`
      ),
      api.get(
        `v1alpha1/clouds/aws/regions/${region}/vpcs?cloudAccountUid=${cloudAccountUid}`
      ),
    ]).then(([zones, instanceTypes, vpcs]) => ({
      azs: zones.zones,
      instanceTypes: instanceTypes.instanceTypes,
      vpcids: vpcs.vpcs,
    }));

    dispatch({
      promise,
      type: "FETCH_CLOUD_CONFIG_PARAMS",
    });

    try {
      response = await promise;
    } catch (err) {
      return {};
    }

    return response;
  };
}

export function fetchUnassignedNodes() {
  return {
    promise: new Promise((resolve) => {
      setTimeout(resolve, 300, [
        {
          ip: "192.168.1.15",
          "agent-version": "1.1.2",
          CPU: 2,
          memory: 3.45,
          "disk-space": 10.0,
          status: "unassigned",
          paused: false,
        },
        {
          ip: "192.168.1.16",
          "agent-version": "1.3.4",
          CPU: 2,
          memory: 4,
          "disk-space": 10.0,
          status: "unassigned",
          paused: false,
        },
        {
          ip: "192.168.1.17",
          "agent-version": "1.1.5",
          CPU: 4,
          memory: 8,
          "disk-space": 10.0,
          status: "unassigned",
          paused: false,
        },
        {
          ip: "192.168.1.18",
          "agent-version": "1.2.5",
          CPU: 16,
          memory: 8,
          "disk-space": 10.0,
          status: "unassigned",
          paused: false,
        },
        {
          ip: "192.168.1.19",
          "agent-version": "1.2.5",
          CPU: 8,
          memory: 8,
          "disk-space": 10.0,
          status: "unassigned",
          paused: false,
        },
      ]);
    }),
    type: "FETCH_UNASSIGNED_NODES",
    schema: [NodeSchema],
  };
}

export function addNode(nodeUuid, nodePoolName) {
  return {
    type: "ADD_NODE",
    addedNodeUuid: nodeUuid,
    nodePoolName,
  };
}

function removeNode(nodeUuid, nodePoolName) {
  return {
    type: "REMOVE_NODE",
    removedNodeUuid: nodeUuid,
    nodePoolName,
  };
}

export function toggleNode(nodeUuid, nodePoolName) {
  return (dispatch, getState) => {
    const currentClusterId = getState().cluster.details.currentClusterId;
    const state = getState().entities.cluster[currentClusterId];
    const npName = nodePoolName || state.spec.nodePools[0].name;
    const addedNodes = getState().cluster.nodes.addedNodes;
    const checked = addedNodes.includes(nodeUuid);

    if (checked) {
      dispatch(removeNode(nodeUuid, npName));
    } else {
      dispatch(addNode(nodeUuid, npName));
    }
  };
}

export function connectNodes() {
  return (dispatch, getState) => {
    const addedNodes = getState().cluster.nodes.addedNodes;
    const nodePoolToAddGuid = getState().cluster.nodes.nodePoolToAddGuid;
    const nodePoolToAdd = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolToAddGuid
    );

    const promise = new Promise((resolve) => {
      setTimeout(resolve, 200, [...getAddedNodes(getState())]);
    });

    dispatch({
      promise,
      addedNodes,
      type: "CONNECT_NODES",
      schema: [NodeSchema],
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "nodePools",
        id: nodePoolToAddGuid,
        updates: {
          nodes: [
            ...nodePoolToAdd.nodes.map((node) => node.guid),
            ...addedNodes,
          ],
        },
      });

      addedNodes.forEach((nodeGuid) => {
        dispatch({
          type: "UPDATE_ENTITY",
          entityType: "node",
          id: nodeGuid,
          updates: { status: "pending" },
        });
      });

      dispatch(connectNodeModal.close());
    });
  };
}

export const updateClusterDebounce = debounce(sendNodePoolSize, 1000);

export function sendNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const cluster = getCluster(getState());
    const clusterCloudConfig = getClusterCloudConfig(getState());

    const desiredNodePoolSize = getState().cluster.nodes.desiredNodePoolSizes[
      nodePool.name
    ];

    let promise;

    const formData = await POPULATE_FIELDS_MAPPING[clusterCloudConfig.kind](
      nodePool
    );
    const payload = PAYLOAD_MAPPING[clusterCloudConfig.kind](formData);
    payload.poolConfig.size = desiredNodePoolSize;

    if (!nodePool.persisted) {
      promise = api.post(
        `v1alpha1/cloudconfigs/${cluster.spec.cloudType}/${clusterCloudConfig.metadata.uid}/machinePools`,
        payload
      );
    } else {
      promise = api.put(
        `v1alpha1/cloudconfigs/${cluster.spec.cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}`,
        payload
      );
    }

    dispatch({
      promise,
      type: "UPDATE_CLUSTER",
    });

    notifications.info({
      message: i18n.t("Cluster update will begin shortly"),
    });

    await promise;
    await dispatch(fetchClusterCloudConfig());

    await dispatch(fetchClusterNodes());
    dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  };
}

// TODO this is for baremetal. should not be a separated function
export function configureNodes() {
  return async (dispatch, getState) => {
    const nodePoolGuid = getState().cluster.nodes.nodePoolToConfigureGuid;
    const nodePools = getNodePools(getState());
    const nodePoolToConfigure = nodePools.find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const promise = new Promise((resolve) => {
      setTimeout(resolve, 300);
    });

    dispatch({
      promise,
      type: "CONFIGURE_NODES",
    });

    promise.then(() => {
      nodePoolToConfigure.nodes.forEach((node) => {
        dispatch({
          type: "UPDATE_ENTITY",
          entityType: "node",
          id: node.guid,
          updates: { status: "configured" },
        });
      });

      dispatch(poolConfigModal.close());
    });
  };
}

export function setNodePoolToAdd(nodePoolGuid) {
  return {
    type: "SET_NODE_POOL_TO_ADD",
    nodePoolToAddGuid: nodePoolGuid,
  };
}

export function setNodePoolToConfigure(nodePoolGuid) {
  return (dispatch) => {
    dispatch({
      type: "SET_NODEPOOL_TO_CONFIGURE",
      nodePoolToConfigureGuid: nodePoolGuid,
    });
  };
}

export function activateCluster() {
  return (dispatch, getState) => {
    const nodes = getNodePoolsNodes(getState());
    const configuredNodes = nodes.filter((node) => {
      const nodeStatus = getNodeStatus(node);
      return nodeStatus === "configured";
    });

    const promise = new Promise((resolve) => setTimeout(resolve, 300, {}));

    dispatch({
      type: "ACTIVATE_CLUSTER",
      promise,
    });

    configuredNodes.forEach((configuredNode) => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "node",
        id: configuredNode.guid,
        updates: { status: "installing" },
      });
    });

    promise.then(() => {
      configuredNodes.forEach((configuredNode) => {
        dispatch({
          type: "UPDATE_ENTITY",
          entityType: "node",
          id: configuredNode.guid,
          updates: { status: "active" },
        });
      });

      const currentClusterId = getState().cluster.details.currentClusterId;

      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "cluster",
        id: currentClusterId,
        updates: { status: "active" },
      });
    });
  };
}

export function showNodeDetails(nodeUuid) {
  return {
    type: "SHOW_NODE_DETAILS",
    nodeUuid,
  };
}

export function hideNodeDetails() {
  return {
    type: "HIDE_NODE_DETAILS",
  };
}

export function pauseNode() {
  return (dispatch, getState) => {
    const nodeUuid = getState().cluster.nodes.currentNodeOverview;
    const promise = new Promise((resolve) => setTimeout(resolve, 500, {}));

    dispatch({
      type: "PAUSE_NODE",
      promise,
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "node",
        id: nodeUuid,
        updates: { paused: true },
      });
    });
  };
}

export function resumeNode() {
  return (dispatch, getState) => {
    const nodeUuid = getState().cluster.nodes.currentNodeOverview;
    const promise = new Promise((resolve) => setTimeout(resolve, 500, {}));

    dispatch({
      type: "RESUME_NODE",
      promise,
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "node",
        id: nodeUuid,
        updates: { paused: false },
      });
    });
  };
}

export function fetchAzureNodeParams() {
  return function(dispatch) {
    dispatch(azureInstanceTypesFetcher.fetch());
    dispatch(azureAZFetcher.fetch());
    dispatch(azureStorageAccountsFetcher.fetch());
  };
}

export function fetchGoogleCloudNodeParams() {
  return function(dispatch) {
    dispatch(gcpAZFetcher.fetch());
    dispatch(gcpInstanceTypesFetcher.fetch());
  };
}

export function fetchMaasCloudNodeParams() {
  return function(dispatch) {
    dispatch(maasCloudForm.fetchers.resourcePoolsFetcher.fetch());
    dispatch(maasCloudForm.fetchers.azsFetcher.fetch());
  };
}

export function fetchVmWareNodeParams() {
  return function(dispatch, getState) {
    dispatch(datacentersFetcher.fetch());
    const useStaticIps = isStaticPlacementEnabled(getState());
    if (useStaticIps) {
      dispatch(ipamFetcher.fetch());
    }
  };
}

export function onAddNodes(nodePoolGuid) {
  return (dispatch) => {
    dispatch(setNodePoolToAdd(nodePoolGuid));
    connectNodeModal.open().then(() => {
      dispatch(connectNodes());
    });
  };
}

export function addCloudNode(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePoolToAdd = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const desiredSize = nodePoolToAdd.size + 1;
    confirmNodePoolSizeModal.open().then(addNodeToCloud);
    const clusterCloudConfig = getClusterCloudConfig(getState());

    if (clusterCloudConfig.kind === "openstack") {
      await dispatch(loadNodePoolCloudProperties());
    }
    dispatch(estimateRatePerNodePool(nodePoolGuid, desiredSize));

    function addNodeToCloud() {
      const loadingAddingNode = getState().cluster.nodes.loadingAddingNode;

      if (loadingAddingNode) {
        return;
      }

      dispatch({
        type: "INSERT_NODE",
        nodePoolName: nodePoolToAdd.name,
      });

      dispatch(updateClusterDebounce(nodePoolGuid));
    }
  };
}

export function changeNodePoolSize(nodePoolGuid, size, maxNodes) {
  return (dispatch, getState) => {
    if (maxNodes && size > maxNodes) {
      return;
    }
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    dispatch({
      type: "UPDATE_DESIRED_NODE_POOL_SIZE",
      nodePoolName: nodePool.name,
      size,
    });
  };
}

export function updateNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePoolToUpdate = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const temporaryNodePoolSize = getState().cluster.nodes
      .temporaryNodePoolSizes[nodePoolToUpdate.name];

    if (!temporaryNodePoolSize) {
      dispatch({
        type: "UPDATE_DESIRED_NODE_POOL_SIZE",
        nodePoolName: nodePoolToUpdate.name,
        size: nodePoolToUpdate.size,
      });

      return;
    }

    if (nodePoolToUpdate.size !== temporaryNodePoolSize) {
      confirmNodePoolSizeModal.open().then(
        () => {
          dispatch({
            type: "UPDATE_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
          });
          dispatch(sendNodePoolSize(nodePoolGuid));
        },
        () => {
          dispatch({
            type: "UPDATE_DESIRED_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
            size: nodePoolToUpdate.size,
          });
        }
      );

      const clusterCloudConfig = getClusterCloudConfig(getState());
      if (clusterCloudConfig.kind === "openstack") {
        await dispatch(loadNodePoolCloudProperties());
      }
      dispatch(estimateRatePerNodePool(nodePoolGuid, temporaryNodePoolSize));
    }
  };
}

export function fetchClusterNodes() {
  return async (dispatch, getState) => {
    const state = getState();
    let nodePools = getNodePools(state);
    let clusterCloudConfig = getClusterCloudConfig(state);
    if (!nodePools.length) {
      await dispatch(fetchClusterCloudConfig());
      nodePools = getNodePools(state);
      clusterCloudConfig = getClusterCloudConfig(state);
    }
    const cloudType =
      clusterCloudConfig.kind === "all" ? "generic" : clusterCloudConfig.kind;

    const promises = nodePools.map((nodePool) => {
      const promise = api.get(
        `v1alpha1/cloudconfigs/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}/machines`
      );

      dispatch({
        type: "FETCH_CLUSTER_NODES",
        nodePool: nodePool.guid,
        promise: promise.then((res) => ({
          ...nodePool,
          nodes: res.items,
        })),
        schema: NodePoolSchema,
      });

      return promise;
    });

    return Promise.all(promises);
  };
}

export function onNodeSelect(uid) {
  return function(dispatch) {
    dispatch({ type: "SELECT_NODE", selectedNode: uid });
    dispatch(getNodeMetrics("1 hours"));
  };
}

export function getNodeMetrics(time) {
  return function thunk(dispatch, getState) {
    const { cluster } = getState();

    const periods = {
      "30 minutes": 5,
      "1 hours": 10,
      "6 hours": 20,
      "12 hours": 40,
      "24 hours": 80,
      "1 weeks": 560,
      "1 months": 2400,
    };

    dispatch({ type: "CHANGE_NODE_METRICS_TIMELINE", time });
    const query = {
      startTime: moment()
        .subtract(...time.split(" "))
        .utc()
        .format(),
      endTime: moment()
        .utc()
        .format(),
      period: periods[time],
    };

    dispatch(
      utilizationFetcher.fetch(cluster.nodes.selectedNode, query, "machine")
    );
  };
}

export function fetchClusterAndNodes(uid) {
  return async (dispatch, getState) => {
    let cluster = getCluster(getState());

    if (!cluster || cluster.metadata.uid !== uid) {
      await dispatch(getClusterByUid(uid));
      cluster = getCluster(getState());
    }

    if (cluster.type === "baremetal") {
      dispatch(fetchUnassignedNodes());
    }

    const nodesFetcher = dispatch(fetchClusterNodes());

    dispatch({
      type: "GETTING_CLUSTER_NODES",
      promise: nodesFetcher,
    });

    await nodesFetcher;

    const pools = getAllNodes(getState());

    dispatch(onNodeSelect(pools[0]?.metadata?.uid));
    pollNodes.start();
  };
}

const POPULATE_FIELDS_MAPPING = {
  async aws(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        acc[`subnet_${key}`] = data.subnetIds[key];
        return acc;
      }, {});
    }

    const { instanceTypes } = await store.dispatch(fetchAwsCloudConfigParams());

    const instancePrice = data?.instanceType
      ? instanceTypes?.find(({ type }) => type === data?.instanceType)?.price
      : undefined;

    const maxPrice = parseFloat(data?.spotMarketOptions?.maxPrice) || undefined;

    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
      instanceOption: maxPrice ? "onSpot" : "onDemand",
      instancePrice,
      maxPricePercentage:
        instancePrice && maxPrice
          ? (100 / instancePrice) * maxPrice
          : undefined,
      ...subnets,
    };
  },
  async eks(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        acc[`subnet_${key}`] = data.subnetIds[key];
        return acc;
      }, {});
    }
    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    await store.dispatch(fetchAwsCloudConfigParams());
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      minNodeSize: data?.minSize || 1,
      maxNodeSize: data?.maxSize || 1,
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      ...subnets,
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    let domains = (data?.placements || []).map((placement) => ({
      ...placement,
      disabled: true,
      network: placement.network.networkName,
      resourcePool: !!placement.resourcePool
        ? placement.resourcePool
        : DEFAULT_RESOURCE_POOL_VALUE,
      parentPoolUid: placement.network.parentPoolRef?.uid,
      staticIp: useStaticIp,
    }));

    if (domains.length === 0) {
      domains = [
        {
          cluster: "",
          resourcePool: "",
          datastore: "",
          network: "",
          staticIp: useStaticIp,
          parentPoolUid: "",
        },
      ];
    }

    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      disk: data?.instanceType?.diskGiB || 55,
      memory: data?.instanceType?.memoryMiB
        ? data?.instanceType?.memoryMiB / 1024
        : 2,
      cpu: data?.instanceType?.numCPUs || 2,
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
      domains,
    };
  },
  azure(data) {
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      osType: data?.osDisk.osType || "linux",
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
    };
  },
  aks(data) {
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
      isSystemNodePool: data?.isSystemNodePool || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
    };
  },
  gcp(data) {
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
    };
  },
  maas(data) {
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      minCPU: data?.instanceType?.minCPU || 1,
      minMem: data?.instanceType?.minMem
        ? data?.instanceType?.minMem / 1024
        : 2,
      azs: data?.azs || [],
      resourcePool: data?.resourcePool,
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
    };
  },
  openstack(data) {
    return {
      poolName: data?.name || "new-worker-pool",
      size: data?.size || 1,
      azs: data?.azs || [],
      disk: data?.diskGiB || 60,
      flavor: data?.flavorConfig?.name || "",
      subnet: data?.subnet?.name || "",
      isControlPlane: data?.isControlPlane || false,
      useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
      updateStrategy: data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value,
    };
  },
};

export const addNodePoolModal = new ModalService("addNodePool");
export const addNodePoolFormActions = createFormActions({
  validator: nodePoolValidator,
  init: async () => {
    const selectedNodePool = getSelectedNodePool(store.getState());
    const clusterCloudConfig = getClusterCloudConfig(store.getState());
    store.dispatch({ type: "RESET_ESTIMATED_RATE" });

    return Promise.resolve(
      await POPULATE_FIELDS_MAPPING[clusterCloudConfig.kind](selectedNodePool)
    );
  },
  submit: async (data) => {
    const state = store.getState();
    const selectedNodePool = getSelectedNodePool(state);
    const currentCluster = getCluster(state);
    const clusterCloudConfig = getClusterCloudConfig(state);
    const { metadata, kind } = clusterCloudConfig;
    const payload = PAYLOAD_MAPPING[kind](data);

    const endpoint = `v1alpha1/cloudconfigs/${kind}/${metadata.uid}/machinePools`;

    const promise = selectedNodePool
      ? api.put(`${endpoint}/${selectedNodePool.name}`, payload)
      : api.post(endpoint, payload);

    try {
      await promise;
    } catch (error) {
      const message = selectedNodePool
        ? i18n.t("Something went wrong when editing the node pool")
        : i18n.t("Something went wrong when creating the node pool");

      notifications.error({
        message,
        description: error.message,
      });

      return;
    }

    const editMessage = i18next.t(
      "Node pool with name '{{poolName}}' was updated successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    const createMessage = i18next.t(
      "Node pool with name '{{poolName}}' was created successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    notifications.success({
      message: selectedNodePool ? editMessage : createMessage,
    });

    await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
    await store.dispatch(fetchClusterCloudConfig());
    store.dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  },
});

// aws params are fetched inside form init
function loadNodePoolCloudProperties() {
  return (dispatch, getState) => {
    const cloudConfig = getClusterCloudConfig(getState());

    if (["aks", "azure"].includes(cloudConfig.kind)) {
      dispatch(fetchAzureNodeParams());
    }

    if (cloudConfig.kind === "gcp") {
      dispatch(fetchGoogleCloudNodeParams());
    }

    if (cloudConfig.kind === "vsphere") {
      dispatch(dnsMappingsFetcher.fetch());
      dispatch(fetchVmWareNodeParams());
    }

    if (cloudConfig.kind === "maas") {
      dispatch(fetchMaasCloudNodeParams());
    }

    if (cloudConfig.kind === "openstack") {
      return openstackNodesForm.effects.fetchProperties();
    }
  };
}

export function openAddNodePoolModal({ type = "", nodePoolGuid } = {}) {
  return (dispatch) => {
    dispatch(loadNodePoolCloudProperties());

    addNodePoolModal.open({ type, nodePoolGuid }).then(
      () => dispatch(addNodePoolFormActions.submit({ module: "nodePool" })),
      () => {
        dispatch(setNodePoolToConfigure());
      }
    );

    dispatch(addNodePoolFormActions.init({ module: "nodePool" }));
  };
}

export const deleteNodePoolConfirm = new ModalService("deleteNodePool");

export function deleteNodePool(nodePoolName) {
  return async (dispatch, getState) => {
    const state = store.getState();
    const currentCluster = getCluster(state);
    const currentCloudConfig = getClusterCloudConfig(state);

    deleteNodePoolConfirm.open().then(async () => {
      const promise = api.delete(
        `v1alpha1/cloudconfigs/${currentCloudConfig.kind}/${currentCloudConfig.metadata.uid}/machinePools/${nodePoolName}`
      );

      dispatch({
        type: "DELETE_NODE_POOL",
        promise,
      });

      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18n.t("Something went wrong when deleting the node pool"),
          description: error.message,
        });

        return;
      }

      notifications.success({
        message: i18next.t(
          "Node pool with name '{{nodePoolName}}' has been deleted successfully. Cluster update will begin shortly",
          { nodePoolName }
        ),
      });

      await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
      await store.dispatch(fetchClusterCloudConfig());
      store.dispatch(fetchClusterEstimatedRate());
    });
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];

    const prevValue = domains[domainIndex].cluster;

    domains.splice(domainIndex, 1, {
      cluster,
      datastore: "",
      network: "",
      resourcePool: "",
      staticIp: domains[domainIndex].staticIp,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    dispatch(propertiesFetcher.key(cluster).fetch());

    if (prevValue !== "") {
      const relatedErrors = domains.reduce((accumulator, domain, index) => {
        if (domain.cluster === prevValue && index !== domainIndex) {
          accumulator.push(index);
        }

        return accumulator;
      }, []);

      dispatch(
        addNodePoolFormActions.validateField({
          name: relatedErrors.map((index) => `domains.${index}.cluster`),
          module: "nodePool",
        })
      );
    }
  };
}

export function onNetworkChange(name, network) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === network && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      addNodePoolFormActions.validateField({
        name: relatedErrors.map((index) => `domains.${index}.network`),
        module: "nodePool",
      })
    );
  };
}

export function onPoolChange(name, value) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains[domainIndex] = {
      ...domains[domainIndex],
      parentPoolUid: value,
    };

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const domains = [...getState().forms.nodePool.data.domains];
    domains.push({
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
    });

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1);

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `domains.${domainIndex}.${field}`
    );
    const formErrors = getState().forms.nodePool.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: updatedErrors,
      })
    );

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onInstanceTypeChange(instanceType) {
  return (dispatch, getState) => {
    const state = getState();
    const initialAzs = state.forms?.nodePool?.initialData?.azs || [];
    const cluster = getCluster(state);
    const { cloudType } = cluster.spec;
    const instanceTypes =
      state.cluster?.details?.cloudConfigParams?.instanceTypes || [];

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          instanceType,
          azs: initialAzs,
          instancePrice:
            instanceTypes.find(({ type }) => type === instanceType)?.price || 0,
        },
      })
    );

    if (cloudType === "azure") {
      const selectedNodePool = getSelectedNodePool(state);
      const instanceTypes = getAzureInstanceTypes(
        selectedNodePool?.isControlPlane
      )(state);

      const selectedInstanceType = instanceTypes
        .map((types) => types.children)
        .flat()
        .find((instance) => instance.title === instanceType);

      const nonSupportedZones =
        selectedInstanceType.description.props?.nonSupportedZones || [];

      if (nonSupportedZones) {
        const azs = initialAzs.filter((az) => !nonSupportedZones?.includes(az));

        dispatch(
          addNodePoolFormActions.onChange({
            module: "nodePool",
            name: "azs",
            value: azs,
          })
        );
      }
    }
  };
}

export function onInstanceOptionChange(instanceOption) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "instanceOption",
        value: instanceOption,
      })
    );
  };
}

export function onAzsChange(value) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "azs",
        value,
      })
    );
  };
}

export const debouncedNodePoolChange = debounce(nodePoolChange, 2000);

export function nodePoolChange(data) {
  return async (dispatch) => {
    if (!data) return;

    let errors = [];
    const validations = nodePoolValidator.run(data);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length) {
      dispatch(fetchNodePoolEstimatedRate(data));
    } else {
      dispatch({ type: "FETCH_NODE_ESTIMATED_RATE_FAILURE" });
    }
  };
}

export function estimateRatePerNodePool(nodePoolGuid, desiredSize) {
  return async (dispatch, getState) => {
    store.dispatch({ type: "RESET_ESTIMATED_RATE" });

    const state = getState();
    const nodePool = getNodePools(state).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const clusterCloudConfig = getClusterCloudConfig(state);
    const cloudType = clusterCloudConfig.kind;
    const formData = await POPULATE_FIELDS_MAPPING[cloudType](nodePool);
    formData.size = desiredSize;

    return dispatch(fetchNodePoolEstimatedRate(formData));
  };
}

export function fetchNodePoolEstimatedRate(data) {
  return async (dispatch, getState) => {
    const clusterCloudConfig = getClusterCloudConfig(getState());
    const kind = clusterCloudConfig.kind;
    const payload = PAYLOAD_MAPPING[kind](data);

    let cloudConfig = clusterCloudConfig?.spec?.clusterConfig;

    if (["azure", "aks"].includes(kind)) {
      cloudConfig.aadProfile = {
        ...cloudConfig?.aadProfile,
        adminGroupObjectIDs: cloudConfig?.aadProfile?.adminGroupObjectIDs || [],
      };
    }

    const ratePayload = {
      cloudConfig,
      machinepoolconfig: [
        {
          cloudConfig: payload.cloudConfig,
          poolConfig: payload.poolConfig,
        },
      ],
    };

    const promise = api.post(
      `v1alpha1/spectroclusters/${kind}/rate?periodType=hourly`,
      ratePayload
    );

    dispatch({
      type: "FETCH_NODE_ESTIMATED_RATE",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: error.message,
      });
      return;
    }

    return promise;
  };
}

export function fetchClusterEstimatedRate() {
  return (dispatch, getState) => {
    const cluster = getCluster(getState());
    const uid = cluster?.metadata?.uid;

    if (!uid) {
      return;
    }

    const promise = api.get(
      `v1alpha1/spectroclusters/${uid}/rate?periodType=hourly`
    );

    dispatch({
      type: "NODES_FETCH_RATES",
      promise,
    });
  };
}
