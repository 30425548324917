export function LIST_FETCH_ITEMS_REQUEST(nextState, { module }, state) {
  if (!state[module]) {
    return;
  }
  nextState[module].isRequesting = true;
  nextState[module].isLoading = true;
}

export function LIST_FETCH_ITEMS_NORMALIZATION(
  nextState,
  { module, result, token },
  state
) {
  if (!state[module]) {
    return;
  }
  nextState[module].items[token] = result;
  nextState[module].pages.add(token);
}

export function LIST_INITIALIZE_NORMALIZATION(...args) {
  LIST_FETCH_ITEMS_NORMALIZATION(...args);
}

export function LIST_FETCH_ITEMS_SUCCESS(nextState, { module }, state) {
  if (!state[module]) {
    return;
  }
  nextState[module].isRequesting = false;
  nextState[module].isLoading = false;
  nextState[module].isInitializing = false;
}

export function LIST_INITIALIZE_SUCCESS(...args) {
  LIST_FETCH_ITEMS_SUCCESS(...args);
}

export function LIST_CHANGE_QUERY(nextState, { module, name, value }) {
  nextState[module].query[name] = value;
  nextState[module].token = "initial";
  nextState[module].items = {
    initial: [],
  };
  nextState[module].pages = new Set();
  nextState[module].isLoading = true;
}

export function BATCH_CHANGE_QUERY(nextState, { module, query }) {
  nextState[module].query = query;
  nextState[module].token = "initial";
  nextState[module].items = {
    initial: [],
  };
  nextState[module].pages = new Set();
  nextState[module].isLoading = true;
}

export function LIST_REMOVE_ITEM(nextState, { module, items = [] }, state) {
  if (items.length === 0) {
    return;
  }
  const pages = state[module].items;
  Object.keys(pages).forEach((page) => {
    items.forEach((guid) => {
      const index = pages[page].indexOf(guid);
      nextState[module].items[page].splice(index, 1);
    });
  });
}

export function LIST_ADD_ITEMS(nextState, { module, items }, state) {
  nextState[module].items[state[module].token].push(...items);
}

export function LIST_SET_NEXT_PAGE_TOKEN(nextState, { module, token }, state) {
  if (!state[module]) {
    return;
  }
  nextState[module].nextToken = token;
}

export function LIST_NEXT_PAGE(nextState, { module }, state) {
  nextState[module].token = state[module].nextToken;
}

export function LIST_INITIALIZE(nextState, { module, query }) {
  nextState[module] = {
    query: query,
    pages: new Set(),
    items: {
      initial: [],
    },
    token: "initial",
    nextToken: null,
    isLoading: true,
    isInitializing: true,
  };
}
