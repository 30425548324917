export const initialState = {
  currentProjectId: null,
  permissions: [],
  allProjects: [],
  projects: [],
  me: null,
  isLoading: false,
  orgLoading: true,
  organization: null,
  step: "email",
  disabledEmail: null,
  plan: null,
  migrationStatus: null,
};

export function SET_CURRENT_PROJECT(nextState, { currentProjectId }) {
  nextState.currentProjectId = currentProjectId;
}

export function SET_BACK_TO_PROJECT(nextState, { projectUid }) {
  nextState.backToProjectUid = projectUid;
}

export function FETCH_CURRENT_USER_SUCCESS(nextState, { result }) {
  nextState.permissions = result.status.tenantPermissions.tenant;
}

export function FETCH_CURRENT_USER_NORMALIZATION(nextState, { result }) {
  nextState.me = result;
}

export function SET_USER_PROJECTS_NORMALIZATION(nextState, { result }, state) {
  nextState.projects = result;
}

export function FETCH_USER_PROJECTS_NORMALIZATION(nextState, { result }) {
  nextState.allProjects = result;
}

export function APP_LOADING(nextState, { isLoading }) {
  nextState.isLoading = isLoading;
}

export function FETCH_TENANT_ORG_REQUEST(nextState) {
  nextState.orgLoading = true;
}

export function FETCH_TENANT_ORG_SUCCESS(nextState, { result }) {
  nextState.orgLoading = false;
  nextState.organization = result;
}

export function FETCH_TENANT_ORG_FAILURE(nextState) {
  nextState.orgLoading = false;
}

export function SET_LOGIN_STEP(nextState, { step }) {
  nextState.step = step;
}

export function SET_REDIRECT_URL(nextState, { url }, state) {
  nextState.organization = state.organization || {};
  nextState.organization.redirectUrl = url;
}

export function SET_DISABLED_EMAIL_FIELD_REQUEST(nextState) {
  nextState.disabledEmail = true;
}

export function SET_DISABLED_EMAIL_FIELD_SUCCESS(nextState) {
  nextState.disabledEmail = false;
}

export function SET_DISABLED_EMAIL_FIELD_FAILURE(nextState) {
  nextState.disabledEmail = false;
}

export function USER_LOGOUT(nextState) {
  nextState.me = null;
}

export function GET_TENANT_PLAN_SUCCESS(nextState, { result }) {
  nextState.plan = result;
}

export function FETCH_QUICKSTART_MIGRATION_STATUS_SUCCESS(
  nextState,
  { result }
) {
  nextState.migrationStatus = result;
}
