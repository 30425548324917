import history from "services/history";
import store from "services/store";
import { matchPath } from "react-router";
import i18next from "i18next";

import * as tasks from "utils/tasks";
import * as PATHS from "utils/constants/routes";

import {
  getClusterByUid,
  getClusterMetrics,
  fetchClusterProfile,
  certsListActions,
  CERTS_MODULE,
} from "state/cluster/actions/details";

import { openImportProcedureModal } from "state/cluster/actions/edit";
import { isClusterImportPending } from "state/cluster/selectors/details";
import {
  fetchClusterAndNodes,
  fetchClusterEstimatedRate,
} from "state/cluster/actions/nodes";
import { fetchClusterAndPollEvents } from "state/cluster/actions/events";
import {
  cloudAccountsFetcher,
  clusterListActions,
} from "state/cluster/actions/list";

import {
  fetchRepositories,
  setAvailableRepositories,
} from "state/clusterprofile/actions/layerConfig";
import { setIsClusterProfileEdit } from "state/clusterprofile/actions/edit";
import { getClusterProfileByUid } from "state/clusterprofile/actions/details";
import { fetchRoles } from "state/roles/actions/list";
import {
  fetchTenantInfo,
  redeemTenantToken,
  redirectToDefaultRoute,
  selectProjectFromRoute,
  setPasswordFormActions,
} from "state/auth/actions";
import { fetchUsers } from "state/users/actions";
import {
  projectFormActions,
  PROJECT_MODULE,
  projectModal,
} from "state/project/actions/create";
import { auditListActions } from "state/audit/actions";
import { openAccountModal } from "state/cloudaccounts/actions/create";
import { onCreateOverlordOpen } from "state/overlord/actions/create";
import {
  openOverlordConfigureModal,
  openSetOverlordNodesModal,
} from "state/overlord/actions/configure";
import {
  openPackRegistryModal,
  openHelmRegistryModal,
} from "state/packregistries/actions/create";
import {
  vmWareOverlordsFetcher,
  openstackOverlordsFetcher,
  maasOverlordsFetcher,
} from "state/cloudaccounts/services";
import { usersFetcher } from "state/users/services";
import { overlordsListActions } from "state/overlord/actions/list";
import { OVERLORD_MODULE } from "state/overlord/services/list";
import { getRepositories } from "state/clusterprofile/selectors/layerConfig";
import { projectListActions } from "state/project/actions/list";
import {
  coresChartTimePeriodInit,
  monthlyCoreHoursChartCalendarChange,
} from "state/project/actions/metrics";
import { ssoIdpsFetcher, defaultTeamsFetcher } from "state/sso/services";
import {
  profileBuilderEditModule,
  profileListActions,
} from "state/clusterprofile/services";
import { getCurrentContext } from "state/auth/selectors";
import { overlordFetcher, IPAM_MODULE } from "state/overlord/services/ipam";
import { ipamListActions } from "state/overlord/actions/ipam";
import { openSSHKeyModal } from "state/sshKeys/actions/create";
import { invoicesFetcher } from "state/billingdetails/services";
import { initAllBillingData } from "state/plandetails/actions";
import { ClusterSchema } from "utils/schemas";
import { dnsListActions } from "state/dns/actions/list";
import { DNS_MAPPING_MODULE } from "state/dns/services";
import {
  loadClusterScan,
  scansCountFetcher,
  watchScansTask,
} from "state/cluster/actions/scan";
import {
  backupLocationsListActions,
  openBackupLocationModal,
  BACKUP_LOCATIONS_MODULE,
} from "state/backuplocations/actions";
import {
  CERTS_LIST_MODULE,
  certificatesListActions,
  openCertificateModal,
} from "state/certificates/actions";
import {
  restoresListActions,
  backupsListActions,
  RESTORE_LIST_MODULE,
  BACKUP_LIST_MODULE,
} from "state/backups/services";
import { RBAC_LIST_MODULE } from "state/rolebindings/services";
import { openRbacModal } from "state/rolebindings/actions/create";
import { rbacListActions } from "state/rolebindings/actions/list";
import { samlFormActions } from "state/sso/saml/actions";
import { oidcFormActions } from "state/sso/oidc/actions";
import { domainsFormActions } from "state/domains/actions";
import { getEnabledSSO } from "state/sso/selectors";

const MAP = {
  [PATHS.ROOT]: () => store.dispatch(redirectToDefaultRoute()),
  [PATHS.CLUSTER_PROFILES.ROOT]: async () => {
    store.dispatch(profileListActions.initialize("clusterprofiles"));
  },
  [PATHS.AUTH.PASSWORD_ACTION]: async () => {
    await store.dispatch(redeemTenantToken());
    store.dispatch(setPasswordFormActions.init({ module: "setPassword" }));
  },
  [PATHS.CLUSTER_PROFILES.DETAILS]: async ({ id }) => {
    if (id === "create") {
      return;
    }
    await store.dispatch(getClusterProfileByUid(id));
    await store.dispatch(fetchRepositories());
    store.dispatch(setIsClusterProfileEdit(true));
    const repositories = getRepositories(store.getState());
    store.dispatch(setAvailableRepositories(repositories));
  },
  [PATHS.AUDIT.ROOT]: () => {
    auditListActions.initialize("auditlogs");
    store.dispatch(usersFetcher.fetch());
  },
  [PATHS.CLUSTERS.OVERVIEW]: async ({ id }) => {
    store.dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(getClusterMetrics(id));
    store.dispatch(fetchClusterProfile());
    tasks.poolClusterErrors.start();
    tasks.pollClusterStatus.start();
    tasks.pollClusterNotifications.start();
    if (isClusterImportPending(store.getState())) {
      store.dispatch(openImportProcedureModal());
    }
  },
  [PATHS.CLUSTERS.TAB_DETAILS]: async ({ id, tab }) => {
    if (tab === "restores") {
      await store.dispatch(getClusterByUid(id));
      await store.dispatch(restoresListActions.initialize(RESTORE_LIST_MODULE));
      tasks.pollRestoreList.start();
    }
  },
  [PATHS.CLUSTERS.SCANS]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    await store.dispatch(scansCountFetcher.key(id).fetch());
    watchScansTask.start();
  },
  [PATHS.CLUSTERS.SCAN_DETAILS]: async ({ id, scanUid, scanType }) => {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(loadClusterScan(scanType, scanUid));
  },
  [PATHS.CLUSTERS.NODES]: async ({ id }) => {
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(fetchClusterEstimatedRate());
  },
  [PATHS.CLUSTERS.ROOT]: () => {
    store.dispatch(profileListActions.initialize("clusterprofiles"));
    store.dispatch(cloudAccountsFetcher.fetch());
    store.dispatch(clusterListActions.initialize("cluster"));
    tasks.pollSpectroClusters.start();
  },
  [PATHS.CLUSTERS.CREATE_CLUSTER]: () => {
    store.dispatch(cloudAccountsFetcher.fetch());
  },
  [PATHS.CLUSTERS.CONFIGURE_LAYER]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(fetchClusterProfile());
  },
  [PATHS.CLUSTERS.NODES_FLAG]: async ({ id, flag }) => {
    if (flag === "metrics") {
      await store.dispatch(fetchClusterAndNodes(id));
      store.dispatch(fetchClusterEstimatedRate());
    }
  },
  [PATHS.CLUSTERS.CERTIFICATES]: ({ id }) => {
    store.dispatch(getClusterByUid(id));
    store.dispatch(certsListActions.initialize(CERTS_MODULE));
  },
  [PATHS.CLUSTERS.EVENTS]: ({ id }) =>
    store.dispatch(fetchClusterAndPollEvents(id)),
  [PATHS.CLUSTERS.BACKUPS]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    await store.dispatch(backupsListActions.initialize(BACKUP_LIST_MODULE));
    tasks.pollBackupList.start();
  },
  [PATHS.MANAGEMENT.USERS]: () => store.dispatch(fetchRoles()),
  [PATHS.MANAGEMENT.TEAMS]: () => store.dispatch(fetchUsers()),
  [PATHS.AUTH.ROOT]: () => store.dispatch(fetchTenantInfo()),
  [PATHS.SETTINGS.CREATE_CLOUD_ACCOUNT]: ({ cloudType }) => {
    store.dispatch(openAccountModal({ cloudType }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.SETTINGS.CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    if (uid === "create") {
      return;
    }
    store.dispatch(openAccountModal({ cloudType, uid, viewMode: true }));
  },
  [PATHS.SETTINGS.EDIT_CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    store.dispatch(openAccountModal({ cloudType, uid }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.PROJECTS.ROOT]: async () => {
    store.dispatch(coresChartTimePeriodInit());
    store.dispatch(projectListActions.initialize(PROJECT_MODULE));
    store.dispatch(monthlyCoreHoursChartCalendarChange());
  },
  [PATHS.PROJECTS.CREATE_PROJECT]: () => store.dispatch(fetchRoles()),
  [PATHS.PROJECTS.EDIT_PROJECT]: async ({ id }) => {
    projectModal.open({ id, modalType: "edit" }).then(
      () => {
        return store.dispatch(
          projectFormActions.submit({ module: PROJECT_MODULE })
        );
      },
      () => {
        history.push(PATHS.PROJECTS.ROOT);
      }
    );
    store.dispatch(projectFormActions.init({ module: PROJECT_MODULE }));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAYS]: () => {
    store.dispatch(overlordsListActions.initialize(OVERLORD_MODULE));
    tasks.pollDatacenters.start();
  },
  [PATHS.SETTINGS.CREATE_PRIVATE_CLOUD_GATEWAY]: ({ cloudType }) => {
    store.dispatch(onCreateOverlordOpen(cloudType));
  },
  [PATHS.SETTINGS.EDIT_PRIVATE_CLOUD_GATEWAY]: ({ cloudType, uid }) => {
    store.dispatch(openOverlordConfigureModal(uid, cloudType));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAY_NODES]: ({ cloudType, uid }) => {
    store.dispatch(openSetOverlordNodesModal(uid, cloudType));
  },

  [PATHS.PRIVATE_CLOUD_GATEWAYS.IPAM_DNS_TABS]: ({ id, uid, tab }) => {
    if (id === "system") {
      store.dispatch({
        type: "FETCH_CLUSTER",
        promise: Promise.resolve({
          metadata: {
            name: "System Private Gateway",
            uid: "system",
            annotations: {
              overlordUid: uid,
            },
          },
        }),
        schema: ClusterSchema,
      });
    } else {
      store.dispatch(fetchClusterAndPollEvents(id));
    }
    if (tab === "ipam") {
      store.dispatch(ipamListActions.initialize(IPAM_MODULE));
    }
    if (tab === "dns") {
      store.dispatch(dnsListActions.initialize(DNS_MAPPING_MODULE));
    }
    store.dispatch(overlordFetcher.fetch());
  },

  [PATHS.SETTINGS.CREATE_PACK_REGISTRY]: () =>
    store.dispatch(openPackRegistryModal()),
  [PATHS.SETTINGS.EDIT_PACK_REGISTRY]: ({ uid }) =>
    store.dispatch(openPackRegistryModal(uid)),
  [PATHS.SETTINGS.CREATE_HELM_REGISTRY]: () => {
    store.dispatch(openHelmRegistryModal());
  },
  [PATHS.SETTINGS.EDIT_HELM_REGISTRY]: ({ uid }) => {
    store.dispatch(openHelmRegistryModal(uid));
  },
  [PATHS.SETTINGS.SSO]: async () => {
    if (
      Object.keys(store.getState().forms?.saml?.initialData || {}).length === 0
    ) {
      store.dispatch(ssoIdpsFetcher.fetch());
      store.dispatch(defaultTeamsFetcher.fetch());
      await store.dispatch(samlFormActions.init({ module: "saml" }));
      await store.dispatch(oidcFormActions.init({ module: "oidc" }));

      const enabledSso = getEnabledSSO(store.getState());

      store.dispatch({
        type: "SSO_TOGGLE",
        enabledSso,
      });

      store.dispatch(domainsFormActions.init({ module: "domains" }));
    }
  },

  [PATHS.SETTINGS.CREATE_SSH_KEY]: () => store.dispatch(openSSHKeyModal()),
  [PATHS.SETTINGS.EDIT_SSH_KEY]: ({ uid }) =>
    store.dispatch(openSSHKeyModal(uid)),

  [PATHS.SETTINGS.BILLING_DETAILS]: () => {
    store.dispatch(initAllBillingData());
    store.dispatch(invoicesFetcher.fetch());
  },
  [PATHS.SETTINGS.PLAN_DETAILS]: () => {
    store.dispatch(initAllBillingData());
  },
  [PATHS.SETTINGS.BACKUP_LOCATIONS]: () => {
    store.dispatch(
      backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE)
    );
  },

  [PATHS.SETTINGS.CREATE_BACKUP_LOCATION]: () =>
    store.dispatch(openBackupLocationModal()),
  [PATHS.SETTINGS.EDIT_BACKUP_LOCATION]: ({ uid }) =>
    store.dispatch(openBackupLocationModal(uid)),

  [PATHS.SETTINGS.ROLE_BINDINGS]: () => {
    store.dispatch(rbacListActions.initialize(RBAC_LIST_MODULE));
  },
  [PATHS.SETTINGS.CREATE_ROLE_BINDINGS]: () => store.dispatch(openRbacModal()),
  [PATHS.SETTINGS.EDIT_ROLE_BINDINGS]: ({ uid }) =>
    store.dispatch(openRbacModal(uid)),

  "/settings/certificates": () => {
    store.dispatch(certificatesListActions.initialize(CERTS_LIST_MODULE));
  },
  "/settings/certificates/create": () => store.dispatch(openCertificateModal()),
  "/settings/certificates/:uid/edit": ({ uid }) =>
    store.dispatch(openCertificateModal(uid)),
};

// NOTE:
// the argument location contains the path you are going to navigate
// the state location contains the current path you are on
history.block((location) => {
  const state = store.getState();

  if (location.pathname.includes("auth")) {
    return;
  }

  if (
    history.location.pathname.includes("/clusterprofiles/") &&
    state.location?.params?.id !== "create"
  ) {
    const {
      getPackErrors,
      hasIncompleteLayers,
      hasUnsavedChanges,
    } = profileBuilderEditModule.selectors;

    const disabledConditions = [
      getPackErrors(state).length !== 0,
      hasIncompleteLayers(state),
      hasUnsavedChanges(state),
    ].filter(Boolean);

    if (disabledConditions.length === 0)
      return i18next.t(
        "Are you sure you want to leave without saving your changes?"
      );
  }
});

function cleanUp() {
  stopAllPollingTasks();
}

function stopAllPollingTasks() {
  Object.keys(tasks).forEach((key) => {
    if (key === "pollClusterLogStatus") {
      return;
    }
    tasks[key].stop();
  });
}

export function onLocationChange(location, action) {
  if (action === "REPLACE") return;

  cleanUp();

  const projectMatch = matchPath(history.location.pathname, {
    path: "/projects/:projectUid/*",
    exact: true,
  });

  if (projectMatch) {
    store.dispatch(selectProjectFromRoute(projectMatch.params));
  }

  if (history.location.pathname.startsWith("/admin/")) {
    store.dispatch(selectProjectFromRoute({ projectUid: "ADMIN_VIEW" }));
  }

  Object.keys(MAP).forEach((path) => {
    let match = matchPath(location.pathname, {
      path,
      exact: true,
      strict: true,
    });

    if (!match) {
      match = matchPath(location.pathname, {
        path: `/projects/:projectUid${path}`,
        exact: true,
        strict: true,
      });
    }

    if (!match) {
      match = matchPath(location.pathname, {
        path: `/admin${path}`,
        exact: true,
        strict: true,
      });
    }

    if (match) {
      store.dispatch({ type: "UPDATE_ROUTER_PARAMS", params: match.params });
      MAP[path](match.params);
    }
  });
}

function startListening() {
  return history.listen(onLocationChange);
}

let listener = null;
export default function observeHistory() {
  listener = startListening();

  onLocationChange(history.location);
}

export function stopListening() {
  listener();
}

export function appendContext(url = "") {
  const shouldNotPrefix = [
    "/auth",
    "/my-profile",
    "/projects/",
    "/admin/",
  ].some((path) => url.startsWith(path));

  if (shouldNotPrefix) {
    return url;
  }

  const currentContext = getCurrentContext(store.getState());

  if (!currentContext) {
    return url;
  }

  if (currentContext?.isAdmin) {
    return `/admin${url}`;
  } else {
    return `/projects/${currentContext.projectUid}${url}`;
  }
}

const historyPush = history.push;
export function hookHistoryWithContext() {
  history.push = function(...args) {
    const argAsObject = typeof args[0] !== "string";
    let nextLocation = args[0] || "";
    if (argAsObject) {
      nextLocation = args[0]?.pathname || "";
    }

    nextLocation = appendContext(nextLocation);
    historyPush(
      argAsObject ? { ...args[0], pathname: nextLocation } : nextLocation
    );
  };
}
