import React from "react";
import styled, { keyframes } from "styled-components";
import { Trans } from "react-i18next";

import Title from "components/common/Title";

import environmentPath from "assets/images/login/environment.png";
import humanPath from "assets/images/login/human.png";
import maskPath from "assets/images/login/mask.png";

import { ReactComponent as Logo } from "assets/icons/logo_portrait.svg";
import FullPageOverlay from "components/common/layouts/Page";
import { white } from "utils/constants/colors";
import Button, { TextButton } from "components/ui/Button";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: #091e3b;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 10px;
  overflow: hidden;
`;

const FormWrap = styled.div`
  width: 416px;
  padding: 53px 63px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  input {
    background-color: transparent;
    color: ${white};
  }

  .has-error input,
  .has-error input:hover {
    background-color: transparent;
    color: ${white};
  }

  img {
    margin: 0 auto;
    display: block;
  }

  a.button,
  ${Button}:not(${TextButton}) {
    border: 2px solid #5fd5f9;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    height: 48px;
    background-color: transparent;
    width: 100%;
    border-radius: 4px;
    color: #fff;

    :hover {
      background-color: transparent;
    }
  }

  a.button {
    line-height: 44px;
  }
`;

const WelcomeHero = styled.div`
  position: relative;
  flex-grow: 1;
  background: ${white};
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 105%;
`;

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const Human = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -55px;
  animation: ${float} 6s ease-in-out infinite;
  transform: translatey(0px);
`;

const WelcomeContent = styled.div`
  padding: 50px 63px;

  h1 {
    font-size: 38px;
    line-height: 48px;
    color: rgb(77, 74, 190);
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin: 10px 0;
  }
`;

const LoginLogo = styled(Logo)`
  width: 160px;
  align-self: center;
  height: auto;
`;

const MaskImg = styled.img`
  position: absolute;
  top: 25px;
  left: 70px;
`;

export default function Login({ children, displayMask }) {
  return (
    <FullPageOverlay>
      <Trans>
        <Title>Login</Title>
      </Trans>
      <ContentWrap>
        <FormWrap>
          <LoginLogo />
          {children}
        </FormWrap>
        <WelcomeHero>
          <Environment src={environmentPath} alt="tech environment" />
          <Human>
            <img src={humanPath} alt="tech guru" />
            {displayMask && <MaskImg src={maskPath} alt="mask" />}
          </Human>
          <WelcomeContent>
            <h1>
              <Trans>Welcome to Spectro Cloud!</Trans>
            </h1>
            <p>
              <Trans>
                {"It's time for a computing platform"}
                <br />
                <strong>without boundaries</strong>
              </Trans>
            </p>
          </WelcomeContent>
        </WelcomeHero>
      </ContentWrap>
    </FullPageOverlay>
  );
}
