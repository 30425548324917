import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import PageHeader from "components/common/layouts/App/PageHeader";
import Tabs from "components/common/Tabs";
import Switch from "components/common/Switch";
import Button from "components/ui/Button";

import UsersList from "../users/index";
import TeamsList from "../teams/components/list";

import { openUserModal } from "state/users/actions";
import { openTeamFormModal } from "state/teams/actions";
import PageToolbar from "components/common/layouts/App/PageToolbar";
import Icon from "components/ui/Icon";
import { pageWidthConstrain } from "components/styled/mixins";
import { lightWhite, lightMidGray } from "utils/constants/colors";
import Link from "components/common/History/Link";

const Container = styled.div`
  .ant-tabs {
    border-radius: 8px 8px 0px 0px;
    background: ${lightWhite};
    ${pageWidthConstrain}
  }

  .ant-tabs-nav {
    padding: 0 21px;
    margin-bottom: 0;
  }

  .ant-tabs-nav-container {
    border-bottom: 1px solid ${lightMidGray};
    padding: 15px 0 0 20px;
    background: ${lightWhite};
  }
`;

const ManagementLayout = ({ match, openUserModal, openTeamFormModal }) => {
  const { t } = useTranslation();
  const activeKey = match.params.tab;

  const tabs = [
    {
      href: "/management/users",
      label: t("Users"),
      key: "users",
    },
    {
      href: "/management/teams",
      label: t("Teams"),
      key: "teams",
    },
  ];

  return (
    <Container>
      <PageHeader
        title={t("Users and Teams Management")}
        tabTitleContent={{
          activeTab: tabs.find((t) => t.key === activeKey)?.label,
          pageTitle: t("{{title}} Cluster", {
            title: t("Users and Teams Management"),
          }),
        }}
      />
      <PageToolbar>
        <Switch value={activeKey}>
          <Switch.Case value={"users"}>
            <Link to="/management/users/create" onClick={openUserModal}>
              <Button data-qa="create_user">
                <Icon type="plus" /> {t("Create User")}
              </Button>
            </Link>
          </Switch.Case>
        </Switch>
        <Switch value={activeKey}>
          <Switch.Case value={"teams"}>
            <Link
              to="/management/teams/create"
              onClick={() => openTeamFormModal("create")}
            >
              <Button data-qa="create_team">
                <Icon type="plus" /> {t("Create team")}
              </Button>
            </Link>
          </Switch.Case>
        </Switch>
      </PageToolbar>
      <Tabs tabs={tabs} activeKey={activeKey}>
        <Switch value={activeKey}>
          <Switch.Case value={"users"}>
            <UsersList />
          </Switch.Case>
        </Switch>
        <Switch value={activeKey}>
          <Switch.Case value={"teams"}>
            <TeamsList />
          </Switch.Case>
        </Switch>
      </Tabs>
    </Container>
  );
};

export default connect((state) => ({}), { openUserModal, openTeamFormModal })(
  ManagementLayout
);
