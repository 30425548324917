import i18next from "i18next";
import api from "services/api";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import {
  ClusterProfileSchema,
  ClusterSchema,
  ManifestSchema,
  PackSchema,
  PackVersionSchema,
} from "utils/schemas";

export function fetchClusterProfilePacks(profile) {
  return function thunk(dispatch) {
    const promise = api
      .get(
        `v1alpha1/clusterprofiles/${profile.metadata.uid}/packs?includePackMeta=schema,presets`
      )
      .then((res) => {
        const packMapping = res.items.reduce((accumulator, item) => {
          accumulator[item.metadata.uid] = item;
          return accumulator;
        }, {});

        return {
          ...profile,
          spec: {
            ...profile.spec,
            published: {
              ...profile.spec.published,
              packs: profile.spec.published.packs.map((pack) => {
                return {
                  ...pack,
                  ...packMapping[pack.packUid],
                };
              }),
            },
          },
        };
      });

    dispatch({
      type: "FETCH_CLUSTER_PROFILE_PACKS",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function fetchProfileResolvedValues(uid) {
  return async function thunk(dispatch) {
    const promise = api.get(
      `v1alpha1/clusterprofiles/${uid}/packs/resolvedValues`
    );
    dispatch({
      type: "FETCH_CLUSTER_PROFILE_RESOLVED_VALUES",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

function parseManifest(manifest) {
  return {
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  };
}

export default class ProfileStackActions {
  constructor({
    guid,
    onChange,
    dataFetcher,
    profileSelectorModal,
    profileRemovalConfirm,
    listingActions,
  }) {
    this.guid = guid;
    this.onChange = onChange;
    this.dataFetcher = dataFetcher;
    this.profileSelectorModal = profileSelectorModal;
    this.listingActions = listingActions;
    this.profileRemovalConfirm = profileRemovalConfirm;
  }

  get state() {
    return store.getState().profileStack[this.guid];
  }

  dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: this.guid,
    });
  };

  initialize({ profiles = [] } = {}) {
    this.dispatch({
      type: "PROFILE_STACK_INIT",
      profiles,
    });
  }

  discardChanges = () => {
    this.dispatch({
      type: "PROFILE_STACK_RESET_STATE",
    });
  };

  selectLayer = (layerGuid, profileGuid) => {
    this.dispatch({
      type: "PROFILE_STACK_SELECT_LAYER",
      layerGuid,
    });

    this.selectProfile(profileGuid);
  };

  updateResolvedValues = (resolvedValues) => {
    this.dispatch({
      type: "UPDATE_RESOLVED_VALUES",
      resolvedValues,
    });
  };

  addProfile = () => {
    this.profileSelectorModal.open().then(async () => {
      const exists = this.state.profiles.find(
        (profile) => profile.guid === this.state.selectedProfile
      );

      if (exists) {
        notifications.warn({ message: i18next.t("Profile already exists") });
        return;
      }

      let selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );

      await store.dispatch(fetchClusterProfilePacks(selectedProfile));
      selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );

      this.dispatch({
        type: "PROFILE_STACK_ADD_PROFILE",
        profile: selectedProfile,
      });
      if (!this.state.selectedLayer) {
        this.resetSelectedLayer();
      }

      const resolvedValues = await store.dispatch(
        fetchProfileResolvedValues(selectedProfile.metadata.uid)
      );
      this.updateResolvedValues([
        {
          resolved: resolvedValues.resolved,
          uid: selectedProfile.metadata.uid,
        },
      ]);
    });
    store.dispatch(
      this.listingActions.initialize(this.guid, {
        filters: "spec.published.type=add-on",
      })
    );
  };

  resetSelectedLayer() {
    const firstProfile = this.state.profiles?.[0];
    const firstLayer = firstProfile?.spec?.published?.packs?.[0];
    this.selectLayer(firstLayer?.guid, firstProfile?.guid);
  }

  removeProfile = (profileToDelete) => {
    this.dispatch({
      type: "PROFILE_STACK_DELETE_PROFILE",
      profileToDelete,
    });

    if (profileToDelete?.guid === this.state.selectedProfile) {
      this.resetSelectedLayer();
    }
  };

  keepProfile = (profileToKeep) => {
    this.dispatch({
      type: "PROFILE_STACK_UNDELETE_PROFILE",
      profileToKeep,
    });
  };

  setLayersErrors = (errors) => {
    this.dispatch({
      type: "PROFILE_STACK_SET_LAYERS_ERRORS",
      errors,
    });
  };

  replaceProfile = (profileGuid) => {
    this.profileSelectorModal.open().then(async () => {
      let selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );

      await store.dispatch(fetchClusterProfilePacks(selectedProfile));
      selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );
      this.dispatch({
        type: "PROFILE_STACK_REPLACE_PROFILE",
        newProfile: selectedProfile,
        currentProfile: profileGuid,
      });
    });
    store.dispatch(
      this.listingActions.initialize(this.guid, {
        filters: "spec.published.type!=add-on",
      })
    );
  };

  toggleExpand = (profileGuids) => {
    this.dispatch({
      type: "PROFILE_STACK_UPDATE_EXPANDED",
      profileGuids,
    });
  };

  selectProfile = (profileGuid) => {
    this.dispatch({
      type: "PROFILE_STACK_SELECT_PROFILE",
      profileGuid,
    });
  };

  backToProfileList = () => {
    this.dispatch({
      type: "PROFILE_STACK_VIEW_LIST",
    });
  };

  onValuesChange = (values) => {
    this.dispatch({
      type: "PROFILE_STACK_VALUE_CHANGE",
      values,
      layer: this.state.selectedLayer,
    });
  };

  onManifestSelect = async ({
    manifestGuid,
    layerGuid,
    profileGuid,
    isAttachedManifest = true,
    editMode,
  }) => {
    const manifest = getStoreEntity(manifestGuid, ManifestSchema);
    const layer =
      getStoreEntity(layerGuid, PackVersionSchema) ||
      getStoreEntity(layerGuid, PackSchema);

    let entityGuid = isAttachedManifest ? manifestGuid : layerGuid;

    if (layer.type === "manifest") {
      entityGuid = manifestGuid || layer.manifests[0].guid;
    }

    this.selectLayer(entityGuid, profileGuid);

    if (!this.state.values?.[entityGuid]) {
      let apiUrl = "";

      if (editMode) {
        const cluster = getStoreEntity(
          store.getState().cluster.details.currentClusterId,
          ClusterSchema
        );
        apiUrl = `v1alpha1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${manifest.uid}`;
      } else {
        const currentProfile = getStoreEntity(
          this.state.selectedProfile,
          ClusterProfileSchema
        );

        apiUrl = `v1alpha1/clusterprofiles/${currentProfile.metadata.uid}/packs/${layer.metadata.uid}/manifests/${manifest.uid}`;
      }

      const promise = api
        .get(apiUrl)
        .then((manifest) => parseManifest(manifest));

      await store.dispatch({
        type: "FETCH_ATTACHED_MANIFEST",
        promise,
        schema: ManifestSchema,
      });

      this.dispatch({
        type: "PROFILE_STACK_VALUE_CHANGE",
        values: getStoreEntity(manifestGuid, ManifestSchema).content,
        layer: entityGuid,
        isChangePersisted: true,
      });
    }
  };
}
