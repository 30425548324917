import { productTourModal } from "./services";

export function openProductTourModal() {
  return (dispatch, getState) => {
    productTourModal.open();
  };
}

export function setTourNextStep() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = state.productTour.stepNumber;
    const totalSteps = state.productTour.totalSteps;

    if (currentStep === totalSteps) {
      productTourModal.close();
      dispatch({
        type: "RESET_TOUR_MODAL",
      });
      return;
    }

    dispatch({
      type: "TOUR_MODAL_NEXT_STEP",
    });
  };
}

export function setTourPreviousStep() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = state.productTour.stepNumber;

    if (currentStep > 1) {
      dispatch({
        type: "TOUR_MODAL_PREVIOUS_STEP",
      });
    }
  };
}
