import i18next from "i18next";
import store, { getStoreEntity } from "services/store";

import api from "services/api";
import ModalService from "services/modal";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";

import {
  getSelectedTenantRoles,
  getSelectedUser,
  getSelectedTeam,
  getCurrentTenantRoles,
} from "../selectors";
import ListActions from "modules/list/actions";
import { RoleSchema } from "utils/schemas";

export const tenantRolesModal = new ModalService();

export const tenantRolesListActions = new ListActions({
  async fetchData() {
    const state = store.getState();
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      currentEntityType === "user"
        ? getSelectedUser(state)
        : getSelectedTeam(state);

    const response = await api.get(
      `v1alpha1/${currentEntityType}s/${selectedEntity.metadata.uid}/roles`
    );

    if (currentEntityType === "user") {
      const parsedRoles =
        response?.roles?.map((tenantRole) => ({
          metadata: { uid: tenantRole.uid, name: tenantRole.name },
        })) || [];

      const parsedInheritedRoles =
        response?.inheritedRoles
          ?.filter(
            (inheritedRole) =>
              !response.roles.some(
                (tenantRole) => tenantRole.uid === inheritedRole.uid
              )
          )
          .map((inheritedRole) => ({
            metadata: {
              uid: inheritedRole.uid,
              name: inheritedRole.name,
              inherited: true,
            },
          })) || [];

      return {
        items: [...parsedRoles, ...parsedInheritedRoles],
      };
    }

    return {
      items:
        response?.roles?.map((role) => ({
          metadata: {
            name: role.name,
            uid: role.uid,
            inherited: false,
          },
        })) || [],
    };
  },
  schema: [RoleSchema],
});

export const tenantRolesValidator = new Validator();
tenantRolesValidator.addRule(["roles"], Missing());

export function getSelectedEntityRoles() {
  const state = store.getState();
  const currentEntityType = state.roleManagement.entityType;

  if (currentEntityType === "team") {
    return getSelectedTeam(state)?.spec?.roles || [];
  }

  const inheritedRoles = getCurrentTenantRoles(state)
    .filter((role) => role.metadata.inherited)
    .map((role) => role.metadata.uid);

  return getSelectedUser(state)?.spec?.roles.filter(
    (role) => !inheritedRoles.includes(role.metadata.uid)
  );
}

export async function updateData() {
  const state = store.getState();
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    state.entities[currentEntityType][state.roleManagement.selectedEntityId];
  const selectedRoles = getSelectedTenantRoles(state);
  const rolesUids = selectedRoles.map((role) => role.metadata.uid);

  function updateStoreEntity() {
    store.dispatch({
      type: "UPDATE_ENTITY",
      entityType: currentEntityType,
      id: selectedEntity.guid,
      updates: {
        spec: {
          ...selectedEntity.spec,
          roles: selectedRoles.map((role) => role.guid),
        },
      },
    });
  }

  const promise = api.put(
    `v1alpha1/${currentEntityType}s/${selectedEntity.metadata.uid}/roles`,
    {
      roles: rolesUids,
    }
  );

  try {
    await promise;
    notifications.success({
      message: i18next.t("Roles have been updated"),
    });
  } catch (err) {
    notifications.error({
      message: i18next.t("Something went wrong"),
      description: err?.message,
    });

    return;
  }

  updateStoreEntity();
  store.dispatch(tenantRolesListActions.initialize("tenantRoles"));
}

export function onTenantRoleRemove(tenantRoleGuid) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      state.entities[currentEntityType][state.roleManagement.selectedEntityId];
    const currentTenantRoles = getCurrentTenantRoles(state);

    const tenantRoleIndex = currentTenantRoles.findIndex(
      (tenantRole) => tenantRole.guid === tenantRoleGuid
    );

    const roles = [...selectedEntity.spec.roles];
    roles.splice(tenantRoleIndex, 1);

    function updateStoreEntity() {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: currentEntityType,
        id: selectedEntity.guid,
        updates: {
          spec: {
            ...selectedEntity.spec,
            roles,
          },
        },
      });
    }

    const rolesUids = roles.map(
      (guid) => getStoreEntity(guid, RoleSchema).metadata.uid
    );

    const promise = api.put(
      `v1alpha1/${currentEntityType}s/${selectedEntity.metadata.uid}/roles`,
      {
        roles: rolesUids,
      }
    );

    dispatch({
      type: "REMOVE_TENANT_ROLE",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t("Role has been removed"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    updateStoreEntity();
    dispatch(tenantRolesListActions.initialize("tenantRoles"));
  };
}
