export function PROFILE_BUILDER_INIT(nextState, { guid, layers, isEditMode }) {
  nextState[guid] = {
    layers,
    isEditMode,
    initialLayers: [...layers],
    packLayers: [],
    draftLayers: [],
    selectedLayer: null,
    isEditorExpanded: false,
    showEditor: false,
    formType: null,
    openedEditor: "",
    expandedPack: null,
    appliedManifests: [],
    currentAttachedManifestGuid: null,
    isImportClusterLayerExpanded: false,
    errors: {},
    packsEditor: {
      selectedGuid: null,
      currentName: "",
    },
    resolvedValues: null,
    helmRepoUid: null,
    packRepoUid: null,
  };
}

export function PROFILE_BUILDER_SELECT_LAYER(
  nextState,
  { guid, layerGuid, openedEditor, isEditorExpanded }
) {
  nextState[guid].selectedLayer = layerGuid;
  nextState[guid].openedEditor = openedEditor;
  nextState[guid].isEditorExpanded = isEditorExpanded;
}

export function PROFILE_BUILDER_UNSELECT_LAYER(nextState, { guid }) {
  nextState[guid].selectedLayer = null;
}

export function PROFILE_BUILDER_SUBMIT_DRAFT_LAYERS(
  nextState,
  { guid },
  state
) {
  nextState[guid].draftLayers = [];
  nextState[guid].selectedLayer = null;
  nextState[guid].layers = state[guid].layers
    .filter((layer) => layer.type !== "new")
    .map((layer) => ({
      ...layer,
      isDraft: false,
    }));
  nextState[guid].formType = null;
  nextState[guid].isEditorExpanded = false;
  nextState[guid].packLayers = [];
  nextState[guid].currentAttachedManifestGuid = null;
}

export function PROFILE_BUILDER_EDITOR_TOGGLE(nextState, { guid }, state) {
  const showEditor = !state[guid].showEditor;
  nextState[guid].showEditor = showEditor;
}

export function PROFILE_BUILDER_ADD_UIDS_TO_DRAFT_LAYERS(
  nextState,
  { guid, layers }
) {
  nextState[guid].layers = layers;
}

export function PROFILE_BUILDER_UPDATE_LAYER_REPOS(
  nextState,
  { guid, expandedPack, isImportClusterLayerExpanded }
) {
  nextState[guid].expandedPack = expandedPack;
  nextState[guid].isImportClusterLayerExpanded = isImportClusterLayerExpanded;
}

export function PROFILE_BUILDER_PACK_VALUES_TOGGLE(
  nextState,
  { guid, sectionName, isEditorExpanded }
) {
  nextState[guid].openedEditor = sectionName;
  nextState[guid].isEditorExpanded = isEditorExpanded;
  nextState[guid].currentAttachedManifestGuid = null;
}

export function PROFILE_BUILDER_TOGGLE_ADD_FORM(nextState, { guid, formType }) {
  nextState[guid].formType = formType;
  nextState[guid].isEditorExpanded = false;
  nextState[guid].currentAttachedManifestGuid = null;
}
export function PROFILE_BUILDER_TOGGLE_DRAFT_LAYERS(
  nextState,
  { guid, layerGuid, value },
  state
) {
  if (value) {
    nextState[guid].layers.push(
      state[guid].packLayers.find((layer) => layer.guid === layerGuid)
    );
    nextState[guid].draftLayers.push(layerGuid);
    return;
  }

  nextState[guid].layers = state[guid].layers.filter(
    (layer) => layer.guid !== layerGuid
  );
  nextState[guid].draftLayers.filter((layer) => layer.guid !== layerGuid);
}

export function PROFILE_BUILDER_ADD_DRAFT_LAYER(
  nextState,
  { guid, layer },
  state
) {
  nextState[guid].layers.push(layer);
  nextState[guid].draftLayers = [...state[guid].draftLayers, layer.guid];
}

export function PROFILE_BUILDER_ADD_DRAFT_LAYERS(nextState, { guid, layers }) {
  nextState[guid].layers.push(
    ...layers.filter((layer) => layer.matchedRegistries.length === 1)
  );
  nextState[guid].packLayers = layers;
  nextState[guid].draftLayers = layers.reduce(
    (acc, layer) => [...acc, layer.guid],
    []
  );
}

export function PROFILE_BUILDER_UPDATE_EXPANDED_PACK_REPOSITORY(
  nextState,
  { guid, newRegistry },
  state
) {
  const { expandedPack, packLayers } = state[guid];
  const updatedPackLayers = packLayers.map((layer) => {
    if (layer.guid === expandedPack.guid) {
      return {
        ...layer,
        matchedRegistries: [...layer.matchedRegistries, newRegistry],
      };
    }
    return layer;
  });

  nextState[guid].expandedPack = {
    ...expandedPack,
    matchedRegistries: [...expandedPack.matchedRegistries, newRegistry],
  };
  nextState[guid].packLayers = updatedPackLayers;
}

export function PROFILE_BUILDER_ADD_REPO_TO_EXPANDED_LAYER(
  nextState,
  { guid, registryUid },
  state
) {
  nextState[guid].packLayers = state[guid].packLayers.map((layer) => {
    if (layer.guid !== state[guid].expandedPack.guid) {
      return layer;
    }

    return {
      ...layer,
      matchedRegistries: [registryUid],
      config: {
        ...layer.config,
        registryUid,
      },
    };
  });
  nextState[guid].isImportClusterLayerExpanded = false;
  nextState[guid].expandedPack = null;
}

export function PROFILE_BUILDER_REMOVE_LAYER(nextState, { guid }, state) {
  const layers = state[guid].layers;
  const selectedLayer = state[guid].selectedLayer;
  nextState[guid].layers = layers.filter(
    (layer) => layer.guid !== selectedLayer
  );
  delete nextState[guid].errors[selectedLayer];
}

export function PROFILE_BUILDER_CLEAR_IMPORT_CLUSTER(
  nextState,
  { guid },
  state
) {
  nextState[guid].layers = state[guid].layers.filter(
    (layer) => !state[guid].draftLayers.includes(layer.guid)
  );
  nextState[guid].draftLayers = [];
  nextState[guid].packLayers = [];
  nextState[guid].isImportClusterLayerExpanded = false;
}

export function PROFILE_BUILDER_UPDATE_LAYER(
  nextState,
  { guid, layer },
  state
) {
  const layers = state[guid].layers;
  const selectedLayer = state[guid].selectedLayer;
  const draftLayer = state[guid].draftLayers?.[0];

  nextState[guid].layers = layers.map((currentLayer) => {
    if (
      currentLayer.guid === selectedLayer ||
      currentLayer.guid === draftLayer
    ) {
      return {
        ...currentLayer,
        ...layer,
        config: {
          ...currentLayer.config,
          ...layer.config,
        },
      };
    }
    return currentLayer;
  });
}

export function PROFILE_BUILDER_EDITOR_SELECT_LAYER(
  nextState,
  { guid, layerGuid }
) {
  nextState[guid].packsEditor.selectedGuid = layerGuid;
}

export function PROFILE_BUILDER_EDITOR_SET_NAME(nextState, { guid, name }) {
  nextState[guid].packsEditor.currentName = name;
}

export function PROFILE_BUILDER_APPLY_EDITOR_CHANGES(
  nextState,
  { guid, packsData },
  state
) {
  const layers = state[guid].layers;

  nextState[guid].layers = layers.map((layer) => {
    const packData = packsData[layer.guid];

    if (!layer.config) {
      return {
        ...layer,
      };
    }

    return {
      ...layer,
      config: {
        ...layer.config,
        values: packData ? packData.values : layer.config?.values,
        selectedPresets: packData
          ? packData.presets
          : layer.config?.selectedPresets,
        manifests: packData?.manifests
          ? [...packData.manifests]
          : [...(layer.config?.manifests || [])],
      },
    };
  });
}

export function TOGGLE_ATTACHED_MANIFEST_EDITOR(
  nextState,
  { guid, manifestGuid, isEditorExpanded }
) {
  nextState[guid].currentAttachedManifestGuid = manifestGuid;
  nextState[guid].openedEditor = isEditorExpanded ? "attach-manifest" : null;
  nextState[guid].isEditorExpanded = isEditorExpanded;
}

export function TOGGLE_NEW_MANIFEST_EDITOR(
  nextState,
  { guid, isEditorExpanded, currentAttachedManifestGuid }
) {
  nextState[guid].openedEditor = isEditorExpanded ? "attach-manifest" : null;
  nextState[guid].isEditorExpanded = isEditorExpanded;
  nextState[guid].currentAttachedManifestGuid = currentAttachedManifestGuid;
}

export function APPLY_MANIFEST_NAME(
  nextState,
  { manifestGuid, guid, isEditorExpanded }
) {
  nextState[guid].appliedManifests.push(manifestGuid);
  nextState[guid].isEditorExpanded = isEditorExpanded;
}

export function FORM_LOAD_SUCCESS(nextState, { module, result }) {
  if (module === "layerPack") {
    nextState[result.profileModuleGuid].appliedManifests = result.manifests.map(
      (manifest) => manifest.guid
    );
  }
}

export function PROFILE_BUILDER_UPDATE_PACK_ERROR(
  nextState,
  { guid, packGuid, error },
  state
) {
  const packErrors = state[guid].errors[packGuid] || [];
  const indexOfError = packErrors.findIndex(
    (currentError) =>
      currentError.guid === error.guid && error.field === currentError.field
  );

  if (indexOfError !== -1) {
    nextState[guid].errors[packGuid][indexOfError] = error;
  } else {
    nextState[guid].errors[packGuid] = [...packErrors, error];
  }
}

export function VALIDATE_PROFILE_PACKS_REQUEST(nextState, { guid, result }) {
  nextState[guid].backendErrors = null;
}

export function VALIDATE_PROFILE_PACKS_SUCCESS(nextState, { guid, result }) {
  nextState[guid].backendErrors = result?.packs?.results || null;
}

export function PROFILE_BUILDER_UPDATE_RESOLVED_VALUES(
  nextState,
  { guid, resolvedValues }
) {
  nextState[guid].resolvedValues = resolvedValues;
}

export function PROFILE_BUILDER_SET_REPOSITORY(
  nextState,
  { guid, repositoryUid, packType }
) {
  if (!packType) {
    return;
  }
  if (packType === "helmChart") {
    nextState[guid].helmRepoUid = repositoryUid;
  } else {
    nextState[guid].packRepoUid = repositoryUid;
  }
}

export function PROFILE_BUILDER_ADD_PACK_VERSION_NORMALIZATION(
  nextState,
  { guid, entities },
  state
) {
  if (!entities?.packVersion) {
    return;
  }
  let layers = state[guid].layers;

  Object.keys(entities.packVersion).forEach((packVersionGuid) => {
    const { packUid, tag } = entities.packVersion[packVersionGuid];
    layers = layers.map((layer) => {
      if (
        layer.config?.packUid === packUid &&
        layer.config?.tag === tag &&
        !layer.config?.packVersionGuid
      ) {
        return {
          ...layer,
          config: {
            ...layer.config,
            packVersionGuid,
          },
        };
      }
      return layer;
    });
  });
  nextState[guid].layers = layers;
}

export function APPLY_MANIFESTS(
  nextState,
  { guid, appliedManifests, currentAttachedManifestGuid }
) {
  nextState[guid].appliedManifests = appliedManifests;
  nextState[guid].isEditorExpanded = !!appliedManifests.length;
  nextState[guid].currentAttachedManifestGuid = appliedManifests.length
    ? currentAttachedManifestGuid
    : null;
}
