import querystring from "querystring";
import { ClusterSchema } from "utils/schemas";

import api from "services/api";
import ModalService from "services/modal";
import history from "services/history";
import store from "services/store";

import ListActions from "modules/list/actions";
import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";

import { getBoolean } from "utils/parsers";

export const clusterDependency = new ModalService("clusterDependency");
export const FILTERS_MODULE = "clusterFilters";

function getQueryFromFilters(data) {
  const { deleted, filter, order, sortBy } = data;
  return {
    filter: {
      clusterName: {
        beginsWith: filter || "",
      },
      isDeleted: getBoolean(deleted),
    },
    sort: [
      {
        field: sortBy || "creationTimestamp",
        order: order || "asc",
      },
    ],
  };
}

export function refreshClustersList() {
  return (dispatch) => {
    const promise = api.post("v1alpha1/dashboard/spectroclusters");
    dispatch({
      type: "REFRESH_CLUSTERS",
      promise,
      schema: {
        items: [ClusterSchema],
      },
    });
  };
}

export const clusterListActions = new ListActions({
  schema: [ClusterSchema],
  initialQuery: () => getQueryFromFilters(history.getQuery()),
  fetchData(query) {
    const { continue: continueToken } = query;

    return api.post(
      `v1alpha1/dashboard/spectroclusters${
        continueToken ? `?continue=${continueToken}` : ""
      }`,
      query
    );
  },
});

export const cloudAccountsFetcher = dataFetcher({
  selectors: ["cloud-accounts-list"],
  async fetchData([_]) {
    return await api
      .get("v1alpha1/cloudaccounts/summary")
      .then((res) => res.items);
  },
});

export function openClusterConfiguration(resourceType) {
  const resourceLinks = {
    cloudAccount: "/settings/cloudaccounts",
    clusterProfile: "/clusterprofiles",
  };

  return () => {
    clusterDependency.open().then(() => {
      history.push(resourceLinks[resourceType]);
    });
  };
}

export const filterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    return Promise.resolve({
      filter: query.filter || "",
      sortBy: query.sortBy || "creationTimestamp",
      order: query.order || "asc",
      deleted: getBoolean(query.deleted),
    });
  },
  submit: (data) => {
    const query = getQueryFromFilters(data);
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);

    store.dispatch(
      clusterListActions.batchChangeQuery({ module: "cluster", query })
    );
  },
});

export function switchOrder() {
  return (dispatch, getState) => {
    const order = getState().forms[FILTERS_MODULE]?.data?.order;
    dispatch(
      filterFormActions.onChange({
        module: FILTERS_MODULE,
        name: "order",
        value: order === "asc" ? "desc" : "asc",
      })
    );
  };
}
