import uuid from "uuid/v4";
import store from "services/store";
import createProfileFormActions from "./createProfileForm";
import importedPacksActions from "./importedPacks";
import manifestsActions from "./manifests";
import editorActions from "./editor";
import {
  FORM_TYPES,
  helmRegistryFetcher,
  MODULES,
  configValidator,
} from "../services";
import { BASE_LAYERS } from "utils/constants/clusterprofile";
import { extractPayload, mapFormDataToLayers } from "../utils";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
  getPreviousPresetDelta,
  parseYAMLValues,
} from "utils/parsers";
import { packListActions } from "../services";
import api from "services/api";
import notifications from "services/notifications";
import i18next from "i18next";
import { getAvailableRepositories } from "../selectors";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";

export default class ProfileBuilderActions {
  constructor({
    guid,
    revertPackValuesConfirm,
    removeLayerConfirm,
    addNewRepositoryModal,
    getState,
  }) {
    this.guid = guid;
    this.revertPackValuesConfirm = revertPackValuesConfirm;
    this.removeLayerConfirm = removeLayerConfirm;
    this.addNewRepositoryModal = addNewRepositoryModal;
    this.getState = getState;

    this.forms = createProfileFormActions(guid, {
      toggleNewManifestEditor: this.toggleNewManifestEditor,
      togglePackValues: this.togglePackValues,
      getFormUpdatesOnPresetsChange: this.getFormUpdatesOnPresetsChange,
      setNewHelmRegistry: this.setNewHelmRegistry,
    });

    this.importedPacks = importedPacksActions(guid, {
      forms: this.forms,
      addNewRepositoryModal: this.addNewRepositoryModal,
    });

    this.packManifests = manifestsActions(guid, {
      forms: this.forms,
      toggleNewManifestEditor: this.toggleNewManifestEditor,
      onEditorSelectLayer: this.onEditorSelectLayer,
    });

    this.editor = editorActions(guid, {
      forms: this.forms,
      packManifests: this.packManifests,
      getFormUpdatesOnPresetsChange: this.getFormUpdatesOnPresetsChange,
      revertPackValuesConfirm: this.revertPackValuesConfirm,
      onEditorSelectLayer: this.onEditorSelectLayer,
      setCurrentEditorName: this.setCurrentEditorName,
    });
  }

  get state() {
    return this.getState();
  }

  dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: this.guid,
    });
  };

  initialize = ({ layers = [], isEditMode = false } = {}) => {
    this.dispatch({
      type: "PROFILE_BUILDER_INIT",
      isEditMode,
      layers: layers.map((layer) => ({
        ...layer,
        formType: layer.formType || FORM_TYPES.PACK,
      })),
    });
  };

  updateResolvedValues = (resolvedValues) => {
    this.dispatch({
      type: "PROFILE_BUILDER_UPDATE_RESOLVED_VALUES",
      resolvedValues,
    });
  };

  setNewHelmRegistry = async ({ uid }) => {
    await store.dispatch(helmRegistryFetcher.fetch());
    const allHelmRegistries = helmRegistryFetcher.selector(store.getState())
      .result;
    const newRegistry = allHelmRegistries.find((reg) => reg.uid === uid);
    this.dispatch({
      type: "PROFILE_BUILDER_UPDATE_EXPANDED_PACK_REPOSITORY",
      newRegistry,
    });

    store.dispatch(
      this.forms.importActions.onChange({
        module: MODULES.IMPORT_MODULE,
        name: "packRepoId",
        value: newRegistry?.uid,
      })
    );
  };

  startConfiguration = () => {
    const unconfiguredLayer = this.state.layers.find(
      (layer) => BASE_LAYERS.includes(layer.type) && !layer.config?.tag
    );
    unconfiguredLayer &&
      this.openForm({
        formType: FORM_TYPES.PACK,
        guid: unconfiguredLayer?.guid,
      });
  };

  initPackForm = async (guid) => {
    await store.dispatch(
      this.forms.packActions.init({
        module: MODULES.PACK_MODULE,
      })
    );

    const formData = store.getState().forms[MODULES.PACK_MODULE].data;
    const repos = getAvailableRepositories(store.getState());
    if (!formData.repository && repos?.length === 1) {
      this.forms.packFieldChange({ name: "repository", value: repos[0].value });
    }

    if (store.getState().forms.layerPack?.data?.repository) {
      store.dispatch(packListActions.initialize(MODULES.PACK_LIST_MODULE));
    }

    store.dispatch(
      this.forms.packActions.onChange({
        module: MODULES.PACK_MODULE,
        name: "activeSelectField",
        value: !guid ? "packType" : "",
      })
    );
  };

  toggleNewManifestEditor = (manifestGuid) => {
    let isEditorExpanded = !this.state.isEditorExpanded;
    if (
      manifestGuid &&
      manifestGuid !== this.state.currentAttachedManifestGuid
    ) {
      isEditorExpanded = true;
    }
    this.dispatch({
      type: "TOGGLE_NEW_MANIFEST_EDITOR",
      isEditorExpanded: isEditorExpanded,
      currentAttachedManifestGuid: manifestGuid,
    });
  };

  togglePackValues = (sectionName = "") => {
    const manifests = [
      ...(store.getState().forms.layerPack?.data?.manifests || []),
    ];
    this.forms.packFieldChange({
      name: "manifests",
      value: manifests,
    });

    this.dispatch({
      type: "PROFILE_BUILDER_PACK_VALUES_TOGGLE",
      sectionName: sectionName,
      isEditorExpanded: !!sectionName,
    });
  };

  onEditorSelectLayer = (layerGuid) => {
    this.dispatch({
      type: "PROFILE_BUILDER_EDITOR_SELECT_LAYER",
      layerGuid,
    });
  };

  setCurrentEditorName = (name) => {
    this.dispatch({
      type: "PROFILE_BUILDER_EDITOR_SET_NAME",
      name,
    });
  };

  openPreviousLayer = async () => {
    const layers = this.state.orderedLayers;
    const currentLayer = layers.find(
      (layer) => layer.guid === this.state.selectedLayer
    );
    const currentIndex = layers.indexOf(currentLayer);
    const previousGuid = layers[currentIndex + 1]?.guid;

    await this.onFormSubmit({ formType: layers[currentIndex]?.formType });

    this.openForm({
      formType: layers[currentIndex + 1]?.formType,
      guid: previousGuid,
    });
  };

  openNextLayer = async () => {
    const layers = this.state.orderedLayers;
    const currentLayer = layers.find(
      (layer) => layer.guid === this.state.selectedLayer
    );
    const currentIndex = layers.indexOf(currentLayer);
    const previousGuid = layers[currentIndex - 1]?.guid;

    await this.onFormSubmit({ formType: layers[currentIndex]?.formType });

    this.openForm({
      formType: layers[currentIndex - 1]?.formType,
      guid: previousGuid,
    });
  };

  onFormSubmit = async ({ formType }) => {
    let selectedLayer = this.state.selectedLayer;
    if (!selectedLayer) {
      selectedLayer = this.state.draftLayers?.[0];
    }

    if (formType === FORM_TYPES.MANIFEST) {
      await store.dispatch(
        this.forms.manifestFormActions.submit({
          module: MODULES.MANIFEST_MODULE,
        })
      );
    }

    if (formType === FORM_TYPES.IMPORT) {
      await this.importedPacks.updateImportedPackLayers();
    }

    this.dispatch({
      type: "PROFILE_BUILDER_SUBMIT_DRAFT_LAYERS",
    });
    const layer = this.state.layers.find(
      (layer) => layer.guid === selectedLayer
    );

    this.validateLayer(layer);
  };

  onFormCancel = () => {
    this.dispatch({
      type: "PROFILE_BUILDER_TOGGLE_ADD_FORM",
      formType: null,
    });

    this.dispatch({
      type: "PROFILE_BUILDER_CLEAR_IMPORT_CLUSTER",
    });

    this.dispatch({
      type: "PROFILE_BUILDER_UNSELECT_LAYER",
    });
  };

  validateLayer = async (layer) => {
    if (layer) {
      if (!layer.config) {
        return;
      }
      const validations = configValidator.run(layer.config);
      for await (const error of validations) {
        this.dispatch({
          type: "PROFILE_BUILDER_UPDATE_PACK_ERROR",
          error,
          packGuid: layer.guid,
        });
      }
    }
  };

  openForm = async ({ formType, guid }) => {
    const layerGuid = guid || uuid();

    const selectedLayer =
      guid && this.state.layers.find((layer) => layer.guid === guid);
    const layerFormType = formType || selectedLayer?.formType;
    const newLayer = {
      guid: layerGuid,
      type: "new",
      isDraft: true,
      formType: layerFormType,
      persisted: false,
      config: null,
    };
    const formTypeDispatcher = {
      [FORM_TYPES.PACK]: () => this.initPackForm(guid),
      [FORM_TYPES.IMPORT]: () => this.importedPacks.initImportForm(guid),
      [FORM_TYPES.MANIFEST]: () => this.initManifestForm(layerGuid),
    };

    this.dispatch({
      type: "PROFILE_BUILDER_TOGGLE_ADD_FORM",
      formType: layerFormType,
    });

    if (layerFormType === FORM_TYPES.IMPORT) {
      formTypeDispatcher[layerFormType]();
      return;
    }

    if (guid) {
      this.dispatch({
        type: "PROFILE_BUILDER_SELECT_LAYER",
        layerGuid: guid,
        openedEditor: this.state.isEditMode ? "packs" : "",
        isEditorExpanded: this.state.isEditMode && selectedLayer.config?.tag,
      });
    } else {
      this.dispatch({
        type: "PROFILE_BUILDER_ADD_DRAFT_LAYER",
        layer: newLayer,
        isEditorExpanded: false,
      });
    }

    formTypeDispatcher[layerFormType]();

    this.validateLayer(selectedLayer);
  };

  initManifestForm = () => {
    store.dispatch(
      this.forms.manifestFormActions.init({
        module: MODULES.MANIFEST_MODULE,
      })
    );
  };

  onPackRevert = () => {
    this.revertPackValuesConfirm.open().then(() => {
      const defaultValues = this.editor.getSelectedPackDefaultValues();
      const defaultPresets = getDefaultPresetsFromValues(defaultValues);
      const valuesWithoutComment = getPackValuesWithoutPresetsComment(
        defaultValues
      );

      store.dispatch(
        this.forms.packActions.batchChange({
          module: MODULES.PACK_MODULE,
          updates: {
            presets: defaultPresets,
            values: valuesWithoutComment,
          },
        })
      );
      this.dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: mapFormDataToLayers(
          store.getState().forms[MODULES.PACK_MODULE]?.data
        ),
      });
    });
  };

  getFormUpdatesOnPresetsChange = (
    { value, group, presets },
    { currentPresets, currentValues }
  ) => {
    const defaultValues = getPackValuesWithoutPresetsComment(
      this.editor.getSelectedPackDefaultValues()
    );
    const selectedPresets = presets.find(
      (preset) => preset.group === group && preset.value === value
    );

    let base =
      getPreviousPresetDelta({
        presetValues: currentPresets,
        values: currentValues,
        presets,
        group,
        defaultValues,
      }) || currentValues;

    const updatedValues =
      parseYAMLValues(base, selectedPresets) || currentValues;
    const updatedPresets = {
      ...(currentPresets || {}),
      [group]: value,
    };

    return {
      values: updatedValues,
      presets: updatedPresets,
    };
  };

  removeSelectedLayer = () => {
    this.removeLayerConfirm.open().then(() => {
      this.dispatch({
        type: "PROFILE_BUILDER_REMOVE_LAYER",
      });
      this.onFormSubmit({ formType: FORM_TYPES.PACK });
    });
  };

  async validateProfilePacks(
    { cloudType = "all", profileType = "cluster" } = {},
    isEdit = false
  ) {
    const packs = extractPayload(this.state.layers);

    const payload = {
      cloudType,
      packs,
      type: profileType,
    };

    let endpoint = "v1alpha1/clusterprofiles/validate/packs";

    if (isEdit) {
      const clusterProfile = getRawClusterProfile(store.getState());
      endpoint = `v1alpha1/clusterprofiles/${clusterProfile.metadata.uid}/validate/packs`;
    }

    const promise = api.post(endpoint, payload);

    this.dispatch({
      type: "VALIDATE_PROFILE_PACKS",
      promise,
    });

    await promise;
    const validationErrors = this.state.backendErrors || [];

    if (validationErrors.length > 0) {
      notifications.warning({
        message: i18next.t(
          "There are pack validation errors. Please fix them in order to continue"
        ),
      });
      return Promise.reject();
    }

    return Promise.resolve();
  }
}
