import store, { getStoreEntity } from "services/store";
import api, { nonProjectApi } from "services/api";
import createFormActions from "modules/form/actions";
import fetchers, { MODULES, packListActions } from "../services";
import { mapFormDataToLayers } from "../utils";
import { ManifestSchema } from "utils/schemas";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
} from "utils/parsers";
import MANIFEST_ICON from "assets/icons/manifest.svg";

import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { validator } from "state/packregistries/actions/create";
import i18next from "i18next";
import notifications from "services/notifications";
import { getAvailableRepositories } from "../selectors";
import { createSelector } from "reselect";
import { extractInstallOrder } from "utils/yaml";

function parseManifest(manifest) {
  return {
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  };
}

export const getSelectedPack = createSelector(
  (state) => fetchers.packsListSearchFetcher.selector(state)?.result?.items,
  (state) => state.forms.layerPack?.data.pack,
  (items = [], packName) => {
    return items.find((pack) => pack.spec.name === packName);
  }
);

function getHelmRepositoryPayload(
  { name, endpoint, noAuth, username, password },
  expandedPack
) {
  return {
    metadata: {
      name,
    },
    spec: {
      endpoint,
      auth: {
        type: noAuth ? "noAuth" : "basic",
        username: noAuth ? undefined : username,
        password: noAuth ? undefined : password,
      },
      createOption: {
        skipSync: false,
        charts: [
          {
            name: expandedPack.config?.name,
            version: expandedPack.config?.tag,
          },
        ],
      },
    },
  };
}

export default function createProfileFormActions(
  guid,
  { togglePackValues, getFormUpdatesOnPresetsChange, setNewHelmRegistry }
) {
  const getState = () => {
    return store.getState().profileBuilder[guid];
  };

  const dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: guid,
    });
  };

  const packActions = createFormActions({
    init: async () => {
      const { selectedLayer, layers } = getState();
      const layer = layers.find((layer) => layer.guid === selectedLayer) || {};
      const { type, config } = layer;
      let packValues;

      store.dispatch(fetchers.manifestsPresetsFetcher.fetch());

      if (!fetchers.repositoriesFetcher.selector(store.getState()).result) {
        await store.dispatch(fetchers.repositoriesFetcher.fetch());
      }
      if (!fetchers.helmRepositoriesFetcher.selector(store.getState()).result) {
        store.dispatch(fetchers.helmRepositoriesFetcher.fetch());
      }
      if (config?.name && config?.registryUid && config?.tag) {
        store.dispatch(
          fetchers.packVersionsFetcher.fetch({
            repositoryUid: config.registryUid,
            packName: config.name,
            layerType: type,
          })
        );
      }
      if (config?.packUid) {
        packValues =
          (
            await store.dispatch(
              fetchers.packValuesFetcher.fetch(config?.packUid)
            )
          )?.[0]?.spec || {};

        dispatch({
          type: "PROFILE_BUILDER_UPDATE_LAYER",
          layer: {
            config: {
              presets: packValues.presets,
              schema: packValues.schema,
            },
          },
        });
      }

      const mergedManifests = await fetchManifestInformation();

      const installOrder = extractInstallOrder(config);
      const isBasicLayer = ["os", "cni", "csi", "k8s"].includes(type);

      return Promise.resolve({
        profileModuleGuid: guid,
        activeSelectField: "",
        packType: type || "",
        installOrder: isBasicLayer ? undefined : installOrder || "0",
        repository: config?.registryUid || getLastSelectedRepoUid(type) || "",
        pack: config?.name || "",
        version: config?.tag || "",
        packUid: config?.packUid,
        values: getPackValuesWithoutPresetsComment(config?.values),
        manifests: mergedManifests,
        presets:
          config?.selectedPresets ||
          getDefaultPresetsFromValues(config?.values),
      });
    },
  });

  const editorActions = createFormActions({
    init: async () => {
      const { layers } = getState();

      const packVersions = layers.reduce((acc, layer) => {
        const { selectedPresets, values, manifests } = layer.config || {};

        acc[layer.guid] = {
          presets: selectedPresets || getDefaultPresetsFromValues(values),
          values:
            layer.type === "manifest"
              ? undefined
              : getPackValuesWithoutPresetsComment(values),
          manifests: manifests || [],
        };
        return acc;
      }, {});

      return Promise.resolve(packVersions);
    },
  });

  const fetchManifestInformation = async () => {
    const { selectedLayer, layers } = getState();

    const layer =
      layers.find((layer) => layer.guid === selectedLayer) ||
      layers.find((layer) => layer.isDraft) ||
      {};

    const currentProfile = getRawClusterProfile(store.getState());
    if (currentProfile && layer?.config?.packUid && layer?.persisted) {
      try {
        const manifestPromise = api
          .get(
            `v1alpha1/clusterprofiles/${currentProfile.metadata.uid}/packs/${layer.config.packUid}/manifests`
          )
          .then(({ items }) => (items || []).map(parseManifest));

        await store.dispatch({
          promise: manifestPromise,
          type: "FETCH_PACK_MANIFESTS",
          layer: selectedLayer,
          schema: [ManifestSchema],
        });
      } catch (err) {}
    }

    return (layer.config?.manifests || []).map((manifest) => {
      const entity = getStoreEntity(manifest.guid, ManifestSchema) || {};

      return {
        ...entity,
        ...manifest,
      };
    });
  };

  const manifestFormActions = createFormActions({
    init: async () => {
      const { selectedLayer, layers } = getState();
      const layer = layers.find((layer) => layer.guid === selectedLayer) || {};
      const { config = {} } = layer;

      const mergedManifests = await fetchManifestInformation();
      dispatch({
        type: "APPLY_MANIFESTS",
        appliedManifests: mergedManifests.length
          ? mergedManifests.map((manifest) => manifest.guid)
          : [],
        currentAttachedManifestGuid: mergedManifests?.[0]?.guid,
      });

      const installOrder = extractInstallOrder(config);

      return Promise.resolve({
        uid: config?.uid,
        name: config?.name || "",
        type: "manifest",
        installOrder: installOrder || "0",
        manifests: mergedManifests,
        persisted: !!config?.uid,
      });
    },
    submit: async (data) => {
      if (!data.name) {
        return;
      }

      const mergedManifests = await fetchManifestInformation();

      dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: {
          type: "manifest",
          name: data.name,
          config: {
            uid: data.uid,
            name: data.name,
            manifests: mergedManifests,
            installOrder: data.installOrder,
            type: "manifest",
            logo: MANIFEST_ICON,
          },
        },
      });
    },
  });

  const importActions = createFormActions({
    init: async () => {
      return Promise.resolve({});
    },
    submit: () => {},
  });

  const getLastSelectedRepoUid = (packType) => {
    const repos = getAvailableRepositories(store.getState());
    const { helmRepoUid, packRepoUid } = getState();
    const lastSelectedRepoUid =
      packType === "helmChart" ? helmRepoUid : packRepoUid;

    if (repos?.length === 1) {
      return repos[0].value;
    }
    if (!packType) {
      return "";
    }
    return lastSelectedRepoUid;
  };

  async function packFieldChange({ name, value }) {
    const { formType } = getState();
    const isManifest = formType === "manifest" && name === "manifests";

    if (isManifest) {
      store.dispatch(
        manifestFormActions.onChange({
          module: MODULES.MANIFEST_MODULE,
          name,
          value,
        })
      );
    }

    let formUpdates;
    if (name !== "presets" && name !== "values" && !isManifest) {
      store.dispatch(
        packActions.onChange({
          module: MODULES.PACK_MODULE,
          name,
          value,
        })
      );
    }

    if (["packType", "pack", "repository"].includes(name)) {
      store.dispatch(
        packActions.onChange({
          module: MODULES.PACK_MODULE,
          name: "manifests",
          value: [],
        })
      );

      togglePackValues("");
    }

    if (name === "packType") {
      formUpdates = {
        pack: undefined,
        repository: "",
        version: "",
        values: "",
        presets: null,
      };
    }

    if (name === "pack") {
      const repository = store.getState().forms.layerPack?.data?.repository;
      const packType = store.getState().forms.layerPack?.data?.packType;
      const selectedPack = getSelectedPack(store.getState());

      formUpdates = {
        version: "",
        values: "",
        presets: null,
      };

      await store.dispatch(
        fetchers.packVersionsFetcher.fetch({
          repositoryUid: repository,
          packName: value,
          layerType: packType,
        })
      );

      if (
        selectedPack?.spec?.registries?.[0]?.annotations
          ?.versionAutoSelected === "true"
      ) {
        const packVersion = fetchers.packVersionsFetcher
          .selector(store.getState())
          .result?.find((version) => !version.tag.includes(".x"));

        if (packVersion) {
          this.packFieldChange({ name: "version", value: packVersion.tag });
          formUpdates = null;
        }
      }
    }

    if (name === "repository") {
      const packType = store.getState().forms.layerPack?.data?.packType;
      store.dispatch(packListActions.initialize(MODULES.PACK_LIST_MODULE));
      dispatch({
        type: "PROFILE_BUILDER_SET_REPOSITORY",
        packType,
        repositoryUid: value,
      });
      formUpdates = {
        version: "",
        values: "",
        pack: null,
        presets: null,
      };
    }

    if (name === "version") {
      const selectedPack = fetchers.packVersionsFetcher
        .selector(store.getState())
        .result?.find((pack) => pack.tag === value);
      const packUid = selectedPack?.pack?.metadata?.uid;
      const [packValues] =
        (await store.dispatch(fetchers.packValuesFetcher.fetch(packUid))) || [];
      const values = packValues?.spec?.values;

      formUpdates = {
        packUid,
        values: getPackValuesWithoutPresetsComment(values),
        presets: getDefaultPresetsFromValues(values),
      };
    }

    if (name === "presets") {
      const { presets: currentPresets, values: currentValues } =
        store.getState().forms[MODULES.PACK_MODULE]?.data || {};
      formUpdates = getFormUpdatesOnPresetsChange(value, {
        currentPresets,
        currentValues,
      });
    }

    if (name === "values") {
      formUpdates = {
        values: value,
        installOrder: extractInstallOrder({ values: value }),
      };
    }

    formUpdates &&
      store.dispatch(
        packActions.batchChange({
          module: MODULES.PACK_MODULE,
          updates: formUpdates,
        })
      );

    if (name === "packType") {
      packFieldChange({
        name: "repository",
        value: getLastSelectedRepoUid(value),
      });
    }

    dispatch({
      type: "PROFILE_BUILDER_UPDATE_LAYER",
      layer: mapFormDataToLayers(store.getState().forms[formType]?.data),
    });
  }

  return {
    packActions,
    editorActions,
    manifestFormActions,
    importActions,
    packFieldChange,
    helmRegistryFormActions: createFormActions({
      init: async () => {
        return Promise.resolve({
          name: "",
          endpoint: "",
          username: "",
          password: "",
          isValid: false,
          noAuth: false,
        });
      },
      submit: async (data) => {
        const payload = getHelmRepositoryPayload(data, getState().expandedPack);
        try {
          const packRegistry = await nonProjectApi.post(
            "v1alpha1/registries/helm",
            payload
          );
          setNewHelmRegistry(packRegistry);
        } catch (error) {
          notifications.error({
            message: i18next.t(
              "Something went wrong when creating a helm registry"
            ),
            description: error.message,
          });
        }
      },
      validator,
    }),

    importFieldChange: (name, value) => {
      store.dispatch(
        importActions.onChange({
          module: MODULES.IMPORT_MODULE,
          name,
          value,
        })
      );
    },
    onEditorPresetsFormChange: (value) => {
      const { packsEditor } = getState();

      store.dispatch(
        editorActions.onChange({
          module: MODULES.PACKS_EDITOR_MODULE,
          name: `${packsEditor.selectedGuid}.presets`,
          value,
        })
      );
    },

    onEditorValuesFormChange: (value) => {
      const { packsEditor } = getState();

      store.dispatch(
        editorActions.onChange({
          module: MODULES.PACKS_EDITOR_MODULE,
          name: `${packsEditor.currentName}`,
          value,
        })
      );
    },

    newManifestFieldChange: ({ name, value }) => {
      store.dispatch(
        manifestFormActions.onChange({
          module: MODULES.MANIFEST_MODULE,
          name,
          value,
        })
      );
    },
  };
}
