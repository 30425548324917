import dataFetcher from "modules/dataFetcher";
import api from "services/api";

export const ssoIdpsFetcher = dataFetcher({
  selectors: ["ssoIdps"],
  fetchData() {
    return api.get("v1alpha1/auth/sso/idps");
  },
});

export const defaultTeamsFetcher = dataFetcher({
  selectors: ["defaultTeams"],
  fetchData() {
    return api.get("v1alpha1/teams/summary");
  },
});
