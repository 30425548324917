export const initialState = {
  stepNumber: 1,
  totalSteps: 4,
};

export function TOUR_MODAL_NEXT_STEP(nextState, _, state) {
  nextState.stepNumber = state.stepNumber + 1;
}

export function TOUR_MODAL_PREVIOUS_STEP(nextState, _, state) {
  nextState.stepNumber = state.stepNumber - 1;
}

export function RESET_TOUR_MODAL(nextState) {
  nextState.stepNumber = initialState.stepNumber;
}
