import React, { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import Fields from "components/ui/Fields";
import Button, { TextButton } from "components/ui/Button";
import Switch from "components/common/Switch";

import { nextStep, previousStep, onForgotPassword } from "state/auth/actions";

import { red, navy } from "utils/constants/colors";

import { Context } from "modules/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/pro-solid-svg-icons";

const ErrorWrap = styled.div`
  color: ${red};
  margin: 10px 0;
  text-align: center;
  text-transform: none;
  font-weight: normal;
`;

const ForgotPassword = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${navy};
  cursor: pointer;
  padding-bottom: 8px;
`;

const EmailWrap = styled.div`
  display: flex;
  color: #fff;
  align-items: center;

  ${TextButton} {
    color: #fff;
  }
`;

const EmailLabel = styled.span`
  font-size: 15px;
  margin-left: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HiddenInputField = styled.div`
  display: none;
`;

const StyledButton = styled(Button)`
  display: ${(props) => props.step === "resetConfirm" && "none"};
`;

const PasswordWrap = styled.div`
  ${TextButton} {
    width: 18px;
    height: 18px;
    border: none;
    margin-top: 10px;

    svg {
      fill: white;
    }
  }
`;

const TwoStepAuth = ({
  step,
  nextStep,
  submitError,
  emailId,
  previousStep,
  disabledEmail,
  onForgotPassword,
  onPasswordFocus,
}) => {
  const { t } = useTranslation();
  const passwordInputRef = useRef(null);
  const formContext = useContext(Context);

  function renderButtonText() {
    if (step === "email") {
      return t("Next");
    }

    return t("Login");
  }

  function renderError() {
    if (!submitError) {
      return null;
    }
    return <ErrorWrap>{submitError.message}</ErrorWrap>;
  }

  function onEnter(next) {
    return (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        next();
      }
    };
  }

  return (
    <>
      <Switch value={step}>
        <Switch.Case value="email">
          <Fields.Input
            data-qa="email"
            name="emailId"
            type="email"
            placeholder={t("Enter your email")}
            label={t("Email")}
            disabled={disabledEmail}
            autoComplete="username"
            autoFocus
            onKeyDown={(ev) => {
              formContext.onFieldChange("password", "");
              onEnter(nextStep)(ev);
            }}
          />
          <HiddenInputField>
            <PasswordWrap>
              <Fields.PasswordField
                data-qa="hiddenPassword"
                name="password"
                allowAutofill={true}
                displayEyeIcon={true}
              />
            </PasswordWrap>
          </HiddenInputField>
        </Switch.Case>
        <Switch.Case value="password">
          <EmailWrap>
            <TextButton>
              <FontAwesomeIcon
                icon={faLongArrowAltLeft}
                onClick={previousStep}
              />
            </TextButton>
            <EmailLabel>{emailId}</EmailLabel>
            <HiddenInputField>
              <Fields.Input
                data-qa="email"
                name="emailId"
                type="email"
                autoComplete="username"
              />
            </HiddenInputField>
          </EmailWrap>
          <PasswordWrap>
            <Fields.PasswordField
              ref={passwordInputRef}
              data-qa="password"
              name="password"
              label={t("Password")}
              allowAutofill={true}
              autoFocus
              displayEyeIcon={true}
              placeholder={t("Enter your password")}
              onKeyDown={onEnter(nextStep)}
              onFocus={() => onPasswordFocus(true)}
              onBlur={() => onPasswordFocus(false)}
            />
          </PasswordWrap>
          <ForgotPassword onClick={onForgotPassword}>
            {t("Forgot password?")}
          </ForgotPassword>
        </Switch.Case>
      </Switch>
      <div>
        {renderError()}
        <StyledButton
          data-qa="login"
          htmlType="submit"
          onClick={nextStep}
          step={step}
        >
          {renderButtonText()}
        </StyledButton>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    step: state.auth.step,
    emailId: state.forms.login.data.emailId,
    disabledEmail: state.auth.disabledEmail,
  }),
  {
    nextStep,
    previousStep,
    onForgotPassword,
  }
)(TwoStepAuth);
