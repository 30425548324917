import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrap = styled.div`
  color: rgba(154, 190, 217, 0.5);
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.02em;
`;

const MenuFooter = ({ version }) => {
  const { t } = useTranslation();
  let text = t("Spectro Cloud&#x00AE;");
  if (version) {
    text = t("SpectroCloud&#x00AE; Version {{version}}", { version });
  }
  return <Wrap dangerouslySetInnerHTML={{ __html: text }} />;
};

export default MenuFooter;
