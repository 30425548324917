import i18n from "i18next";
import { createSelector } from "reselect";

import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import history from "services/history";
import notifications from "services/notifications";

export const getRawCluster = getEntity(
  (state) => state.cluster.details.currentClusterId,
  ClusterSchema
);

export const createSelectorByPath = (formSelector, detailPageSelector) =>
  createSelector(
    formSelector,
    detailPageSelector,
    () => history.location.pathname,
    (formSelector, detailPageSelector, path) => {
      if (path.endsWith("create")) {
        return formSelector;
      }
      return detailPageSelector;
    }
  );

const getClusterRegion = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig.location : null
);

const getGCPClusterRegion = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig.region : null
);

const getClusterProject = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig.project : null
);

const getClusterAccountUID = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig?.spec.cloudAccountRef?.uid : null
);

const getRegion = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.region,
  getClusterRegion
);

const getGCPRegion = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.region,
  getGCPClusterRegion
);

const getProject = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.project,
  getClusterProject
);

const getCredential = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.credential,
  getClusterAccountUID
);

export const azureInstanceTypesFetcher = dataFetcher({
  selectors: ["azure", getRegion, "instanceTypes"],
  fetchData([_, region]) {
    return api.get(`v1alpha1/clouds/azure/regions/${region}/instancetypes`);
  },
});

export const azureAZFetcher = dataFetcher({
  selectors: ["azure", getRegion, "azs"],
  fetchData([_, region]) {
    return api.get(`v1alpha1/clouds/azure/regions/${region}/zones`);
  },
});

export const azureStorageAccountsFetcher = dataFetcher({
  selectors: ["azure", "storageAccounts"],
  fetchData() {
    return api.get("v1alpha1/clouds/azure/storageaccounttypes");
  },
});

export const gcpAZFetcher = dataFetcher({
  selectors: ["gcp", getProject, getGCPRegion, getCredential],
  async fetchData([_, project, region, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/gcp/projects/${project}/regions/${region}/zones?cloudAccountUid=${credential}`
    );
    return res.zones;
  },
});

export const gcpInstanceTypesFetcher = dataFetcher({
  selectors: ["gcp", getGCPRegion],
  async fetchData([_, region]) {
    let res;
    try {
      res = await api.get(
        `v1alpha1/clouds/gcp/regions/${region}/instancetypes`
      );
    } catch (error) {
      notifications.error({
        message: i18n.t("Something went wrong."),
        description: error.message,
      });
    }
    return res;
  },
});
