import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import ModalService from "services/modal";
import StripeService from "services/stripe";
import { getCurrentUser } from "state/auth/selectors";
import { InvoiceSchema } from "utils/schemas";

export const PAYMENT_CARD_MODULE = "paymentCard";
export const CARD_SELECTION_MODULE = "cardSelection";

export const cardFormModal = new ModalService("cardForm");
export const invoiceModal = new ModalService("invoiceDetails");

export const stripeService = new StripeService();

export const publishableKeyFetcher = dataFetcher({
  selectors: ["payments", "publishableKey"],
  async fetchData() {
    const response = await api.get("v1alpha1/payments/stripe/config");
    if (response?.publishKey) {
      stripeService.loadStripe(response?.publishKey);
    }
    return response;
  },
});

export const paymentCardsFetcher = dataFetcher({
  selectors: ["payments", "cardSelectors"],
  fetchData() {
    return api.get("v1alpha1/payments/stripe/paymentMethods");
  },
});

export const invoicesFetcher = dataFetcher({
  selectors: [
    "invoices",
    (store) => getCurrentUser(store)?.metadata?.annotations?.tenantUid,
  ],
  async fetchData([_, tenantUid]) {
    const response = await api.get(`v1alpha1/tenants/${tenantUid}/invoices`);
    return response?.invoices;
  },
});

export const invoiceFetcher = dataFetcher({
  selectors: [
    "invoice",
    (store) => getCurrentUser(store)?.metadata?.annotations?.tenantUid,
    () => invoiceModal.data?.invoiceUid,
  ],
  schema: InvoiceSchema,
  fetchData([_, tenantUid, invoiceUid]) {
    return api.get(`v1alpha1/tenants/${tenantUid}/invoices/${invoiceUid}`);
  },
});
