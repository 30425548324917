import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { canUpgradePlan, getTenantPlanName } from "state/auth/selectors";
import styled from "styled-components";
import { gold } from "utils/constants/colors";
import flags from "services/flags";

import { pendingCancellationModal } from "state/plandetails/services";
import { isSubscriptionInPending } from "state/plandetails/selectors";
import PendingCancellationModal from "pages/settings/plandetails/components/PendingCancellationModal";
import ArrowLabel from "components/styled/ArrowLabel";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  height: 24px;
  margin: 0px -8px 0;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

const UpgradeContainer = styled(Link)`
  flex-grow: 1;
  color: ${(props) => (props.warning ? "#fa7690" : gold)};
  margin-left: 20px;
`;

function PlanContainer({ planName, canUpgrade, pendingCancellation }) {
  const { t } = useTranslation();

  if (!planName || !flags.has("billing")) {
    return null;
  }

  const openPendingCancellationModal = () => {
    pendingCancellationModal.open();
  };

  const renderUpgradeContainer = () => {
    if (pendingCancellation || canUpgrade) {
      return (
        <UpgradeContainer
          to="/admin/settings/plandetails"
          warning={pendingCancellation}
        >
          {pendingCancellation ? t("Details") : t("Upgrade now")}
        </UpgradeContainer>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <ArrowLabel
        label={pendingCancellation ? t("Pending Cancellation") : planName()}
        type={pendingCancellation ? "warning" : "default"}
        onClick={pendingCancellation && openPendingCancellationModal}
      />
      {renderUpgradeContainer()}
      <PendingCancellationModal />
    </Wrapper>
  );
}

export default connect((state) => ({
  planName: getTenantPlanName(state),
  canUpgrade: canUpgradePlan(state),
  pendingCancellation: isSubscriptionInPending(state),
}))(PlanContainer);
