import i18next from "i18next";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ModalService from "services/modal";
import api, { nonProjectApi } from "services/api";

import { CloudAccountSchema, DNSMappingSchema } from "utils/schemas";
import notifications from "services/notifications";
import { createSelector } from "reselect";
import store from "services/store";

export const DNS_MAPPING_MODULE = "dnsMapping";
export const dnsFormModal = new ModalService("dnsForm");

export const dnsFetcher = dataFetcher({
  selectors: ["dnsMapping", () => dnsFormModal.data?.uid || ""],
  schema: DNSMappingSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1alpha1/users/assets/vsphere/dnsMappings/${uid}`);
  },
});

export const dnsMappingsFetcher = dataFetcher({
  selectors: ["dnsMapping"],
  schema: [DNSMappingSchema],
  async fetchData() {
    const response = await api.get("v1alpha1/users/assets/vsphere/dnsMappings");
    return response.items;
  },
});

export const vsphereCloudAccountsFetcher = dataFetcher({
  selectors: ["cloudAccounts", "vsphere"],
  schema: [CloudAccountSchema],
  async fetchData() {
    const res = await api.get(`v1alpha1/cloudaccounts/vsphere`);
    return res.items;
  },
});

export const overlordsFetcher = dataFetcher({
  selectors: ["privateCloudGateway", "vsphere"],
  async fetchData() {
    const response = await nonProjectApi.get("v1alpha1/overlords/vsphere");
    return response.items;
  },
});

export const getCloudAccountUid = createSelector(
  (state) => state.forms?.[DNS_MAPPING_MODULE]?.data?.privateGatewayUid,
  (state) => state.forms?.[DNS_MAPPING_MODULE]?.data?.cloudAccount,
  overlordsFetcher.selector,
  (privateGatewayUid, cloudAccount, overlords) => {
    if (cloudAccount) {
      return cloudAccount;
    }

    return overlords?.result?.find(
      (overlord) => overlord.metadata.uid === privateGatewayUid
    )?.spec?.cloudAccountUid;
  }
);

export const datacenterFetcher = dataFetcher({
  selectors: ["vsphere", getCloudAccountUid],
  async fetchData([_, accountUid]) {
    try {
      const response = await api.get(
        `v1alpha1/cloudaccounts/vsphere/${accountUid}/properties/datacenters`
      );
      return response.items;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to get the datacenters"
        ),
        description: err?.message,
      });
    }
  },
});

export const clusterFetcher = keyedDataFetcher({
  selectors: ["vsphere"],
  async fetchData([_, datacenter]) {
    const datacenters = datacenterFetcher.selector(store.getState());
    return (
      datacenters.result.find(
        (datacenterData) => datacenterData.datacenter === datacenter
      )?.computeclusters || []
    );
  },
});

export const networksFetcher = keyedDataFetcher({
  selectors: [
    "vsphere",
    getCloudAccountUid,
    (state) => state.forms?.[DNS_MAPPING_MODULE]?.data?.datacenter,
  ],
  async fetchData([_, cloudAccountUid, datacenter, computecluster]) {
    try {
      const response = api.get(
        `v1alpha1/cloudaccounts/vsphere/${cloudAccountUid}/properties/computecluster/resources?datacenter=${datacenter}&computecluster=${computecluster}`
      );
      const data = await response;
      return data.computecluster.networks;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to get the properties"
        ),
        description: err?.message,
      });
    }
  },
});
