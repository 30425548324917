import store from "services/store";
import withPolling from "utils/withPolling";
import { getCluster, isBrownfield } from "state/cluster/selectors/details";
import {
  fetchClusterStatus,
  fetchClusterNotifications,
  fetchLogStatus,
} from "state/cluster/actions/details";
import { fetchClusterProfileNotifications } from "state/clusterprofile/actions/details";
import { getNodeStatus, getNodePools } from "state/cluster/selectors/nodes";
import { fetchClusterNodes } from "state/cluster/actions/nodes";
import { overlordsListActions } from "state/overlord/actions/list";
import { OVERLORD_MODULE } from "state/overlord/services/list";
import { eventListActions, clusterErrorFetcher } from "state/cluster/services";
import { refreshClustersList } from "state/cluster/actions/list";
import {
  backupsListActions,
  restoresListActions,
  BACKUP_LIST_MODULE,
  RESTORE_LIST_MODULE,
} from "state/backups/services";

const getActivatedCluster = async () => {
  let state = store.getState();
  let cluster = getCluster(state);
  await store.dispatch(fetchClusterStatus(cluster.metadata.uid));

  return Promise.reject();
};

export const pollClusterStatus = withPolling(getActivatedCluster, 5000);

const getActiveNodes = async () => {
  const nodePoolsNodes = await store.dispatch(fetchClusterNodes());
  let state = store.getState();
  const isClusterBrownField = isBrownfield(state);
  const clusterNodes = nodePoolsNodes.reduce(
    (accumulator, { items }) => [...accumulator, ...items],
    []
  );

  const activeNodes = clusterNodes.filter((node) => {
    const nodeStatus = getNodeStatus(node);
    return nodeStatus === "running";
  });

  const nodePoolsSize = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.size,
    0
  );

  const nodes = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.nodes.length,
    0
  );

  let shouldStop = nodes === activeNodes.length && nodes === nodePoolsSize;
  if (isClusterBrownField) {
    shouldStop = nodes && shouldStop;
  }

  if (!shouldStop) {
    throw new Error("Not all nodes are active");
  }

  return Promise.resolve();
};

export const pollNodes = withPolling(getActiveNodes, 5000);

const getClusterEvents = () => {
  const listState = store.getState().list.clusterevents;
  if (listState.nextToken === undefined) {
    store.dispatch(eventListActions.fetchItems("clusterevents"));
  }
  store.dispatch(eventListActions.nextPage("clusterevents"));

  return Promise.reject();
};

export const pollClusterEvents = withPolling(getClusterEvents, 7000);

const getDatacenters = () => {
  store.dispatch(overlordsListActions.fetchItems(OVERLORD_MODULE));

  return Promise.reject();
};

export const pollDatacenters = withPolling(getDatacenters, 5000);
export const pollClusterNotifications = withPolling(() => {
  store.dispatch(fetchClusterNotifications());
  return Promise.reject();
}, 5000);

export const pollClusterProfileNotifications = withPolling(() => {
  store.dispatch(fetchClusterProfileNotifications());
  return Promise.reject();
}, 5000);

export const poolClusterErrors = withPolling(() => {
  store.dispatch(
    clusterErrorFetcher.fetch({
      interval: "5 minute",
      status: ["Error"],
    })
  );

  return Promise.reject();
}, 20000);

export const pollSpectroClusters = withPolling(() => {
  store.dispatch(refreshClustersList());
  return Promise.reject();
}, 20000);

export const pollClusterLogStatus = withPolling(
  () => store.dispatch(fetchLogStatus()),
  5000
);

export const pollBackupList = withPolling(async () => {
  store.dispatch(backupsListActions.fetchItems(BACKUP_LIST_MODULE));
  return Promise.reject();
}, 10000);

export const pollRestoreList = withPolling(async () => {
  store.dispatch(restoresListActions.fetchItems(RESTORE_LIST_MODULE));
  return Promise.reject();
}, 10000);
