import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Fields from "components/ui/Fields";
import Button from "components/ui/Button";

import { red, navy } from "utils/constants/colors";

import { onForgotPassword } from "state/auth/actions";

const ErrorWrap = styled.div`
  color: ${red};
  margin: 10px 0;
  text-align: center;
  text-transform: none;
  font-weight: normal;
`;

const ForgotPassword = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${navy};
  cursor: pointer;
  padding-bottom: 8px;
`;

const PasswordAuth = ({
  onSubmit,
  submitError,
  onForgotPassword,
  onPasswordFocus,
}) => {
  const { t } = useTranslation();

  function renderError() {
    if (!submitError) {
      return null;
    }
    return <ErrorWrap>{submitError.message}</ErrorWrap>;
  }

  return (
    <>
      <div>
        <Fields.Input
          data-qa="email"
          submitOnEnter
          label="email"
          name="emailId"
          type="email"
          placeholder="Email"
        />
        <Fields.Input
          data-qa="password"
          submitOnEnter
          name="password"
          label="password"
          type="password"
          placeholder="Password"
          onFocus={() => onPasswordFocus(true)}
          onBlur={() => onPasswordFocus(false)}
        />
        <ForgotPassword onClick={onForgotPassword}>
          {t("Forgot password?")}
        </ForgotPassword>
      </div>
      <div>
        {renderError()}
        <Button data-qa="login" htmlType="submit" onClick={onSubmit}>
          {t("Login")}
        </Button>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    step: state.auth.step,
  }),
  {
    onForgotPassword,
  }
)(PasswordAuth);
