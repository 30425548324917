import clusters1 from "./clusters1.png";
import clusters2 from "./clusters2.png";
import clusters3 from "./clusters3.png";
import profiles1 from "./profiles1.png";
import profiles2 from "./profiles2.png";
import profiles3 from "./profiles1.png";
import tenants1 from "./tenants1.png";
import tenants2 from "./tenants2.png";
import tenants3 from "./tenants3.png";

export default {
  clusters1,
  clusters2,
  clusters3,
  profiles1,
  profiles2,
  profiles3,
  tenants1,
  tenants2,
  tenants3,
};
