import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import {
  faExclamationCircle,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_COLOR } from "utils/constants/colors";
import Modal from "components/ui/Modal";
import Button, { TextButton } from "components/ui/Button";

const icons = {
  error: faExclamationCircle,
  warning: faExclamationCircle,
  success: faCheckCircle,
  info: faInfoCircle,
};

const Icon = styled(FontAwesomeIcon)`
  margin: 15px;
`;

const ConfirmationModal = styled(Modal)`
  ${(props) =>
    props.type &&
    css`
      .ant-modal-header {
        border-bottom: 0px;
      }
      .ant-modal-footer {
        border-top: 0px;
      }
      .ant-modal-body {
        padding: 0px 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .ant-modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0;

        button:last-child {
          margin-top: 10px;
          margin-left: 0px;
        }
      }
    `}
`;

function DEFAULT_CONFIRM_FOOTER(service, props) {
  return [
    <Button
      danger={props.danger}
      key="confirm-ok"
      data-qa="confirm-ok"
      onClick={() => service.resolve()}
      disabled={props.disabledConfirm}
    >
      {props.okLabel || "OK"}
    </Button>,
    <TextButton
      secondary
      key="confirm-cancel"
      data-qa="confirm-cancel"
      onClick={() => service.reject()}
    >
      {props.cancelLabel || "Cancel"}
    </TextButton>,
  ];
}

export function ConfirmComponent(props) {
  const { footer, service, visible, title, type } = props;
  const { t } = useTranslation();
  let header = title || t("Confirm action");
  if (!visible) {
    return null;
  }

  let footerComponents = footer || DEFAULT_CONFIRM_FOOTER;
  if (typeof footerComponents === "function") {
    footerComponents = footerComponents(service, props);
  }
  if (type) {
    header = (
      <Icon
        size="2x"
        key="icon"
        icon={icons[props.type]}
        color={STATUS_COLOR[props.type]}
      />
    );
  }

  return (
    <ConfirmationModal
      {...props}
      title={header}
      closable={!type}
      footer={footerComponents}
    />
  );
}

export default connect(
  (_, ownProps) => ({
    visible: ownProps.service.isOpened(),
  }),
  (_, ownProps) => ({
    onCancel: ownProps.service.close,
  })
)(ConfirmComponent);
