import store from "services/store";
import uuid from "uuid/v4";

import ProfileBuilderActions from "./actions";
import ModalService from "services/modal";
import { createSelector } from "reselect";
import isEqual from "fast-deep-equal";
import { extractPayload } from "./utils";
import { extractInstallOrder } from "utils/yaml";

class ProfileBuilder {
  constructor() {
    this.guid = uuid();
    this.revertPackValuesConfirm = new ModalService();
    this.removeLayerConfirm = new ModalService();
    this.addNewRepositoryModal = new ModalService();

    this.actions = new ProfileBuilderActions({
      guid: this.guid,
      revertPackValuesConfirm: this.revertPackValuesConfirm,
      removeLayerConfirm: this.removeLayerConfirm,
      addNewRepositoryModal: this.addNewRepositoryModal,
      getState: () => this.state,
    });
  }

  get state() {
    const state = store.getState().profileBuilder[this.guid];

    return {
      ...state,
      orderedLayers: this.selectors.getOrderedLayers(store.getState()),
    };
  }

  get payload() {
    const state = this.state;

    if (!state) {
      return [];
    }

    return extractPayload(state.layers);
  }

  isProfileValid() {
    const { layers, draftLayers, selectedLayer, showEditor } = this.state || {};
    const isDraftsEmpty = !draftLayers?.length;
    const packLayersConfigured =
      layers?.length &&
      layers
        .filter((layer) => layer.type !== "manifest")
        .every((layer) => layer.config?.tag);
    const manifestLayersConfigured =
      layers?.length &&
      layers
        .filter((layer) => layer.type === "manifest")
        .every((layer) => layer.config?.name && layer.config.manifests?.length);
    const isValid =
      packLayersConfigured &&
      manifestLayersConfigured &&
      isDraftsEmpty &&
      !selectedLayer &&
      !showEditor;
    return isValid;
  }

  selectors = {
    getOrderedLayers: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (layers) => {
        if (!layers) {
          return [];
        }

        return layers.sort((layer1, layer2) => {
          const order1 = parseInt(extractInstallOrder(layer1.config));
          const order2 = parseInt(extractInstallOrder(layer2.config));
          const areBasicLayer = [layer1.type, layer2.type].some((type) =>
            ["os", "cni", "csi", "k8s"].includes(type)
          );

          if (areBasicLayer) {
            return 0;
          }

          if (layer1.type === "new") {
            return 1;
          }

          if (Number.isNaN(order1) || Number.isNaN(order2)) {
            return 0;
          }

          return order1 - order2;
        });
      }
    ),
    getPackErrors: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.errors,
      (layers = [], errors = []) => {
        return layers.reduce((accumulator, layer) => {
          const packErrors = errors[layer.guid]?.filter(
            (error) => !!error.result
          );
          if (Array.isArray(packErrors) && packErrors.length !== 0) {
            accumulator.push({
              name: layer.config.name,
              errors: packErrors.map((error) => ({
                ...error,
                message: error.result,
              })),
            });
          }

          return accumulator;
        }, []);
      }
    ),
    hasIncompleteLayers: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (layers) =>
        (layers || []).some((layer) => {
          return layer.type === "manifest" ? false : !layer.config?.tag;
        })
    ),
    hasUnsavedChanges: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.initialLayers,
      (layers, initialLayers) => {
        const payloads = [
          extractPayload(layers),
          extractPayload(initialLayers),
        ];

        return isEqual(...payloads);
      }
    ),
  };
}

export default ProfileBuilder;
