import React from "react";
import i18n from "i18next";
import { createForm } from "modules/form";

import LoginLayout from "components/common/layouts/Auth";
import Fields from "components/ui/Fields";
import Button from "components/ui/Button";
import { setPasswordFormActions } from "state/auth/actions";

function SetPassword(props) {
  return (
    <>
      <div>
        <Fields.Input
          type="password"
          label={i18n.t("New password")}
          data-qa="newPassword"
          name="newPassword"
          submitOnEnter
        />
        <Fields.Input
          type="password"
          label={i18n.t("Confirm password")}
          data-qa="confirmPassword"
          name="confirmPassword"
          submitOnEnter
        />
      </div>
      <Button
        data-qa="set_password"
        onClick={props.onSubmit}
        loading={props.submitting}
      >
        {i18n.t("Set password")}
      </Button>
    </>
  );
}

const Form = createForm({
  Component: SetPassword,
  actions: setPasswordFormActions,
});

export default function Login() {
  return (
    <LoginLayout>
      <Form module="setPassword" preventInitOnMount />
    </LoginLayout>
  );
}
