import i18n from "i18next";
import base64 from "base-64";

import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";
import store from "services/store";

import createActions from "modules/form/actions";

import { getCurrentUser } from "state/auth/selectors";
import { nonProjectApi } from "services/api";

const validator = new Validator();
validator.addRule("identityProvider", Missing());
validator.addRule(["ssoIdps"], Missing());
validator.addRule("identityProvider", (value, key, data) => {
  const parser = new DOMParser();
  const xmlParsed = parser.parseFromString(value, "text/xml");

  if (xmlParsed.getElementsByTagName("parsererror").length > 0) {
    return i18n.t("The XML inserted is not valid.");
  }
});

async function submit(data) {
  const state = store.getState();
  const user = getCurrentUser(state);
  const tenantUid = user?.status?.tenant.tenantUid;

  const payload = {
    issuer: data.issuer || "",
    certificate: data.certificate || "",
    attributes: data.attributes || "",
    isSingleLogoutEnabled: data.isSingleLogoutEnabled || false,
    singleLogoutUrl: data.singleLogoutUrl || "",
    syncSsoTeams: data?.syncSsoTeams,
    defaultTeams: data?.defaultTeams || [],
    isSsoEnabled: state.sso?.enabledSso === "saml",
    identityProvider: data.ssoIdps,
    federationMetadata: base64.encode(data.identityProvider),
    nameIdFormat: data.nameIdFormat,
  };

  try {
    await nonProjectApi.post(
      `v1alpha1/tenants/${tenantUid}/saml/config`,
      payload
    );

    notifications.success({
      message: i18n.t("SAML configured successfully"),
    });
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong."),
      description: error.message,
    });
  }
}

function decodeServiceMetadata(metadata) {
  const metadataModified = metadata?.serviceProviderMetadata
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  return base64.decode(metadataModified);
}

async function init() {
  const user = getCurrentUser(store.getState());
  const tenantUid = user?.metadata?.annotations?.tenantUid;
  const metadata = await nonProjectApi.get(
    `v1alpha1/tenants/${tenantUid}/saml/config`
  );

  const decodedServiceMetadata = decodeServiceMetadata(metadata);

  return Promise.resolve({
    issuer: metadata?.issuer || "",
    certificate: metadata?.certificate || "",
    isSsoEnabled: metadata?.isSsoEnabled || false,
    identityProvider: metadata?.federationMetadata
      ? base64.decode(metadata?.federationMetadata)
      : "",
    serviceProvider: decodedServiceMetadata || "",
    ssoIdps: metadata?.identityProvider || "",
    isSingleLogoutEnabled: metadata?.isSingleLogoutEnabled || false,
    singleLogoutUrl: metadata?.singleLogoutUrl || "",
    entityId: metadata?.entityId || "",
    nameIdFormat: metadata?.nameIdFormat || "",
    loginUrl: metadata?.acsUrl || "",
    attributes: metadata?.attributes || "",
    syncSsoTeams:
      typeof metadata?.syncSsoTeams === "undefined"
        ? true
        : metadata?.syncSsoTeams,

    defaultTeams: metadata?.defaultTeams || [],
    persisted: metadata?.isSsoEnabled || false,
  });
}

export const samlFormActions = createActions({
  init,
  submit,
  validator,
});
