import * as YAML from "yaml";
import merge from "lodash/merge";
import uuid from "uuid/v4";

YAML.scalarOptions.null.nullStr = "";

function extractComments(document) {
  const comments = {};
  function commentsFromItems(items = [], prefixKey = "") {
    items.forEach((item) => {
      if (!item?.key) {
        return;
      }
      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      if (item.value?.commentBefore || item.key.commentBefore || item.comment) {
        comments[key] = {
          value: item.value?.commentBefore,
          key: item.key.commentBefore,
          item: item.comment,
        };
      }
      commentsFromItems(item.value?.items || [], key);
    });
    return comments;
  }

  return commentsFromItems(document?.contents?.items || []);
}

function addComments(document, comments) {
  function addCommentsToItems(items, prefixKey) {
    items.forEach((item) => {
      if (!item.key) {
        return;
      }
      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      if (!comments[key]) {
        addCommentsToItems(item.value?.items || [], key);
        return;
      }
      item.key.commentBefore = comments[key].key;
      item.comment = comments[key].item;

      if (item.value) {
        item.value.commentBefore = comments[key].value;
        addCommentsToItems(item.value.items || [], key);
      }
    });
  }

  addCommentsToItems(document.contents.items);
}

// FIXME: this is a hack to prevent the
// yaml library from transforming an object full of null values
// into ? [key]
// this generates an UID that will be removed from the content of the
// toString() function of the parsedYaml document
const uid = uuid();
export function removeNullHax(content) {
  const regex = new RegExp(`\\s+${uid}: ${uid}`, "g");

  return content.replace(regex, "");
}

function checkForNull(obj) {
  const keys = Object.keys(obj || {});
  if (keys.length === 0) {
    return;
  }

  let allNull = true;

  keys.forEach((key) => {
    const value = obj[key];
    if (value !== null) {
      allNull = false;
    }
    if (value !== null && typeof value === "object") {
      checkForNull(value);
    }
  });

  if (allNull) {
    obj[uid] = uid;
  }
}

export function mergeYaml(base, ...overwrites) {
  if (overwrites.length === 0) {
    return base;
  }

  const baseDoc = YAML.parseDocument(base);
  const parsedBase = baseDoc.toJS();
  let comments = extractComments(baseDoc);

  const mergedObject = overwrites.reduce((accumulator, overwrite) => {
    const overwriteDoc = YAML.parseDocument(overwrite);
    const parsedOverwrite = overwriteDoc.toJS();
    comments = {
      ...comments,
      ...extractComments(overwriteDoc),
    };
    return merge(accumulator, parsedOverwrite);
  }, parsedBase);

  checkForNull(mergedObject);

  const outputDoc = YAML.parseDocument(YAML.stringify(mergedObject));

  addComments(outputDoc, comments);

  const originalToString = outputDoc.toString.bind(outputDoc);
  outputDoc.toString = (...args) => {
    const contents = originalToString(...args);
    return removeNullHax(contents);
  };

  return outputDoc;
}

export function extractInstallOrder(
  config = {},
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  if (config?.installOrder !== undefined) {
    return config.installOrder;
  }

  const yamlDoc =
    YAML.parseDocument(config?.values || "") || new YAML.Document();
  return yamlDoc.getIn(path);
}

export function updateInstallOrder(
  { values, installOrder },
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  if (typeof installOrder === "undefined") {
    return values;
  }

  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    if (yamlDoc.getIn(path) !== installOrder) {
      const mergedDoc = mergeYaml(
        values,
        `
        pack:
          spectrocloud.com/install-priority: "${installOrder}"
      `.trim()
      );
      const packValuesIndex = mergedDoc.contents.items.findIndex(
        (item) => item.key.value === "pack" || item.key === "pack"
      );

      if (packValuesIndex !== -1 && packValuesIndex !== 0) {
        const packValues = mergedDoc.contents.items[packValuesIndex];
        mergedDoc.contents.items.splice(packValuesIndex, 1);
        mergedDoc.contents.items.splice(0, 0, packValues);
      }

      const yamlString = mergedDoc.toString();
      return yamlString.replace(/\n$/, "");
    }

    return values;
  } catch (err) {
    return values;
  }
}
