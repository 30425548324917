import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Tooltip } from "antd";

import { ReactComponent as Logo } from "assets/icons/logo_landscape.svg";
import { ReactComponent as AdminIcon } from "assets/icons/admin.svg";
import { ReactComponent as CircleStar } from "assets/icons/circle_star.svg";

import * as colors from "utils/constants/colors";

import Permissions from "components/ui/Permissions";
import Flags from "components/ui/Flags";
import Icon from "components/ui/Icon";
import Button, { TextButton } from "components/ui/Button";
import MenuFooter from "components/common/layouts/App/MenuFooter";
import ProductTourModal from "./ProductTourModal";

import ContextDropdown from "./ContextDropdown";

import backgroundPattern from "assets/images/background_pattern.png";
import { faUserAstronaut, faBug } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowLeft,
  faPowerOff,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";

import Link from "components/common/History/Link";
import PlanContainer from "./PlanContainer";
import { downloadBugReport } from "services/store/bugReporter";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const MenuWrap = styled.div`
  display: flex;
  width: 256px;
  min-width: 256px;
  overflow: hidden;
  background: linear-gradient(96.32deg, #202976, #131a5b, #0d1440, #111111) 0%
    0% / 200% 200%;
  background-position: 0% 0%;
  transition: background-position 180ms ease-in;

  ${(props) =>
    props.isDarker &&
    css`
      background-position: 100% 0%;
    `}
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  width: 256px;
  min-width: 256px;
  padding: 0 8px;
  justify-content: space-between;

  &.menu-swap-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  &.menu-swap-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &.menu-swap-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  &.menu-swap-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  &.menu-swap-enter-active {
    transition: opacity 280ms ease-out, transform 280ms ease-out;
  }
  &.menu-swap-exit-active {
    transition: opacity 280ms ease-in, transform 280ms ease-in;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 44px 0 16px 0;
`;

const Content = styled.div`
  background: url(${backgroundPattern}),
    linear-gradient(141.34deg, #f2f2f2 35.24%, #e3e3e3 67.92%);
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  > .ant-layout-content {
    padding: 64px;
    position: relative;
    background: none;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;

const MenuDivider = styled.div`
  text-align: center;
  color: ${colors.white};

  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.03);
    background: -webkit-gradient(
      linear,
      0 0,
      100% 0,
      from(rgba(255, 255, 255, 0)),
      to(rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.1))
    );
  }
`;

const LogoContent = styled.div`
  text-align: center;
  color: ${colors.white};
  padding: 16px 0;
`;

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  margin-bottom: 8px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background: rgba(0, 0, 0, 0);
  .anticon {
    font-size: 20px;
  }

  svg path {
    fill: rgba(255, 255, 255, 0.8);
  }

  ${(props) =>
    props.selected &&
    css`
      color: #5fd5f9;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      svg path {
        fill: #5fd5f9;
      }
    `}

  &:hover {
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    svg path {
      fill: rgba(255, 255, 255, 1);
    }
  }

  transition: all 0.2s ease-in-out;

  &.back-to-project {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const LayoutContent = styled.div`
  background-color: ${colors.lightGray};
  padding: 64px;
  position: relative;
  background: none;
  overflow-y: auto;
  flex: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  font-size: 20px;
  .svg-inline--fa.fa-w-20 {
    width: 20px;
  }
`;

const ExtraMenu = styled.div`
  ${MenuLink} {
    margin: 8px 0;
  }
`;

const ProductTourButton = styled(TextButton)`
  padding: 10px;
  &,
  &:hover,
  &:focus {
    display: flex;
    align-items: center;
    color: ${colors.gold};

    > ${IconWrap} {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: ${colors.gold};
      }
    }
  }
`;

const BugReporter = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3000;
`;

const MenuItemsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  ${MenuLink} {
    height: 40px;
    width: 40px;
    margin: 10px 0;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }
  svg {
    font-size: 15px;
    path {
      fill: rgba(255, 255, 255, 1);
    }
  }
`;

export const contentRef = React.createRef();

function AppLayout(props) {
  const { t } = useTranslation();
  let activeKey = "/";
  const location = props.history.location.pathname;
  if (location.startsWith("/projects/")) {
    activeKey = location.split("/")[3];
  }
  if (location.startsWith("/admin/")) {
    activeKey = location.split("/")[2];
  }
  const adminMode = !!props.currentContext?.isAdmin;

  function renderItems(item, index) {
    return (
      <Flags has={item.flags} key={index}>
        <MenuLink
          key={item.key}
          to={item.path}
          selected={activeKey === item.key}
          data-qa-type="menu-link"
          data-qa={item.key}
        >
          <IconWrap>
            <Icon
              type={item.icon}
              theme={item.theme}
              component={item.component}
              awesome={item.awesome}
            />
          </IconWrap>
          <div>{item.label()}</div>
        </MenuLink>
      </Flags>
    );
  }

  function renderBugReporter() {
    return (
      <Flags has="bug-reporter">
        <BugReporter>
          <Button data-qa="bug-report" onClick={() => downloadBugReport()}>
            <Icon awesome={faBug} /> Bug report
          </Button>
        </BugReporter>
      </Flags>
    );
  }

  function renderAdminLink() {
    if (adminMode) {
      return null;
    }

    return (
      <Permissions isAdmin>
        <MenuLink
          as="a"
          data-qa="admin-mode"
          onClick={() => props.selectProject("ADMIN_VIEW")}
        >
          <IconWrap>
            <Icon component={AdminIcon} />
          </IconWrap>
          <div>{t("Go to Admin")}</div>
        </MenuLink>
      </Permissions>
    );
  }

  function renderBackLink() {
    if (!adminMode) {
      return;
    }

    if (props.backToProject) {
      return (
        <MenuLink
          as="a"
          className="back-to-project"
          onClick={() => props.selectProject(props.backToProject.guid)}
        >
          <IconWrap>
            <Icon awesome={faArrowLeft} />
          </IconWrap>
          <div>
            {t("Back to {{projectName}}", {
              projectName: props.backToProject.project.metadata.name,
            })}
          </div>
        </MenuLink>
      );
    }
  }

  function renderMenuIconsRow() {
    return (
      <MenuItemsRow>
        <Tooltip title={t("Logout")}>
          <MenuLink to="#" onClick={props.onLogout}>
            <Icon awesome={faPowerOff} />
          </MenuLink>
        </Tooltip>
        <Tooltip title={props.username}>
          <MenuLink to="/my-profile">
            <Icon awesome={faUserAstronaut} />
          </MenuLink>
        </Tooltip>
        {process.env.SUPPORT_LINK && (
          <Tooltip title={t("Help")}>
            <MenuLink
              as="a"
              href={process.env.SUPPORT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon awesome={faQuestionCircle} />
            </MenuLink>
          </Tooltip>
        )}
      </MenuItemsRow>
    );
  }

  return (
    <StyledLayout>
      <MenuWrap isDarker={adminMode}>
        <SwitchTransition>
          <CSSTransition key={adminMode} classNames="menu-swap" timeout={140}>
            <Menu>
              <div>
                <PlanContainer />
                <ContextDropdown {...props} />
                <MenuItems>{props.userMenuItems.map(renderItems)}</MenuItems>
              </div>
              <ExtraMenu>
                <MenuItems>
                  {renderAdminLink()}
                  {renderBackLink()}
                  <ProductTourButton onClick={props.openProductTourModal}>
                    <IconWrap>
                      <CircleStar />
                    </IconWrap>
                    <div>{t("Product Tour")}</div>
                  </ProductTourButton>
                </MenuItems>
                <MenuDivider />
                {renderMenuIconsRow()}
                <MenuDivider />
                <Link to="/">
                  <LogoContent>
                    <Logo />
                    <MenuFooter version={process.env.VERSION} />
                  </LogoContent>
                </Link>
              </ExtraMenu>
            </Menu>
          </CSSTransition>
        </SwitchTransition>
      </MenuWrap>
      <Content>
        <LayoutContent ref={contentRef}>{props.children}</LayoutContent>
        <ProductTourModal />
      </Content>
      {renderBugReporter()}
    </StyledLayout>
  );
}

export default withRouter(AppLayout);
