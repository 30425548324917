import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import Icon from "components/ui/Icon";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { white, mediumLightGray } from "utils/constants/colors";

//

const Spacer = styled.div`
  background: radial-gradient(
    41.9% 100% at 50% 0%,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.3;
  height: 44px;
  width: 100%;
  margin-bottom: -44px;

  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.05);
    background: -webkit-gradient(
      linear,
      0 0,
      100% 0,
      from(rgba(255, 255, 255, 0)),
      to(rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.3))
    );
  }
`;

const SingleProject = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${white};
  height: 40px;
  font-weight: 500;
  padding-left: 11px;
`;

const PurpleTheme = styled.div`
  margin: 26px 0px 31px;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${mediumLightGray};
    padding-left: 10px;
    position: relative;
    top: 6px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-arrow {
    top: 2px;
    margin-top: 7px;
    font-size: 18px;
  }
  .anticon {
    color: ${white};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: ${white};
    height: 40px;

    .ant-select-selection__rendered {
      line-height: 38px;
      svg {
        margin-right: 17px;
      }
    }
  }
  .ant-select-focused,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
`;

const ContextDropdown = ({ selectProject, currentContext, projects }) => {
  const { t } = useTranslation();
  if (currentContext?.isAdmin) {
    return null;
  }

  function renderDropdown() {
    if (projects.length === 0 || projects.length === 1) {
      return (
        <SingleProject>{currentContext?.project?.metadata.name}</SingleProject>
      );
    }
    return (
      <Select
        value={currentContext?.guid}
        onChange={selectProject}
        dropdownClassName="sidebar-menu"
        suffixIcon={<Icon awesome={faAngleDown} />}
      >
        {projects.map(renderProject)}
      </Select>
    );
  }

  function renderProject(context) {
    return (
      <Select.Option key={context.guid} value={context.guid}>
        {context.project.metadata.name}
      </Select.Option>
    );
  }

  return (
    <PurpleTheme>
      <label>{t("Project")}</label>
      {renderDropdown()}
      <Spacer />
    </PurpleTheme>
  );
};

export default ContextDropdown;
