import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import observeHistory, {
  hookHistoryWithContext,
} from "services/history/observer";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "services/store";
import { initApp } from "state/auth/actions";
import history from "services/history";
import { initializeFullStory, updateFullStoryCookie } from "utils/fullstory";
import { enableMapSet, setAutoFreeze } from "immer";
// if (process.env.NODE_ENV !== "production") {
//   var axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

enableMapSet();
setAutoFreeze(false);

hookHistoryWithContext();

ReactDOM.render(<App />, document.getElementById("root"), async () => {
  if (history.location.pathname.startsWith("/auth")) {
    updateFullStoryCookie();
    observeHistory();
    return;
  }
  initializeFullStory();
  updateFullStoryCookie();
  await store.dispatch(initApp());

  observeHistory();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
