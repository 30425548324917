import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import Button, { TextButton } from "components/ui/Button";
import Modal from "components/ui/Modal";
import Switch from "components/common/Switch";
import StageSlider from "./StageSlider";
import productsTourImages from "assets/images/productTour";

import { productTourModal } from "state/productTour/services";
import {
  setTourNextStep,
  setTourPreviousStep,
} from "state/productTour/actions";

import { ReactComponent as Logo } from "assets/icons/logo_landscape_black.svg";
import clusterprofile_background from "assets/images/clusterprofile_background.svg";
import { ReactComponent as StackerLayers } from "assets/images/stacked_layers.svg";
import { darkGray, gray } from "utils/constants/colors";

const TourModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: normal;

  > svg {
    height: 40px;
    margin-right: 8px;
  }
`;

function Title() {
  return (
    <TitleWrapper>
      <Logo />
    </TitleWrapper>
  );
}

const FirstScreenWrapper = styled.div`
  margin: -24px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${clusterprofile_background});
  background-repeat: no-repeat;
  background-size: 100%;
`;

const WelcomeMessage = styled.div`
  font-size: 32px;
  letter-spacing: -0.01em;
  margin-bottom: 50px;
  font-weight: 300px;

  > span {
    color: #4432f5;
    font-weight: bold;
  }
`;

const Description = styled.div`
  margin-top: 50px;
  font-size: 16px;
  color: ${darkGray};
`;

const Instruction = styled.div`
  font-style: italic;
  font-size: 16px;
  color: ${gray};
  margin-top: 20px;
`;

function FirstScreen() {
  const { t } = useTranslation();

  return (
    <FirstScreenWrapper>
      <WelcomeMessage>
        {t("Welcome to")} <span>Spectro Cloud</span>
      </WelcomeMessage>
      <StackerLayers />
      <Description>
        {t(
          "The easy way to keep control of your Kubernetes infrastructure stack."
        )}
      </Description>
      <Instruction>{t("Click next to continue")}</Instruction>
    </FirstScreenWrapper>
  );
}

function SecondScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Clusters")}
      description={t("how and where you want them")}
      docsHref="https://docs.spectrocloud.com/clusters/"
      options={[
        {
          key: "clusters1",
          image: productsTourImages.clusters1,
          tab: t(
            "Deploy clusters into public clouds, private clouds, on bare metal or edge."
          ),
        },
        {
          key: "clusters2",
          image: productsTourImages.clusters2,
          tab: t("Centralized cloud accounts management."),
        },
        {
          key: "clusters3",
          image: productsTourImages.clusters3,
          tab: t(
            "Monitor clusters’ health, pack updates, events, cpu and memory usage."
          ),
        },
      ]}
    />
  );
}

function ThirdScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Cluster Profiles")}
      description={t("Flexibility and Consistency at Scale")}
      docsHref="https://docs.spectrocloud.com/cluster-profiles/"
      options={[
        {
          key: "profiles1",
          image: productsTourImages.profiles1,
          tab: t(
            "Define whole Kubernetes infrastructure stacks with declarative models."
          ),
        },
        {
          key: "profiles2",
          image: productsTourImages.profiles2,
          tab: t("Centralized cloud accounts management."),
        },
        {
          key: "profiles3",
          image: productsTourImages.profiles3,
          tab: t("Monitor cpu and memory usage as well as events."),
        },
      ]}
    />
  );
}

function ForthScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Tenant Mode")}
      description={t("Flexibility and Consistency at Scale")}
      // TODO: update if tenants docs available in the future
      docsHref="https://docs.spectrocloud.com/user-management/"
      options={[
        {
          key: "tenants1",
          image: productsTourImages.tenants1,
          tab: t("Organize clusters in projects and monitor core-hours usage."),
        },
        {
          key: "tenants2",
          image: productsTourImages.tenants2,
          tab: t(
            "Create teams to ease the role management throughout projects."
          ),
        },
        {
          key: "tenants3",
          image: productsTourImages.tenants3,
          tab: t(
            "Keep track and easily identify activities through Audit Log functionality."
          ),
        },
      ]}
    />
  );
}

function ProductTourModal({
  stepNumber,
  totalSteps,
  setTourNextStep,
  setTourPreviousStep,
}) {
  const { t } = useTranslation();

  return (
    <TourModal
      title={<Title />}
      width="700px"
      footer={[
        <Button
          key="setTourNextStep "
          data-qa="setTourNextStep"
          onClick={setTourNextStep}
        >
          {stepNumber < totalSteps ? t("Next") : t("Finish")}
        </Button>,
        stepNumber > 1 && (
          <TextButton
            secondary
            key="setTourPreviousStep"
            data-qa="setTourPreviousStep"
            onClick={setTourPreviousStep}
          >
            {t("Previous")}
          </TextButton>
        ),
      ]}
      service={productTourModal}
    >
      <Switch value={stepNumber}>
        <Switch.Case value={1}>
          <FirstScreen />
        </Switch.Case>
        <Switch.Case value={2}>
          <SecondScreen />
        </Switch.Case>
        <Switch.Case value={3}>
          <ThirdScreen />
        </Switch.Case>
        <Switch.Case value={4}>
          <ForthScreen />
        </Switch.Case>
      </Switch>
    </TourModal>
  );
}

export default connect(
  (state) => ({
    stepNumber: state.productTour.stepNumber,
    totalSteps: state.productTour.totalSteps,
  }),
  { setTourNextStep, setTourPreviousStep }
)(ProductTourModal);
