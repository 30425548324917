import dataFetcher from "modules/dataFetcher";
import ModalService from "services/modal";
import api from "services/api";

import { SSHKeysSchema } from "utils/schemas";

export const SSH_KEYS_MODULE = "sshKeys";
export const sshKeyFormModal = new ModalService("sshKeyForm");

export const sshKeyFetcher = dataFetcher({
  selectors: ["sshKeys", () => sshKeyFormModal.data?.uid || ""],
  schema: SSHKeysSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1alpha1/users/assets/sshkeys/${uid}`);
  },
});

export const sshKeysFetcher = dataFetcher({
  selectors: ["sshKeys"],
  schema: [SSHKeysSchema],
  async fetchData() {
    const response = await api.get("v1alpha1/users/assets/sshkeys");
    return response.items;
  },
});
