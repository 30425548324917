import { createBrowserHistory } from "history";
import querystring from "querystring";

// NOTE: we require the files instead of importing it to prevent
// circular dependencies store -> history -> services/modal -> store
const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    const changePageConfirm = require("utils/changePageConfirm")
      .changePageConfirm;

    changePageConfirm.open({ message }).then(() => callback(true));
  },
});

export default history;

history.getQuery = function() {
  return querystring.parse(history.location.search.replace(/^\?/g, ""));
};
