export default (task, duration = 10000) => {
  let running = false;
  let currentTask = null;

  async function runTask() {
    if (!running) {
      return;
    }

    try {
      await task();
      running = false;
    } catch (e) {
      currentTask = setTimeout(runTask, duration);
    }
  }

  return {
    start() {
      if (running) {
        return;
      }
      running = true;
      runTask();
    },
    stop() {
      running = false;
      clearTimeout(currentTask);
    },
  };
};
