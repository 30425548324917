import i18next from "i18next";
import querystring from "querystring";

import AsyncAction from "modules/asyncAction";
import store, { getStoreEntity } from "services/store";
import { deleteProfileModal } from "../services";
import { ClusterProfileSchema } from "utils/schemas";
import api from "services/api";
import notifications from "services/notifications";
import history from "services/history";

import createFormActions from "modules/form/actions";
import { profileListActions } from "state/clusterprofile/services";

export const FILTERS_MODULE = "profilesFilters";

export const deleteProfileAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const clusterprofile = getStoreEntity(guid, ClusterProfileSchema);
    const profileName = clusterprofile.metadata.name;
    const promise = api.delete(
      `v1alpha1/clusterprofiles/${clusterprofile.metadata.uid}`
    );

    store.dispatch({
      type: "DELETE_CLUSTER_PROFILE",
      promise,
    });
    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Cluster profile '{{profileName}}' was deleted successfully",
          { profileName }
        ),
      });
      history.push("/clusterprofiles");
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting {{profileName}}",
          { profileName }
        ),
        description: err.message,
      });
    }
  },
});

export function onDeleteProfile(guid) {
  return function thunk() {
    deleteProfileModal.open().then(() => {
      deleteProfileAsyncAction.key(guid).trigger();
    });
  };
}

export function getQueryFromFilters(data) {
  const { search, cloudTypes, profileTypes, sortBy, order } = data;
  const environment =
    cloudTypes && !Array.isArray(cloudTypes) ? [cloudTypes] : cloudTypes;
  const profileType =
    profileTypes && !Array.isArray(profileTypes)
      ? [profileTypes]
      : profileTypes;

  return {
    filter: {
      environment: environment || [],
      profileName: {
        beginsWith: search || "",
      },
      profileType: profileType || [],
    },
    sort: [
      {
        field: sortBy || "creationTimestamp",
        order: order || "desc",
      },
    ],
    limit: 20,
  };
}

export const filterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();

    return Promise.resolve({
      search: query?.search || "",
      cloudTypes: query?.cloudTypes || [],
      profileTypes: query?.profileTypes || [],
      order: query?.order || "desc",
      sortBy: query?.sortBy || "creationTimestamp",
    });
  },
  submit: (data) => {
    if (!store.getState().list?.clusterprofiles) {
      return;
    }
    const query = getQueryFromFilters(data);
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);

    store.dispatch(
      profileListActions.batchChangeQuery({ module: "clusterprofiles", query })
    );
  },
});

export function switchOrder() {
  return (dispatch, getState) => {
    const order = getState().forms[FILTERS_MODULE]?.data?.order;
    dispatch(
      filterFormActions.onChange({
        module: FILTERS_MODULE,
        name: "order",
        value: order === "asc" ? "desc" : "asc",
      })
    );
  };
}
