import moment from "moment";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { getCurrentUser } from "state/auth/selectors";

export const projectCPUUtilizationFetcher = dataFetcher({
  selectors: [
    "projectCores",
    (state) => getCurrentUser(state)?.status.tenant.tenantUid,
  ],
  async fetchData([_, tenantUid], query, filter) {
    let promise;
    if (filter.unit === "hours") {
      promise = api.get(`v1alpha1/tenants/${tenantUid}/usage/cpuCoreHours`, {
        startTime: moment()
          .subtract(24, "hours")
          .utc()
          .format(),
        endTime: moment()
          .utc()
          .format(),
      });
    } else {
      promise = api.get(`v1alpha1/tenants/${tenantUid}/plans/usage/monthly`, {
        startDate: query.startTime.format("YYYY-MM-DD"),
        endDate: query.endTime.format("YYYY-MM-DD"),
      });
    }

    return await promise;
  },
});

export const projectsMonthlyCoreHoursFetcher = dataFetcher({
  selectors: [
    "projectMonthlyCoreHours",
    (state) => getCurrentUser(state)?.status.tenant.tenantUid,
  ],
  async fetchData([_, tenantUid], query) {
    const promise = api.get(
      `v1alpha1/tenants/${tenantUid}/plans/usage/monthly`,
      {
        startDate: query.startTime.format("YYYY-MM-DD"),
        endDate: query.endTime.format("YYYY-MM-DD"),
      }
    );

    return await promise;
  },
});
