import { getEntity } from "utils/entities";
import { RoleSchema } from "utils/schemas";

export const getRoles = getEntity((state) => state.roles.list.items, [
  RoleSchema,
]);

export const getSelectedMemberRoles = getEntity(
  (state) => state.forms.newMembers.data?.roles,
  [RoleSchema]
);
