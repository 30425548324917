import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { getCurrentContext } from "state/auth/selectors";
import { useTranslation } from "react-i18next";

import { lightMidGray, gray, white, concrete } from "utils/constants/colors";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import Link from "components/common/History/Link";
import Title from "components/common/Title";

const Wrap = styled.div`
  background: ${white};
  height: 64px;
  margin: -64px -64px 30px -64px;
  padding: 0 64px;
  ${(props) =>
    props.hasBorder &&
    css`
      border-bottom: 1px solid ${lightMidGray};
    `}
  ${(props) =>
    !props.hasTabs &&
    css`
      padding-bottom: 80px;
    `}
  ${(props) =>
    props.hasBorder &&
    props.hasBreadcrumbs &&
    css`
      height: 74px;
    `}

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    line-height: 64px;
    padding-top: 16px;
  }
`;

const BreadcrumbWrap = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 23px;
  margin-top: 10px;
  color: ${gray};

  a {
    color: ${gray};
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderTitle = styled.div`
  display: block;
  font-size: 20px;
  line-height: 28px;
  margin-top: 0;
`;

const BackArrow = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: -38px;
  color: ${concrete};
  font-size: 20px;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function renderBreadcrumb(breadcrumb, index, breadcrumbs) {
  const isLast = index === breadcrumbs.length - 1;
  let labelElement = breadcrumb.label;
  if (breadcrumb.href) {
    labelElement = <Link to={breadcrumb.href}>{breadcrumb.label}</Link>;
  }
  return (
    <BreadcrumbWrap key={index}>
      {labelElement} {!isLast && " / "}
    </BreadcrumbWrap>
  );
}

function PageHeader({
  title,
  breadcrumbs,
  hasTabs = false,
  context,
  tabTitleContent,
}) {
  const { t } = useTranslation();
  let content = null;
  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length;
  let tabTitle = title;

  const backLink = useMemo(() => {
    if (!breadcrumbs) {
      return null;
    }

    return [...breadcrumbs].reverse().find((breadcrumb) => breadcrumb.href);
  }, [breadcrumbs]);

  function renderBackArrow() {
    if (!backLink) {
      return null;
    }

    return (
      <Link to={backLink.href}>
        <BackArrow icon={faArrowLeft} />
      </Link>
    );
  }

  if (title) {
    content = <h1>{title}</h1>;
    tabTitle = title;
  }

  if (tabTitleContent) {
    if (tabTitleContent.activeTab) {
      tabTitle = `${tabTitleContent.activeTab} | ${tabTitleContent.pageTitle}`;
    } else {
      tabTitle = tabTitleContent.pageTitle;
    }
  }

  if (hasBreadcrumbs) {
    const base = context.isAdmin
      ? t("Administration")
      : context.project?.metadata.name;
    const title = breadcrumbs.pop();
    content = (
      <>
        <Breadcrumbs>
          {[
            {
              label: base,
              href: "/",
            },
            ...breadcrumbs,
          ].map(renderBreadcrumb)}
        </Breadcrumbs>
        <TitleWrap>
          {renderBackArrow()}
          <HeaderTitle>{title.label}</HeaderTitle>
        </TitleWrap>
      </>
    );
  }

  return (
    <>
      <Title>{tabTitle}</Title>
      <Wrap
        hasBorder={!hasTabs}
        hasBreadcrumbs={hasBreadcrumbs}
        hasTabs={hasTabs}
      >
        {content}
      </Wrap>
    </>
  );
}

export default connect((state) => ({
  context: getCurrentContext(state),
}))(PageHeader);
