import api from "services/api";

import { PackRegistriesSchema, HelmRegistriesSchema } from "utils/schemas";

import ListActions from "modules/list/actions";

export const packRegistriesActions = new ListActions({
  fetchData() {
    return api.get("v1alpha1/registries/pack/summary");
  },
  schema: [PackRegistriesSchema],
});

export const helmRegistriesActions = new ListActions({
  fetchData() {
    return api.get("v1alpha1/registries/helm/summary");
  },
  schema: [HelmRegistriesSchema],
});
