import i18n from "i18next";
import { createSelector } from "reselect";
import i18next from "i18next";

import api, { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import ModalService from "services/modal";

import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ProfileStackModule from "modules/profileStack";

import { ClusterSchema } from "utils/schemas";
import { getRawCluster } from "../selectors/details";

export const onSpotWarningConfirm = new ModalService("onSpotWarning");

export const cloudAccountsFetcher = dataFetcher({
  selectors: ["credentials", (state) => state.cluster.create.selectedCloud],
  async fetchData([_, selectedCloud]) {
    const res = await api.get(`v1alpha1/cloudaccounts/${selectedCloud}`);
    return res.items;
  },
});

export const regionsFetcher = dataFetcher({
  selectors: [
    "regions",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.credential,
    (state) => state.forms.cluster.data?.subscriptionId,
  ],
  async fetchData([_, selectedCloud, credential, subscriptionId]) {
    try {
      let apiEndpoint = `v1alpha1/clouds/${selectedCloud}/regions?cloudAccountUid=${credential}`;

      if (selectedCloud === "azure") {
        apiEndpoint = `${apiEndpoint}&subscriptionId=${subscriptionId}`;
      }

      const res = await api.get(apiEndpoint);
      return res.regions;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to retrieve the required networks."
        ),
        duration: 0,
        description: err.message,
      });
    }
  },
});

export const subscriptionsFetcher = dataFetcher({
  selectors: ["subscriptions", (state) => state.forms.cluster.data?.credential],
  async fetchData([_, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/azure/subscriptions?cloudAccountUid=${credential}`
    );
    return res.subscriptionList;
  },
});

export const virtualNetworksFetcher = dataFetcher({
  selectors: [
    "virtualNetworks",
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, region, subscriptionId, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/azure/regions/${region}/subscriptions/${subscriptionId}/networks?cloudAccountUid=${credential}`
    );
    return res.virtualNetworkList;
  },
});

export const adminGOIDsFetcher = dataFetcher({
  selectors: ["adminGOIDs", (state) => state.forms.cluster.data?.credential],
  async fetchData([_, credential]) {
    try {
      const res = await api.get(
        `v1alpha1/clouds/azure/groups?cloudAccountUid=${credential}`
      );

      return res.groups;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when retrieving the admin group object IDs"
        ),
        description: error.message,
      });
    }
  },
});

export const resourceGroupsFetcher = dataFetcher({
  selectors: [
    "resourceGroups",
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, region, subscriptionId, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/azure/regions/${region}/subscriptions/${subscriptionId}/resourceGroups?cloudAccountUid=${credential}`
    );
    return res.resourceGroupList;
  },
});

function getClusterDetails(state) {
  // TODO find a better way
  if (state.router.location.pathname.includes("/nodes")) {
    return getStoreEntity(
      state.cluster.details.currentClusterId,
      ClusterSchema
    );
  }

  return null;
}

const getClusterAccountUid = createSelector(
  getClusterDetails,
  (state) => state?.forms?.cluster?.data?.credential,
  (cluster, credential) => {
    if (cluster) {
      return cluster.spec.cloudConfig.spec.cloudAccountRef.uid;
    }

    return credential;
  }
);

export const propertiesFetcher = keyedDataFetcher({
  selectors: [
    "vsphere",
    getClusterAccountUid,
    (state) => {
      const cluster = getClusterDetails(state);

      if (cluster) {
        return cluster.spec.cloudConfig.spec.clusterConfig.placement.datacenter;
      }

      return state.forms.cluster.data.datacenter;
    },
  ],
  async fetchData([_, accountUid, datacenter, computecluster]) {
    try {
      const response = api.get(
        `v1alpha1/cloudaccounts/vsphere/${accountUid}/properties/computecluster/resources?datacenter=${datacenter}&computecluster=${computecluster}`
      );
      const data = await response;
      return data.computecluster;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the properties"
        ),
        description: err?.message,
      });
    }
  },
});

export const datacentersFetcher = dataFetcher({
  selectors: ["datacenters", getClusterAccountUid],

  async fetchData([_, accountUid]) {
    try {
      const response = await api.get(
        `v1alpha1/cloudaccounts/vsphere/${accountUid}/properties/datacenters`
      );
      return response.items;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the datacenters"
        ),
        description: err?.message,
      });
    }
  },
});

export const maasDomainsFetcher = dataFetcher({
  selectors: ["domains", getClusterAccountUid],

  async fetchData([_, accountUid]) {
    // const response = await api.get(
    //   `v1alpha1/cloudaccounts/maas/${accountUid}/properties/domains`
    // );
    // return response.items;

    return [
      {
        metadata: {
          name: "maas.sc",
          uid: "maas.sc",
        },
      },
    ];
  },
});

export const projectsFetcher = dataFetcher({
  selectors: [
    "projects",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/${selectedCloud}/projects?cloudAccountUid=${credential}`
    );
    return res.projects;
  },
});

export const regionsByProjectFetcher = dataFetcher({
  selectors: [
    "regions",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.project,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, project, credential]) {
    try {
      const res = await api.get(
        `v1alpha1/clouds/${selectedCloud}/projects/${project}/regions?cloudAccountUid=${credential}`
      );
      return res.regions;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to retrieve the required networks."
        ),
        duration: 0,
        description: err.message,
      });
    }
  },
});

export const networksFetcher = dataFetcher({
  selectors: [
    "networks",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.project,
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, project, region, credential]) {
    const res = await api.get(
      `v1alpha1/clouds/${selectedCloud}/projects/${project}/regions/${region}/networks?cloudAccountUid=${credential}`
    );
    return res.networks;
  },
});

export const sshFetcher = dataFetcher({
  selectors: [
    "ssh",
    (state) => state?.cluster?.create?.selectedCloud,
    (state) => state?.forms?.cluster?.data?.region,
    (state) => state?.forms?.cluster?.data?.credential,
  ],
  async fetchData([_, selectedCloud, region, accountUid]) {
    try {
      const response = await api.get(
        `v1alpha1/clouds/${selectedCloud}/regions/${region}/keypairs?cloudAccountUid=${accountUid}`
      );
      return response;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong when trying to get the SSH keys"),
        description: err?.message,
      });
    }
  },
});

const getSelectedCloudAccount = createSelector(
  (state) => state.router.location,
  getRawCluster,
  (state) => state?.forms?.cluster?.data?.credential,
  cloudAccountsFetcher.selector,
  (location, cluster, accountUid, accounts) => {
    // TODO find a better way
    if (location.pathname.includes("/nodes")) {
      return cluster.spec.cloudConfig.spec.cloudAccount;
    }
    return accounts?.result?.find(
      (account) => account.metadata.uid === accountUid
    );
  }
);

const getSelectedCloudAccountType = createSelector(
  getSelectedCloudAccount,
  (selectedAccount) => {
    return selectedAccount?.kind;
  }
);

const getSelectedCloudAccountOverlordUid = createSelector(
  getSelectedCloudAccount,
  (selectedAccount) => {
    return selectedAccount?.metadata?.annotations?.overlordUid;
  }
);

export const ipamFetcher = dataFetcher({
  selectors: [
    "overlordPools",
    getSelectedCloudAccountType,
    getSelectedCloudAccountOverlordUid,
  ],
  async fetchData([_, type, uid]) {
    const response = await nonProjectApi.get(
      `v1alpha1/overlords/${type}/${uid}/pools`
    );

    // TODO: assuming that if there is a clusterUid then you are nodes page
    const clusterUid = store.getState().location?.params?.id;

    return response.items.filter((pool) => {
      if (pool.spec.restrictToSingleCluster === false) {
        return true;
      }

      if (!pool.status.inUse) {
        return true;
      }

      if (clusterUid) {
        return pool.status.associatedClusters.includes(clusterUid);
      }

      return false;
    });
  },
});

export const profileModule = new ProfileStackModule({
  name: "cluster-create",
});
