import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { CLUSTERS } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const Create = React.lazy(() => import("./create"));
const Details = React.lazy(() => import("./details"));
const Certificates = React.lazy(() => import("./certificates"));

export default createNamespace({
  namespace: "clusters",
  Component: function Main() {
    return (
      <>
        <Route exact path={CLUSTERS.ROOT} component={Index} />
        <Route exact path={CLUSTERS.CREATE_CLUSTER} component={Create} />
        <Route exact path={CLUSTERS.CLUSTER_DETAILS} component={Details} />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.TAB_DETAILS}
          component={Details}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.SCAN_DETAILS}
          component={Details}
        />
        <Route
          adminEnabled
          path={CLUSTERS.CERTIFICATES}
          component={Certificates}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.IPAM_DNS_DETAILS}
          component={Details}
        />
      </>
    );
  },
});
