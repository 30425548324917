import { createSelector } from "reselect";
import i18n from "i18next";
import { getEntity } from "utils/entities";
import { generatePath } from "react-router";
import {
  UserSchema,
  ProjectPermissionsSchema,
  ProjectSchema,
} from "utils/schemas";
import * as PATHS from "utils/constants/routes";
import { getClustersLink } from "state/cluster/selectors/list";
import { getClusterProfilesLink } from "state/clusterprofile/selectors/list";

import { faUserLock, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ClusterProfileIcon } from "assets/icons/clusterprofiles.svg";
import { ReactComponent as ClustersIcon } from "assets/icons/clusters.svg";
import { ReactComponent as AuditLogsIcon } from "assets/icons/audit_logs.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/project_settings.svg";
import { ReactComponent as AdminProjectsIcon } from "assets/icons/admin_projects.svg";

import permissionsService from "services/permissions";
import flags from "services/flags";

export const ADMIN_GUID = "ADMIN_VIEW";

const TENANT_BLACKLIST = ["dashboard", "clusters"];
const PROJECT_BLACKLIST = ["projects", "management", "roles", "overlords"];

export const getUserProjects = getEntity((state) => state.auth.projects, [
  ProjectPermissionsSchema,
]);

export const getCurrentUser = getEntity((state) => state.auth.me, UserSchema);

export const getUserContexts = createSelector(
  (state) => state.auth.permissions,
  getUserProjects,
  (permissions, projects) => {
    let updatedProjects = [...projects];
    if (permissions.length) {
      updatedProjects = [
        { guid: ADMIN_GUID, projectUid: ADMIN_GUID },
        ...updatedProjects,
      ];
    }

    return updatedProjects;
  }
);

export const getCurrentProject = getEntity((state) => {
  return state.auth.currentProjectId;
}, ProjectPermissionsSchema);

export const getBackToProject = getEntity(
  (state) =>
    state.auth.projects.includes(state.auth.backToProjectUid)
      ? state.auth.backToProjectUid
      : null,
  ProjectPermissionsSchema
);

export const getCurrentContext = createSelector(
  getCurrentProject,
  (state) => state.auth.currentProjectId,
  (currentProject, currentProjectId) => {
    if (currentProjectId === ADMIN_GUID) {
      return { guid: ADMIN_GUID, isAdmin: true };
    }

    return currentProject;
  }
);

export const getAllPermissions = createSelector(
  (state) => state.auth.permissions,
  getCurrentContext,
  (permissions, currentContext) => {
    let contextPermissions = currentContext?.permissions || [];
    if (currentContext?.isAdmin) {
      contextPermissions = permissions;
    }
    if (permissions.length) {
      return [...contextPermissions, "ADMIN"];
    }

    return contextPermissions;
  }
);

export const getAllProjects = getEntity((state) => state.auth.allProjects, [
  ProjectSchema,
]);

export const getProjectsDropdownData = createSelector(
  getAllProjects,
  (projects) => {
    const items = projects?.map((project) => ({
      label: project.metadata.name,
      value: project.metadata.uid,
    }));

    return [{ label: "All", value: "all" }, ...items];
  }
);

// TODO: add permissions for audit
export const getUserMenu = createSelector(
  getCurrentContext,
  () => permissionsService.perms,
  getClustersLink,
  getClusterProfilesLink,
  (currentContext, permissions, clustersLink, clusterProfilesLink) => {
    const menuItems = [
      {
        key: "projects",
        path: PATHS.PROJECTS.ROOT,
        permissions: [
          "project.get",
          "project.list",
          "project.edit_info",
          "project.delete",
          "project.roles.get",
          "project.roles.list",
          "project.roles.create",
        ],
        component: AdminProjectsIcon,
        flags: [],
        label: () => i18n.t("Projects"),
      },
      {
        key: "clusterprofiles",
        path: clusterProfilesLink,
        permissions: [
          "clusterProfile.create",
          "clusterProfile.edit",
          "clusterProfile.list",
          "clusterProfile.get",
          "clusterProfile.publish",
        ],
        component: ClusterProfileIcon,
        flags: [],
        label: () => i18n.t("Cluster Profiles"),
      },
      {
        key: "clusters",
        path: clustersLink,
        permissions: ["cluster.create", "cluster.list", "cluster.get"],
        component: ClustersIcon,
        flags: [],
        label: () => i18n.t("Clusters"),
      },
      {
        key: "roles",
        path: PATHS.ROLES.ROOT,
        permissions: ["role.list"],
        awesome: faUserLock,
        flags: ["beta"],
        label: () => i18n.t("Roles"),
      },
      {
        key: "management",
        path: generatePath(PATHS.MANAGEMENT.ROOT, { tab: "users" }),
        permissions: ["user.list"],
        awesome: faUserFriends,
        flags: ["beta"],
        label: () => i18n.t("Users & Teams"),
      },
      {
        key: "auditlogs",
        path: PATHS.AUDIT.ROOT,
        permissions: ["audit.get", "audit.list"],
        component: AuditLogsIcon,
        flags: ["audit"],
        label: () => i18n.t("Audit Logs"),
      },
      {
        key: "settings",
        path: PATHS.SETTINGS.CLOUD_ACCOUNTS,
        permissions: ["cloudaccount.list"],
        component: SettingsIcon,
        theme: "filled",
        flags: [],
        label: () =>
          currentContext?.isAdmin
            ? i18n.t("Admin Settings")
            : i18n.t("Project Settings"),
      },
    ];

    return menuItems.filter((item) => {
      const projectBlacklisted =
        !currentContext?.isAdmin && PROJECT_BLACKLIST.includes(item.key);

      if (projectBlacklisted) {
        return false;
      }

      const tenantBlacklisted =
        currentContext?.isAdmin && TENANT_BLACKLIST.includes(item.key);

      if (tenantBlacklisted) {
        return false;
      }

      if (!permissionsService.has(item.permissions)) {
        return false;
      }

      return true;
    });
  }
);

export const getTenantPlanType = createSelector(
  (state) => state.auth?.plan,
  (plan) => {
    return plan?.spec?.type;
  }
);

export const getTenantPlanName = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    const planTypes = {
      Trial: () => i18n.t("Trial"),
      MonthlyOnDemand: () => i18n.t("Monthly on demand"),
      AnnualSubscription: () => i18n.t("Annual subscription"),
    };

    return plan?.spec?.type ? planTypes[plan?.spec?.type] : "";
  }
);

export const canUpgradePlan = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    return permissionsService.isAdmin && plan?.spec?.type === "Trial";
  }
);

export const getApplicationEnv = (state) => state.auth.organization.appEnv;
export const isOnPremApplication = createSelector(getApplicationEnv, (appEnv) =>
  ["quick-start", "enterprise", "airgap"].includes(appEnv)
);

export const restrictOnPremPublicClouds = createSelector(
  isOnPremApplication,
  () => flags.has("enable-onprem-public-clouds"),
  (isOnPrem, hasFlag) => isOnPrem && !hasFlag
);
