import uuid from "uuid";
import { PACK_STEPS } from "utils/constants/clusterprofile";

export const OS_GUID = uuid();
export const ADD_GUID = uuid();

export const LAYERS = [
  {
    type: "csi",
    guid: uuid(),
    config: null,
  },
  {
    type: "cni",
    guid: uuid(),
    config: null,
  },
  {
    type: "k8s",
    guid: uuid(),
    config: null,
  },
  {
    type: "os",
    guid: OS_GUID,
    config: null,
  },
];

const SIDEBAR_STEPS = [...LAYERS, { type: "new", guid: ADD_GUID }].reduce(
  (accumulator, layer) => {
    accumulator[layer.guid] = PACK_STEPS[layer.type];
    return accumulator;
  },
  {}
);

export const initialState = {
  sidebarStep: Object.keys(SIDEBAR_STEPS).reduce((accumulator, key) => {
    accumulator[key] = SIDEBAR_STEPS[key][0];
    return accumulator;
  }, {}),
  packTypes: [],
  repositories: [],
  treeSearchValue: "",
  selectedRepository: null,
  versions: [],
  editorNotifications: [],
  layers: [],
  layerTypes: [
    { type: "load balancer" },
    { type: "ingress" },
    { type: "logging" },
    { type: "monitoring" },
    { type: "security" },
    { type: "authentication" },
    { type: "servicemesh" },
    { type: "registry" },
    { type: "system app" },
  ],
  selectedClusterProfilePacks: [],
  isEdit: false,
  expandedKeys: [],
  validatingPacks: false,
  validationErrors: [],
};

export function FORM_LOAD_REQUEST(nextState, { module }) {
  if (module === "clusterprofile") {
    nextState.validationErrors = [];
  }
}

export function SET_PACK_STEP(nextState, { step }, { selectedLayer }) {
  nextState.sidebarStep[selectedLayer] = step;
}

export function SELECT_PACK_VERSION(nextState, { tag }) {
  nextState.selectedPackVersion = tag;
}

export function DESELECT_PACK_VERSION(nextState) {
  nextState.selectedPackVersion = initialState.selectedPackVersion;
}

export function FETCH_PACK_VERSIONS_REQUEST(nextState, { selectedPackName }) {
  nextState.isSidebarLoading = true;
  nextState.selectedPackName = selectedPackName;
}

export function FETCH_PACK_VERSIONS_NORMALIZATION(nextState, { result }) {
  nextState.versions = result;
}

export function FETCH_PACK_VERSIONS_SUCCESS(nextState) {
  nextState.isSidebarLoading = false;
  nextState.treeSearchValue = "";
}

export function FETCH_PACK_VERSIONS_FAILURE(nextState) {
  nextState.isSidebarLoading = false;
}

export function SELECT_LAYER(nextState, { selectedLayer }) {
  nextState.selectedLayer = selectedLayer;
  nextState.selectedLayerType = initialState.selectedLayerType;
  nextState.selectedPackName = initialState.selectedPackName;
  nextState.selectedPackVersion = initialState.selectedPackVersion;
  nextState.versions = initialState.versions;
}

export function SELECT_LAYER_TYPE(nextState, { selectedLayerType }) {
  nextState.selectedLayerType = selectedLayerType;
  nextState.selectedLayer = initialState.selectedLayer;
}

export function ADD_LAYER(nextState, { guid, layerType }, state) {
  nextState.sidebarStep[guid] = PACK_STEPS[layerType][0];
  nextState.selectedLayer = guid;
  nextState.editorNotifications.push(guid);
}

function getPreferredRepository(repositories) {
  return (
    repositories.find((repo) => repo.spec.name === "Public Repo") ||
    repositories[0]
  )?.guid;
}

export function SET_AVAILABLE_REPOSITORIES(
  nextState,
  { availableRepositories }
) {
  nextState.availableRepositories = availableRepositories;
  nextState.selectedRepository = getPreferredRepository(availableRepositories);
}

export function LAYER_SET_DESCRIPTION(
  nextState,
  { description, currentLayer },
  state
) {
  const layerIdx = state.layers.findIndex(
    (layer) => layer.guid === currentLayer
  );
  nextState.layers[layerIdx].description = description;
}

export function UPDATE_SELECTED_REPOSITORY(nextState, { repository }) {
  nextState.selectedRepository = repository;
}

export function FETCH_SELECTED_CLUSTER_PROFILE_PACKS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.selectedClusterProfilePacks = [...result];
}

export function SET_CLUSTER_PROFILE_EDIT(nextState, { isEdit }) {
  nextState.isEdit = isEdit;
}

export function SET_VERSION_EXPANDED_KEYS(nextState, { expandedKeys }) {
  nextState.expandedKeys = expandedKeys;
}

export function VERSION_TREE_SEARCH(nextState, { treeSearchValue }) {
  nextState.treeSearchValue = treeSearchValue;
}

export function ACKNOWLEDGE_EDITOR_NOTIFICATIONS(
  nextState,
  { layerGuid },
  state
) {
  const index = state.editorNotifications.findIndex(
    (guid) => guid === layerGuid
  );
  if (index !== -1) {
    nextState.editorNotifications.splice(index, 1);
  }
}
