/*
 copied from https://github.com/dtschust/redux-bug-reporter/blob/master/src/store-enhancer.js
 */
/* eslint-disable */

import { cloneDeep } from "lodash";
import history from "services/history";
import { stopListening } from "services/history/observer";

export const overloadStoreActionType = "REDUX_BUG_REPORTER_OVERLOAD_STORE";
const initializePlaybackActionType = "REDUX_BUG_REPORTER_INITIALIZE_PLAYBACK";
const finishPlaybackActionType = "REDUX_BUG_REPORTER_FINISH_PLAYBACK";

function isRouteChangeAction(action) {
  return (
    action.type === "@@router/LOCATION_CHANGE" &&
    action?.payload?.action !== "REPLACE"
  );
}

export const playbackFlag = "REDUX_BUG_REPORTER_PLAYBACK";

const isClientRender = () => typeof window !== "undefined";

export const overloadStore = function(payload) {
  return {
    type: overloadStoreActionType,
    payload,
  };
};

export const initializePlayback = function() {
  return {
    type: initializePlaybackActionType,
  };
};

export const finishPlayback = function(payload) {
  return {
    type: finishPlaybackActionType,
  };
};
let storeEnhancer = (f) => f;
if (isClientRender()) {
  storeEnhancer = (createStore) => (
    originalReducer,
    initialState,
    enhancer
  ) => {
    middlewareData.playbackEnabled = false;
    // Handle the overloading in the reducer here
    let reducer = function(state, action = {}) {
      if (action.type === overloadStoreActionType) {
        console.warn(
          "Overriding the store. You should only be doing this if you are using the bug reporter"
        );
        return action.payload;
      } else if (action.type === initializePlaybackActionType) {
        // starting playback
        middlewareData.playbackEnabled = true;
        return state;
      } else if (action.type === finishPlaybackActionType) {
        // stopping playback
        middlewareData.playbackEnabled = false;
        return state;
      }

      // Log the action
      if (isClientRender() && !middlewareData.playbackEnabled) {
        let actions = middlewareData.getActions();
        // If this is the first action, log the initial state
        if (actions.length === 0) {
          middlewareData.setBugReporterInitialState(state);
        }

        if (isRouteChangeAction(action)) {
          const locationChanges = actions.filter(isRouteChangeAction);
          if (locationChanges.length === 2) {
            const latestLocationChangeIndex = actions.indexOf(
              locationChanges[1]
            );
            middlewareData.actions.splice(0, latestLocationChangeIndex);
            middlewareData.setBugReporterInitialState(state);
          }
        }

        // Potentially redact any sensitive data in the action payload
        if (action.meta && action.meta.redactFromBugReporter) {
          let redactedAction = cloneDeep(action);
          let meta = redactedAction.meta;
          if (meta.redactFromBugReporterFn) {
            redactedAction = meta.redactFromBugReporterFn(redactedAction);

            // clean up the redaction flags
            delete redactedAction.meta.redactFromBugReporter;
            delete redactedAction.meta.redactFromBugReporterFn;
          } else {
            // if there's no redactFromBugReporterFn, remove everything except the event type
            redactedAction = { type: redactedAction.type };
          }
          middlewareData.addAction(redactedAction);
        } else {
          middlewareData.addAction(action);
        }
      }

      // Remove the playback flag from the payload
      if (action[playbackFlag]) {
        delete action[playbackFlag];
      }

      return originalReducer(...arguments);
    };
    let store = createStore(reducer, initialState, enhancer);
    let origDispatch = store.dispatch;
    middlewareData.clearActions();
    middlewareData.setBugReporterInitialState({});

    // wrap around dispatch disable all non-playback actions during playback
    let dispatch = function(action) {
      // Allow overload and finishPlayback actions
      if (
        action &&
        action.type &&
        (action.type === overloadStoreActionType ||
          action.type === finishPlaybackActionType)
      ) {
        return origDispatch(...arguments);
      }
      if (middlewareData.playbackEnabled && !action[playbackFlag]) {
        // ignore the action
        return;
      }

      return origDispatch(...arguments);
    };

    return {
      ...store,
      dispatch,
    };
  };
}

export let middlewareData = {
  playbackEnabled: false,
  actions: [],
  bugReporterInitialState: {},
  addAction: function(action) {
    this.actions.push(action);
  },
  clearActions: function() {
    this.actions = [];
  },
  getActions: function() {
    return this.actions;
  },
  setBugReporterInitialState: function(state) {
    this.bugReporterInitialState = state;
  },
  getBugReporterInitialState: function() {
    return this.bugReporterInitialState;
  },
};

export function generateBugReportedContent(extraInfo = {}) {
  const actions = middlewareData.getActions();
  const initialState = middlewareData.getBugReporterInitialState();
  return JSON.stringify({ actions, initialState, extraInfo });
}

export function downloadBugReport(content = generateBugReportedContent()) {
  let blob = new Blob([content], { type: "text/text" });

  let elem = window.document.createElement("a");
  elem.href = window.URL.createObjectURL(blob);
  elem.download = `reduxLogs-${new Date().toString()}`;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}

export function bootstrapPlayback(store) {
  const dispatch = store.dispatch;
  window.bugReporterPlayback = ({delay = 100} = {}) => {
    let elem = window.document.createElement("input");
    elem.type = "file";
    elem.click();
    elem.addEventListener("change", (ev) => {
      var reader = new FileReader();
      reader.addEventListener("load", (ev) => {
        let content;
        try {
          content = JSON.parse(ev.target.result);
        } catch (err) {}
        if (!content) {
          console.warn("Error reading the file");
          return;
        }
        const { actions, initialState, extraInfo } = content;
        stopListening();
        initializePlayback();
        overloadStore(initialState);
        const performNextAction = () => {
          let action = actions[0];

          // Let store know this is a playback action
          action[playbackFlag] = true;

          if (isRouteChangeAction(action)) {
              history.push(action.payload.location);
          }

          dispatch(action);
          actions.splice(0, 1);
          if (actions.length > 0) {
            setTimeout(performNextAction, delay);
          } else {
            finishPlayback();
            console.warn("Playback complete!", extraInfo);
          }
        };

        performNextAction();
      });
      reader.readAsText(ev.target.files[0]);
    });
  };
}

export default storeEnhancer;
