import uuid from "uuid/v4";
import api from "services/api";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import * as YAML from "yaml";

import {
  PackSchema,
  RepositorySchema,
  HelmRepositorySchema,
  PackVersionSchema,
  ManifestSchema,
  HelmRegistriesSchema,
} from "utils/schemas";
import { createContext } from "react";
import i18next from "i18next";
import notifications from "services/notifications";
import Validator from "services/validator";
import { generateEditorYamlSchema } from "utils/presenters";
import { ApplyIf } from "services/validator/rules";

export const ProfileBuilderContext = createContext();

export const MODULES = {
  PACK_MODULE: "layerPack",
  IMPORT_MODULE: "importedPacks",
  MANIFEST_MODULE: "manifest",
  PACKS_EDITOR_MODULE: "packsEditor",
  PACK_LIST_MODULE: "packsList",
};

export const FORM_TYPES = {
  PACK: MODULES.PACK_MODULE,
  IMPORT: MODULES.IMPORT_MODULE,
  MANIFEST: MODULES.MANIFEST_MODULE,
};

export const clusterPacksFetcher = keyedDataFetcher({
  selectors: ["selectedCluster"],
  async fetchData([_, selectedClusterId]) {
    try {
      const response = await api.get(
        `v1alpha1/spectroclusters/${selectedClusterId}/features/helmCharts`
      );
      return response.charts?.reduce((acc, layer) => {
        return [
          ...acc,
          {
            guid: uuid(),
            type: "helmChart",
            isDraft: true,
            formType: MODULES.PACK_MODULE,
            matchedRegistries: layer.matchedRegistries,
            config: {
              registryUid:
                layer.matchedRegistries.length === 1
                  ? layer.matchedRegistries[0]?.uid
                  : null,
              name: layer.name,
              packUid: layer.uid,
              uid: layer.uid,
              tag: layer.version,
              values: layer.values || "",
              selectedPresets: [],
              schema: [],
              manifests: [],
            },
          },
        ];
      }, []);
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the helm charts"
        ),
        description: e.message,
      });
    }
  },
});

export const manifestsPresetsFetcher = dataFetcher({
  selectors: ["packManifests"],
  schema: [ManifestSchema],
  async fetchData() {
    const payload = [
      {
        metadata: {
          uid: uuid(),
          name: "manifest1",
        },
        spec: {
          value: "manifest 1 random test",
          single: true,
        },
      },
      {
        metadata: {
          uid: uuid(),
          name: "manifest2",
        },
        spec: {
          value: "manifest 2 random txt as values",
          single: true,
        },
      },
      {
        metadata: {
          uid: uuid(),
          name: "manifest3",
        },
        spec: {
          value: "manifest 3 some random text as value",
          single: false,
        },
      },
    ];

    const promise = new Promise((res) => setTimeout(res, 300, payload));
    const data = await promise;
    return data;
  },
});

export const repositoriesFetcher = dataFetcher({
  selectors: ["repositories"],
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1alpha1/registries/pack");
    return response?.items || [];
  },
});

export const helmRepositoriesFetcher = dataFetcher({
  selectors: ["helmRepositories"],
  schema: [HelmRepositorySchema],
  async fetchData() {
    const response = await api.get("v1alpha1/registries/helm");
    return response?.items || [];
  },
});

export const packsListSearchFetcher = dataFetcher({
  selectors: [
    "packsListSearch",
    (state) => state.forms.clusterprofile?.data?.cloudType,
    (state) => state.forms.layerPack?.data?.packType,
    (state) => state.forms.layerPack?.data?.repository,
  ],
  async fetchData(
    [_, cloudType, layerType, registryUid],
    { limit, continue: continueToken, search }
  ) {
    const isAddon = !["os", "cni", "csi", "k8s"].includes(layerType);
    const layer = isAddon ? "addon" : layerType;
    const filter = {
      displayName: {
        beginsWith: search,
      },
      matchCaseInsensitive: true,
      type: [layerType === "helmChart" ? "helm" : "spectro"],
      layer: [layer],
      environment: [isAddon ? "all" : cloudType],
      addOnType: isAddon ? [layerType] : undefined,
      registryUid: [registryUid],
    };
    const sort = [
      {
        field: "displayName",
        order: "asc",
      },
    ];

    const response = await api.post(
      `v1alpha1/packs/search?limit=${limit}${
        continueToken ? `&continue=${continueToken}` : ""
      }`,
      { filter, sort }
    );

    const items =
      response?.items?.sort((packA, packB) => {
        const packADisabled =
          packA.spec?.registries?.[0]?.annotations?.disabled === "true";
        const packBDisabled =
          packB.spec?.registries?.[0]?.annotations?.disabled === "true";
        return packADisabled - packBDisabled;
      }) || [];

    return { items, cloudType };
  },
});

export const packVersionsFetcher = dataFetcher({
  selectors: [
    "packVersions",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackVersionSchema],
  async fetchData([_, cloudType], { repositoryUid, packName, layerType }) {
    const isBasicLayer = ["os", "cni", "csi", "k8s"].includes(layerType);

    const promise = api.get(
      `v1alpha1/packs/${packName}/registries/${repositoryUid}?cloudType=${
        isBasicLayer ? cloudType : "all"
      }&layer=${layerType}`
    );

    const response = await promise;

    return response?.tags.map((tag) => ({
      ...tag,
      logoUrl: response.logoUrl,
      name: response.name,
    }));
  },
});

export const clustersFetcher = dataFetcher({
  selectors: ["selectedClusterId"],
  async fetchData(_, { search = "" } = {}) {
    const filters = {
      filter: {
        name: {
          beginsWith: search,
        },
        state: "Running",
        isImported: true,
      },
      sort: "name",
    };

    const response = await api.post(
      `v1alpha1/dashboard/spectroclusters/metadata`,
      filters
    );

    return response?.items || [];
  },
});

export const helmRegistryFetcher = dataFetcher({
  selectors: ["helmRegistryList"],
  async fetchData() {
    const response = await api.get("v1alpha1/registries/helm/summary");
    return response.items;
  },
  schema: [HelmRegistriesSchema],
});

export const packValuesFetcher = dataFetcher({
  selectors: [
    "packValues",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackSchema],
  async fetchData(_, packUid) {
    const response = await api.get(`v1alpha1/packs/${packUid}`);
    return (
      response?.packValues?.map(({ packUid, ...spec }) => ({
        metadata: { uid: packUid },
        spec,
      })) || []
    );
  },
});

export const packListActions = new ListActions({
  debounceDelay: 200,
  dataFetcher: packsListSearchFetcher,
  schema: [PackSchema],
  initialQuery: () => ({
    limit: 20,
    search: "",
  }),
});

async function validateYamlSchema(value, _, data) {
  const schema = generateEditorYamlSchema(data.schema, {
    ignorePasswords: false,
  });
  const yamlFiles = value.split("\n---\n");

  const Ajv = (await import("ajv/dist/2019")).default;
  const ajv = new Ajv({ strict: false });

  const errors = [];
  for (const file of yamlFiles) {
    try {
      const valuesAsJson = YAML.parse(file);
      if (schema) {
        const validate = ajv.compile(schema);
        const isSchemaCompliant = validate(valuesAsJson);
        if (!isSchemaCompliant) {
          errors.push("YAML values don't match the provided schema");
        }
      }
    } catch (err) {
      errors.push(`${data.name}: Invalid YAML configuration`);
    }
  }

  return errors.length ? errors[0] : false;
}

export const configValidator = new Validator();
export const manifestsValidator = new Validator();

configValidator.addRule(
  "values",
  ApplyIf((value, key, data) => data.type !== "manifest", validateYamlSchema)
);
configValidator.addRule("manifests", manifestsValidator);

manifestsValidator.addRule("content", validateYamlSchema);

export default {
  clustersFetcher,
  clusterPacksFetcher,
  helmRegistryFetcher,
  packVersionsFetcher,
  packsListSearchFetcher,
  repositoriesFetcher,
  helmRepositoriesFetcher,
  manifestsPresetsFetcher,
  packValuesFetcher,
};
