import i18n from "i18next";
import baremetal from "assets/images/environments/baremetal.png";
import azure from "assets/images/environments/azure.png";
import aws from "assets/images/environments/aws.png";
import google_cloud from "assets/images/environments/google_cloud.png";
import vmware from "assets/images/environments/vmware.png";
import maas from "assets/images/environments/maas.png";
import openshift from "assets/images/environments/openshift.png";
import openstack from "assets/images/environments/openstack.png";
import small_vm from "assets/images/environments/small_vm.png";
import small_aws from "assets/images/environments/small_aws.png";
import small_azure from "assets/images/environments/small_azure.png";
import small_google from "assets/images/environments/small_google.png";
import small_maas from "assets/images/environments/small_maas.png";
import small_openshift from "assets/images/environments/small_openshift.png";
import small_openstack from "assets/images/environments/small_openstack.png";
import small_baremetal from "assets/images/environments/baremetal.png";

import OS_ICON from "assets/icons/os_layer.svg";
import K8S_ICON from "assets/icons/k8s_layer.svg";
import CNI_ICON from "assets/icons/cni_layer.svg";
import CSI_ICON from "assets/icons/csi_layer.svg";
import LOAD_BALANCER_ICON from "assets/icons/load_balancer_layer.svg";
import SECURITY_ICON from "assets/icons/security_layer.svg";
import LOGGING_ICON from "assets/icons/logging_layer.svg";
import INGRESS_ICON from "assets/icons/ingress_layer.svg";
import MONITORING_ICON from "assets/icons/monitoring_layer.svg";
import AUTHENTICATION_ICON from "assets/icons/authentication_layer.svg";
import SYSTEM_APP_ICON from "assets/icons/system_app_layer.svg";
import SERVICE_MESH_ICON from "assets/icons/service_mesh_layer.svg";
import REGISTRY_ICON from "assets/icons/registry_layer.svg";
import HELM_CHART_ICON from "assets/icons/helm_chart_layer.svg";
import MANIFEST_ICON from "assets/icons/manifest.svg";

import {
  cyan,
  yellow,
  green,
  marine,
  concrete,
  darkOrange,
  dodgerBlue,
} from "utils/constants/colors";
import flags from "services/flags";

export const MANAGED_TO_PURE_ENVIRONMENT = {
  eks: "aws",
  aks: "azure",
  gke: "gcp",
};

export const AWS_ENVIRONMENT = {
  logoPath: aws,
  name: "Amazon AWS",
  apiKey: "aws",
  smallLogoPath: small_aws,
};

export const BAREMETAL_ENVIRONMENT = {
  logoPath: baremetal,
  smallLogoPath: small_baremetal,
  name: "Bare Metal",
  apiKey: "baremetal",
  disabled: true,
  comingSoon: true,
};

export const AZURE_ENVIRONMENT = {
  logoPath: azure,
  name: "Azure",
  apiKey: "azure",
  smallLogoPath: small_azure,
  disabled: !flags.has("azure"),
};

export const GOOGLE_CLOUD_ENVIRONMENT = {
  logoPath: google_cloud,
  name: "Google Cloud",
  apiKey: "gcp",
  smallLogoPath: small_google,
  disabled: !flags.has("gcp"),
};

export const VMWARE_ENVIRONMENT = {
  logoPath: vmware,
  name: "VMware",
  apiKey: "vsphere",
  smallLogoPath: small_vm,
};

export const MAAS_ENVIRONMENT = {
  logoPath: maas,
  name: "Maas",
  apiKey: "maas",
  smallLogoPath: small_maas,
};

export const OPENSHIFT_ENVIRONMENT = {
  logoPath: openshift,
  name: "OpenShift",
  apiKey: "openshift",
  smallLogoPath: small_openshift,
  comingSoon: true,
  disabled: true,
};

export const EKS_ENVIRONMENT = {
  logoPath: aws,
  name: "EKS",
  apiKey: "eks",
  smallLogoPath: small_aws,
  isSupervised: true,
};
export const AKS_ENVIRONMENT = {
  logoPath: azure,
  name: "AKS",
  apiKey: "aks",
  smallLogoPath: small_azure,
  isSupervised: true,
  disabled: !flags.has("aks"),
};

export const GKE_ENVIRONMENT = {
  logoPath: google_cloud,
  name: "GKE",
  apiKey: "gke",
  smallLogoPath: small_google,
  isSupervised: true,
  disabled: !flags.has("gke"),
};

export const OPENSTACK_ENVIRONMENT = {
  logoPath: openstack,
  name: "Openstack",
  apiKey: "openstack",
  smallLogoPath: small_openstack,
  disabled: !flags.has("openstack"),
};

export const ENVIRONMENTS = [
  AWS_ENVIRONMENT,
  VMWARE_ENVIRONMENT,
  AZURE_ENVIRONMENT,
  GOOGLE_CLOUD_ENVIRONMENT,
  OPENSTACK_ENVIRONMENT,
  MAAS_ENVIRONMENT,
  OPENSHIFT_ENVIRONMENT,
  BAREMETAL_ENVIRONMENT,
  EKS_ENVIRONMENT,
  AKS_ENVIRONMENT,
  GKE_ENVIRONMENT,
];

const CSI_LAYER = {
  type: "csi",
  title: () => i18n.t("Storage"),
  color: cyan,
  icon: CSI_ICON,
};
const CNI_LAYER = {
  type: "cni",
  title: () => i18n.t("Network"),
  color: yellow,
  icon: CNI_ICON,
};
export const KUBERNETES_LAYER = {
  type: "k8s",
  title: () => i18n.t("Kubernetes"),
  color: concrete,
  icon: K8S_ICON,
};
const OS_LAYER = {
  type: "os",
  title: () => i18n.t("OS"),
  color: darkOrange,
  icon: OS_ICON,
};

const LOAD_BALANCER_LAYER = {
  type: "load balancer",
  title: () => i18n.t("Load balancer"),
  color: yellow,
  isDeletable: true,
  icon: LOAD_BALANCER_ICON,
};
export const INGRESS_LAYER = {
  type: "ingress",
  title: () => i18n.t("Ingress"),
  color: yellow,
  isDeletable: true,
  icon: INGRESS_ICON,
};
const LOGGING_LAYER = {
  type: "logging",
  title: () => i18n.t("Logging"),
  color: green,
  isDeletable: true,
  icon: LOGGING_ICON,
};
const MONITORING_LAYER = {
  type: "monitoring",
  title: () => i18n.t("Monitoring"),
  color: green,
  isDeletable: true,
  icon: MONITORING_ICON,
};
const SECURITY_LAYER = {
  type: "security",
  title: () => i18n.t("Security"),
  color: marine,
  isDeletable: true,
  icon: SECURITY_ICON,
};
const AUTHENTICATION_LAYER = {
  type: "authentication",
  title: () => i18n.t("Authentication"),
  color: marine,
  isDeletable: true,
  icon: AUTHENTICATION_ICON,
};
const SYSTEM_LAYER = {
  type: "system app",
  title: () => i18n.t("System App"),
  color: darkOrange,
  isDeletable: true,
  icon: SYSTEM_APP_ICON,
};

const SERVICE_MESH_LAYER = {
  type: "servicemesh",
  title: () => i18n.t("Service Mesh"),
  color: cyan,
  isDeletable: true,
  icon: SERVICE_MESH_ICON,
};

const REGISTRY_LAYER = {
  type: "registry",
  title: () => i18n.t("Registry"),
  color: cyan,
  isDeletable: true,
  icon: REGISTRY_ICON,
};

export const HELM_CHART_LAYER = {
  type: "helmChart",
  title: () => i18n.t("Helm Chart"),
  color: concrete,
  isDeletable: true,
  icon: HELM_CHART_ICON,
};

const MANIFEST_LAYER = {
  type: "manifest",
  title: () => i18n.t("Manifest"),
  color: "#FF5722",
  isDeletable: true,
  icon: MANIFEST_ICON,
};

const SERVERLESS_LAYER = {
  type: "serverless",
  title: () => i18n.t("Serverless"),
  color: dodgerBlue,
  isDeletable: true,
  icon: SERVICE_MESH_ICON,
};

export const LAYERS = [
  OS_LAYER,
  CSI_LAYER,
  CNI_LAYER,
  KUBERNETES_LAYER,
  LOAD_BALANCER_LAYER,
  INGRESS_LAYER,
  LOGGING_LAYER,
  MONITORING_LAYER,
  SECURITY_LAYER,
  AUTHENTICATION_LAYER,
  SERVICE_MESH_LAYER,
  SYSTEM_LAYER,
  REGISTRY_LAYER,
  HELM_CHART_LAYER,
  MANIFEST_LAYER,
  SERVERLESS_LAYER,
];

export const SPECTRO_TAG = "spectro__tag";

export const ENV_COLORS = {
  aws: "#ff9900",
  vsphere: "#717075",
};

export const PASSWORD_UPDATE_POLICY = [
  {
    label: "3 months",
    value: 90,
  },
  { label: "6 months", value: 183 },
  { label: "Custom", value: "Custom" },
];

export const UPDATE_STRATEGIES = [
  {
    label: "Expand First",
    value: "RollingUpdateScaleOut",
  },
  {
    label: "Contract First",
    value: "RollingUpdateScaleIn",
  },
];

export const CLUSTER_PROFILE_TYPES = [
  {
    label: "Full",
    value: "cluster",
    "data-qa-value": "full",
  },
  {
    label: "Infrastructure",
    value: "infra",
    "data-qa-value": "infra",
  },
  {
    label: "Add-on",
    value: "add-on",
    "data-qa-value": "addon",
  },
];

export const SPECTRO_FOLDER_PREFIX = "spc-";

export const ADDON_LAYERS = [
  LOAD_BALANCER_LAYER,
  INGRESS_LAYER,
  LOGGING_LAYER,
  MONITORING_LAYER,
  SECURITY_LAYER,
  AUTHENTICATION_LAYER,
  SERVICE_MESH_LAYER,
  SYSTEM_LAYER,
  // SERVERLESS_LAYER,
  REGISTRY_LAYER,
  HELM_CHART_LAYER,
];

export const BEGINNING_OF_TIME = "0001-01-01T00:00:00.000Z";

export const DEFAULT_SCHEDULE_OPTIONS = [
  {
    label: () => i18n.t("Every week on Sunday at midnight"),
    value: "0 0 * * SUN",
  },
  {
    label: () => i18n.t("Every two weeks at midnight"),
    value: "0 0 1,15 * *",
  },
  {
    label: () => i18n.t("Every month on the 1st at midnight"),
    value: "0 0 1 * *",
  },
  {
    label: () => i18n.t("Every two months on the 1st at midnight"),
    value: "0 0 1 */2 *",
  },
  {
    label: () => i18n.t("Never"),
    value: "never",
  },
  {
    label: () => i18n.t("Custom"),
    value: "custom",
  },
];

export const DOWNLOAD_LOGS_OPTIONS = [
  {
    label: () => i18n.t("Kube-system logs"),
    value: "kubeSystem",
  },
  {
    label: () => i18n.t("Spectro Cloud logs"),
    value: "spectroCloud",
  },
  { label: () => i18n.t("Nodes logs"), value: "nodes" },
];

export const EXPIRY_PERIODS = [
  {
    label: () => i18n.t("1 month"),
    value: 730,
  },
  {
    label: () => i18n.t("2 months"),
    value: 1460,
  },
  { label: () => i18n.t("3 months"), value: 2190 },
  { label: () => i18n.t("Custom"), value: "custom" },
];

export const FOUR_MONTHS_IN_HOURS = 2920;
