import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import store from "services/store";

import { ProjectRolesSchema } from "utils/schemas";

import Modal from "components/ui/Modal";
import { TextButton } from "components/ui/Button";
import Form from "./components/Form";
import RoleCard from "./components/RoleCard";

import createFormActions from "modules/form/actions";
import { getAllProjects } from "state/auth/selectors";
import {
  getSelectedProjectRoleEntity,
  getCurrentProjectRoles,
  getSelectedTeam,
  getSelectedUser,
} from "state/roleManagement/selectors";

import { createForm } from "modules/form";
import createList, { Blocks } from "modules/list";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import {
  projectRolesModal,
  onProjectRolesRemove,
} from "state/roleManagement/actions/projectRoles";

import { getFullName } from "utils/presenters";
import { winterMist } from "utils/constants/colors";

const AddRoleWrap = styled.div`
  border-width: 1px 0;

  &:last-child {
    border-width: 0 0 1px 0;
  }

  border-style: solid;
  border-color: ${winterMist};
  padding: 11px;
  margin: 0 -20px;
`;

const AddRoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const Title = styled.span`
  text-transform: uppercase;
`;

const Button = styled(TextButton)`
  text-align: right;
  min-width: 63px;
`;

export default function createProjectRoleManagement({
  listActions,
  updateData,
}) {
  const moduleName = "projectRoles";
  const projectRolesValidator = new Validator();
  projectRolesValidator.addRule(["projectUid", "roles"], Missing());

  const formActions = createFormActions({
    validator: projectRolesValidator,
    submit: async (data) => {
      await updateData(data);
      store.dispatch(listActions.initialize(moduleName));
    },
    init: () => {
      const selectedProjectRole = getSelectedProjectRoleEntity(
        store.getState()
      );

      return Promise.resolve({
        projectUid: selectedProjectRole?.project.metadata.uid || null,
        roles: selectedProjectRole?.roles.map((role) => role.guid),
      });
    },
  });

  const ConnectedForm = connect((state) => {
    const projects = getAllProjects(state);
    const selectedProjectRoleId =
      state.modal.data[projectRolesModal.guid]?.selectedProjectRoleId;
    const userProjectRoles = getCurrentProjectRoles(state);
    const parsedProjects = projects
      .filter(
        (project) =>
          selectedProjectRoleId ||
          !userProjectRoles.some(
            (projectRole) => projectRole.project.guid === project.guid
          )
      )
      .map((project) => ({
        value: project.metadata.uid,
        label: project.metadata.name,
      }));

    return {
      projects: parsedProjects,
      selectedProjectRoleId,
    };
  })(Form);

  const ProjectRolesForm = createForm({
    Component: ConnectedForm,
    actions: formActions,
  });

  function openModal(selectedProjectRoleId) {
    return (dispatch) => {
      projectRolesModal.open({ selectedProjectRoleId }).then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });

      dispatch(formActions.init({ module: moduleName }));
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [ProjectRolesSchema],
  });

  const ConnectedProjectRolesCard = connect(null, (dispatch) => ({
    openModal: (guid) => () => dispatch(openModal(guid)),
    onRemove: (guid) => () => dispatch(onProjectRolesRemove(guid)),
  }))(RoleCard);

  function Item({ data }) {
    return <ConnectedProjectRolesCard projectRole={data} />;
  }

  function ProjectRolesModule({ openModal, selectedEntity }) {
    const { t } = useTranslation();

    return (
      <>
        <AddRoleWrap>
          <AddRoleHeader>
            <Title>{t("project roles")}</Title>
            <Button onClick={() => openModal(null)} data-qa="add_project_role">
              {t("Add Role")}
            </Button>
          </AddRoleHeader>
          <ListModule module={moduleName}>
            <Blocks.List Item={Item} />
          </ListModule>
        </AddRoleWrap>
        <Modal
          service={projectRolesModal}
          title={t("Add Roles to {{name}}", {
            name: selectedEntity.metadata.name || getFullName(selectedEntity),
          })}
        >
          <ProjectRolesForm module={moduleName} />
        </Modal>
      </>
    );
  }

  return connect(
    (state) => ({
      selectedEntity:
        state.roleManagement.entityType === "user"
          ? getSelectedUser(state)
          : getSelectedTeam(state),
    }),
    {
      openModal,
    }
  )(ProjectRolesModule);
}
