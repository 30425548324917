import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";

import { createForm } from "modules/form";

import { loginActions } from "state/auth/actions";
import { getCurrentUser } from "state/auth/selectors";

import LoginLayout from "components/common/layouts/Auth";
import PasswordAuth from "./flows/PasswordAuth";
import TwoStepAuth from "./flows/TwoStepAuth";
import MigrationSuccessful from "./flows/MigrationSuccessful";

import { white } from "utils/constants/colors";

import Loader from "components/ui/Loader";
import history from "services/history";

const InactivityMessage = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LoginForm({ organization, isInactive, migrationStatus, ...rest }) {
  const { t } = useTranslation();

  if (!organization) {
    return <TwoStepAuth {...rest} />;
  }

  if (
    organization.appEnv === "quick-start" &&
    migrationStatus?.status?.endpoint
  ) {
    return <MigrationSuccessful endpoint={migrationStatus.status.endpoint} />;
  }

  if (organization.authType === "password") {
    return <PasswordAuth {...rest} />;
  }

  if (["saml", "oidc"].includes(organization.authType)) {
    return (
      <StyledLink
        className="button"
        href={organization.redirectUrl}
        data-qa="inactive-relogin-sso"
      >
        {t("Sign in")}
      </StyledLink>
    );
  }

  return <TwoStepAuth {...organization} {...rest} />;
}

const Form = createForm({
  Component: connect((state) => ({
    organization: state.auth.organization,
    migrationStatus: state.auth.migrationStatus,
  }))(LoginForm),
  actions: loginActions,
});

function Login({ isLoading, step, currentUser }) {
  const [displayMask, setDisplayMask] = useState(false);
  const isInactive = !!currentUser && history.getQuery().inactive === "true";

  function renderContent() {
    if (isLoading) {
      return <Loader color={white} />;
    }

    return (
      <Form
        module="login"
        isInactive={isInactive}
        onPasswordFocus={setDisplayMask}
      />
    );
  }

  function renderInactivityWarning() {
    if (!isInactive || step !== "email") {
      return null;
    }

    return (
      <InactivityMessage>
        <Trans>
          You have been disconnected due to inactivity. Please sign back in
        </Trans>
      </InactivityMessage>
    );
  }
  return (
    <LoginLayout displayMask={displayMask}>
      {renderInactivityWarning()}
      {renderContent()}
    </LoginLayout>
  );
}

export default connect((state) => ({
  step: state.auth.step,
  isLoading: state.auth.orgLoading,
  organization: state.auth.organization,
  currentUser: getCurrentUser(state),
}))(Login);
