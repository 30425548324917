import store from "services/store";
import i18next from "i18next";

import createFormActions from "modules/form/actions";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import api from "services/api";
import notifications from "services/notifications";

import {
  DEFAULT_SCHEDULE_OPTIONS,
  EXPIRY_PERIODS,
  FOUR_MONTHS_IN_HOURS,
} from "utils/constants";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";

import { backupStatusesFetcher, fetchBackupStatuses } from "../services";
import { getScheduledBackupPayload } from "../selectors";

const scheduleBackupsValidator = new Validator();
scheduleBackupsValidator.addRule(
  ["location", "backupPrefix", "expiryPeriod"],
  Missing()
);

export const scheduleBackupsFormAction = createFormActions({
  init: async () => {
    let data;
    await store.dispatch(fetchBackupLocationsFetcher.fetch());
    if (store.getState().location?.params?.id) {
      data = await store.dispatch(fetchBackupStatuses());
    }
    const config = data?.spec?.config || {};
    const scheduledRunTime = config.schedule?.scheduledRunTime;
    const isCustomSchedule = !DEFAULT_SCHEDULE_OPTIONS.find(
      (option) => option.value === scheduledRunTime
    );
    const expiryPeriod =
      EXPIRY_PERIODS.find((period) => period.value === config.durationInHours)
        ?.value || "custom";

    const getPeriodOption = () => {
      if (config.durationInHours) {
        return expiryPeriod;
      }
      return EXPIRY_PERIODS[0].value;
    };

    const getCustomHours = () => {
      if (config.durationInHours && expiryPeriod === "custom") {
        return config.durationInHours;
      }
      return FOUR_MONTHS_IN_HOURS;
    };

    const getScheduleOption = () => {
      if (!scheduledRunTime) {
        return "never";
      }
      if (isCustomSchedule) {
        return "custom";
      }
      return scheduledRunTime;
    };

    return Promise.resolve({
      location: config.backupLocationUid || null,
      schedule: getScheduleOption(),
      occurrence: isCustomSchedule ? scheduledRunTime : undefined,
      backupPrefix: config.backupPrefix || "",
      includeAllDisks: config.includeAllDisks,
      includeClusterResources: config.includeClusterResources,
      namespaces: config.namespaces || [],
      expiryPeriod: getPeriodOption(),
      expiryHours: getCustomHours(),
    });
  },
  validator: scheduleBackupsValidator,
  submit: async (data) => {
    const payload = getScheduledBackupPayload(data);
    const clusterUid = store.getState().location.params.id;
    const backupUid = backupStatusesFetcher.selector(store.getState())?.result
      ?.metadata?.uid;

    let promise;
    if (backupUid) {
      promise = api.put(
        `v1alpha1/spectroclusters/${clusterUid}/features/backup`,
        payload
      );
    } else {
      promise = api.post(
        `v1alpha1/spectroclusters/${clusterUid}/features/backup`,
        payload
      );
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: backupUid
          ? i18next.t("Something went wrong while editing the backup schedule")
          : i18next.t(
              "Something went wrong while creating the backup schedule"
            ),
        description: error.message,
      });
      return Promise.reject();
    }
    notifications.success({
      message: backupUid
        ? i18next.t("Backup schedule was updated successfully")
        : i18next.t("Backup schedule was created successfully"),
    });
  },
});
