import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as list from "./list";
import * as details from "./details";
import * as create from "./create";
import * as nodes from "./nodes";

export default combineReducers({
  list: createReducer(list),
  details: createReducer(details),
  create: createReducer(create),
  nodes: createReducer(nodes),
});
