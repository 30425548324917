import i18next from "i18next";
import { createMaasFormFactory } from "modules/cluster/maas";
import createFormActions from "modules/form/actions";
import api from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { getOverlordData } from "state/overlord/selectors/configure";
import { Missing } from "services/validator/rules";
import {
  overlordConfigureModal,
  OVERLORD_ACCOUNT_MODULE,
  OVERLORD_CLOUD_MODULE,
  setOverlordNodesModal,
} from "state/overlord/services/list";
import { fetchOverlordConfiguration } from "state/overlord/actions/configure";

const maasOverlordAccountValidator = new Validator();
const maasOverlordCloudValidator = new Validator();

maasOverlordAccountValidator.addRule(["apiKey", "apiEndpoint"], Missing());

export const maasOverlordAccountFormActions = createFormActions({
  validator: maasOverlordAccountValidator,
  init: async () => {
    const overlordUid = store.getState().location?.params?.uid;
    if (overlordUid) {
      await store.dispatch(fetchOverlordConfiguration(overlordUid));
    }

    const overlordData = getOverlordData(store.getState());
    const cloudAccountUid = overlordData?.spec?.cloudAccountUid;

    if (!cloudAccountUid) {
      return Promise.resolve({
        name: overlordData?.metadata?.name || "new-cloud-gateway",
        apiEndpoint: "",
        apiKey: "",
        shareWithProjects: true,
      });
    }

    const data = await api.get(
      `v1alpha1/cloudaccounts/maas/${cloudAccountUid}`
    );

    return Promise.resolve({
      name: data.metadata.name,
      ...data.spec,
      shareWithProjects: data.metadata.annotations.scopeVisibility === "20",
    });
  },
  submit: async function thunk(data) {
    const overlordData = getOverlordData(store.getState());
    const payload = {
      account: {
        apiEndpoint: data.apiEndpoint,
        apiKey: data.apiKey.trim(),
      },
      name: data.name,
      shareWithProjects: data.shareWithProjects,
    };

    let promiseMethod = api.post;

    if (overlordData?.spec?.cloudAccountUid) {
      promiseMethod = api.put;
    }

    const promise = promiseMethod(
      `v1alpha1/overlords/maas/${overlordConfigureModal.data.uid}/account`,
      payload
    );

    store.dispatch({
      type: "CONFIGURE_OVERLORD_ACCOUNT",
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to create maas cloud account"
        ),
        description: err?.message,
      });
      return;
    }
  },
});

maasOverlordCloudValidator.addRule(["sshKeys"], (value, key, data) => {
  const filteredValues = value.filter(Boolean);

  if (filteredValues.length > 0) {
    return false;
  }

  return Missing()(filteredValues, key, data);
});

maasOverlordCloudValidator.addRule(
  ["domain", "resourcePool", "subnet", "azs"],
  Missing()
);

export const maasOverlordCloudFormActions = createFormActions({
  validator: maasOverlordCloudValidator,
  init: async () => {
    let overlordData = getOverlordData(store.getState());
    const spectroClusterUid = overlordData?.spec?.spectroClusterUid;

    await store.dispatch(fetchOverlordConfiguration(overlordData.metadata.uid));
    overlordData = getOverlordData(store.getState());

    if (!spectroClusterUid) {
      return Promise.resolve({
        domain: "",
        resourcePool: "",
        subnet: "",
        azs: [],
        sshKeys: [""],
        size: 1,
      });
    }

    const cluster = await api.get(
      `v1alpha1/spectroclusters/${spectroClusterUid}`
    );

    const cloudConfig = await api.get(
      `v1alpha1/cloudconfigs/${cluster.spec.cloudConfigRef.kind}/${cluster.spec.cloudConfigRef.uid}`
    );

    const clusterConfig = cloudConfig?.spec?.clusterConfig;
    const machineConfig = cloudConfig?.spec?.machinePoolConfig?.[0];

    return Promise.resolve({
      name: overlordData.metadata.name,
      ...clusterConfig,
      domain: clusterConfig?.domain || "",
      resourcePool: clusterConfig?.resourcePool || "",
      subnet: clusterConfig?.subnet || "",
      azs: machineConfig?.azs || [],
      sshKeys: machineConfig?.sshKeys || [],
      size: machineConfig?.size,
      mode: "configure-edit",
    });
  },
  submit: async (data) => {
    const payload = {
      clusterConfig: {
        domain: data.domain,
        sshKeys: (data?.sshKeys || []).map((sshKey) => sshKey.trim()),
      },
      machineConfig: {
        azs: data.azs,
        resourcePool: data.resourcePool,
      },
      size: data.size,
    };

    const overlordData = getOverlordData(store.getState());
    let promiseMethod = api.post;
    if (overlordData?.spec?.spectroClusterUid) {
      promiseMethod = api.put;
    }

    const promise = promiseMethod(
      `v1alpha1/overlords/maas/${overlordConfigureModal.data?.uid ||
        setOverlordNodesModal.data?.uid}/cloudconfig`,
      payload
    );

    store.dispatch({
      type: "CREATE_OVERLORD_MAAS_CLOUD_CONFIG",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Private cloud gateway configuration saved successfully"
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "We could not save the cloud config for the overlord"
        ),
        description: err.message,
      });
    }

    return promise;
  },
});

export const maasOverlordCloudForm = createMaasFormFactory(
  {
    formActions: maasOverlordCloudFormActions,
    formModuleName: OVERLORD_CLOUD_MODULE,
    getCloudAccountUid(state) {
      return getOverlordData(state).spec.cloudAccountUid;
    },
  },
  { isOverlord: true }
);

export const maasOverlordAccountForm = createMaasFormFactory(
  {
    formActions: maasOverlordAccountFormActions,
    formModuleName: OVERLORD_ACCOUNT_MODULE,
  },
  { isOverlord: true }
);
