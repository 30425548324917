import React from "react";
import styled from "styled-components";
import Button from "components/ui/Button";
import { pageWidthConstrain } from "components/styled/mixins";

//

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 0;
  top: -83px;
  ${pageWidthConstrain}
  ${Button} {
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2),
      inset 0px -1px 0px rgba(0, 0, 0, 0.2);
    height: 40px;
    font-weight: 500;
    font-size: 15px;
  }
`;

export default function PageToolbar({ children }) {
  return <Wrap>{children}</Wrap>;
}
