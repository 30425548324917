import React from "react";
import styled, { css } from "styled-components";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { gold, white } from "utils/constants/colors";
import Icon from "components/ui/Icon";

const COLORS = {
  warning: "#d04863",
  default: gold,
};

const Label = styled.div`
  position: relative;
  height: 24px;
  background-color: ${(props) => COLORS[props.type]};
  color: ${white};
  padding: 0 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${(props) =>
    props.hasAction &&
    css`
      cursor: pointer;
    `}

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: -6px;
    border-left: 7px solid ${(props) => COLORS[props.type]};
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
  }

  span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.05em;
    white-space: nowrap;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  font-size: 15px;
`;

export default function ArrowLabel({ type, label, onClick }) {
  const isWarning = type === "warning";
  const hasAction = typeof onClick === "function";

  return (
    <Label type={type} hasAction={hasAction} onClick={onClick}>
      {isWarning && <StyledIcon awesome={faExclamationCircle} />}
      <span>{label}</span>
    </Label>
  );
}
