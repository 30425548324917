import store from "services/store";
import i18next from "i18next";
import moment from "moment";
import React from "react";

import {
  ClusterSchema,
  CertificateSchema,
  ClusterProfileSchema,
  CloudAccountSchema,
} from "utils/schemas";
import {
  pollNodes,
  pollClusterStatus,
  pollClusterNotifications,
  pollClusterLogStatus,
} from "utils/tasks";

import history from "services/history";
import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import Validator from "services/validator";
import { includeMastersCache } from "services/localstorage/cache";
import { Missing } from "services/validator/rules";

import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { getCurrentUser } from "state/auth/selectors";

import { fetchClusterCloudConfig } from "./nodes";

import ListActions from "modules/list/actions";

import {
  getCluster,
  getUpdateNotification,
  getRawCluster,
  getClusterProfileParams,
  isClusterActive,
  isClusterProvisioning,
  isEventConfirmed,
  getClusterProfileTemplate,
  getUpdateNotificationProfiles,
  isBrownfield,
  getClusterDivergencies,
} from "state/cluster/selectors/details";
import {
  utilizationFetcher,
  gaugeFetcher,
  clusterCertificatesFetcher,
  profileModule,
} from "state/cluster/services";
import {
  parseYAMLValues,
  getDefaultPresetsFromValues,
  getPackValuesWithoutPresetsComment,
} from "utils/parsers";
import { getEntity } from "utils/entities";
import { DOWNLOAD_LOGS_OPTIONS } from "utils/constants";
import { TextButton } from "components/ui/Button";
import { updatesFormAction } from "state/cluster/services";

export const CERTS_MODULE = "certificates";

//

export function onClusterProfileConfigurationSave() {
  return async function thunk(dispatch, getState) {
    const clusterUid = getState().location.params.id;

    try {
      await dispatch(validateEditClusterProfilePacks(clusterUid));
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to save the configuration"
        ),
        description: err?.message,
      });
      return;
    }

    const { packsValidationErrors } = store.getState().cluster.details;

    if (packsValidationErrors.length > 0) {
      notifications.warning({
        message:
          "One of the packs has validation errors. Please fix them in order to finish the configuration",
      });
      return;
    }

    const promise = api.put(`v1alpha1/spectroclusters/${clusterUid}/profiles`, {
      profiles: profileModule.payload,
    });

    dispatch({
      type: "CONFIGURE_CLUSTER_PROFILES",
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "An error occured while trying to update the profiles."
        ),
        description: err.message,
      });
      return;
    }
    notifications.withAudit({
      message: i18next.t("Cluster profiles were updated successfully"),
      promise,
    });

    dispatch(fetchClusterProfile());

    return promise;
  };
}

export const clusterConfigFormActions = createFormActions({
  init() {
    const params = getClusterProfileParams(store.getState()).reduce(
      (packAccumulator, pack) => {
        return {
          ...packAccumulator,
          [pack.guid]: {
            values: getPackValuesWithoutPresetsComment(pack.values),
            presets: getDefaultPresetsFromValues(pack.values),
          },
        };
      },
      {}
    );
    return Promise.resolve(params);
  },
  submit: () => store.dispatch(onClusterProfileConfigurationSave()),
});

function validateEditClusterProfilePacks(clusterUid) {
  return function thunk(dispatch) {
    const promise = api
      .post(`v1alpha1/spectroclusters/${clusterUid}/validate/packs`, {
        profiles: profileModule.payload,
      })
      .then((res) => {
        const packsErrors = res.profiles.map((profile) => ({
          results: profile?.packs?.results,
          uid: profile.uid,
        }));
        profileModule.actions.setLayersErrors(packsErrors);
        return packsErrors;
      });

    dispatch({
      type: "VALIDATE_EDIT_CLUSTER_PROFILE_PACKS",
      promise,
    });

    return promise;
  };
}

export function selectTab(selectedTab) {
  const { key, url } = selectedTab;

  return (dispatch) => {
    dispatch({
      type: "CLUSTER_DETAILS_NEXT_TAB",
      activeKey: key,
    });

    history.push(url);
  };
}

export function fetchCluster(uid) {
  return function thunk(dispatch) {
    const getClusterPromise = api.get(`v1alpha1/spectroclusters/${uid}`);
    dispatch({
      promise: getClusterPromise,
      type: "FETCH_CLUSTER",
      schema: ClusterSchema,
    });
    return getClusterPromise;
  };
}

export function fetchClusterStatus(uid) {
  return function thunk(dispatch) {
    const promise = api
      .get(`v1alpha1/dashboard/spectroclusters/${uid}`)
      .then((data) => ({
        metadata: { ...data.metadata, labels: data.metadata?.labels || null },
        status: { ...data.status },
      }));

    return dispatch({
      promise,
      type: "FETCH_CLUSTER_STATUS",
      schema: ClusterSchema,
    });
  };
}

export function fetchClusterNotifications() {
  return async (dispatch, getState) => {
    const state = getState();
    const cluster = getCluster(state);
    const isActive = isClusterActive(state) || isClusterProvisioning(state);

    if (!isActive) {
      return;
    }

    await dispatch({
      type: "FETCH_CLUSTER_NOTIFICATIONS",
      promise: api
        .get(
          `v1alpha1/notifications/spectrocluster/${cluster.metadata.uid}?isDone=false`
        )
        .then((res) => {
          return {
            metadata: cluster.metadata,
            notifications: res.items,
          };
        }),
      schema: ClusterSchema,
    });

    const isAcknowledged = isEventConfirmed(state);
    if (isAcknowledged) {
      pollClusterNotifications.stop();
      return;
    }
  };
}

export function fetchResolvedValues(uid) {
  return function(dispatch) {
    const promise = api
      .get(`v1alpha1/spectroclusters/${uid}/packs/resolvedValues`)
      .then((res) => res.profiles)
      .catch((err) =>
        notifications.error({
          message: i18next.t(
            "An error occured while trying to get the resolved values."
          ),
          description: err.message,
        })
      );

    dispatch({
      type: "FETCH_RESOLVED_VALUES",
      promise,
      schema: ClusterSchema,
    });

    return promise;
  };
}

export function fetchClusterProfile() {
  return async function(dispatch, getState) {
    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
    profileModule.actions.initialize({ profiles: [] });

    let cluster = getRawCluster(getState());
    const getClusterPromise = api
      .get(`v1alpha1/spectroclusters/${cluster.metadata.uid}`)
      .then((data) => ({
        metadata: {
          uid: data.metadata.uid,
        },
        spec: {
          clusterProfileRef: data.spec.clusterProfileRef,
          clusterProfileTemplates: data.spec.clusterProfileTemplates,
        },
      }));
    dispatch({
      promise: getClusterPromise,
      type: "REFRESH_CLUSTER_PACKS",
      schema: ClusterSchema,
    });

    await getClusterPromise;
    cluster = getRawCluster(getState());

    const resolvedValues = await dispatch(
      fetchResolvedValues(cluster.metadata.uid)
    );

    const profilesPromise = api.get(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}/profiles?includePackMeta=schema,presets`
    );
    dispatch({
      promise: profilesPromise,
      type: "FETCH_CLUSTER_BUNDLE",
      schema: { profiles: [ClusterProfileSchema] },
    });

    await profilesPromise;
    const profiles = getClusterProfileTemplate(getState());

    profileModule.actions.initialize({ profiles });

    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE_SUCCESS" });
    profileModule.actions.updateResolvedValues(resolvedValues);

    const redirectSelectedPack = getState().location.params.layerUid;
    if (redirectSelectedPack) {
      profiles.forEach((profile) => {
        const foundPack = profile.spec?.published?.packs.find(
          (pack) => pack?.metadata.uid === redirectSelectedPack
        );

        if (!foundPack) {
          return;
        }

        if (foundPack.spec.type === "manifest") {
          profileModule.actions.onManifestSelect({
            manifestGuid: foundPack.manifests?.[0].guid,
            layerGuid: foundPack.guid,
            profileGuid: profile.guid,
            editMode: true,
            isAttachedManifest: false,
          });
          return;
        }

        profileModule.actions.selectLayer(foundPack.guid);

        return;
      });
    }
  };
}

export function getClusterByUid(uid) {
  return async (dispatch, getState) => {
    let cluster = getCluster(getState());

    if (cluster?.metadata?.uid === uid) {
      pollClusterNotifications.start();
      pollClusterStatus.start();
      return;
    }

    await dispatch(fetchCluster(uid));
    await dispatch(fetchClusterCloudConfig());

    cluster = getCluster(getState());
    if (
      cluster.spec.cloudType === "vsphere" &&
      cluster.spec.cloudConfig?.spec?.cloudAccountRef?.uid
    ) {
      dispatch({
        type: "FETCH_CLOUD_ACCOUNT",
        promise: api.get(
          `v1alpha1/cloudaccounts/vsphere/${cluster.spec.cloudConfig.spec.cloudAccountRef.uid}`
        ),
        schema: CloudAccountSchema,
      });
    }

    pollClusterStatus.start();
    pollClusterNotifications.start();
  };
}

export function getClusterAfterEdit(uid) {
  return async (dispatch) => {
    dispatch(getClusterByUid(uid));
  };
}

export const clusterUpdateConfirm = new ModalService("clusterUpdate");

export function openClusterUpdateModal() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const notification = getUpdateNotification(state);
    const cluster = getRawCluster(state);
    const clusterName = cluster.metadata.name;
    if (!notification) {
      return;
    }

    clusterUpdateConfirm.open().then(
      async () => {
        const profiles = getUpdateNotificationProfiles(getState());
        const formData = getState().forms["cluster-update-notification"].data;

        const payload = profiles.map((profile) => ({
          uid: profile.metadata.uid,
          packs: profile.spec.packs.map((pack) => {
            let manifests = (pack.manifests || []).map((manifest) => ({
              name: manifest.name,
              content: formData[manifest.guid],
            }));

            return {
              name: pack.metadata.name,
              values: formData[pack.guid],
              manifests,
            };
          }),
        }));
        try {
          await api.patch(`v1alpha1/${notification.action.link}`, {
            profiles: payload,
          });
          notifications.success({
            message: i18next.t(
              "Updates applied to the {{clusterName}} cluster",
              { clusterName }
            ),
            description: i18next.t("Cluster will update shortly"),
          });
          dispatch(fetchClusterNotifications());
          dispatch(fetchClusterProfile());
          pollNodes.start();
        } catch (err) {
          notifications.open({
            message: i18next.t(
              "Something went wrong when trying to confirm this notification"
            ),
            description: err.message,
          });

          return Promise.reject();
        }
      },
      async () => {
        await api.patch(
          `v1alpha1/notifications/${notification.metadata.uid}/ack`
        );
      }
    );

    await dispatch(
      updatesFormAction.init({ module: "cluster-update-notification" })
    );
    const notificationProfiles = getUpdateNotificationProfiles(getState());
    function isPackSelectable(pack) {
      return !pack?.event?.type?.includes("Delete");
    }
    const selectableProfile = notificationProfiles.find((profile) => {
      return profile.spec.packs.some(isPackSelectable);
    });

    if (!selectableProfile) {
      return;
    }
    const selectablePack = selectableProfile.spec.packs.find(isPackSelectable);
    if (!selectablePack) {
      return;
    }

    dispatch(notificationSelectLayer(selectableProfile, selectablePack));
  };
}

export function setCurrentPackToEdit(guid) {
  return {
    type: "SET_CURRENT_PACK_TO_EDIT",
    guid,
  };
}

export const deleteClusterModal = new ModalService("deleteCluster");
export const deleteClusterAsyncAction = new AsyncAction({
  promise: async () => {
    const cluster = getRawCluster(store.getState());
    const clusterName = cluster.metadata.name;
    const promise = api.delete(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}`
    );
    store.dispatch({
      type: "DELETE_CLUSTER_PROFILE",
      promise,
    });

    try {
      await promise;
      pollClusterStatus.start();
      notifications.open({
        message: i18next.t(
          "Deletion of cluster '{{clusterName}}' is in progress",
          {
            clusterName,
          }
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting {{clusterName}}",
          { clusterName }
        ),
        description: err.message,
      });
    }
  },
});

export function onDeleteCluster() {
  return function thunk() {
    deleteClusterModal.open().then(() => {
      deleteClusterAsyncAction.trigger();
    });
  };
}

export function onMetricsTimePeriodChange(time) {
  return function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());

    const periods = {
      "30 minutes": 1,
      "1 hours": 5,
      "6 hours": 20,
      "12 hours": 40,
      "24 hours": 80,
      "1 weeks": 560,
      "1 months": 2400,
    };

    const timeFilter = time.split(" ");

    dispatch({
      type: "METRICS_TIME_PERIOD_CHANGE",
      result: {
        time: timeFilter[0],
        unit: timeFilter[1],
        period: periods[time],
      },
    });

    let query = {
      startTime: moment()
        .subtract(...timeFilter)
        .utc()
        .format(),
      endTime: moment()
        .utc()
        .format(),
      period: periods[time],
    };

    dispatch(utilizationFetcher.fetch(cluster.metadata.uid, query));
  };
}

export function togglePoolMetrics() {
  return function thunk(dispatch, getState) {
    dispatch({
      type: "TOGGLE_GAUGE_METRICS",
    });

    const state = getState();
    const cluster = getRawCluster(state);
    const includeMasters = state.cluster.details.includeMasters;
    const currentUser = getCurrentUser(getState());

    includeMastersCache.set(currentUser.metadata.uid, includeMasters);

    dispatch(getClusterMetrics(cluster.metadata.uid));
  };
}

export function getGaugeMetrics() {
  return function thunk(dispatch, getState) {
    const state = getState();
    const cluster = getRawCluster(state);
    const shouldIncludeMasters = state.cluster.details.includeMasters;

    const gaugeQuery = {
      discrete: true,
      startTime: moment()
        .subtract(10, "minutes")
        .utc()
        .format(),
      endTime: moment()
        .utc()
        .format(),
    };

    dispatch(
      gaugeFetcher.fetch(
        cluster.metadata.uid,
        {
          ...gaugeQuery,
          metricKind: "cpuRequest,cpuTotal,memoryRequest,memoryTotal",
        },
        shouldIncludeMasters
      )
    );
  };
}

export function getClusterMetrics(clusterId) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const shouldIncludeMasters = state.cluster.details.includeMasters;
    const { time, unit, period } = state.cluster.details.metricsFilters;

    const chartQuery = {
      includeMasterMachines: shouldIncludeMasters,
      startTime: moment()
        .subtract(time, unit)
        .utc()
        .format(),
      endTime: moment()
        .utc()
        .format(),
      period: period,
    };

    dispatch(getGaugeMetrics());
    dispatch(
      utilizationFetcher.fetch(clusterId, {
        ...chartQuery,
        metricKind: "cpuUsage,cpuTotal,memoryUsage,memoryTotal",
      })
    );
  };
}

export const renewCertificatesService = new ModalService("renewCertificates");

export const certsListActions = new ListActions({
  dataFetcher: clusterCertificatesFetcher,
  schema: [CertificateSchema],
});

export function onRenewAllCerts() {
  return function thunk(dispatch, getState) {
    renewCertificatesService.open().then(async () => {
      const cluster = getCluster(getState());
      dispatch({ type: "RENEW_ALL_CERTIFICATES" });

      try {
        await api.patch(
          `v1alpha1/spectroclusters/${cluster.metadata.uid}/k8certificates/renew`
        );
      } catch (err) {
        notifications.open({
          message: i18next.t(
            "Something went wrong while trying to renew the certificates"
          ),
          description: err.message,
        });

        return;
      }

      notifications.open({
        message: i18next.t("Certificates renewal will begin shortly"),
      });

      dispatch(certsListActions.initialize(CERTS_MODULE));
    });
  };
}

// FIXME: this action is not sent to the editor anymore
// Decide if it needs cleaning up or integrate it in the profile stack module

export function onPresetsChange({ value, group, presets }) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const packGuid = state.cluster?.details?.currentPackGuid;
    const pack = state.forms?.clusterConfig?.data?.[packGuid] || {};
    const selectedPresets = presets.find(
      (preset) => preset.group === group && preset.value === value
    );

    const updatedValues =
      parseYAMLValues(pack.values, selectedPresets) || pack.values;
    const updatedPresets = {
      ...(pack.presets || {}),
      [group]: value,
    };

    dispatch(
      clusterConfigFormActions.batchChange({
        module: "clusterConfig",
        updates: {
          [packGuid]: {
            ...pack,
            values: updatedValues,
            presets: updatedPresets,
          },
        },
      })
    );
  };
}

export const LOGS_MODULE = "downloadLogs";
export const downloadLogsModal = new ModalService("downloadLogs");
export const onDemandUpdateModal = new ModalService("onDemandUpdate");

const logsValidator = new Validator();
logsValidator.addRule(["logs"], Missing());

export const downloadLogsFormActions = createFormActions({
  init() {
    const brownfield = isBrownfield(store.getState());
    const logs = DOWNLOAD_LOGS_OPTIONS.map((log) => log.value);
    return Promise.resolve({
      logs: brownfield ? logs.filter((log) => log !== "nodes") : logs,
    });
  },
  validator: logsValidator,
  async submit(data) {
    const cluster = getCluster(store.getState());
    const clusterUid = cluster?.metadata?.uid;
    const kubeSystemLogs = data.logs.includes("kubeSystem");
    const spectroCloudLogs = data.logs.includes("spectroCloud");
    const nodesLogs = data.logs.includes("nodes");

    let clusterNamespace = `cluster-${cluster.metadata.uid}`;
    if (cluster.metadata.annotations?.namespace) {
      clusterNamespace = cluster.metadata.annotations?.namespace;
    }

    const payload = {
      noOfLines: 10000,
      duration: 36000,
      k8s: {
        namespaces: [
          kubeSystemLogs && "kube-system",
          spectroCloudLogs && clusterNamespace,
        ].filter(Boolean),
      },
      node: {
        logs: nodesLogs ? ["/var/log/syslog", "/var/log/cloud-init"] : [],
      },
    };

    const promise = api.post(
      `v1alpha1/spectroclusters/${clusterUid}/features/logFetcher`,
      payload
    );
    store.dispatch({
      type: "LOGS_DOWNLOAD",
      promise,
      clusterGuid: cluster.guid,
    });

    try {
      await promise;
      pollClusterLogStatus.start();
      notifications.success({
        message: i18next.t(
          "The request was sent successfully. The download will be available soon."
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("An error occured while preparing the archive"),
        description: error.message,
      });
    }

    return promise;
  },
});

export const downloadLogsAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      downloadLogsFormActions.submit({ module: LOGS_MODULE })
    );
  },
});

export const onDemandUpdateAsyncAction = new AsyncAction({
  promise: async () => {
    const cluster = getCluster(store.getState());
    const osPatchConfig =
      cluster.spec?.clusterConfig?.machineManagementConfig?.osPatchConfig || {};

    const promise = api.patch(
      `v1alpha1/spectroclusters/${cluster.metadata.uid}/clusterConfig/osPatch`,
      {
        osPatchConfig: {
          onDemandPatchAfter: moment().add(10, "minutes"),
          patchOnBoot: osPatchConfig.patchOnBoot,
        },
      }
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Node group upgrade activated successfully. If a newer OS version is found, upgrade will begin shortly. "
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to set the update."
        ),
        description: err?.message,
      });
      return;
    }

    store.dispatch(fetchCluster(cluster.metadata.uid));
  },
});

export function openDownloadLogsModal() {
  return (dispatch) => {
    downloadLogsModal.open().then(downloadLogsAsyncAction.trigger);
    dispatch(downloadLogsFormActions.init({ module: LOGS_MODULE }));
  };
}

export function onDemandUpdate() {
  onDemandUpdateModal.open().then(() => {
    onDemandUpdateAsyncAction.trigger();
  });
}

export function fetchLogStatus() {
  return async (dispatch, getState) => {
    const state = getState();
    const logClusterGuid = state.cluster.details.logClusterGuid;
    const cluster = getEntity(() => logClusterGuid, ClusterSchema)(state);
    const promise = api.get(
      `v1alpha1/spectroclusters/${cluster?.metadata?.uid}/features/logFetcher`
    );
    let response;

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to get the log status"
        ),
        description: error?.message,
      });
      return Promise.resolve();
    }

    if (response?.status?.state === "Completed") {
      const logFetcherUid = state.cluster.details.logFetcherUid;
      const downloadLink = `/v1alpha1/spectroclusters/features/logFetcher/${logFetcherUid}/download`;
      const clusterName = cluster.metadata.name;

      notifications.success({
        duration: 0,
        message: i18next.t(
          "The logs archive for {{clusterName}} was created successfully",
          {
            clusterName,
          }
        ),
        description: (
          <TextButton
            data-qa="spectro-logs"
            style={{
              padding: "0",
            }}
            as="a"
            href={downloadLink}
            download="spectro_logs"
          >
            {i18next.t('Download "{{clusterName}}" logs', { clusterName })}
          </TextButton>
        ),
      });
      return Promise.resolve();
    }
    return Promise.reject();
  };
}

export function notificationSelectManifest(profile, manifest, layer) {
  return async function thunk(dispatch, getState) {
    if (manifest.event.type === "PackManifestDelete") {
      return;
    }

    dispatch({
      type: "NOTIFICATION_SELECT_MANIFEST",
      layer,
      manifest,
    });

    const cluster = getRawCluster(getState());

    const manifestGuid = manifest.guid;
    if (
      getState().forms["cluster-update-notification"].data[manifestGuid] !==
      undefined
    ) {
      return;
    }

    let clusterManifestFetcher = () =>
      api.get(
        `v1alpha1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${manifest.uid}`
      );
    let profileManifestFetcher = () =>
      manifest.parentUid &&
      api.get(
        `v1alpha1/clusterprofiles/${profile.metadata.uid}/packs/${layer.metadata.uid}/manifests/${manifest.parentUid}`
      );
    if (manifest.event.type === "PackManifestCreate") {
      clusterManifestFetcher = () =>
        api.get(
          `v1alpha1/clusterprofiles/${profile.metadata.uid}/packs/${layer.metadata.uid}/manifests/${manifest.uid}`
        );
    }

    const isInversed = getClusterDivergencies(getState())[manifest.guid]
      .isInversed;

    let [clusterManifest, profileManifest] = [
      await clusterManifestFetcher(),
      await profileManifestFetcher(),
    ];

    if (!isInversed) {
      [profileManifest, clusterManifest] = [
        await clusterManifestFetcher(),
        await profileManifestFetcher(),
      ];
    }

    dispatch(
      updatesFormAction.batchChange({
        module: "cluster-update-notification",
        updates: {
          [manifestGuid]: !isInversed
            ? profileManifest?.spec?.published?.content
            : clusterManifest?.spec?.published?.content,
        },
      })
    );

    if (profileManifest) {
      dispatch({
        type: "UPDATE_CLUSTER_MANIFEST_DIVERGENCE",
        profile,
        manifest,
        layer,
        content: profileManifest?.spec?.published?.content,
      });
    }
  };
}

export function notificationSelectLayer(profile, layer) {
  return function thunk(dispatch, getState) {
    dispatch({
      type: "NOTIFICATION_SELECT_LAYER",
      layer,
    });
  };
}
