import Color from "color";
import moment from "moment";
import i18n from "i18next";
import { LAYERS, SPECTRO_FOLDER_PREFIX, SPECTRO_TAG } from "./constants";
import { formatToKilocoreHours } from "./number";
import _ from "lodash";
import {
  white,
  lightMidGray,
  BAR_CHART_COLORS,
  navy,
} from "utils/constants/colors";

export function presentClusterProfileParams(packs = []) {
  if (!packs?.length) {
    return [];
  }

  return packs.map((packVersion) => {
    return {
      ...packVersion,
      ...presentLayer({
        ...packVersion,
        type:
          packVersion?.spec?.addonType ||
          packVersion?.spec?.layer ||
          packVersion.name,
      }),
    };
  });
}

export function presentLayer(pack) {
  const metadata = LAYERS.find((layer) => layer.type === pack.type) || {
    title: () => null,
    color: lightMidGray,
  };

  const solidColor = metadata.color;

  const shade = Color(white)
    .mix(Color(solidColor).mix(Color(white), 0.3), 0.25)
    .hex();

  const disabledColor = Color(shade)
    .mix(Color(white), 0.78)
    .mix(Color(lightMidGray), 0.68)
    .hex();

  const disabledInner = Color(disabledColor)
    .mix(Color(solidColor), 0.1)
    .hex();

  const colors = {
    inner: Color(solidColor)
      .mix(Color(shade), 0.45)
      .hex(),
    shade,
    outline: Color(solidColor)
      .mix(Color(shade), 0.9)
      .hex(),
  };

  function getDescription() {
    if (!pack.name) {
      return null;
    }

    if (pack.type === "manifest") {
      return pack.name;
    }

    return pack.name && (pack.tag || pack.version)
      ? `${pack.name} ${pack.tag || pack.version}`
      : "";
  }

  return {
    ...metadata,
    ...colors,
    ...pack,
    description: getDescription(),
    packName: pack.name,
    disabledColor,
    disabledInner,
    title: metadata.title(),
    logo:
      pack?.logo ||
      pack?.spec?.logoUrl ||
      pack?.pack?.spec?.logoUrl ||
      metadata.icon ||
      "",
  };
}

export function presentClusterProfileLayers(clusterprofile) {
  if (!clusterprofile) {
    return [];
  }

  const packs = clusterprofile?.spec?.published?.packs || [];
  return packs.map((packVersion) => {
    return presentLayer({
      ...packVersion,
      type:
        packVersion?.spec?.addonType ||
        packVersion?.spec?.layer ||
        packVersion.name,
    });
  });
}

export function formatTags(tags = []) {
  return tags.reduce((acc, item) => {
    if (item.includes(":")) {
      const [key, value] = item.split(":");

      acc[key] = value;

      return acc;
    }
    acc[item] = SPECTRO_TAG;
    return acc;
  }, {});
}

export function getFullName(user) {
  const { firstName = "", lastName = "" } = user?.spec || {};
  return `${firstName} ${lastName}`;
}

export function getContractAcceptance(user) {
  return user?.status?.isContractAccepted;
}

export function presentProjectCoresBarChart({ data, projects, filter, query }) {
  let barData;
  const projectNames = projects.map((project) => project.metadata.name);
  const timestampFormats = {
    hour: "HH:mm",
    day: "DD MMM",
  };

  const isSameTimestamp = (date, unit) => ({ timestamp }) =>
    moment(timestamp)
      .utcOffset(new Date().getTimezoneOffset())
      .isSame(date, unit);

  const getBarChartData = (xValues, data, unit) =>
    xValues.map((date) => {
      const projectsValues = projectNames.reduce((acc, currentProjectName) => {
        const projectData = data
          ?.find(isSameTimestamp(date, unit))
          ?.projects.find(({ project }) => project.name === currentProjectName);

        const alloyHours = projectData?.alloyCpuCoreHours || 0;
        const pureHours = projectData?.pureCpuCoreHours || 0;

        return {
          ...acc,
          [currentProjectName]: {
            totalCoreHours: alloyHours + pureHours,
            alloyHours,
            pureHours,
          },
        };
      }, {});

      return {
        timestamp: moment(date).format(timestampFormats[unit]),
        ...projectsValues,
      };
    }, []);

  if (!filter) {
    return {
      data: [],
      keys: projectNames,
      indexBy: "timestamp",
    };
  }

  if (filter.unit === "hours") {
    const xValues = Array.from({ length: 24 }).map((_, index) =>
      moment()
        .subtract(24 - index - 1, "hours")
        .startOf("hour")
    );

    barData = getBarChartData(xValues, data?.hourlyUsages, "hour");
  }

  if (filter.unit === "days") {
    const length = parseInt(filter.value);

    const xValues = Array.from({ length }).map((_, index) =>
      moment(query.endTime)
        .subtract(length - (index + 1), filter.unit)
        .startOf("hour")
    );

    const dailyUsages =
      data.items?.map((item) => item.dailyUsages).flat() || [];

    barData = getBarChartData(xValues, dailyUsages, "day");
  }

  return {
    data: barData,
    keys: projectNames,
    indexBy: "timestamp",
  };
}

function presentLineChartData(data, filter, query, clusterType) {
  const isCurrentMonth = moment(data[0]?.timestamp).isSame(moment(), "month");

  return Array.from({ length: filter.value }).reduce((acc, _, index) => {
    const currentXValue = query.startTime
      .startOf("month")
      .add(index, "days")
      .startOf("day");

    const isPastCurrentDay = currentXValue.date() > moment().date();

    let currentYValue = 0;

    if (isCurrentMonth && isPastCurrentDay) {
      return [
        ...acc,
        {
          x: currentXValue.format("YYYY-MM-DD"),
          y: null,
        },
      ];
    }

    const dayUsage = data.find(
      (usage) =>
        moment(usage.timestamp)
          .utcOffset(new Date().getTimezoneOffset())
          .date() === currentXValue.date()
    );

    if (dayUsage) {
      currentYValue =
        (acc[index - 1]?.y || 0) +
        dayUsage[
          clusterType === "alloy"
            ? "totalAlloyCpuCoreHours"
            : "totalPureCpuCoreHours"
        ];
    } else {
      currentYValue = acc[index - 1]?.y || 0;
    }

    return [
      ...acc,
      {
        x: currentXValue.format("YYYY-MM-DD"),
        y: currentYValue,
      },
    ];
  }, []);
}

// TODO: might refactor this a bit
export function presentMonthlyProjectCoreHoursMetrics({ data, filter, query }) {
  if (!data?.items) {
    return [];
  }

  if (data.items.length === 0) {
    return [
      {
        id: i18n.t("Kilocore-hours"),
        data: [],
      },
    ];
  }

  return [
    {
      id: i18n.t("Alloy kilocore-hours"),
      data: presentLineChartData(
        data.items[0].dailyUsages,
        filter,
        query,
        "alloy"
      ),
    },
    {
      id: i18n.t("Pure kilocore-hours"),
      data: presentLineChartData(data.items[0].dailyUsages, filter, query),
    },
  ];
}

export function presentProjectsCreditsUsedMetrics(data, allProjects) {
  if (data?.items.length === 0) {
    return [];
  }

  const { totalMonthlyUsage } = data.items[0];

  const filteredProjects = totalMonthlyUsage.projects.filter(
    ({ alloyCpuCoreHours, pureCpuCoreHours }) =>
      alloyCpuCoreHours + pureCpuCoreHours > 0
  );

  const parsedProjectsData = {
    projects: filteredProjects.map(
      ({ project, alloyCpuCoreHours, pureCpuCoreHours }, index) => {
        const projectIndex = allProjects.findIndex(
          (item) => item.metadata.name === project.name
        );

        const color = projectIndex > -1 ? BAR_CHART_COLORS[projectIndex] : navy;

        // label is also used to determine who's parent is this project
        return {
          label: project.name || i18n.t("[deleted project]"),
          usage: formatToKilocoreHours(alloyCpuCoreHours + pureCpuCoreHours),
          color,
          index,
        };
      }
    ),
    projectsChildren: filteredProjects
      .map(({ project, alloyCpuCoreHours, pureCpuCoreHours }, index) => {
        const projectName = project.name;

        const projectIndex = allProjects.findIndex(
          (item) => item.metadata.name === projectName
        );

        const color = projectIndex > -1 ? BAR_CHART_COLORS[projectIndex] : navy;
        return [
          {
            parent: project.name || i18n.t("[deleted project]"),
            type: "pure",
            label: i18n.t("PURE"),
            usage: formatToKilocoreHours(pureCpuCoreHours),
            color: Color(color)
              .alpha(0.6)
              .rgb()
              .string(),
            index: index * 2,
          },
          {
            parent: project.name || i18n.t("[deleted project]"),
            type: "alloy",
            label: i18n.t("ALLOY"),
            usage: formatToKilocoreHours(alloyCpuCoreHours),
            color: Color(color)
              .alpha(0.3)
              .rgb()
              .string(),
            index: index * 2 + 1,
          },
        ];
      })
      .flat(),
  };

  return {
    chartData: parsedProjectsData,
    totalAlloyCpuCoreHours: formatToKilocoreHours(
      totalMonthlyUsage.totalAlloyCpuCoreHours
    ),
    totalPureCpuCoreHours: formatToKilocoreHours(
      totalMonthlyUsage.totalPureCpuCoreHours
    ),
  };
}

export function groupPresetsOptions(presets = []) {
  const result = presets.reduce((acc, item) => {
    acc[item.group] = acc[item.group] || [];
    acc[item.group].push({
      ...item,
      label: item.displayName,
      value: item.name,
    });
    return acc;
  }, {});

  return result;
}

function extractSchemaField(field, path) {
  const accumulator = {
    type: field.type,
    description: field.hints.join(". "),
  };

  if (field?.listOptions?.length) {
    return {
      ...accumulator,
      enum: field.listOptions,
    };
  }

  let regex = field.regex;
  if (field.type === "boolean") {
    regex = null;
  }

  if (field.type === "string" && field.required) {
    accumulator.minLength = 1;
  }

  // eslint-disable-next-line no-template-curly-in-string
  if (field.format.startsWith("${password}")) {
    accumulator.passwordRegex = regex;
    regex = null;
    accumulator.passwordTypeField = true;
  }

  return {
    ...accumulator,
    ...(regex ? { pattern: regex } : {}),
    "~schemaField": true,
    path,
  };
}

export function generateEditorYamlSchema(
  schema = [],
  { ignorePasswords = true } = {}
) {
  if (!schema.length) {
    return null;
  }

  return schema.reduce(
    (accumulator, item) => {
      const path = item.name.split(".").reduce((nameAccumulator, name) => {
        if (name === "$[]") {
          return `${nameAccumulator}.items`;
        }
        let separator = ".properties.";
        if (!nameAccumulator) {
          separator = "properties.";
        }
        return `${nameAccumulator}${separator}${name}`;
      }, "");

      const fieldMeta = extractSchemaField(item, path);
      if (fieldMeta.passwordTypeField) {
        accumulator.passwordFields[path] = fieldMeta;
        if (!ignorePasswords) {
          fieldMeta.regex = fieldMeta.passwordRegex;
        }
      }
      _.set(accumulator, path, fieldMeta);

      if (item.required) {
        const parts = path.split(".");
        const fieldName = parts.pop();
        parts.pop();
        const descriptor = _.get(accumulator, parts.join("."));

        if (!descriptor) {
          return accumulator;
        }
        descriptor.required = descriptor.required || [];
        descriptor.required.push(fieldName);
      }

      return accumulator;
    },
    {
      $id: "http://json-schema.org/draft/2019-09/schema#",
      $schema: "http://json-schema.org/draft/2019-09/schema#",
      type: "object",
      properties: {},
      passwordFields: {},
    }
  );
}

export function presentDatacenterFolders(datacenter) {
  return (
    datacenter?.folders
      ?.filter((folder) => {
        const paths = folder.split("/");

        return paths.every((path) => !path.startsWith(SPECTRO_FOLDER_PREFIX));
      })
      .map((folder) => ({
        label: folder,
        value: folder,
      })) || []
  );
}
