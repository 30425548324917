import React from "react";
import styled, { css } from "styled-components";
import Color from "color";
import i18n from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Radio, Tooltip } from "antd";

import Card from "components/styled/Card";
import RadioGroup from "components/ui/RadioGroup";

import * as colors from "utils/constants/colors";
import { getThemeValue, createTheme } from "utils/theme";

export const defaultTheme = createTheme({
  vMargin: "14px",
  hMargin: "27px",
});

const CardWrap = styled(Card)`
  background: ${colors.white};
  box-sizing: border-box;
  border-radius: 8px;
  width: 162px;
  height: 162px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin: ${getThemeValue(defaultTheme, "vMargin")}
    ${getThemeValue(defaultTheme, "hMargin")};
  display: inline-block;
  vertical-align: top;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 0px rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 0px ${colors.blue};
    `}

  ${(props) =>
    props.hideRadio &&
    css`
      .ant-radio {
        visibility: hidden;
      }
    `}
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.midGray};
`;

const Icon = styled.div`
  height: 40px;
  width: 50px;
  margin: 0 auto;

  img {
    color: transparent;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const CardType = styled.div`
  height: 64px;
  width: 100%;
  text-align: center;
  display: grid;
  align-items: center;
`;

const Field = styled(Radio)`
  height: 20px;
  width: 20px;
  margin: 20px 0 0 20px;
`;

const MainWrap = styled.div`
  margin: 0 -${getThemeValue(defaultTheme, "hMargin")};

  ${(props) =>
    props.hideRadio &&
    css`
      .ant-radio {
        display: none;
      }
    `}
`;
const RIBBON_COLOR = "#78909C";
const ComingSoonRibbon = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
  span {
    width: 200px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    top: 12px;
    right: -57px;
    z-index: 2;
    overflow: hidden;
    transform: rotate(45deg);
    box-shadow: 0 0 0 3px ${RIBBON_COLOR}, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
    background: ${RIBBON_COLOR};
    text-align: center;
    color: ${Color(RIBBON_COLOR)
      .lighten(0.6)
      .hex()};
    z-index: 2;
    text-indent: 31px;
    font-size: 12px;
    letter-spacing: -0.6px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 40px;
    right: 0px;
    top: 48px;
    background: ${Color(RIBBON_COLOR)
      .darken(0.5)
      .hex()};
    border-radius: 0px 8px 8px 0px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 8px;
    right: 49px;
    background: ${Color(RIBBON_COLOR)
      .darken(0.5)
      .hex()};
    border-radius: 8px 8px 0px 0px;
  }
`;

const HasNoAccountIcon = styled(FontAwesomeIcon)`
  color: ${colors.orange};
  margin-left: 20px;
`;

const CardOption = ({ data, onClick, selected, disabled, hideRadio }) => {
  const name = data.label || data.name;

  function renderImage() {
    if (!data.logo) {
      return null;
    }

    return (
      <Icon>
        <img src={data.logo} alt={name} />
      </Icon>
    );
  }

  function renderComingSoon() {
    if (!data.comingSoon) {
      return null;
    }

    return (
      <ComingSoonRibbon>
        <span>{i18n.t("Coming Soon")}</span>
      </ComingSoonRibbon>
    );
  }

  function renderAccountTooltip() {
    if (data.comingSoon) {
      return null;
    }

    if (data.hasAccount === false) {
      return (
        <Tooltip
          title={i18n.t(
            "You have no {{name}} cloud account configured. To create a new one, click on the card",
            { name }
          )}
          placement={"top"}
        >
          <HasNoAccountIcon icon={faExclamationCircle} />
        </Tooltip>
      );
    }
  }

  return (
    <CardWrap
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      hideRadio={hideRadio}
    >
      {renderAccountTooltip()}
      <Field data-qa={data.value} checked={selected} />
      {renderImage()}

      <CardType>
        <Label>{name}</Label>
      </CardType>
      {renderComingSoon()}
    </CardWrap>
  );
};

export default function CardSelector({ options, hideRadio, ...rest }) {
  function renderCard(data) {
    return (
      <CardOption
        data={data}
        value={data.value}
        key={data.value}
        disabled={data.disabled}
        hideRadio={data.hasAccount === false || data.comingSoon}
      />
    );
  }
  return (
    <MainWrap hideRadio={hideRadio}>
      <RadioGroup {...rest}>{options.map(renderCard)}</RadioGroup>
    </MainWrap>
  );
}
