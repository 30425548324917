import i18n from "i18next";
import { createSelector } from "reselect";

import _ from "lodash";
import { getEntity } from "utils/entities";
import {
  ClusterProfileSchema,
  CredentialSchema,
  SSHSchema,
  RegionSchema,
  VpcidSchema,
  SSHKeysSchema,
} from "utils/schemas";
import {
  presentClusterProfileLayers,
  formatTags,
  groupPresetsOptions,
  generateEditorYamlSchema,
  presentDatacenterFolders,
} from "utils/presenters";
import { getVsphereFolderPayload, getCronScheduleValue } from "utils/parsers";

import { getCheckpointStep, getCurrentStep } from "modules/wizard/selectors";
import {
  propertiesFetcher,
  datacentersFetcher,
  virtualNetworksFetcher,
  profileModule,
  cloudAccountsFetcher,
} from "state/cluster/services/create";
import {
  azureInstanceTypesFetcher,
  azureAZFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
} from "state/cluster/services/nodes";

import { dnsMappingsFetcher } from "state/dns/services";
import { getScheduledBackupPayload } from "state/backups/selectors";
import { getRawCluster } from "./details";
import { MANAGED_TO_PURE_ENVIRONMENT } from "utils/constants";
import store from "services/store";
import { clusterRbacsFetcher } from "state/rolebindings/services";
import { openstackCloudForm } from "../actions/create";

//

export const getSelectedClusterProfile = getEntity(
  (state) => state.forms?.cluster?.data?.clusterprofile,
  ClusterProfileSchema
);

const RESET_STEPS = [1];
export const showResetFieldsWarning = createSelector(
  getCurrentStep("cluster"),
  getCheckpointStep("cluster"),
  (currentStep, checkpoint) => {
    if (currentStep >= checkpoint) {
      return false;
    }

    return RESET_STEPS.includes(currentStep);
  }
);

export const getFormError = createSelector(
  getCurrentStep("cluster"),
  (state) => state.forms.cluster,
  (currentStep, formState) => {
    if (!formState) {
      return null;
    }
    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18n.t(
      "Please resolve the errors on the page in order to continue"
    );
    if (currentStep === 1) {
      if (errors.find((err) => err.field === "type")) {
        message = i18n.t("Please select an item in order to continue");
      }
    }

    if (currentStep === 3) {
      message = i18n.t("Please select an item in order to continue");
    }

    return message;
  }
);

export const getCredentials = getEntity(
  (state) => state.cluster.create.credentials,
  [CredentialSchema]
);

export const getCredentialsAsOptions = createSelector(
  getCredentials,
  (items) => {
    return items.map((item) => {
      return {
        label: item.metadata.name,
        value: item.metadata.uid,
        description: item.metadata?.annotations?.description,
      };
    });
  }
);

export const getSelectedCredential = createSelector(
  cloudAccountsFetcher.selector,
  (state) => state.forms.cluster?.data.credential,
  (credentials, value) => {
    return credentials?.result?.find(
      (credential) => credential.metadata.uid === value
    );
  }
);

export const getSSHs = getEntity((state) => state.cluster.create.sshs, [
  SSHSchema,
]);

export const getRegions = getEntity((state) => state.cluster.create.regions, [
  RegionSchema,
]);

export const getSelectedCloud = createSelector(
  getSelectedClusterProfile,
  (state) => state.forms?.cluster?.data,
  (selectedClusterProfile, formData) => {
    const cloudType = selectedClusterProfile?.spec?.published?.cloudType;
    return cloudType;
  }
);

export const getSelectedEnvironment = createSelector(
  getSelectedCloud,
  (cloudType) => {
    let environment = cloudType;
    if (MANAGED_TO_PURE_ENVIRONMENT[cloudType]) {
      environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType];
    }

    return environment;
  }
);

export const isSupervizedEnvironment = createSelector(
  getSelectedCloud,
  (cloudType) => {
    return !!MANAGED_TO_PURE_ENVIRONMENT[cloudType];
  }
);

const getSelectedSSHEntities = getEntity(
  (state) => state.forms.cluster?.data?.sshKeys,
  [SSHKeysSchema]
);
const getSelectedSSHEntity = getEntity(
  (state) => state.forms.cluster?.data?.sshKey,
  SSHKeysSchema
);

export const getSelectedSSHKeys = createSelector(
  getSelectedSSHEntities,
  (entities) => entities?.map((entity) => entity?.spec?.publicKey) || []
);

export const getSelectedSSHKey = createSelector(
  getSelectedSSHEntity,
  (entity) => entity?.spec?.publicKey || ""
);

const getOsPatchingSchedule = createSelector(
  (state) => state.forms?.cluster?.data,
  (data) => {
    return getCronScheduleValue(
      data?.osPatchingScheduleOption,
      data?.osPatchingSchedule
    );
  }
);

const getClusterRoleBindingsPayload = createSelector(
  clusterRbacsFetcher.selector,
  (state) => state.forms?.cluster?.data?.roleBindingOptions,
  ({ result }, selectedOptions) => {
    return (selectedOptions || []).reduce((acc, uid) => {
      const item = result.find((item) => item.metadata?.uid === uid);
      if (item && !item.spec?.isDefault) {
        acc.push({
          uid,
          name: item?.metadata?.name,
        });
      }
      return acc;
    }, []);
  }
);

export const getPayload = createSelector(
  (state) => state.forms?.cluster?.data,
  getSelectedCloud,
  getSelectedSSHKeys,
  getSelectedSSHKey,
  () => profileModule.payload,
  getOsPatchingSchedule,
  getClusterRoleBindingsPayload,
  (
    data = {},
    cloudType,
    selectedSSHKeys,
    selectedSSHKey,
    profiles,
    osPatchingSchedule,
    clusterRbac
  ) => {
    const dnsSetting = data.nodePools?.[0]?.domains?.[0]?.dns?.spec?.dnsName;
    const backupPolicy = getScheduledBackupPayload(data);

    let policies = {
      scanPolicy: {},
      ...(Object.keys(backupPolicy).length ? { backupPolicy } : {}),
    };

    if (data.kubebenchEnabled) {
      policies.scanPolicy.kubeBench = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubebench.type,
            data.kubebench.recurrency
          ),
        },
      };
    }

    if (data.kubehunterEnabled) {
      policies.scanPolicy.kubeHunter = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubehunter.type,
            data.kubehunter.recurrency
          ),
        },
      };
    }

    if (data.sonobuoyEnabled) {
      policies.scanPolicy.sonobuoy = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.sonobuoy.type,
            data.sonobuoy.recurrency
          ),
        },
      };
    }

    const cloudTypeConfig = {
      aws: {
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              spotMarketOptions:
                nodePool.instanceOption === "onSpot"
                  ? {
                      maxPrice: `${(
                        (nodePool.instancePrice * nodePool.maxPricePercentage) /
                        100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })}`,
                    }
                  : undefined,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
              subnets: nodePool.azs.reduce((acc, az) => {
                const azSubnets = nodePool[`subnet_${az}`];
                const id = Array.isArray(azSubnets)
                  ? azSubnets.join(",")
                  : azSubnets;

                if (azSubnets) {
                  acc.push({
                    az,
                    id,
                  });
                }
                return acc;
              }, []),
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
        cloudConfig: {
          region: data.region,
          sshKeyName: data.ssh,
          vpcId: data.vpcid,
        },
      },
      eks() {
        function getNodePoolPayload(nodePool) {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
              subnets: nodePool.azs.reduce((acc, az) => {
                const azSubnets = nodePool[`subnet_${az}`];
                const id = Array.isArray(azSubnets)
                  ? azSubnets.join(",")
                  : azSubnets;

                if (azSubnets) {
                  acc.push({
                    az,
                    id,
                  });
                }
                return acc;
              }, []),
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              maxSize: nodePool.maxNodeSize,
              minSize: nodePool.minNodeSize,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }
        let machinePoolConfig = data.nodePools?.map(getNodePoolPayload);
        if (data.staticPlacement) {
          machinePoolConfig = [
            getNodePoolPayload({
              isMaster: true,
              poolName: "master-pool",
              size: 0,
              azs: [],
              ...data.controlPlane,
            }),
            ...machinePoolConfig,
          ];
        }

        return {
          machinePoolConfig,
          cloudConfig: {
            region: data.region,
            sshKeyName: data.ssh,
            vpcId: data.vpcid,
            endpointAccess: {
              private: !!data?.endpointAccess?.includes("private"),
              public: !!data?.endpointAccess?.includes("public"),
              publicCIDRs: data.publicAccessCidrs,
            },
          },
          fargateProfiles: data.fargateProfiles.map((profile) => ({
            ...profile,
            selectors: profile.selectors.map((selector) => ({
              ...selector,
              labels: formatTags(selector.labels),
            })),
          })),
        };
      },
      vsphere: {
        cloudConfig: {
          ...(!!dnsSetting
            ? {
                controlPlaneEndpoint: {
                  type: "DDNS",
                  ddnsSearchDomain: dnsSetting,
                },
              }
            : {}),
          network: {
            networkName: data.network || "",
            staticIp: data.networkType === "staticIP",
          },
          placement: {
            datacenter: data.datacenter,
            folder: getVsphereFolderPayload(data),
            network: {
              networkName: data.network || "",
              staticIp: data.networkType === "staticIP",
            },
          },
          staticIp: data.networkType === "staticIP",
          sshKeys: selectedSSHKeys,
          ntpServers: data.ntpServers,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: {
                diskGiB: nodePool.disk,
                memoryMiB: nodePool.memory * 1024,
                numCPUs: nodePool.cpu,
              },
              placements: nodePool.domains.map(
                ({ network, parentPoolUid, dns, ...rest }) => ({
                  ...rest,
                  network: {
                    networkName: network,
                    staticIp: data.networkType === "staticIP",
                    parentPoolUid: parentPoolUid,
                  },
                })
              ),
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
      },
      maas: {
        cloudConfig: {
          domain: data.domain,
          sshKeys: selectedSSHKeys,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: {
                minCPU: nodePool.minCPU,
                minMemInMB: nodePool.minMem * 1024,
              },
              azs: nodePool.azs,
              resourcePool: nodePool.resourcePool,
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
      },
      azure: {
        cloudConfig: {
          subscriptionId: data.subscriptionId,
          location: data.region,
          sshKey: selectedSSHKey,
          resourceGroup: data.resourceGroup,
          vnetName: data.vnetName,
          controlPlaneSubnet: JSON.parse(data.controlPlaneSubnet || "{}"),
          workerSubnet: JSON.parse(data.workerSubnet || "{}"),
          ...(data.hasTenantName && {
            aadProfile: {
              managed: data.managedAD,
              adminGroupObjectIDs: data.adminGroupObjectIDs,
            },
          }),
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              osDisk: {
                diskSizeGB: nodePool.disk,
                managedDisk: {
                  storageAccountType: nodePool.storageAccountType,
                },
                osType: nodePool.osType,
              },
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
      },
      aks: {
        cloudConfig: {
          subscriptionId: data.subscriptionId,
          location: data.region,
          sshKey: selectedSSHKey,
          resourceGroup: data.resourceGroup,
          vnetName: data.vnetName,
          controlPlaneSubnet: JSON.parse(data.controlPlaneSubnet || "{}"),
          workerSubnet: JSON.parse(data.workerSubnet || "{}"),
          ...(data.hasTenantName && {
            aadProfile: {
              managed: data.managedAD,
              adminGroupObjectIDs: data.adminGroupObjectIDs,
            },
          }),
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            managedPoolConfig: {
              isSystemNodePool: nodePool?.isSystemNodePool || false,
            },
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              osDisk: {
                diskSizeGB: nodePool.disk,
                managedDisk: {
                  storageAccountType: nodePool.storageAccountType,
                },
                osType: nodePool.osType,
              },
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: ["worker"],
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
      },
      gcp: {
        cloudConfig: {
          project: data.project,
          region: data.region,
          network: data.network,
          sshKey: selectedSSHKey,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
            },
            poolConfig: {
              name: nodePool.poolName,
              size: nodePool.size,
              labels: [nodePool.isMaster ? "master" : "worker"],
              isControlPlane: nodePool.isMaster,
              useControlPlaneAsWorker: nodePool.allowWorkerCapability,
              updateStrategy: {
                type: nodePool.updateStrategy,
              },
            },
          };
        }),
      },
      openstack() {
        const selectors = openstackCloudForm.selectors;
        const state = store.getState();
        const selectedDomain = selectors.getOpenstackSelectedDomain(state);
        const selectedNetwork = selectors.getOpenstackSelectedNetwork(state);
        const subnets = selectors.getOpenstackNetworkSubnets(state);
        const flavorsData = openstackCloudForm.fetchers.flavorsFetcher.selector(
          state
        );
        const projects = openstackCloudForm.fetchers.projectsFetcher.selector(
          state
        )?.result;

        const selectedProject = (projects?.items || []).find(
          (project) => project.name === data.project
        );

        const subnet = subnets.find((subnet) => subnet.id === data.subnet);

        return {
          cloudConfig: {
            region: data.region,
            domain: _.pick(selectedDomain, ["id", "name"]),
            project: _.pick(selectedProject, ["id", "name"]),
            network: data.staticPlacement
              ? _.pick(selectedNetwork, ["id", "name"])
              : undefined,
            subnet,
            dnsNameservers: !data.staticPlacement
              ? data.dnsNameservers
              : undefined,
            nodeCidr: !data.staticPlacement ? data.nodeCidr : undefined,
            sshKeyName: data.sshKeyName,
          },
          machinePoolConfig: data.nodePools?.map((nodePool) => {
            const flavorConfig = (flavorsData.result || []).find(
              (flavor) => flavor.name === nodePool.flavor
            );
            const subnet = subnets.find(
              (subnet) => subnet.id === nodePool.subnet
            );
            return {
              cloudConfig: {
                flavorConfig: flavorConfig
                  ? {
                      numCPUs: flavorConfig.vcpus,
                      memoryMiB: flavorConfig.memory,
                      name: flavorConfig.name,
                    }
                  : undefined,
                azs: nodePool.azs,
                diskGiB: nodePool.disk,
                subnet,
              },
              poolConfig: {
                name: nodePool.poolName,
                size: nodePool.size,
                labels: [nodePool.isMaster ? "master" : "worker"],
                isControlPlane: nodePool.isMaster,
                useControlPlaneAsWorker: nodePool.allowWorkerCapability,
                updateStrategy: {
                  type: nodePool.updateStrategy,
                },
              },
            };
          }),
        };
      },
    };

    let cloudSpecificConfig = cloudTypeConfig[cloudType] || {};
    if (typeof cloudSpecificConfig === "function") {
      cloudSpecificConfig = cloudSpecificConfig();
    }

    return {
      metadata: {
        annotations: {
          description: data.description,
        },
        name: data.name,
        labels: formatTags(data.tags),
      },
      spec: {
        ...cloudSpecificConfig,
        cloudAccountUid: data.credential,
        profiles,
        policies,
        clusterConfig: {
          machineManagementConfig: {
            osPatchConfig: {
              schedule: osPatchingSchedule,
              patchOnBoot: data.patchOnBoot,
            },
          },
          clusterRbac,
        },
      },
    };
  }
);

export const getClusterRatePayload = createSelector(
  getPayload,
  (clusterPayload) => {
    const { cloudConfig, machinePoolConfig } = clusterPayload?.spec || {};
    return {
      cloudConfig,
      machinepoolconfig: machinePoolConfig,
    };
  }
);

export const getClusterRate = createSelector(
  (state) => state.cluster?.create?.estimatedRate,
  (data) => {
    return data?.rate || {};
  }
);

export const getProfiles = createSelector(
  () => profileModule.state,
  (profilesState) => {
    return (profilesState?.profiles || []).map((profile) => ({
      guid: profile.guid,
      name: profile.metadata.name,
      layers: presentClusterProfileLayers(profile),
    }));
  }
);

export const getSelectedClusterProfileLayers = createSelector(
  getProfiles,
  (profiles) => {
    return profiles.reduce((accumulator, profile) => {
      return accumulator.concat(profile.layers);
    }, []);
  }
);

export const getSelectedLayer = createSelector(
  getSelectedClusterProfileLayers,
  (state) => state.cluster.create.selectedLayer,
  (layers, guid) => {
    return layers.find((layer) => layer.guid === guid);
  }
);

export const getParamsForLayer = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return selectedLayer?.values;
  }
);

export const getVpcId = getEntity((state) => state.cluster.create.vpcids, [
  VpcidSchema,
]);

export const getVpcIdAsOptions = createSelector(getVpcId, (vpcs) => {
  return vpcs.map((vpc) => ({
    label: vpc.name || vpc.vpcId,
    value: vpc.vpcId,
  }));
});

export const getParsedNodePools = createSelector(
  (state) => state.forms.cluster.data.nodePools,
  (nodePools) =>
    nodePools.map((nodePool) => ({
      value: nodePool.poolName,
      label: nodePool.poolName,
    }))
);

export const getSubnets = createSelector(
  (state) => state.cluster.details.cloudConfigParams,
  (state) => state.location.params,
  (state) => state.forms.cluster?.data.vpcid,
  (state) => state.forms.fargateProfiles?.data.vpcid,
  (
    cloudConfigParams,
    locationParams,
    clusterSelectedVpcId,
    fargateSelctedVpcId
  ) => {
    if (!cloudConfigParams) {
      return [];
    }

    let selectedVpcId = !!locationParams.id
      ? fargateSelctedVpcId
      : clusterSelectedVpcId;

    const { vpcids } = cloudConfigParams;
    let currentAzVpc = vpcids.find((vpc) => vpc.vpcId === selectedVpcId);

    if (!currentAzVpc) {
      return;
    }

    return currentAzVpc.subnets;
  }
);

export const getSubnetsForSelectedAz = createSelector(
  getSubnets,
  (azSubnets) => {
    if (!azSubnets) {
      return;
    }

    return azSubnets.reduce((acc, value) => {
      acc[value.az] = acc[value.az] || [];

      acc[value.az].push({
        label: value.name || value.subnetId,
        description: value.isPrivate ? i18n.t("Private") : null,
        isPrivate: value.isPrivate,
        value: value.subnetId,
      });
      return acc;
    }, {});
  }
);

export const getFargateSubnets = createSelector(getSubnets, (subnets) => {
  if (!subnets) {
    return [];
  }

  return subnets
    .filter((subnet) => !!subnet.isPrivate)
    .map((subnet) => ({
      label: subnet.name || subnet.subnetId,
      value: subnet.subnetId,
    }));
});

export const getCloudConfigSubnets = createSelector(
  (state) => state.forms.nodes.data,
  (formData) => {
    return formData.azs.reduce((acc, az) => {
      const subnetIsSet = formData[`subnet_${az}`];

      if (subnetIsSet) {
        acc.push({
          az,
          id: subnetIsSet,
        });
      }
      return acc;
    }, []);
  }
);

export const getVMwarePlacementOptions = createSelector(
  propertiesFetcher.selector,
  dnsMappingsFetcher.selector,
  (state) => state.forms.cluster?.data,
  getSelectedCredential,
  getRawCluster,
  (properties, dnsMapping, formData, cloudAccount, cluster) => {
    const asOption = (item) => ({
      label: item === "" ? "Default" : item,
      value: item,
    });

    // Depending on where this selector is used
    // it has to decide between the formData and cluster
    const dnsData = {
      datacenter:
        formData?.datacenter ||
        cluster?.spec.cloudConfig?.spec.clusterConfig.placement.datacenter,
      privateGatewayUid:
        cloudAccount?.metadata?.annotations?.overlordUid ||
        cluster?.spec.cloudConfig?.spec.cloudAccount?.metadata?.annotations
          ?.overlordUid,
    };

    function presentProperties(properties) {
      if (!properties) {
        return {};
      }

      properties.resourcePools = properties.resourcePools || [];

      if (!properties.resourcePools.includes("")) {
        properties.resourcePools.push("");
      }

      const output = ["datastores", "networks", "resourcePools"].reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: (properties[key] || []).sort().map(asOption),
        }),
        {}
      );

      output.networks = output.networks.map((options) => ({
        ...options,
        dnsMapping: (dnsMapping.result || []).find((dnsMapping) => {
          return _.isEqual(
            _.pick(dnsMapping.spec, [
              "network",
              "privateGatewayUid",
              "datacenter",
            ]),
            {
              network: options.value,
              ...dnsData,
            }
          );
        }),
      }));

      return output;
    }

    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = presentProperties(properties[key].result);
      return accumulator;
    }, {});
  }
);

export const getVMwarePlacementOptionsLoadingState = createSelector(
  propertiesFetcher.selector,
  (properties) => {
    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = properties[key].isLoading;
      return accumulator;
    }, {});
  }
);

export const getDatacenters = createSelector(
  datacentersFetcher.selector,
  (datacenters) => datacenters.result
);

export const getDatacenterClustersOptions = createSelector(
  getDatacenters,
  (state) => state.forms.cluster?.data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );

    return (center?.computeclusters || []).map((computecluster) => ({
      label: computecluster,
      value: computecluster,
    }));
  }
);

export const getDatacenterFolderOptions = createSelector(
  getDatacenters,
  (state) => state.forms.cluster?.data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );

    return presentDatacenterFolders(center);
  }
);

export const getAzureSubnets = createSelector(
  virtualNetworksFetcher.selector,
  (state) => state.forms.cluster.data.vnetName,
  (fetcherState, vnet) => {
    if (!vnet) {
      return [];
    }

    if (!fetcherState.result) {
      return [];
    }

    const selectedVirtualNetwork = fetcherState.result.find(
      (network) => network.name === vnet
    );

    if (!selectedVirtualNetwork) {
      return [];
    }

    return selectedVirtualNetwork.subnets.map(({ name, ...rest }) => ({
      label: name,
      value: JSON.stringify({ name, ...rest }),
    }));
  }
);

export function getNonSupportedZones(instanceTypes, selectedInstance) {
  if (!selectedInstance) {
    return [];
  }
  const selectedOption = instanceTypes?.find(
    (type) => type.type === selectedInstance
  );
  return selectedOption?.nonSupportedZones || [];
}

export function mapAwsAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs
    .map((az) => ({
      label: az.name,
      value: az.zoneId,
      disabled: nonSupportedZones?.some((zone) => zone === az.name),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const createAWSAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    (state) => state.cluster.details?.cloudConfigParams?.azs,
    (state) => state.cluster.details?.cloudConfigParams?.instanceTypes,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    (azs, instanceTypes, selectedInstance) => {
      return mapAwsAzs(azs, instanceTypes, selectedInstance);
    }
  );
};

export function mapAzureAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs.zoneList
    .map((az) => ({
      label: az.id,
      value: az.id,
      disabled: nonSupportedZones?.some((zone) => zone === az.id),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const createAzureAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    azureAZFetcher.selector,
    azureInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    ({ result: azs }, { result: instanceTypes }, selectedInstance) => {
      return mapAzureAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
    }
  );
};

export function mapGcpAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs
    .map((az) => ({
      label: az.name,
      value: az.name,
      disabled: nonSupportedZones?.some((zone) => zone === az.name),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const getMasterNodePoolAzs = createSelector(
  (state) => state.forms.cluster?.data?.nodePools,
  (nodePools) => {
    return nodePools?.find((nodePool) => nodePool.isMaster)?.azs;
  }
);

export const createGCPAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    getMasterNodePoolAzs,
    gcpAZFetcher.selector,
    gcpInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.isMaster,
    (
      selectedMasterAzs,
      { result: allAzs },
      { result: instanceTypes },
      selectedInstance,
      isMaster
    ) => {
      const masterNamedAzs = selectedMasterAzs?.map((az) => ({ name: az }));
      const azs = isMaster ? allAzs : masterNamedAzs;
      return mapGcpAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
    }
  );
};

export const getPresetsOptions = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return groupPresetsOptions(selectedLayer?.spec?.presets);
  }
);

export const getEditorSchema = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return generateEditorYamlSchema(selectedLayer?.spec?.schema);
  }
);

export const getDefaultPackValues = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return selectedLayer?.spec?.values;
  }
);

export const getPackValidationErrors = createSelector(
  (state) => state.forms.cluster?.errors,
  (formErrors = []) => {
    const packValidationErrors =
      formErrors.find((error) => error.field === "layers")?.result || [];

    return packValidationErrors;
  }
);

export const getResolvedValues = createSelector(
  getSelectedClusterProfile,
  (profile) => profile?.status?.resolved || {}
);
