import React from "react";
import { useTranslation } from "react-i18next";

export const InstanceTypeDescription = ({
  cpu,
  memory,
  price,
  nonSupportedZones,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {cpu && <div>CPU: {cpu} vCPU</div>}
      {memory && <div>RAM: {memory} GB</div>}
      {price && (
        <div>
          {t("Price")}: ${price}/h
        </div>
      )}
      {nonSupportedZones && nonSupportedZones.length && (
        <div>
          {t("Non Supported Zones")}: {nonSupportedZones.join(", ")}
        </div>
      )}
    </div>
  );
};

export const renderInstanceTypeDescription = (props) => {
  const { cpu, memory, price, nonSupportedZones } = props;

  if (
    cpu ||
    memory ||
    price ||
    (nonSupportedZones && nonSupportedZones.length)
  ) {
    return <InstanceTypeDescription {...props} />;
  }

  return null;
};
