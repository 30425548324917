import store from "services/store";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";
import { getGCPPayloadData } from "./index";

const validator = new Validator();
validator.addRule(["name"], Missing());
validator.addRule(["name"], isKubernetesName());
validator.addRule(["jsonCredentials"], ignoreIfFileIsProvided(Missing()));
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
    },
    spec: getGCPPayloadData(data),
  };

  return store.dispatch(createAccount("gcp", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  const fileName = data?.spec.jsonCredentialsFileName;

  return Promise.resolve({
    name: data?.metadata.name || "",
    jsonCredentials: (!fileName && data?.spec.jsonCredentials) || "",
    jsonFile: fileName && {
      name: fileName,
    },
  });
}

export const googleAccountFormActions = createActions({
  submit,
  validator,
  init,
});

export function updateJSONCredentialsFile(payload) {
  return async (dispatch) => {
    const isFileRemoved = payload?.status === "removed";

    await dispatch(
      googleAccountFormActions.onChange({
        module: "cloudAccounts",
        name: "jsonFile",
        value: !isFileRemoved ? payload : null,
      })
    );

    if (isFileRemoved || payload?.response) {
      dispatch({ type: "SET_ACCOUNT_AS_INVALID" });
    }
  };
}

function ignoreIfFileIsProvided(validationFn) {
  return function validation(value, key, data) {
    if (data?.jsonCredentials || data?.jsonFile?.response?.uid) {
      return false;
    }

    return validationFn(value, key, data);
  };
}
