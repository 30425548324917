import set from "lodash/set";

export function FORM_LOAD_REQUEST(nextState, { module }) {
  nextState[module] = {};
  nextState[module].data = {};
  nextState[module].initialData = {};
  nextState[module].errors = [];
  nextState[module].isLoading = true;
  nextState[module].submitError = null;
  nextState[module].submitting = false;
}

export function FORM_LOAD_SUCCESS(nextState, { module, result }) {
  nextState[module].initialData = result;
  nextState[module].data = result;
  nextState[module].isLoading = false;
}

export function UPDATE_FORM_ERRORS(nextState, { module, errors }, state) {
  errors.forEach((error) => {
    const existingIndex = nextState[module].errors.findIndex(
      (err) => err.guid === error.guid && err.field === error.field
    );
    if (error.result) {
      if (existingIndex === -1) {
        nextState[module].errors.push(error);
        return;
      }

      nextState[module].errors.splice(existingIndex, 1, error);
      return;
    }
    if (existingIndex !== -1) {
      nextState[module].errors.splice(existingIndex, 1);
    }
  });
}

export function FORM_LOAD_FAILURE(nextState, { module, result }) {
  nextState[module].loadingError = result;
  nextState[module].isLoading = false;
}

export function FORM_ON_CHANGE(nextState, { name, module, value }) {
  if (!name) {
    return;
  }
  set(nextState[module].data, name, value);
}

export function FORM_CLEAR_VALIDATIONS(nextState, { module }) {
  nextState[module].errors = [];
}

export function VALIDATE_FORM_START(nextState, { module }) {
  nextState[module].validating = true;
}

export function VALIDATE_FORM_END(nextState, { module }) {
  nextState[module].validating = false;
}

export function FORM_SUBMIT_REQUEST(nextState, { module }) {
  nextState[module].submitting = true;
}

export function FORM_SUBMIT_SUCCESS(nextState, { module }) {
  nextState[module].submitting = false;
  nextState[module].submitError = null;
}

export function FORM_SUBMIT_FAILURE(nextState, { module, error }) {
  nextState[module].submitting = false;
  nextState[module].submitError = error;
}

export function FORM_BATCH_UPDATE(nextState, { module, updates }, state) {
  nextState[module].data = {
    ...state[module].data,
    ...updates,
  };
}

export function CRON_PERIOD_CHANGE(nextState, { selectedPeriod, fieldName }) {
  nextState[fieldName] = selectedPeriod;
}
