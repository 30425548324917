import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { winterMist } from "utils/constants/colors";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/Modal";
import { TextButton } from "components/ui/Button";
import RoleCard from "./components/RoleCard";
import { TenantRoleSelector } from "components/common/ScopedRolesSelector";

import createFormActions from "modules/form/actions";

import { createForm } from "modules/form";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import {
  tenantRolesModal,
  onTenantRoleRemove,
} from "state/roleManagement/actions/tenantRoles";
import createList, { Blocks } from "modules/list";
import { RoleSchema } from "utils/schemas";

const AddRoleWrap = styled.div`
  border-width: 1px 0;

  &:last-child {
    border-width: 0 0 1px 0;
  }

  border-style: solid;
  border-color: ${winterMist};
  padding: 11px;
  margin: 0 -20px;
`;

const AddRoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const Title = styled.span`
  text-transform: uppercase;
`;

const Button = styled(TextButton)`
  text-align: right;
  min-width: 63px;
`;

export default function createTenantRoleManagement({
  updateData,
  getSelectedEntityRoles,
  listActions,
}) {
  const moduleName = "tenantRoles";
  const tenantRolesValidator = new Validator();
  tenantRolesValidator.addRule(["roles"], Missing());

  const formActions = createFormActions({
    validator: tenantRolesValidator,
    submit: updateData,
    init: () => {
      return Promise.resolve({
        roles: getSelectedEntityRoles().map((role) => role.guid),
      });
    },
  });

  const TenantRolesForm = createForm({
    Component: function Form() {
      return <TenantRoleSelector name="roles" />;
    },
    actions: formActions,
  });

  function openModal() {
    return (dispatch) => {
      dispatch(formActions.init({ module: moduleName }));

      tenantRolesModal.open().then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [RoleSchema],
  });

  function Item({ data, loading, onRemove }) {
    return (
      <RoleCard
        key={data.guid}
        tenantRole={data}
        loading={loading}
        inherited={data.metadata?.inherited}
        onRemove={() => onRemove(data.guid)}
      />
    );
  }

  const ConnectedItem = connect(
    (state) => ({
      loading: state.roleManagement.loading,
    }),
    {
      onRemove: onTenantRoleRemove,
    }
  )(Item);

  function TenantRolesModule({ openModal }) {
    const { t } = useTranslation();

    return (
      <>
        <AddRoleWrap>
          <AddRoleHeader>
            <Title>{t("tenant roles")}</Title>
            <Button onClick={() => openModal(null)} data-qa="add_tenant_role">
              {t("Add Role")}
            </Button>
          </AddRoleHeader>
          <ListModule module={moduleName}>
            <Blocks.List Item={ConnectedItem} />
          </ListModule>
        </AddRoleWrap>
        <Modal service={tenantRolesModal} title={t("Add Roles")}>
          <TenantRolesForm module={moduleName} />
        </Modal>
      </>
    );
  }

  return connect(null, {
    openModal,
  })(TenantRolesModule);
}
