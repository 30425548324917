import api from "services/api";
import { RoleBindingSchema } from "utils/schemas";
import ListActions from "modules/list/actions";

export const rbacListActions = new ListActions({
  schema: [RoleBindingSchema],
  fetchData() {
    return api.get("v1alpha1/users/assets/clusterrbacs");
  },
});
