import entities from "utils/entities";

export const ManifestSchema = entities.schema.Definition("packManifest", {
  _idAttribute: "uid",
});

export const PackSchema = entities.schema.Definition("pack", {
  manifests: [ManifestSchema],
  spec: {
    manifests: [ManifestSchema],
  },
});
export const PackVersionSchema = entities.schema.Definition(
  "packVersion",
  {
    pack: PackSchema,
    manifests: [ManifestSchema],
    spec: {
      manifests: [ManifestSchema],
    },
  },
  {
    pack: (value, key, entity) => ({
      metadata: { name: entity.name, uid: entity.packUid },
      manifests: entity.manifests || [],
    }),
    params: {},
  }
);

export const ClusterProfileTemplateSchema = entities.schema.Definition(
  "clusterProfileTemplate",
  {
    packs: [PackVersionSchema],
  }
);

export const NodeSchema = entities.schema.Definition("node", {}, []);

export const NodePoolSchema = entities.schema.Definition(
  "nodePools",
  {
    _idAttribute: "uid",
    nodes: [NodeSchema],
  },
  {
    size: 0,
    isControlPlane: false,
  }
);

export const CloudAccountSchema = entities.schema.Definition(
  "cloudaccount",
  {},
  {
    name: "",
  }
);

export const CloudConfigSchema = entities.schema.Definition(
  "cloudConfig",
  {
    spec: {
      machinePoolConfig: [NodePoolSchema],
      cloudAccount: CloudAccountSchema,
    },
  },
  {
    spec: (value = {}, key, data) => ({
      ...value,
      machinePoolConfig: (value.machinePoolConfig || []).map((pool) => ({
        ...pool,
        uid: `${data.metadata.uid}_${pool.name}`,
      })),
      cloudAccount: {
        metadata: {
          uid: value.cloudAccountRef?.uid,
          name: value.cloudAccountRef?.name,
        },
      },
    }),
  }
);

export const NotificationEvent = entities.schema.Definition(
  "notificationEvent",
  {
    pack: PackSchema,
    manifest: ManifestSchema,
    profile: entities.schema.Definition("clusterprofile"),
  },
  {
    _idAttribute: "uid",
    pack(key, value, data) {
      return { metadata: { uid: data.packUid, name: data.packName } };
    },
    profile(key, value, data) {
      return { metadata: { uid: data.profileUid } };
    },
    manifest(key, value, data) {
      if (!data.manifestUid) {
        return undefined;
      }
      return {
        name: data.manifestName,
        uid: data.manifestUid,
        metadata: {
          uid: data.manifestUid,
          name: data.manifestName,
        },
        spec: {
          parentUid: data.manifestParentUid,
        },
      };
    },
  }
);

export const NotificationSchema = entities.schema.Definition(
  "notification",
  {
    events: [NotificationEvent],
  },
  {
    events(value, key, data) {
      const events = data?.action?.events || {};

      return Object.keys(events).map((key) => ({
        ...events[key],
        uid: key,
      }));
    },
  }
);

export const ClusterProfileSchema = entities.schema.Definition(
  "clusterprofile",
  {
    spec: {
      draft: ClusterProfileTemplateSchema,
      published: ClusterProfileTemplateSchema,
      packs: [PackSchema],
    },
    notifications: [NotificationSchema],
  },
  {
    spec(value, key, entity) {
      return { ...entity.specSummary, ...(value || {}) };
    },
  }
);

export const ClusterSchema = entities.schema.Definition(
  "cluster",
  {
    spec: {
      cloudConfig: CloudConfigSchema,
      clusterprofile: ClusterProfileSchema,
      clusterProfileTemplate: ClusterProfileTemplateSchema,
      clusterProfileTemplates: [ClusterProfileTemplateSchema],
    },
    notifications: [NotificationSchema],
  },
  {
    spec(value) {
      if (!value) {
        return {};
      }
      const spec = { ...value };
      if (value.clusterProfileRef) {
        spec.clusterprofile = {
          metadata: value.clusterProfileRef,
        };
      }

      if (value.cloudConfigRef) {
        spec.cloudConfig = {
          metadata: value.cloudConfigRef,
        };
      }

      return spec;
    },
  }
);

export const BackupLocationSchema = entities.schema.Definition(
  "backuplocation",
  {}
);
export const RegionSchema = entities.schema.Definition("region", {});
export const CredentialSchema = entities.schema.Definition("credential", {});
export const SSHSchema = entities.schema.Definition("ssh", {});
export const RepositorySchema = entities.schema.Definition("repository");
export const HelmRepositorySchema = entities.schema.Definition(
  "helmRepository"
);
export const VpcidSchema = entities.schema.Definition("vpcid");

export const EventSchema = entities.schema.Definition("clusterevent", {}, {});
export const AuditSchema = entities.schema.Definition("auditlogs", {}, {});

export const CertificateSchema = entities.schema.Definition(
  "certificate",
  {},
  {}
);

export const MaintenancePoliciesSchema = entities.schema.Definition(
  "maintenancepolicies",
  {},
  {}
);
export const ProjectSchema = entities.schema.Definition("project", {}, {});
export const ProjectPermissionsSchema = entities.schema.Definition(
  "projectPermissions",
  {
    _idAttribute: "projectUid",
    project: ProjectSchema,
  },
  {
    project(key, value, entity) {
      return { metadata: { uid: entity.projectUid } };
    },
  }
);

export const RoleSchema = entities.schema.Definition("role", {}, {});
export const ProjectRolesSchema = entities.schema.Definition(
  "projectRoles",
  {
    project: ProjectSchema,
    roles: [RoleSchema],
    inheritedRoles: [RoleSchema],
  },
  {}
);

export const TenantRolesSchema = entities.schema.Definition(
  "tenantRoles",
  {},
  {}
);

export const UserSchema = entities.schema.Definition(
  "user",
  {
    spec: {
      roles: [RoleSchema],
      projects: [ProjectSchema],
    },
  },
  {
    spec: (value = {}) => ({
      ...value,
      projects: entities.schema.UidStrings(value.projects || []),
      teams: entities.schema.UidStrings(value.teams || []),
      roles: entities.schema.UidStrings(value.roles || []),
    }),
  }
);

export const TeamSchema = entities.schema.Definition(
  "team",
  {
    spec: {
      roles: [RoleSchema],
      projects: [ProjectSchema],
      users: [UserSchema],
    },
  },
  {
    spec: (value = {}) => ({
      ...value,
      projects: entities.schema.UidStrings(value.projects || []),
      users: entities.schema.UidStrings(value.users || []),
      roles: entities.schema.UidStrings(value.roles || []),
    }),
  }
);

export const MemberSchema = entities.schema.Definition("member", {}, {});

export const OverlordSchema = entities.schema.Definition("overlord", {}, {});

export const DatacenterSchema = entities.schema.Definition(
  "datacenter",
  {},
  {}
);

export const PackRegistriesSchema = entities.schema.Definition(
  "packregistries",
  {},
  {}
);

export const HelmRegistriesSchema = entities.schema.Definition(
  "helmregistries",
  {},
  {}
);

export const IPPoolSchema = entities.schema.Definition("ipPool", {}, {});

export const SSHKeysSchema = entities.schema.Definition("sshKeys", {}, {});
export const DNSMappingSchema = entities.schema.Definition(
  "dnsmapping",
  {},
  {}
);

export const InvoiceProjectSchema = entities.schema.Definition(
  "invoiceProject",
  {
    _idAttribute: "projectUid",
    project: ProjectSchema,
  },
  {
    project(key, value, entity) {
      return { metadata: { uid: entity.projectUid } };
    },
  }
);

export const InvoiceSchema = entities.schema.Definition("invoice", {
  spec: {
    project: [InvoiceProjectSchema],
  },
});

export const ClusterScanSchema = entities.schema.Definition("scan", {});
export const BackupSchema = entities.schema.Definition("backup", {}, {});

export const BackupStatusSchema = entities.schema.Definition(
  "backupStatus",
  {},
  {}
);

export const RestoreStatusSchema = entities.schema.Definition(
  "restoreStatus",
  {},
  {}
);

export const CertificateAuthoritySchema = entities.schema.Definition(
  "certificateAuthority",
  {},
  {}
);

export const RoleBindingSchema = entities.schema.Definition(
  "clusterRoleBinding",
  {},
  {}
);
