import i18n from "i18next";

import ListActions from "modules/list/actions";
import { OverlordSchema } from "utils/schemas";
import api from "services/api";
import notifications from "services/notifications";

import { deleteOverlordConfirmation, OVERLORD_MODULE } from "../services/list";
import { getStoreEntity } from "services/store";

export const overlordsListActions = new ListActions({
  async fetchData() {
    const overlords = await api.get("v1alpha1/overlords").then((res) =>
      res.items.map((overlord, index) => ({
        ...overlord,
        index,
        status: {
          ...overlord.status,
          state: overlord.status.state.toLowerCase(),
        },
      }))
    );

    return Promise.resolve({
      items: overlords,
    });
  },

  schema: [OverlordSchema],
});

export function resetOverlord(uid) {
  return async function thunk(dispatch) {
    const promise = api.put(`v1alpha1/overlords/vsphere/${uid}/reset`);
    dispatch({
      type: "OVERLORD_RESET",
      uid,
      promise,
    });

    try {
      const { msg } = await promise;
      notifications.success({
        message: i18n.t("Private cloud gateway reset successfully"),
        description: msg,
      });
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while reseting the private cloud gateway"
        ),
        description: err?.message,
      });
    }
  };
}

export function deleteOverlord(guid) {
  return (dispatch) => {
    deleteOverlordConfirmation.open({ guid }).then(async () => {
      const overlord = getStoreEntity(
        deleteOverlordConfirmation.data.guid,
        OverlordSchema
      );

      const promise = api.delete(`v1alpha1/overlords/${overlord.metadata.uid}`);

      dispatch({
        type: "DELETE_OVERLORD",
        promise,
        guid,
      });

      try {
        const { msg } = await promise;
        dispatch(overlordsListActions.initialize(OVERLORD_MODULE));
        notifications.success({
          message: i18n.t(
            'Private cloud gateway "{{overlordName}}" has been deleted successfully',
            { overlordName: overlord.metadata.name || "(unnamed)" }
          ),
          description: msg,
        });
      } catch (error) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to delete the private cloud gateway"
          ),
          description: error.message,
        });
      }
    });
  };
}
