import ListActions from "modules/list/actions";
import querystring from "querystring";
import dataFetcher from "modules/dataFetcher";
import ModalService from "services/modal";
import api from "services/api";

import {
  BackupStatusSchema,
  BackupSchema,
  RestoreStatusSchema,
} from "utils/schemas";
import createFormActions from "modules/form/actions";
import history from "services/history";
import store from "services/store";
import { getRawCluster } from "state/cluster/selectors/details";

export const BACKUP_LIST_MODULE = "backupList";
export const RESTORE_LIST_MODULE = "restoreList";
export const RESTORE_FORM_MODULE = "restore";
export const BACKUP_FILTERS_MODULE = "backupFilters";
export const CREATE_BACKUP_FORM_MODULE = "backup";
export const SCHEDULE_BACKUPS_MODULE = "scheduleBackups";

export const backupDetailsModal = new ModalService("backupDetails");
export const deleteBackupConfirmation = new ModalService("deleteBackup");
export const restoreFromBackupModal = new ModalService("restoreFromBackup");
export const createBackupModal = new ModalService("createBackup");

function getQueryFromFilters(data) {
  const { searchTerm } = data;
  return {
    searchTerm: searchTerm || "",
  };
}

export const backupsListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [BackupStatusSchema],
  async fetchData() {
    const response = await store.dispatch(fetchBackupStatuses());

    const items = response?.status?.clusterBackupStatuses?.reduce(
      (acc, backup) => {
        if (
          backup?.state?.toLowerCase() === "scheduled" &&
          !backup.backupStatusMeta?.length
        ) {
          // No scheduled backups yet
          return acc;
        }
        if (!backup?.state && !backup.backupStatusMeta?.length) {
          return acc;
        }
        if (
          backup?.actor?.actorType === "clusterPolicy" &&
          !backup.backupStatusMeta?.length &&
          backup?.state !== "failed"
        ) {
          return acc;
        }

        if (backup?.backupStatusMeta?.length) {
          backup.backupStatusMeta.forEach((status) => {
            acc.push({
              ...backup,
              backupStatusMeta: [status],
            });
          });
          return acc;
        }

        acc.push(backup);
        return acc;
      },
      []
    );

    return {
      items,
    };
  },
});

export const restoresListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [RestoreStatusSchema],
  async fetchData() {
    const cluster = getRawCluster(store.getState());
    let response;
    try {
      response = await api.get(
        `v1alpha1/spectroclusters/${cluster.metadata.uid}/features/restore`
      );
    } catch (err) {}

    const items = response?.status?.clusterRestoreStatuses?.reduce(
      (acc, restore) => {
        if (restore?.restoreStatusMeta?.length) {
          restore.restoreStatusMeta.forEach((status) => {
            acc.push({
              ...restore,
              restoreStatusMeta: [status],
            });
          });
          return acc;
        }

        acc.push(restore);
        return acc;
      },
      []
    );

    return {
      items,
    };
  },
});

export function fetchBackupStatuses() {
  return async (dispatch) => {
    const promise = dispatch(backupStatusesFetcher.fetch());
    let response;

    try {
      response = await promise;
    } catch (error) {}

    return response;
  };
}

export const backupStatusesFetcher = dataFetcher({
  selectors: ["backuplist", (state) => getRawCluster(state)?.metadata?.uid],
  fetchData([_, clusterUid]) {
    return api.get(`v1alpha1/spectroclusters/${clusterUid}/features/backup`);
  },
});

export const backupFetcher = dataFetcher({
  selectors: ["backup", (state) => getRawCluster(state)?.metadata?.uid],
  schema: BackupSchema,
  async fetchData([_, clusterUid], { backupUid, backupName }) {
    const response = await api.get(
      `v1alpha1/spectroclusters/${clusterUid}/features/backup?backupRequestUid=${backupUid}`
    );
    const backupStatusMeta =
      response?.status?.clusterBackupStatuses?.[0]?.backupStatusMeta;

    if (backupStatusMeta?.length > 1) {
      response.status.clusterBackupStatuses[0].backupStatusMeta = backupStatusMeta.filter(
        (backup) => backup.backupName === backupName
      );
    }

    return response;
  },
});

export const filterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    return Promise.resolve({
      searchTerm: query.searchTerm || "",
    });
  },
  submit: (data) => {
    const query = getQueryFromFilters(data);
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
    store.dispatch(
      backupsListActions.batchChangeQuery({
        module: BACKUP_LIST_MODULE,
        query,
      })
    );
  },
});

export const clusterListFetcher = dataFetcher({
  selectors: ["clusters", (state) => getRawCluster(state)?.metadata?.uid],
  async fetchData([_, currentClusterUid], query) {
    const { search } = query || {};
    const filters = {
      sort: "name",
      filter: {
        name: {
          beginsWith: search || "",
        },
        state: "Running",
      },
    };
    const response = await api.post(
      "v1alpha1/dashboard/spectroclusters/metadata",
      filters
    );

    return {
      items: response?.items,
      currentClusterUid,
    };
  },
});
