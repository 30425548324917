import isEmpty from "lodash/isEmpty";
import { SPECTRO_FOLDER_PREFIX, SPECTRO_TAG } from "./constants";
import { mergeYaml } from "./yaml";
import * as YAML from "yaml";
import { getTerminalKeys } from "./objects";
import get from "lodash/get";
import set from "lodash/set";

const presetsCommentRegex = /^(# spectrocloud.com\/enabled-presets:).*/gm;

export function parseTagsForInput(tags) {
  return ((tags && Object.keys(tags)) || []).map((key) => {
    const value = tags[key];
    if (value !== SPECTRO_TAG) {
      return `${key}:${value}`;
    }

    return key;
  });
}

export function flattenObjectKeys(data = {}) {
  let flattenedObject = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value && typeof value === "object") {
      let flatObject = flattenObjectKeys(value);
      Object.keys(flatObject).forEach((flatObjectKey) => {
        const flatObjectValue = flatObject[flatObjectKey];
        flattenedObject[key + "." + flatObjectKey] = flatObjectValue;
      });
    } else {
      flattenedObject[key] = value;
    }
  });

  return flattenedObject;
}

export function parseYAMLValues(values = "", presetValues) {
  if (!presetValues) {
    return;
  }

  try {
    const addValues = presetValues.add?.replace(/\u21b5/g, "\n") || "";
    const parsedYaml = mergeYaml(values, addValues);
    const removePaths = presetValues.remove || [];

    removePaths.forEach((path) => {
      const pathArray = path.split(".");
      const keyExists = parsedYaml.hasIn(pathArray);

      if (keyExists) {
        parsedYaml.deleteIn(pathArray);
        const parentPath = pathArray.slice(0, -1);
        const node = parsedYaml.getIn(parentPath);
        if (node?.items && node.items.length === 0) {
          parsedYaml.setIn(parentPath, null);
        }
      }
    });
    return parsedYaml.toString();
  } catch (err) {
    console.error(err);
  }
}

// presets changes
// a ----- changes values ----> a' ---- applies preset1 ---> a''
// a'' ------ applies preset2 ====> a''' - means that we need the state
// in which a' was before applying the preset1 on top of which we
// apply the preset2
// unapplying a preset means that we remove what has been added
// and add what was removed from preset1 populated with the pack defaults
export function getPreviousPresetDelta({
  presetValues,
  presets,
  group,
  values,
  defaultValues,
}) {
  let prevPreset = presetValues[group];
  if (prevPreset) {
    prevPreset = presets.find(
      (preset) => preset.group === group && preset.value === prevPreset
    );
  }

  if (!prevPreset) {
    return "";
  }

  const defaults = YAML.parseDocument(defaultValues).toJS();
  const removedDefaults = (prevPreset.remove || []).reduce(
    (accumulator, key) => {
      const path = key.split(".");
      const value = get(defaults, path);
      if (value !== undefined) {
        set(accumulator, path, value);
      }

      return accumulator;
    },
    {}
  );
  return parseYAMLValues(values, {
    remove: getTerminalKeys(YAML.parseDocument(prevPreset.add).toJS()),
    add: YAML.stringify(removedDefaults),
  });
}

export function getDefaultPresetsFromValues(values = "") {
  const newValues = values?.replace(/\u21b5/g, "\n") || "";
  const comment = newValues.match(presetsCommentRegex)?.[0];
  const presetValues =
    comment && comment.trim().split("spectrocloud.com/enabled-presets:")?.[1];
  if (presetValues) {
    const groups = presetValues.split(",");
    return groups.reduce((acc, group) => {
      const [key, value] = group.trim().split(":");
      if (value) {
        return {
          ...acc,
          [key]: value.trim(),
        };
      }
      return {
        ...acc,
        "": key,
      };
    }, {});
  }
  return {};
}

export function getPackValuesWithoutPresetsComment(values = "") {
  const newValues = values?.replace(/\u21b5/g, "\n");
  return (newValues?.replace(presetsCommentRegex, "") || "").trim();
}

export function getPackValuesWithPresetsComment(values = "", presets) {
  if (isEmpty(presets)) {
    return (values || "").trim();
  }

  const enabledPresets = "# spectrocloud.com/enabled-presets: ";
  const presetValues = Object.keys(presets)
    .reduce((acc, key) => {
      if (key === "") {
        return [...acc, presets[key]];
      }
      return [...acc, `${key}:${presets[key]}`];
    }, [])
    .join(",");
  const comment = `${enabledPresets}${presetValues}`;
  return `${comment}\n${values}`.trim();
}

export function getVsphereFolderPayload(formData) {
  return formData.useFolderSufix
    ? `${formData.folder}/${SPECTRO_FOLDER_PREFIX}${formData.name}`
    : formData.folder;
}

export function trim(data = {}, fields) {
  let keys = fields;

  if (!Array.isArray(fields)) {
    keys = [fields];
  }

  return keys.reduce(
    (acc, field) => {
      if (typeof acc[field] === "string") {
        acc[field] = acc[field].trim();
      }
      return acc;
    },
    { ...data }
  );
}

export function getBoolean(value) {
  if (typeof value === "string") {
    return value.toLowerCase() === "true";
  }
  return !!value;
}

export function extractPath(obj, path) {
  let currentPath = path || "";
  const objectKeys = Object.keys(obj);
  if (typeof obj !== "object") {
    return currentPath;
  }

  const lastKey = objectKeys[objectKeys.length - 1];

  if (objectKeys.length > 0) {
    currentPath = currentPath ? currentPath + "." + lastKey : lastKey;
    return extractPath(obj[lastKey], currentPath);
  }

  return currentPath;
}

export function getCronScheduleValue(schedule, occurrence) {
  if (!schedule || schedule === "never") {
    return "";
  }
  return schedule === "custom" ? occurrence || "* * * * *" : schedule;
}
