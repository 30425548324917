const RELEASED_FLAGS = [
  "beta",
  "azure",
  "gcp",
  "audit",
  "notifications",
  "saml",
  "billing",
  "brownfield",
  "backup",
  "scans",
  "openstack",
  "aks",
  "oidc",
];

export class Flags {
  constructor(params = "") {
    if (!Array.isArray(params)) {
      this.params = params.split(",") || [];
      return;
    }
    this.params = params;
  }

  update(flags) {
    this.params = flags;
  }

  has(list = []) {
    if (this.params.includes("all")) {
      return true;
    }

    let flags = list;
    if (!Array.isArray(list)) {
      flags = [flags];
    }

    if (list.length === 0) {
      return true;
    }

    return flags.some((param) => this.params.includes(param));
  }

  is(list = []) {
    if (this.params.includes("all")) {
      return true;
    }

    let flags = list;
    if (!Array.isArray(list)) {
      flags = [flags];
    }

    if (list.length === 0) {
      return true;
    }

    return flags.every((param) => this.params.includes(param));
  }
}

const flags = new Flags(`${process.env.FLAGS},${RELEASED_FLAGS.join(",")}`);

export default flags;
