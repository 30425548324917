import i18next from "i18next";
import { createOpenstackFormFactory } from "modules/cluster/openstack";
import createFormActions from "modules/form/actions";
import api from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { getOverlordData } from "state/overlord/selectors/configure";
import {
  ApplyIf,
  areValidIPTags,
  isValidIPRange,
  Missing,
} from "services/validator/rules";
import {
  overlordConfigureModal,
  OVERLORD_ACCOUNT_MODULE,
  OVERLORD_CLOUD_MODULE,
  setOverlordNodesModal,
} from "state/overlord/services/list";
import { fetchOverlordConfiguration } from "state/overlord/actions/configure";

const openstackOverlordAccountValidator = new Validator();
const openstackOverlordCloudValidator = new Validator();

openstackOverlordAccountValidator.addRule(
  [
    "name",
    "username",
    "password",
    "identityEndpoint",
    "parentRegion",
    "defaultDomain",
  ],
  Missing()
);

openstackOverlordAccountValidator.addRule(
  ["caCert"],
  ApplyIf((value, key, data) => !data.insecure, Missing())
);

export const openstackOverlordAccountFormActions = createFormActions({
  validator: openstackOverlordAccountValidator,
  init: async () => {
    const overlordUid = store.getState().location?.params?.uid;
    if (overlordUid) {
      await store.dispatch(fetchOverlordConfiguration(overlordUid));
    }

    const overlordData = getOverlordData(store.getState());
    const cloudAccountUid = overlordData?.spec?.cloudAccountUid;

    if (!cloudAccountUid) {
      return Promise.resolve({
        name: overlordData?.metadata?.name || "new-cloud-gateway",
        username: "",
        password: "",
        identityEndpoint: "",
        caCert: "",
        parentRegion: "",
        defaultDomain: "",
        insecure: true,
        shareWithProjects: true,
        defaultProject: "",
      });
    }

    const data = await api.get(
      `v1alpha1/cloudaccounts/openstack/${cloudAccountUid}`
    );

    return Promise.resolve({
      name: data.metadata.name,
      ...data.spec,
      shareWithProjects: data.metadata.annotations.scopeVisibility === "20",
    });
  },
  submit: async function thunk(data) {
    const overlordData = getOverlordData(store.getState());
    const payload = {
      account: {
        caCert: !data.insecure ? data.caCert.trim() : undefined,
        defaultDomain: data.defaultDomain,
        identityEndpoint: data.identityEndpoint,
        insecure: data.insecure,
        parentRegion: data.parentRegion,
        password: data.password,
        username: data.username,
        defaultProject: data.defaultProject,
      },
      name: data.name,
      shareWithProjects: data.shareWithProjects,
    };

    let promiseMethod = api.post;

    if (overlordData?.spec?.cloudAccountUid) {
      promiseMethod = api.put;
    }

    const promise = promiseMethod(
      `v1alpha1/overlords/openstack/${overlordConfigureModal.data.uid}/account`,
      payload
    );

    store.dispatch({
      type: "CONFIGURE_OVERLORD_ACCOUNT",
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to create openstack cloud account"
        ),
        description: err?.message,
      });
      return;
    }
  },
});

openstackOverlordCloudValidator.addRule(
  ["region", "domain", "project", "sshKeyName", "flavor", "azs"],
  Missing()
);

openstackOverlordCloudValidator.addRule(
  ["network"],
  ApplyIf((value, key, data) => data.placement === "static", Missing())
);

openstackOverlordCloudValidator.addRule(
  ["nodeCidr", "dnsNameservers"],
  ApplyIf((value, key, data) => data.placement === "dynamic", Missing())
);

openstackOverlordCloudValidator.addRule(
  ["nodeCidr"],
  ApplyIf((value, key, data) => data.placement === "dynamic", isValidIPRange())
);

openstackOverlordCloudValidator.addRule(
  ["dnsNameservers"],
  ApplyIf((value, key, data) => data.placement === "dynamic", areValidIPTags())
);

openstackOverlordCloudValidator.addRule(
  ["subnet"],
  ApplyIf((value, key, data) => {
    const state = store.getState();
    const subnets = overlordCloudForm.selectors.getOpenstackNetworkSubnets(
      state
    );
    return data.network && subnets.length > 0;
  }, Missing())
);

export const openStackOverlordCloudFormActions = createFormActions({
  validator: openstackOverlordCloudValidator,
  init: async () => {
    let overlordData = getOverlordData(store.getState());
    const spectroClusterUid = overlordData?.spec?.spectroClusterUid;

    await store.dispatch(fetchOverlordConfiguration(overlordData.metadata.uid));
    overlordData = getOverlordData(store.getState());

    if (!spectroClusterUid) {
      const accountFormData = store.getState().forms[OVERLORD_ACCOUNT_MODULE]
        ?.data;

      const defaults = await overlordCloudForm.effects.getDefaultsFromAccount(
        accountFormData
      );

      return Promise.resolve({
        ...defaults,
        sshKeyName: "",
        mode: "configure",
        placement: "dynamic",
        network: "",
        subnet: "",
        dnsNameservers: [],
        nodeCidr: "",
        flavor: "",
        azs: [],
        size: 1,
      });
    }

    const cluster = await api.get(
      `v1alpha1/spectroclusters/${spectroClusterUid}`
    );

    const cloudConfig = await api.get(
      `v1alpha1/cloudconfigs/${cluster.spec.cloudConfigRef.kind}/${cluster.spec.cloudConfigRef.uid}`
    );

    const clusterConfig = cloudConfig?.spec?.clusterConfig;
    const machineConfig = cloudConfig?.spec?.machinePoolConfig?.[0];

    return Promise.resolve({
      name: overlordData.metadata.name,
      ...clusterConfig,
      domain: clusterConfig?.domain?.name || "",
      project: clusterConfig?.project?.name || "",
      network: clusterConfig?.network?.name || "",
      subnet: clusterConfig?.subnet?.name || "",
      flavor: machineConfig?.flavorConfig?.name || "",
      azs: machineConfig?.azs || [],
      size: machineConfig?.size,
      mode: "configure-edit",
    });
  },
  submit: async (data) => {
    const selectors = overlordCloudForm.selectors;
    const state = store.getState();
    const selectedDomain = selectors.getOpenstackSelectedDomain(state);
    const selectedFlavor = selectors.getOpenstackSelectedFlavor(state);
    const selectedNetwork = selectors.getOpenstackSelectedNetwork(state);
    const selectedNetworkSubnet = selectors.getOpenstackSelectedNetworkSubnet(
      state
    );

    const projects = overlordCloudForm.fetchers.projectsFetcher.selector(state)
      ?.result;
    const selectedProject = (projects?.items || []).find(
      (project) => project.name === data.project
    );

    const placementOptions =
      data.placement === "static"
        ? {
            network: {
              id: selectedNetwork.id,
              name: selectedNetwork.name,
            },
            subnet: selectedNetworkSubnet
              ? {
                  id: selectedNetworkSubnet.id,
                  name: selectedNetworkSubnet.name,
                }
              : undefined,
          }
        : { nodeCidr: data.nodeCidr, dnsNameservers: data.dnsNameservers };

    const payload = {
      clusterConfig: {
        domain: {
          id: selectedDomain.id,
          name: selectedDomain.name,
        },
        project: {
          id: selectedProject?.id,
          name: selectedProject.name,
        },
        region: data.region,
        sshKeyName: data.sshKeyName,
        ...placementOptions,
      },
      machineConfig: {
        azs: data.azs,
        flavorConfig: {
          numCPUs: selectedFlavor.vcpus,
          memoryMiB: selectedFlavor.memory,
          name: selectedFlavor.name,
          diskGiB: selectedFlavor.disk,
        },
      },
      size: data.size,
    };

    const overlordData = getOverlordData(store.getState());
    let promiseMethod = api.post;
    if (overlordData?.spec?.spectroClusterUid) {
      promiseMethod = api.put;
    }

    const promise = promiseMethod(
      `v1alpha1/overlords/openstack/${overlordConfigureModal.data?.uid ||
        setOverlordNodesModal.data?.uid}/cloudconfig`,
      payload
    );

    store.dispatch({
      type: "CREATE_OVERLORD_OPENSTACK_CLOUD_CONFIG",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Private cloud gateway configuration saved successfully"
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "We could not save the cloud config for the overlord"
        ),
        description: err.message,
      });
    }

    return promise;
  },
});

export const overlordCloudForm = createOpenstackFormFactory(
  {
    formActions: openStackOverlordCloudFormActions,
    formModuleName: OVERLORD_CLOUD_MODULE,
    getCloudAccountUid(state) {
      return getOverlordData(state).spec.cloudAccountUid;
    },
  },
  { isOverlord: true }
);

export const overlordAccountForm = createOpenstackFormFactory(
  {
    formActions: openstackOverlordAccountFormActions,
    formModuleName: OVERLORD_ACCOUNT_MODULE,
  },
  { isOverlord: true }
);
