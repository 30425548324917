import i18n from "i18next";

import store from "services/store";
import api from "services/api";
import Validator from "services/validator";
import {
  Missing,
  ApplyIf,
  isKubernetesName,
  MaxLength,
} from "services/validator/rules";
import historyService from "services/history";
import notifications from "services/notifications";

import createActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { SETTINGS } from "utils/constants/routes";
import { getInitialClusterRoleBindings } from "state/cluster/selectors/details";
import { getClusterRoleBindingsPayload } from "../selectors";
import { getCluster } from "state/cluster/selectors/details";

import {
  rbacFormModal,
  RBAC_FORM_MODULE,
  clusterRbacFetcher,
  clusterRbacsFetcher,
} from "../services";

const validator = new Validator();
const bindingValidator = new Validator();
const subjectValidator = new Validator();

validator.addRule(["name"], Missing());
validator.addRule("bindings", bindingValidator);

bindingValidator.addRule(["roleType", "roleName"], Missing());
bindingValidator.addRule(
  ["namespace"],
  ApplyIf((value, key, data) => data.bindingType === "RoleBinding", Missing())
);
bindingValidator.addRule("subjects", subjectValidator);
bindingValidator.addRule("roleName", isKubernetesName());
bindingValidator.addRule("roleName", MaxLength(32));

subjectValidator.addRule(["type", "name"], Missing());
subjectValidator.addRule(
  "namespace",
  ApplyIf((value, key, data) => data.type === "ServiceAccount", Missing())
);
subjectValidator.addRule(
  "namespace",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);
subjectValidator.addRule(
  "name",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);

function getPayload(data = {}, uid) {
  const { name, bindings, isDefault } = data;

  return {
    metadata: {
      name,
      uid,
    },
    spec: {
      bindings: bindings?.map((binding) => {
        const {
          createNamespace,
          namespace,
          bindingType,
          roleType,
          roleName,
          subjects,
        } = binding || {};

        return {
          createNamespace,
          namespace,
          role: {
            kind: roleType,
            name: roleName,
          },
          subjects,
          type: bindingType,
        };
      }),
      isDefault,
    },
  };
}

export function getSubject(data) {
  return {
    type: data?.type || "User",
    name: data?.name || "",
    namespace: data?.namespace || "",
  };
}

export function getBinding(data) {
  return {
    createNamespace: !!data?.createNamespace,
    namespace: data?.namespace || "",
    bindingType: data?.type || "RoleBinding",
    roleType: data?.role?.kind || "Role",
    roleName: data?.role?.name || "",
    subjects: data?.subjects?.length
      ? data.subjects.map((subject) => getSubject(subject))
      : [],
  };
}

export const rbacFormActions = createActions({
  init: async () => {
    const defaultBinding = [getBinding()];
    let data;

    if (rbacFormModal?.data?.uid) {
      data = await store.dispatch(clusterRbacFetcher.fetch());
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      isDefault: !!data?.spec?.isDefault,
      bindings: data?.spec?.bindings?.length
        ? data.spec.bindings.map((binding) => getBinding(binding))
        : defaultBinding,
    });
  },
  submit: async (data) => {
    const uid = rbacFormModal.data?.uid;
    const payload = getPayload(data, uid);
    const promise = uid
      ? api.put(`v1alpha1/users/assets/clusterrbacs/${uid}`, payload)
      : api.post("v1alpha1/users/assets/clusterrbacs", payload);
    let response;

    try {
      response = await promise;
    } catch (err) {
      const message = uid
        ? i18n.t("Something went wrong when editing the role binding")
        : i18n.t("Something went wrong when creating the role binding");

      notifications.error({
        message,
        description: err.message,
      });
      return Promise.reject(err);
    }

    rbacFormModal.close();

    notifications.success({
      message: uid
        ? i18n.t("Role binding '{{name}}' has been updated successfully", {
            name: data?.name,
          })
        : i18n.t("Role binding '{{name}}' has been created successfully", {
            name: data?.name,
          }),
    });
    return response;
  },
  validator,
});

export const clusterRoleBindings = createActions({
  init: async () => {
    await store.dispatch(clusterRbacsFetcher.fetch());
    const initialRoleBindings = getInitialClusterRoleBindings(store.getState());

    return Promise.resolve({
      roleBindingOptions: initialRoleBindings,
    });
  },
  submit: async () => {
    const cluster = getCluster(store.getState());
    const clusterRbac = getClusterRoleBindingsPayload(store.getState());
    const payload = { clusterRbac };
    const promise = api.patch(
      `v1alpha1/spectroclusters/${cluster?.metadata?.uid}/clusterConfig/clusterRbac`,
      payload
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to update the role bindings"
        ),
        description: err.message,
      });

      return Promise.reject(err);
    }

    notifications.success({
      message: i18n.t("Role bindings have been updated successfully"),
    });
  },
});

export const rbacAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(rbacFormActions.submit({ module: RBAC_FORM_MODULE }));
  },
});

export function openRbacModal(uid) {
  return (dispatch) => {
    rbacFormModal.open({ uid }).then(
      async () => {
        await rbacAsyncAction.trigger();
        historyService.push(SETTINGS.ROLE_BINDINGS);
      },
      () => historyService.push(SETTINGS.ROLE_BINDINGS)
    );
    dispatch(rbacFormActions.init({ module: RBAC_FORM_MODULE }));
  };
}

export function resetErrors(fieldName) {
  return (dispatch, getState) => {
    const errors = getState().forms[RBAC_FORM_MODULE]?.errors;
    const updatedErrors = errors.map((error) => {
      const shouldRemove = error.field.includes(fieldName);
      if (shouldRemove) {
        return { ...error, result: false };
      }
      return error;
    });

    dispatch(
      rbacFormActions.updateErrors({
        module: RBAC_FORM_MODULE,
        errors: updatedErrors,
      })
    );
  };
}
