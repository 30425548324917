import i18next from "i18next";
import createFormActions from "modules/form/actions";
import { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import { getCurrentUser } from "state/auth/selectors";

const validator = new Validator();
validator.addRule(
  [
    "issuerUrl",
    "clientId",
    "clientSecret",
    "callbackUrl",
    "logoutUrl",
    "scopes",
    "Email",
    "FirstName",
    "LastName",
    "SpectroTeam",
  ],
  Missing()
);

export const oidcFormActions = createFormActions({
  validator,
  init: async () => {
    const user = getCurrentUser(store.getState());
    const tenantUid = user?.metadata?.annotations?.tenantUid;
    const data = await nonProjectApi.get(
      `v1alpha1/tenants/${tenantUid}/oidc/config`
    );

    return Promise.resolve({
      issuerUrl: data?.issuerUrl || "",
      clientId: data?.clientId || "",
      clientSecret: data?.clientSecret || "",
      syncSsoTeams:
        typeof data?.syncSsoTeams === "undefined" ? true : data?.syncSsoTeams,
      defaultTeams: data?.defaultTeams || [],
      isSsoEnabled: data?.isSsoEnabled || false,
      callbackUrl: data?.callbackUrl || "",
      logoutUrl: data?.logoutUrl || "",
      Email: data?.requiredClaims?.Email || "",
      FirstName: data?.requiredClaims?.FirstName || "",
      LastName: data?.requiredClaims?.LastName || "",
      SpectroTeam: data?.requiredClaims?.SpectroTeam || "",
      scopes: data?.scopes || [],
      persisted: data?.isSsoEnabled || false,
    });
  },
  submit: async (data) => {
    const state = store.getState();
    const user = getCurrentUser(state);
    const tenantUid = user?.status?.tenant.tenantUid;

    const payload = {
      issuerUrl: data.issuerUrl,
      callbackUrl: data.callbackUrl,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      syncSsoTeams: data.syncSsoTeams,
      defaultTeams: data.defaultTeams,
      isSsoEnabled: state.sso?.enabledSso === "oidc",
      logoutUrl: data.logoutUrl,
      requiredClaims: {
        Email: data.Email,
        FirstName: data.FirstName,
        LastName: data.LastName,
        SpectroTeam: data.SpectroTeam,
      },
      scopes: data.scopes,
    };

    try {
      await nonProjectApi.post(
        `v1alpha1/tenants/${tenantUid}/oidc/config`,
        payload
      );

      notifications.success({
        message: i18next.t("OIDC configured successfully"),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong."),
        description: error.message,
      });
    }
  },
});
