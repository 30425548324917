import store from "services/store";
import i18n from "i18next";
import api from "services/api";

import historyService from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing, isValidUrl } from "services/validator/rules";

import createActions from "modules/form/actions";
import { packRegistriesActions, helmRegistriesActions } from "./list";
import {
  getSelectedPackRegistry,
  getSelectedHelmRegistry,
} from "state/packregistries/selectors";

import {
  packRegistryModal,
  packRegistriesFetcher,
  helmRegistryModal,
  helmRegistriesFetcher,
  PACK_MODULE,
  HELM_MODULE,
} from "state/packregistries/services";

export const validator = new Validator();
validator.addRule(["name", "endpoint"], Missing());
validator.addRule(["endpoint"], isValidUrl());
validator.addRule(["username", "password"], (value, key, data) => {
  if (data.noAuth) {
    return false;
  }
  return Missing()(value, key, data);
});

function getSubmitPayload({ name, endpoint, noAuth, username, password }) {
  return {
    metadata: {
      name,
    },
    spec: {
      name,
      endpoint,
      auth: {
        type: noAuth ? "noAuth" : "basic",
        username: noAuth ? undefined : username,
        password: noAuth ? undefined : password,
      },
    },
  };
}

function getValidationPayload(formData) {
  return {
    endpoint: formData.endpoint,
    auth: {
      username: formData.username,
      password: formData.password,
      type: "basic",
    },
  };
}

async function submit(data) {
  const selectedPackRegistry = getSelectedPackRegistry(store.getState());
  const payload = getSubmitPayload(data);

  let response;
  try {
    response = selectedPackRegistry
      ? await api.put(
          `v1alpha1/registries/pack/${selectedPackRegistry.metadata.uid}`,
          payload
        )
      : await api.post("v1alpha1/registries/pack", payload);
  } catch (error) {
    const message = selectedPackRegistry
      ? i18n.t("Something went wrong when editing a pack registry")
      : i18n.t("Something went wrong when creating a pack registry");

    notifications.error({
      message,
      description: error.message,
    });
  }

  if (selectedPackRegistry) {
    store.dispatch(packRegistriesActions.initialize(PACK_MODULE));
    historyService.push("/settings/registries/packregistries");
    return;
  }

  if (!response) {
    return;
  }

  historyService.push("/settings/registries/packregistries");
  store.dispatch(
    packRegistriesActions.addItems({
      module: PACK_MODULE,
      items: [
        {
          name: data.name,
          endpoint: data.endpoint,
          uid: response.uid,
        },
      ],
    })
  );
}

async function init() {
  let data;
  if (packRegistryModal?.data.uid) {
    data = await store.dispatch(packRegistriesFetcher.fetch());
  }

  return Promise.resolve({
    name: data?.metadata?.name || "",
    endpoint: data?.spec?.endpoint || "",
    username: data?.spec?.auth?.username || "",
    password: data?.spec?.auth?.password || "",
    isValid: !!packRegistryModal?.data?.uid,
  });
}

export function openPackRegistryModal(uid) {
  return (dispatch) => {
    packRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          packRegistryFormActions.submit({ module: PACK_MODULE })
        );
      },
      () => historyService.push("/settings/registries/packregistries")
    );
  };
}

export const packRegistryFormActions = createActions({
  init,
  submit,
  validator,
});

export function validatePackRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.packregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      packRegistryFormActions.validateForm({
        module: PACK_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = getValidationPayload(formData);
    const promise = api.post("v1alpha1/registries/pack/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your pack registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onResetField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: PACK_MODULE };
    const isPackRegistryValidated = getState().registry.isValid;

    if (isPackRegistryValidated) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(packRegistryFormActions.onChange(props));
  };
}

export const helmRegistryFormActions = createActions({
  init: async () => {
    let data;
    if (helmRegistryModal?.data?.uid) {
      data = await store.dispatch(helmRegistriesFetcher.fetch());
    }
    return Promise.resolve({
      name: data?.metadata?.name || "",
      endpoint: data?.spec?.endpoint || "",
      username: data?.spec?.auth?.username || "",
      password: data?.spec?.auth?.password || "",
      isValid: !!helmRegistryModal?.data?.uid,
      noAuth: helmRegistryModal?.data?.uid
        ? data?.spec?.auth?.type === "noAuth"
        : true,
    });
  },
  submit: async (data) => {
    const selectedRegistry = getSelectedHelmRegistry(store.getState());
    const payload = getSubmitPayload(data);

    let response;
    try {
      response = selectedRegistry
        ? await api.put(
            `v1alpha1/registries/helm/${selectedRegistry.metadata.uid}`,
            payload
          )
        : await api.post("v1alpha1/registries/helm", payload);
    } catch (error) {
      const message = selectedRegistry
        ? i18n.t("Something went wrong when editing a helm registry")
        : i18n.t("Something went wrong when creating a helm registry");

      notifications.error({
        message,
        description: error.message,
      });
    }

    if (selectedRegistry) {
      store.dispatch(helmRegistriesActions.initialize(HELM_MODULE));
      historyService.push("/settings/registries/helmregistries");
      return;
    }

    if (!response) {
      return;
    }

    historyService.push("/settings/registries/helmregistries");
    store.dispatch(
      helmRegistriesActions.addItems({
        module: HELM_MODULE,
        items: [
          {
            name: data.name,
            endpoint: data.endpoint,
            uid: response.uid,
          },
        ],
      })
    );
  },
  validator,
});

export function openHelmRegistryModal(uid) {
  return (dispatch) => {
    helmRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          helmRegistryFormActions.submit({ module: HELM_MODULE })
        );
      },
      () => historyService.push("/settings/registries/helmregistries")
    );
  };
}

export function validateHelmRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.helmregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      helmRegistryFormActions.validateForm({
        module: HELM_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = getValidationPayload(formData);
    const promise = api.post("v1alpha1/registries/helm/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your helm registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onHelmChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: HELM_MODULE };
    const isRegistryValidated = getState().registry.isValid;

    if (isRegistryValidated) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(helmRegistryFormActions.onChange(props));
  };
}
