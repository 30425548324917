import React, { useEffect, createContext, useContext } from "react";
import { connect } from "react-redux";

import { getEntity } from "utils/entities";
import {
  List,
  LoadAsYouScroll,
  SearchBar,
  Table,
  EmptyPage,
  EmptyResult,
} from "modules/list/components";
import ListActions from "modules/list/actions";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { FieldWrap, Error } from "components/styled/Field";
import { Select, Tooltip, DatePicker } from "antd";
import Icon from "components/ui/Icon";
import styled from "styled-components";

// TODO: refactor this (it's from ui/Fields)
export function Field(Component) {
  function FieldComponent(
    { required = true, label, validation, ...rest },
    ref
  ) {
    let error = null;
    const { t } = useTranslation();

    if (validation) {
      error = validation[0];
    }

    function renderLabel() {
      if (!label) {
        return null;
      }
      return (
        <label>
          {label} {!required && t("(Optional)")}
        </label>
      );
    }

    return (
      <FieldWrap className={error && `has-${error.status}`}>
        {renderLabel()}
        <Component ref={ref} {...rest} validateStatus={error && error.status} />
        {error && <Error>{error.result}</Error>}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

export const ListContext = createContext({});

export function useList() {
  return useContext(ListContext);
}

export default function createList({
  initialQuery,
  parseItems,
  schema,
  actions,
  ...rest
}) {
  const listActions = new ListActions(
    actions || { ...rest, schema, initialQuery }
  );

  function ListModule({
    listState,
    children,
    initialize,
    fetchItems,
    module,
    ...rest
  }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initialize, [module]);

    if (listState === undefined) {
      return null;
    }

    return (
      <ListContext.Provider
        value={{ module, fetchItems, ...listState, ...rest }}
      >
        {children}
      </ListContext.Provider>
    );
  }

  const selectorsCache = {};

  const addReduxContext = connect(
    (state, ownProps) => {
      let itemsSelector = selectorsCache[ownProps.module];
      if (!itemsSelector) {
        const getListOfItems = createSelector(
          (state) => state.list[ownProps.module]?.items || {},
          (state) => state.list[ownProps.module]?.pages || [],
          (items, pages) => {
            const listOfIds = [...pages].reduce((accumulator, page) => {
              return accumulator.concat(items[page] || []);
            }, []);

            return [...new Set(listOfIds)];
          }
        );
        const getItems = getEntity(getListOfItems, schema);
        itemsSelector = getItems;
        if (parseItems) {
          itemsSelector = createSelector(getItems, parseItems);
        }
        selectorsCache[ownProps.module] = itemsSelector;
      }

      return {
        listState: state.list[ownProps.module],
        items: itemsSelector(state),
      };
    },
    (dispatch, ownProps) => ({
      initialize: () => {
        if (ownProps.preventInitOnMount) {
          return;
        }
        dispatch(
          listActions.initialize(
            ownProps.module,
            initialQuery && initialQuery()
          )
        );
      },
      fetchItems: () => dispatch(listActions.fetchItems(ownProps.module)),
      nextPage: () => dispatch(listActions.nextPage(ownProps.module)),
      changeQuery: (name, value) => {
        dispatch(
          listActions.changeQuery({ name, value, module: ownProps.module })
        );
      },
    })
  );
  return addReduxContext(ListModule);
}

export function connectComponent(Component) {
  return function ConnectedListingComponent(props) {
    const context = useContext(ListContext);
    return <Component {...context} {...props} />;
  };
}

export function connectFiltersComponent(Component) {
  return function ConnectedComponent(props) {
    const name = props.name;
    const context = useContext(ListContext);
    return (
      <Component
        value={context.query[name]}
        onChange={context.changeQuery.bind(null, name)}
        {...props}
      />
    );
  };
}

// TODO: refactor this (it's from ui/Fields)
const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

// TODO move this in a different component
function SelectWithOptions({ options = [], ...rest }) {
  function renderOption(option) {
    return (
      <Select.Option key={option.value} value={option.value}>
        <LabelOption>
          <span>{option.label}</span>{" "}
          {option.description && (
            <Tooltip title={option.description} placement="right">
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </LabelOption>
      </Select.Option>
    );
  }

  return <Select {...rest}>{options.map(renderOption)}</Select>;
}

export const Blocks = {
  List: connectComponent(List),
  LoadAsYouScroll: connectComponent(LoadAsYouScroll),
  Search: connectComponent(SearchBar),
  Table: connectComponent(Table),
  EmptyPage: connectComponent(EmptyPage),
  EmptyResult: connectComponent(EmptyResult),
  FilterFields: {
    CheckboxGroup: connectFiltersComponent(Field(Checkbox.Group)),
    Select: connectFiltersComponent(Field(SelectWithOptions)),
    DatePicker: connectFiltersComponent(Field(DatePicker)),
  },
};
